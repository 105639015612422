import { DropdownOption } from "./util-types";

export interface QuestionListItem {
  [key: string]: string;
  answerType: string;
  question: string;
  questionId: string;
  questionFolder: string;
  questionType: string;
}

export interface QuestionItem {
  [key: string]: number | string | boolean | null;
  answerType: string;
  enterAnswer: string | null;
  fifthAnswer: string | null;
  fifthAnswerLongshotPoints: string | null;
  firstAnswer: string | null;
  firstAnswerLongshotPoints: string | null;
  fourthAnswer: string | null;
  fourthAnswerLongshotPoints: string | null;
  isLivePoll: boolean;
  isOnTheSpot: boolean;
  isLookout: boolean;
  pointValue: string | null;
  question: string;
  questionId: string;
  questionFolder: string;
  questionTime: string;
  questionType: string;
  secondAnswer: string | null;
  secondAnswerLongshotPoints: string | null;
  thirdAnswer: string | null;
  thirdAnswerLongshotPoints: string | null;
}

export const questionItemDefaults = (): QuestionItem => ({
  answerType: "",
  enterAnswer: null,
  fifthAnswer: null,
  fifthAnswerLongshotPoints: null,
  firstAnswer: null,
  firstAnswerLongshotPoints: null,
  fourthAnswer: null,
  fourthAnswerLongshotPoints: null,
  isLivePoll: false,
  isOnTheSpot: false,
  isLookout: false,
  pointValue: "",
  question: "",
  questionId: "",
  questionFolder: "",
  questionTime: "",
  questionType: "",
  secondAnswer: null,
  secondAnswerLongshotPoints: null,
  thirdAnswer: null,
  thirdAnswerLongshotPoints: null,
});

export interface FolderItem {
  [key: string]: string;
  folderId: string;
  folderName: string;
}

export interface QuestionConstants {
  allFolders: string;
}

export const allFoldersOption: DropdownOption = {
  label: "All Folders",
  value: "All Folders",
};
export const recallQuestionType = "Recall";
export const predictiveQuestionType = "Predictive";
export const triviaQuestionType = "Trivia";
export const questionTypes: string[] = [
  recallQuestionType,
  predictiveQuestionType,
  triviaQuestionType,
];
export const enterAnswerAnswerType = "Enter Answer";
export const longshotAnswerType = "Longshot";
export const multipleChoiceAnswerType = "Multiple Choice";
export const answerTypes: string[] = [
  enterAnswerAnswerType,
  longshotAnswerType,
  multipleChoiceAnswerType,
];
export const firstAnswer = "firstAnswer";
export const secondAnswer = "secondAnswer";
export const thirdAnswer = "thirdAnswer";
export const fourthAnswer = "fourthAnswer";
export const fifthAnswer = "fifthAnswer";
export const firstAnswerLongshotPoints = "firstAnswerLongshotPoints";
export const secondAnswerLongshotPoints = "secondAnswerLongshotPoints";
export const thirdAnswerLongshotPoints = "thirdAnswerLongshotPoints";
export const fourthAnswerLongshotPoints = "fourthAnswerLongshotPoints";
export const fifthAnswerLongshotPoints = "fifthAnswerLongshotPoints";
export const answerNumberPairs: string[][] = [
  [firstAnswer, firstAnswerLongshotPoints],
  [secondAnswer, secondAnswerLongshotPoints],
  [thirdAnswer, thirdAnswerLongshotPoints],
  [fourthAnswer, fourthAnswerLongshotPoints],
  [fifthAnswer, fifthAnswerLongshotPoints],
];
export const questionPointValues: string[] = ["5", "10", "15", "20", "25"];
export const questionTimeValues: string[] = [
  "10 sec",
  "20 sec",
  "30 sec",
  "45 sec",
  "60 sec",
  "90 sec",
  "600 sec",
];
