import React from "react";
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";

interface Login {
  email: string;
  password: string;
}

const loginDefaults = (): Login => ({
  email: "",
  password: "",
});

const validationSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
});

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.typography.pxToRem(20),
  },
  button: {
    marginTop: theme.typography.pxToRem(25),
    height: theme.typography.pxToRem(50),
  },
}));

interface Props {
  onLogin: (login: Login) => void;
}

export const LoginForm: React.FC<Props> = ({ onLogin }) => {
  const classes = useStyles();
  const formik = useFormik<Login>({
    initialValues: loginDefaults(),
    validationSchema,
    onSubmit: onLogin,
  });
  const { handleSubmit } = formik;
  return (
    <Box width={"30%"}>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" textAlign="center">
          <Typography variant="h4" color="secondary">
            Sign In
          </Typography>
          <TextField
            className={classes.input}
            name="email"
            label="Email*"
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={!!formik.touched.email && !!formik.errors.email}
            helperText={formik.errors.email}
          />
          <TextField
            className={classes.input}
            name="password"
            type="password"
            label="Password*"
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={!!formik.touched.password && !!formik.errors.password}
            helperText={formik.errors.password}
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
          >
            Sign In
          </Button>
        </Box>
      </form>
    </Box>
  );
};
