export interface GameListItem {
  [key: string]: string;
  startTimestamp: string;
  gameTeam: string;
  gameTeamName: string;
  startDisplayTime: string;
  startDisplayDate: string;
}

export interface GameItem {
  [key: string]: number | {} | string | boolean | null | File | undefined;
  gameAdmin: string;
  gameDate: string | null;
  gameDescription: string;
  gameFolder: string;
  gameId: string;
  gameTeam: string;
  gameTeamId: string;
  isDemo: boolean;
  location: string;
  openTime: string | null;
  openTimestamp: string;
  startTime: string | null;
  startTimestamp: string;
  previewImageFile: File | null;
  secondaryImageFile: File | null;
  gameBackgroundImageFile: File | null;
  previewImageUrl: string | null;
  secondaryImageUrl: string | null;
  gameBackgroundImageUrl: string | null;
  rewards: string[];
  homeTeam: string | null;
  awayTeam: string | null;
  title: string | null;
  rewardsToAdd: string | null;
}

export const gameItemDefaults = (): GameItem => ({
  gameAdmin: "",
  gameDate: "",
  gameDescription: "",
  gameFolder: "",
  gameId: "",
  gameTeam: "",
  gameTeamId: "",
  isDemo: false,
  location: "",
  openTime: "",
  startTime: "",
  openTimestamp: "",
  startTimestamp: "",
  previewImageFile: null,
  secondaryImageFile: null,
  gameBackgroundImageFile: null,
  previewImageUrl: null,
  secondaryImageUrl: null,
  gameBackgroundImageUrl: null,
  rewards: ["", "", ""],
  homeTeam: null,
  awayTeam: null,
  title: null,
  rewardsToAdd: null,
});

export const pregameSendMode = "pregame";
export const ingameSendMode = "ingame";
export const btcSendMode = "btc";
export const ttrSendMode = "ttr";
