import { Button, createMuiTheme, Link, ThemeProvider } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { useStore } from "../helpers/use-store";
import { getColorStringFromKey } from "../types/util-types";
import { dashboardColor1, dashboardColor2 } from "../helpers/constants";
import { CPThemeProvider } from "./CPThemeProvider";

interface Props {
  to: string;
  color?: string;
  variant?: "text" | "outlined" | "contained";
  fullWidth?: boolean;
}

export const ButtonLink: React.FC<Props> = ({
  to,
  color,
  variant = "contained",
  fullWidth,
  children,
}) => {
  if (!color) color = dashboardColor2;
  return (
    <CPThemeProvider
      color={color}
      child={
        <Link
          style={{ width: "100%" }}
          color="inherit"
          underline="none"
          to={to}
          component={RouterLink}
        >
          <Button variant={variant} color={"primary"} fullWidth={fullWidth}>
            {children}
          </Button>
        </Link>
      }
    ></CPThemeProvider>
  );
};
