import { makeAutoObservable } from "mobx";
import { RewardItem } from "../../types/rewards-types";
import { DropdownOption } from "../../types/util-types";
import { FirebaseConnector } from "../connectors/firebase-connector";

export class RewardsDomainStore {
  private _rewards: RewardItem[] = [];
  private _rewardOptions: DropdownOption[] = [];

  constructor(public firebaseConnector: FirebaseConnector) {
    makeAutoObservable(this);
  }

  get rewards() {
    return this._rewards;
  }

  get rewardsOptions() {
    this._rewardOptions = this._rewards.map((reward) => {
      return {
        label: reward.title,
        value: reward.rewardId,
      };
    });
    return this._rewardOptions;
  }

  loadRewards = async (reload: boolean = false) => {
    if (!this._rewards.length || reload) {
      this._rewards = await this.firebaseConnector.loadRewards();
    }
  };
}
