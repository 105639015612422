import moment from "moment";
import { isInteger } from "./number-helpers";

export const seasons = ["2019/2020", "2020/2021"];

export const formatDate = (date: string): string => {
  return moment(date).format("MM/DD/YYYY");
};

export const formatTime = (date: string): string => {
  return moment(date).format("hh:mm A");
};

export const dateFormatString = () => {
  return "YYYY_MM_DD";
};

export const monthFormatString = () => {
  return "YYYY_MM";
};

export const parseDateKeyAndFormat = (
  date: string,
  newFormat: string,
): string => {
  let dateMoment;
  if (date.length === dateFormatString().length) {
    dateMoment = moment(date, dateFormatString());
  } else if (date.length === monthFormatString().length) {
    dateMoment = moment(date, monthFormatString());
  } else {
    return "N/A";
  }
  return dateMoment.format(newFormat);
};

export const formatDuration = (doubleInHour: number) => {
  const min = Math.abs(Math.trunc(doubleInHour));
  const sec = (Math.abs(doubleInHour) % 1) * 60;
  const hours = ("" + Math.floor(min / 60)).padStart(2, "0");
  const minutes = ("" + Math.floor(min % 60)).padStart(2, "0");
  const seconds = ("" + Math.floor(sec)).padStart(2, "0");
  return `${doubleInHour < 0 ? "-" : ""}${hours}:${minutes}:${seconds}`;
};

export const localizedMoment = (timestamp: string) => {
  let offset = "-05:00";
  return moment(timestamp).utcOffset(offset);
};

export const noTimezoneMoment = (timestamp: string) => {
  let offset = "00:00";
  return moment.utc(timestamp).utcOffset(offset);
};

export const noTimezoneNow = () => {
  let offset = "00:00";
  return moment.utc().utcOffset(offset);
};

export const getMomentFromYMD = (yMD: string) => {
  let dateInfoList = yMD.split("_");
  if (dateInfoList.length !== 3) return null;
  let year = parseInt(`${dateInfoList[0]}`);
  let month = parseInt(`${dateInfoList[1]}`);
  let date = parseInt(`${dateInfoList[2]}`);
  let result = moment();
  result.set("year", year);
  result.set("month", month);
  result.set("date", date);
  return result;
};

export const extractDateForSorting = (input: string) => {
  if (!input.includes("/")) return;
  let splitList = input.split(" ");
  let result;
  splitList.forEach((element: string) => {
    // console.log(`split: ${element}`);
    let splitListSplitList = element.split("/");
    if (splitListSplitList.length > 1) {
      let year = moment().year();
      let month = moment().month();
      let date = moment().date();
      for (let i = 0; i < splitListSplitList.length; i++) {
        let splitListElement = splitListSplitList[i];
        // console.log(`split split: ${splitListElement}`)
        let splitListInt =
          isInteger(splitListElement) && parseInt(splitListElement);
        if (!splitListInt) {
          // console.log(`${splitListElement} is not a number`)
          return;
        }
        if (i === 0) month = splitListInt;
        if (i === 1) date = splitListInt;
        if (i === 2) year = splitListInt;
      }
      let yMD = `${year}_${month}_${date}`;
      // console.log(`getMonthFromYMD: ${yMD}`)
      result = getMomentFromYMD(yMD);
    }
  });
  return result;
};
