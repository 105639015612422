import { Box, Typography } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import React from "react";
import { SortDirection } from "react-virtualized";
import { SortableTable } from "../../../components/SortableTable";
import { TableContainer } from "../../../components/TableContainer";
import { PickupOverview } from "../../../types/order-types";

interface Props {
  pickups: PickupOverview[];
  onRowClick?: (pickup: PickupOverview) => void;
  onDelete?: any;
}

export const PickupsByTeamTable: React.FC<Props> = ({
  pickups,
  onRowClick,
  onDelete,
}) => {
  return (
    <TableContainer>
      <SortableTable
        // @ts-ignore
        onRowClick={onRowClick}
        defaultSort="formattedPickupDate"
        defaultSortDirection={SortDirection.DESC}
        height="100vh"
        // csvKeys={["pickupDate", "pickupStart", "pickupEnd", "formattedPickupDate", "pickupLocation",]}
        columns={[
          {
            dataKey: "formattedPickupDate",
            label: "Date",
            flexGrow: 3,
            width: 40,
          },
          {
            dataKey: "pickupStart",
            label: "Start",
            flexGrow: 4,
            width: 70,
          },
          {
            dataKey: "pickupEnd",
            label: "End",
            flexGrow: 4,
            width: 100,
          },
          {
            dataKey: "pickupLocation",
            label: "Location",
            flexGrow: 4,
            width: 100,
          },
          {
            dataKey: "delete",
            label: "",
            flexGrow: 1,
            width: 30,
            disableClickEventBubbling: true,
            cellRender: (props) => {
              if (!onDelete) return <></>;
              return (
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className="delete-icon"
                >
                  <Typography color="secondary">
                    <DeleteOutline
                      style={{ cursor: "pointer" }}
                      onClick={(event) => {
                        onDelete(props);
                        event.stopPropagation();
                      }}
                    />
                  </Typography>
                </Box>
              );
            },
          },
        ]}
        rows={pickups}
        // withChevron
      />
    </TableContainer>
  );
};
