import { makeAutoObservable } from "mobx";
import moment from "moment";
import { stadiumRoutes } from "../../app-routes";
import { verifyAllTruthy } from "../../helpers/generic-helpers";
import { Loading, loadingFunction } from "../../helpers/loading";
import { DailyWeeklyMonthlyData } from "../../types/chart-types";
import {
  StadiumOverviewGame,
  StadiumOverviewResponse,
  stadiumOverviewStatsDefaults,
} from "../../types/stadiums-types";
import { exportAsCSVByRow } from "../../types/util-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class StadiumOverviewPageStore {
  private teamId: string = "";
  private stats: StadiumOverviewResponse = stadiumOverviewStatsDefaults();
  // public users: StadiumOverviewUser[] = [];
  // public games: StadiumOverviewGame[] = [];
  private userDetailsByUserId: any = {};
  public gameOverview: any = {};
  public loading: Loading = Loading.NOT_LOADED;
  public teamDetails: any = {};
  public userSpecificDailyEngagement: any = {};

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get dataBlock() {
    const { allUsers } = this.stats;
    return {
      itemsPerRow: 1,
      items: [
        {
          title: "Total Members Engaged",
          value: allUsers.length,
          length: 2,
          onDownload: async () => {
            let csvKeys = ["firstName", "lastName", "username", "email"];
            let rows: any[] = [];
            allUsers.forEach((userId) => {
              rows.push(this.userDetailsByUserId[userId]);
            });
            exportAsCSVByRow(csvKeys, rows, "Total Enrolled Members");
          },
        },
      ],
    };
  }

  get userDemographics() {
    return this.stats.demographicsData;
  }

  get dailyWeeklyMonthlyEngagementData(): DailyWeeklyMonthlyData[] {
    return [
      {
        daily: this.stats.dailyEngagement,
        weekly: {},
        monthly: {},
      },
    ];
  }

  get gamesData() {
    let games: any = this.stats.games || {};
    let result: any[] = [];
    Object.keys(games).forEach((gameId) => {
      let gameDetails = games[gameId];
      let subtext = gameDetails["subtext"];
      let members = gameDetails["members"];
      if (!members) return;
      let date = moment(gameDetails["date"]).format("MM/DD/YYYY");
      let newData = {
        subtext,
        date,
        members,
        gameId,
      };
      result.push(newData);
    });
    return result;
  }

  get mostEngagedMembers() {
    let userDetailsByUserId = this.userDetailsByUserId;
    let engagedMembers: any = this.stats.engagedMembers || {};
    let result: any[] = [];
    Object.keys(engagedMembers).forEach((userId) => {
      let userDetails = userDetailsByUserId[userId];

      if (!verifyAllTruthy({ userDetails, engagedMembers })) return;
      let fullName = userDetails["fullName"];
      let nameAndAvatar = [
        userDetails["fullName"] || "",
        userDetails["avatar"],
        this.teamId,
      ];
      let nQuestionsAnswered = engagedMembers[userId];
      let newData = {
        fullName,
        nameAndAvatar,
        nQuestionsAnswered,
      };
      result.push(newData);
    });
    return result;
  }

  onGameRowClick = (game: StadiumOverviewGame) => {
    const [, , stadiumName] =
      this.uiStore.routerStore.location.pathname.split("/") || [];
    this.uiStore.routerStore.push(
      stadiumRoutes.stadiumGameOverview(stadiumName, game.id)
    );
  };

  getAllUserInfo = async () => {
    if (this.userDetailsByUserId) return this.userDetailsByUserId;
    this.userDetailsByUserId =
      await this.firebaseConnector.getDatabaseObjAtPath(
        `userDetailsByUserIdByTeam/${this.teamId}`
      );
    return this.userDetailsByUserId;
  };

  loadOverview = async (teamId: string) => {
    this.teamId = teamId;
    console.log(`loadUserOverview`);
    loadingFunction(
      this,
      async () => {
        this.teamDetails = await this.firebaseConnector.loadTeamDetails(teamId);
        this.userDetailsByUserId =
          await this.firebaseConnector.loadUsersByTeamIdAsMap(teamId);
        let gameOverview = await this.firebaseConnector.getDatabaseObjAtPath(
          `tracking/gameOverviewByTeam/${teamId}`
        );
        this.stats.dailyEngagement = gameOverview["dailyUsersCount"];
        this.stats.allUsers = gameOverview["allUsers"] || [];
        this.stats.demographicsData = gameOverview["demographicsData"] || {};
        this.stats.engagedMembers = gameOverview["userQuestionsAnswered"] || {};
        this.stats.games = gameOverview["gameDetails"] || {};
        this.userSpecificDailyEngagement = gameOverview["dailyUsers"] || {};
      },
      this.uiStore.notifications
    );

    // try {
    //   this.loading = Loading.LOADING;
    //   const stadiumId = stadiumName; // this.uiStore.session.user?.stadiumId;

    //   if (!stadiumId) throw new Error("Error: User id is not specified!");

    //   const years = selectedSeason.split("/");
    //   // const [stats, users, games] = await Promise.all([
    //   //   await API.post<{ data: StadiumOverviewResponse }>("/stadiumDashboard", { data: { stadiumId, years } }),
    //   //   await API.post<{ data: { users: StadiumOverviewUser[] } }>("/stadiumDashboardUsers", {
    //   //     data: { stadiumId, years },
    //   //   }),
    //   //   await API.post<{ data: { games: StadiumOverviewGame[] } }>("/stadiumDashboardGames", {
    //   //     data: { stadiumId, years },
    //   //   }),
    //   // ]);
    //   // this.stats = stats.data.data;
    //   // this.users = users.data.data.users;
    //   // this.games = games.data.data.games;
    //   this.loading = Loading.SUCCESS;
    // } catch (error: any) {
    //   this.uiStore.notifications.showError(error.message);
    //   this.loading = Loading.ERROR;
    // }
  };
}
