import React from "react";
import { Backdrop, Box, Paper, List, ListItem } from "@material-ui/core";
import { useBackdrop } from "../hooks/useBackdrop"; // Adjust the import path based on your file structure
import {
  getColorStringFromKey,
  getTableCellColorFromRowIndex,
} from "../types/util-types";
import { cpBoldSubHeaderStyle, dashboardColor1 } from "../helpers/constants";
import { CPCloseButton } from "./CPCloseButton";
import { CPDownloadButton } from "./CPDownloadButton";
import { CPSearchBar } from "./CPSearchBar";
import { parseDateKeyAndFormat } from "../helpers/time-helpers";
import { verifyAllTruthy } from "../helpers/generic-helpers";
import { makeStyles } from "@material-ui/core/styles";
import { useStore } from "../helpers/use-store";
import { usersRoutes } from "../app-routes";
import { useParams } from "react-router";

// Additional styles or components can be imported here

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface Props {
  userDetailsByUserId: any;
  userIds: string[];
  barBackdropTitle: string;
  barBackdropOptions: any;
  barBackdropDateKey: string;
  barBackdropSearchTerm: string;
  setBarBackdropSearchTerm: (searchTerm: string) => void;
  barBackdropOpen: boolean;
  setBarBackdropOpen: (open: boolean) => void;
  barBackdropOnDownload: any;
}

const CPBackdrop: React.FC<Props> = ({
  userDetailsByUserId,
  userIds,
  barBackdropTitle,
  barBackdropOptions,
  barBackdropDateKey,
  barBackdropSearchTerm,
  setBarBackdropOpen,
  barBackdropOpen,
  barBackdropOnDownload,
  setBarBackdropSearchTerm,
}) => {
  const classes = useStyles();
  const { routerStore, session } = useStore().ui;
  const { teamId } = useParams<{ teamId: string }>();

  // Function to handle the backdrop click, if needed
  const handleBackdropClick = () => {
    setBarBackdropOpen(false);
  };

  const usersMatchingUserIdList = (
    userIdList: string[],
    searchTerm: string
  ) => {
    let result: any[] = [];
    if (!userIdList) return result;
    userIdList.forEach((userId) => {
      let userDetails = (userDetailsByUserId || {})[userId];
      if (!verifyAllTruthy({ userDetails })) {
        return;
      }
      let fullName = userDetails["fullName"] || "";
      if (!fullName.toLowerCase().includes(searchTerm.toLowerCase())) return;
      let newData = {
        fullName,
        userId,
      };
      result.push(newData);
    });
    return result;
  };

  return (
    <Backdrop
      className={classes.backdrop}
      open={barBackdropOpen}
      onClick={() => {
        setBarBackdropOpen(false);
      }}
    >
      <Box
        width={"500px"}
        height="360px"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Paper
          style={{
            height: "100%",
            width: "100%",
            padding: "18px 10px 24px 24px",
          }}
        >
          <Box display={"flex"} alignItems="center">
            <Box style={cpBoldSubHeaderStyle}>{`${barBackdropTitle}:`}</Box>
            <Box ml={"2px"}>{`${parseDateKeyAndFormat(
              barBackdropDateKey,
              "MM/DD/YY"
            )}`}</Box>
            <Box flex={1} />
            <Box width="185px">
              <CPSearchBar
                label="Search for member..."
                onSearch={(e: any) => setBarBackdropSearchTerm(e.target.value)}
                value={barBackdropSearchTerm}
              />
            </Box>
            <Box ml={"12px"}>
              <CPDownloadButton
                onDownload={() => {
                  if (barBackdropOnDownload != null) {
                    console.log(`calling now with options`, barBackdropOptions);
                    barBackdropOnDownload(barBackdropOptions);
                  }
                }}
              />
            </Box>
            <Box>
              <CPCloseButton
                onClick={() => {
                  setBarBackdropOpen(false);
                }}
              />
            </Box>
          </Box>
          <Box m={"10px 0px 10px 40px"} style={cpBoldSubHeaderStyle}>
            Name
          </Box>
          <List
            style={{
              height: "250px",
              width: "100%",
              overflow: "auto",
              paddingRight: "16px",
            }}
          >
            {Object.values(
              usersMatchingUserIdList(userIds, barBackdropSearchTerm)
            ).map((userDetails, index) => {
              const rowBackground = getTableCellColorFromRowIndex(index);
              let userId = userDetails["userId"];
              let fullName = userDetails["fullName"];
              return (
                <ListItem
                  key={userId}
                  style={{
                    backgroundColor: rowBackground,
                    borderRadius: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (userId)
                      routerStore.push(
                        usersRoutes.userOverview(userId, teamId)
                      );
                  }}
                >
                  <Box display={"flex"} alignItems="center">
                    <Box
                      width={"2px"}
                      height="16px"
                      bgcolor={getColorStringFromKey(dashboardColor1, session)}
                      margin="10px 10px 13px 24px"
                    ></Box>
                    <Box>{fullName}</Box>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Box>
    </Backdrop>
  );
};

export default CPBackdrop;
