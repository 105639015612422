import React from "react";
import { DateCell } from "../../../components/DateCell";
import { PaperTableContainer } from "../../../components/PaperTableContainer";
import { SortableTable } from "../../../components/SortableTable";
import { SponsorOverviewGame } from "../../../types/sponsors-types";

interface Props {
  games: SponsorOverviewGame[];
  onRowClick?: (game: SponsorOverviewGame) => void;
}

export const OverviewGamesTable: React.FC<Props> = ({ games, onRowClick }) => {
  return (
    <PaperTableContainer title="Total Impressions">
      <SortableTable
        columns={[
          {
            dataKey: "name",
            label: "Game",
            flexGrow: 1,
            width: 100,
            cellRender: (row) => (
              <span>{`${row.name}`.replace("@", "vs")}</span>
            ),
          },
          {
            dataKey: "id",
            label: "Date",
            flexGrow: 1,
            width: 100,
            cellRender: (row) => <DateCell date={`${row.id}`} />,
          },
          {
            dataKey: "category",
            label: "Category",
            flexGrow: 1,
            width: 100,
          },
          {
            dataKey: "nTotalImp",
            label: "Total Impressions",
            flexGrow: 1,
            width: 100,
            // cellRender: (row) => row.nTotalImp
          },
        ]}
        rows={games}
        // @ts-ignore
        onRowClick={onRowClick}
      />
    </PaperTableContainer>
  );
};
