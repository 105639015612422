import { Box } from "@material-ui/core";
import { months } from "moment";
import React from "react";
import { SortableTable } from "../../../components/SortableTable";
import { TableContainer } from "../../../components/TableContainer";
import { getMomentFromYMD } from "../../../helpers/time-helpers";

interface Props {
  transactions: any[];
  height: string;
}

export const UserExpiringTransactionsTable: React.FC<Props> = ({
  transactions,
  height,
}) => {
  let body =
    transactions.length === 0 ? (
      <Box height={50}> No Expiring Transactions </Box>
    ) : (
      <SortableTable
        height={height}
        title={`Expiring Soon`}
        columns={[
          // {
          //   dataKey: "description",
          //   label: "Description",
          //   width: 80,
          //   flexGrow: 3,
          // },
          {
            dataKey: "points",
            label: "Points",
            flexGrow: 3,
            width: 40,
          },
          {
            dataKey: "date",
            label: "Expiration Date",
            flexGrow: 1,
            width: 40,
            //TODO watch out, these may sort unexpectedly

            cellRender: (props) => {
              let expirationMoment = getMomentFromYMD(`${props.date}`);
              return (
                <div>
                  {`${months()[expirationMoment?.month() || 0]} 1st ${expirationMoment?.year()}`}
                </div>
              );
            },
          },
        ]}
        rows={transactions}
        // @ts-ignore
      />
    );
  return <TableContainer>{body}</TableContainer>;
};
