import { makeAutoObservable } from "mobx";
import { Loading } from "../../helpers/loading";
import { formatDuration } from "../../helpers/time-helpers";
import { UserOverview, userOverviewDefaults } from "../../types/user-types";
import { API } from "../connectors/api-connector";
import { UiStore } from "./ui-store";
import { FirebaseConnector } from "../connectors/firebase-connector";

export class UserOverviewPageOldStore {
  public userOverview: UserOverview = userOverviewDefaults();
  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get firstDataBlock() {
    const { cash, point, gift } = this.userOverview.reward;
    return {
      items: [
        { title: "Cash Won", value: `$${cash}` },
        { title: "Other Reward", value: point + gift },
      ],
    };
  }

  get secondDataBlock() {
    return {
      items: [
        {
          title: "Engagement Time",
          value: formatDuration(this.userOverview.engTime),
        },
        {
          title: "Exit time",
          value: formatDuration(this.userOverview.exitTime),
        },
      ],
    };
  }

  loadUserOverview = async (params: {
    userId: string;
    sponsorName: string;
    stadiumName: string;
    gameTimestamp?: string;
  }) => {
    try {
      this.loading = Loading.LOADING;
      const { data } = await API.post<{ data: UserOverview }>("/userDetail", {
        data: {
          userId: params.userId,
          gameId: params.gameTimestamp,
          sponsorId: params.sponsorName,
          stadiumId: params.stadiumName,
        },
      });
      this.userOverview = { ...this.userOverview, ...data.data };
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
