import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const usePopoverStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(1),
  },
}));

interface Props {
  className?: string;
  id: string;
  open: boolean;
  anchorEl: null | any;
}

export const Popover: React.FC<Props> = ({
  className,
  id,
  open,
  anchorEl,
  children,
}) => {
  const classes = usePopoverStyles();
  return (
    <Popper
      placement="top"
      id={id}
      open={open}
      anchorEl={anchorEl}
      className={className}
    >
      <Paper>
        <Typography className={classes.typography}>{children}</Typography>
      </Paper>
    </Popper>
  );
};
