import { makeAutoObservable } from "mobx";
import moment from "moment";
import {
  allOption,
  allOptionValue,
  infinitySymbol,
} from "../../helpers/constants";
import { Loading, loadingFunction } from "../../helpers/loading";
import { intValOrZero } from "../../helpers/number-helpers";
import { RowData } from "../../types/data-table-types";
import {
  MarketplaceAuctionStatsResponse,
  MarketplaceProductStatsResponse,
  MarketplaceSweepstakeStatsResponse,
  marketplaceAuctionStatsDefaults,
  marketplaceProductStatsDefaults,
  marketplaceSweepstakeStatsDefaults,
} from "../../types/marketplace-types";
import { getAttributeFromObject } from "../../types/util-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class MarketplaceOverviewPageStore {
  //Products
  private marketplaceProductStats: MarketplaceProductStatsResponse =
    marketplaceProductStatsDefaults();
  private marketplaceSweepstakeStats: MarketplaceSweepstakeStatsResponse =
    marketplaceSweepstakeStatsDefaults();
  private marketplaceAuctionStats: MarketplaceAuctionStatsResponse =
    marketplaceAuctionStatsDefaults();
  public marketplaceProducts: any = {};
  public marketplaceCategories: any = {};
  public marketplaceCollections: any = {};
  public searchInput: string = "";
  public marketplaceProductTypeOptions: any = [];
  public marketplaceSelectedProductTypeFilter: any = allOption;
  //Sweepstakes
  public marketplaceSweepstakes: any = {};
  public liveMarketplaceSweepstakes: RowData[] = [];
  public endedMarketplaceSweepstakes: RowData[] = [];
  //Auctions
  public marketplaceAuctions: any = {};
  public liveMarketplaceAuctions: RowData[] = [];
  public endedMarketplaceAuctions: RowData[] = [];

  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  searchProduct = (input: string) => {
    this.searchInput = input;
  };

  changeFilter = (filter: any) => {
    this.marketplaceSelectedProductTypeFilter = filter;
  };

  get filteredMarketplaceProducts() {
    let filteredProducts: any = {};
    Object.keys(this.marketplaceProducts || {}).forEach((productId: any) => {
      let product = this.marketplaceProducts[productId];
      if (!product["enabled"]) return;
      let isSearchedFor =
        !this.searchInput ||
        product["name"].toLowerCase().includes(this.searchInput.toLowerCase());
      if (!isSearchedFor) return;
      let productType = getAttributeFromObject(`productType/value`, product);
      let productTypeMatches =
        this.marketplaceSelectedProductTypeFilter["value"] === allOptionValue ||
        productType == this.marketplaceSelectedProductTypeFilter["value"];
      if (!productTypeMatches) return;
      filteredProducts[productId] = product;
    });
    return filteredProducts;
  }

  get productsDataBlock() {
    const { liveProducts, liveVariants, liveCategories, liveCollections } =
      this.marketplaceProductStats;
    return {
      itemsPerRow: 4,
      items: [
        {
          title: "Live Products",
          value: liveProducts,
        },
        {
          title: "Live Variants",
          value: liveVariants,
        },
        {
          title: "Live Categories",
          value: liveCategories,
        },
        {
          title: "Live Collections",
          value: liveCollections,
        },
      ],
    };
  }

  get sweepstakesDataBlock() {
    const { sweepstakesConducted, ticketsRedeemed } =
      this.marketplaceSweepstakeStats;
    return {
      itemsPerRow: 2,
      items: [
        { title: "Total Sweepstakes Conducted", value: sweepstakesConducted },
        { title: "Total Tickets Redeemed", value: ticketsRedeemed },
      ],
    };
  }

  get auctionsDataBlock() {
    const { auctionsConducted, nBids } = this.marketplaceAuctionStats;
    return {
      itemsPerRow: 2,
      items: [
        { title: "Total Auctions Conducted", value: auctionsConducted },
        { title: "Total Bids", value: nBids },
      ],
    };
  }

  resetVariables = () => {
    this.marketplaceProductStats = marketplaceProductStatsDefaults();
    this.marketplaceSweepstakeStats = marketplaceSweepstakeStatsDefaults();
    this.marketplaceAuctionStats = marketplaceAuctionStatsDefaults();
    this.liveMarketplaceSweepstakes = [];
    this.endedMarketplaceSweepstakes = [];
    this.liveMarketplaceAuctions = [];
    this.endedMarketplaceAuctions = [];
    this.loading = Loading.NOT_LOADED;
    this.marketplaceProductTypeOptions = [];
  };

  loadOverview = async (teamId: string) => {
    this.resetVariables();
    loadingFunction(
      this,
      async () => {
        //Products
        this.marketplaceProducts =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `marketplaceProductsByTeam/${teamId}`
          );
        this.marketplaceCategories =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `marketplaceCategoriesByTeam/${teamId}`
          );
        this.marketplaceCollections =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `marketplaceCollectionsByTeam/${teamId}`
          );
        Object.keys(this.marketplaceProducts || {}).forEach(
          (productId: any) => {
            let product = this.marketplaceProducts[productId];
            if (!product["enabled"]) return;
            let currentQuantity: any = 0;
            let totalRedemptions = 0;
            let totalStartingQuantity: any = 0;
            let variantQtyRedeemed = product["variantQtyRedeemed"] || {};
            this.marketplaceProductStats.liveProducts += 1;
            Object.keys(product["variants"] || {}).forEach((variantId: any) => {
              let variant = product["variants"][variantId];
              if (variant["enabled"]) {
                this.marketplaceProductStats.liveVariants += 1;
              }
              let variantStartingQuantity = variant["qty"];
              let variantRemaining;
              if (variantStartingQuantity == "") {
                totalStartingQuantity = infinitySymbol;
                variantStartingQuantity = infinitySymbol;
                variantRemaining = infinitySymbol;
              } else {
                variantRemaining =
                  intValOrZero(variantStartingQuantity) -
                  intValOrZero(variantQtyRedeemed[variantId]);
              }
              //product logistics
              let quantityRedeemed = variantQtyRedeemed[variantId];
              totalRedemptions += intValOrZero(quantityRedeemed);
              if (totalStartingQuantity != infinitySymbol) {
                totalStartingQuantity += intValOrZero(variantStartingQuantity);
              }
            });
            if (totalStartingQuantity != infinitySymbol) {
              currentQuantity = totalStartingQuantity - totalRedemptions;
            } else {
              currentQuantity = infinitySymbol;
            }
            product["currentQuantity"] = currentQuantity;
            product["startingQuantity"] = totalStartingQuantity;
            product["totalRedemptions"] = totalRedemptions;
          }
        );
        let productTypes = await this.firebaseConnector.getDatabaseObjAtPath(
          `marketplaceProductTypes`
        );
        Object.keys(productTypes).forEach((index) => {
          let label = getAttributeFromObject(`${index}/label`, productTypes);
          let value = getAttributeFromObject(`${index}/value`, productTypes);
          let visibility =
            getAttributeFromObject(`${index}/visibility`, productTypes) == true;
          if (!visibility) return;
          this.marketplaceProductTypeOptions.push({
            label,
            value,
          });
        });
        Object.keys(this.marketplaceCategories || {}).forEach(
          (categoryId: any) => {
            let category = this.marketplaceCategories[categoryId];
            if (!category["visible"]) return;
            this.marketplaceProductStats.liveCategories += 1;
          }
        );
        Object.keys(this.marketplaceCollections || {}).forEach(
          (collectionId: any) => {
            let collection = this.marketplaceCollections[collectionId];
            if (!collection["visible"]) return;
            this.marketplaceProductStats.liveCollections += 1;
          }
        );
        //Sweepstakes
        this.marketplaceSweepstakes =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `marketplaceSweepstakesByTeam/${teamId}`
          );
        let ticketCountsBySweepstakeId: any = {};
        let ticketCountsByUserIdBySweepstakeId =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `sweepstakeTicketCountByTeamBySweepstakeIdByUser/${teamId}`
          )) || {};

        Object.keys(ticketCountsByUserIdBySweepstakeId).forEach(
          (sweepstakeId) => {
            let ticketCountsByUserId =
              ticketCountsByUserIdBySweepstakeId[sweepstakeId] || {};
            Object.keys(ticketCountsByUserId).forEach((userId) => {
              let val = intValOrZero(`${ticketCountsByUserId[userId]}`);
              let currentVal = ticketCountsBySweepstakeId[sweepstakeId] || 0;
              ticketCountsBySweepstakeId[sweepstakeId] = currentVal + val;
            });
          }
        );
        Object.keys(this.marketplaceSweepstakes || {}).forEach(
          (sweepstakeId: any) => {
            let sweepstake = this.marketplaceSweepstakes[sweepstakeId];
            let ticketsRedeemed = ticketCountsBySweepstakeId[sweepstakeId] || 0;
            this.marketplaceSweepstakeStats.ticketsRedeemed += ticketsRedeemed;
            sweepstake["count"] = ticketsRedeemed;
            this.marketplaceSweepstakeStats.sweepstakesConducted += 1;
            let productId = sweepstake["productId"];
            sweepstake["imageUrl"] = getAttributeFromObject(
              `${productId}/imageUrls/0`,
              this.marketplaceProducts
            );
            sweepstake["id"] = sweepstakeId;
            // let imageUrl = this.marketplaceProducts[productId]["imageUrl"];
            let expirationDate = moment(sweepstake["expirationDateTime"]);
            let isEnded = expirationDate.isBefore(moment());
            if (isEnded) {
              this.endedMarketplaceSweepstakes.push(sweepstake);
            } else {
              this.liveMarketplaceSweepstakes.push(sweepstake);
            }
          }
        );
        //Auctions
        let auctionBids =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `auctionBidsByTeam/${teamId}`
          )) || {};
        this.marketplaceAuctions =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `auctionsByTeam/${teamId}`
          );
        Object.keys(this.marketplaceAuctions || {}).forEach(
          (auctionId: any) => {
            let auction = this.marketplaceAuctions[auctionId];
            let nBids = Object.keys(auctionBids[auctionId] || {}).length;
            this.marketplaceAuctionStats.nBids += nBids;
            auction["count"] = nBids;
            this.marketplaceAuctionStats.auctionsConducted += 1;
            let productId = auction["productId"];
            auction["imageUrl"] = getAttributeFromObject(
              `${productId}/imageUrls/0`,
              this.marketplaceProducts
            );
            auction["id"] = auctionId;
            // let imageUrl = this.marketplaceProducts[productId]["imageUrl"];
            let expirationDate = moment(auction["expirationDateTime"]);
            let isEnded = expirationDate.isBefore(moment());
            if (isEnded) {
              this.endedMarketplaceAuctions.push(auction);
            } else {
              this.liveMarketplaceAuctions.push(auction);
            }
          }
        );
      },
      this.uiStore.notifications
    );
  };
}
