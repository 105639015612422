import { Box, Paper } from "@material-ui/core";
import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import {
  cpBoldSubHeaderStyle,
  cpCardBoxShadow,
  cpLargeCardPadding,
  dashboardColor1,
  dashboardColor2,
  dashboardColor3,
  dashboardColor4,
  dashboardColor5,
  dashboardColor6,
} from "../helpers/constants";
import { useStore } from "../helpers/use-store";
import {
  getColorStringFromKey,
  percentageFromNumber,
} from "../types/util-types";

interface Props {
  title: string;
  data: any[];
  onMouseOver?: any;
  onMouseOut?: any;
}

export const CPPieChartNew: React.FC<Props> = ({
  data,
  title,
  onMouseOver,
  onMouseOut,
}) => {
  const { session } = useStore().ui;
  if (!data) return <div></div>;
  const colorList: string[] = [
    getColorStringFromKey(dashboardColor1, session),
    getColorStringFromKey(dashboardColor2, session),
    getColorStringFromKey(dashboardColor3, session),
    getColorStringFromKey(dashboardColor4, session),
    getColorStringFromKey(dashboardColor5, session),
    getColorStringFromKey(dashboardColor6, session),
  ];
  let pieChartData: any[] = [];
  let isValidData = !!data && Object.keys(data).length > 0;
  let totalCount = 0;
  Object.keys(data || {}).forEach((key: any, index) => {
    let item: any = data[key];
    if (!item) return;
    let value = item["count"];
    totalCount += value;
    let title = item["label"];
    let color = colorList[index % (colorList.length - 1)];
    pieChartData.push({ key, title, color, value });
  });
  let boxHeight = "8px";
  return (
    <Paper style={{ height: "100%", boxShadow: cpCardBoxShadow }}>
      <Box p={cpLargeCardPadding} height="100%">
        <Box
          fontSize="1.2rem"
          fontWeight="fontWeightBold"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box style={cpBoldSubHeaderStyle}>{`${title}`}</Box>
        </Box>
        {!isValidData && (
          <Box
            fontSize="2rem"
            fontWeight="fontWeightBold"
            display="flex"
            paddingTop={"80px"}
            justifyContent="center"
            alignItems="center"
          >
            No data to show
          </Box>
        )}

        {isValidData && (
          <Box
            fontSize="12px"
            // fontWeight="fontWeightBold"
            display="flex"
            alignItems="center"
            width="100%"
            height="100%"
            paddingLeft={"40px"}
            paddingRight={"40px"}
          >
            <Box flex={1} paddingRight={"40px"}>
              <PieChart
                data={pieChartData}
                animate
                style={{ height: "100%" }}
                // label={({ x, y, dx, dy, key, pieChartData, index }: any) => {
                //   return <text
                //     x={x}
                //     y={y}
                //     dx={dx}
                //     dy={dy}
                //     dominant-baseline="central"
                //     text-anchor="middle"
                //     pointerEvents={"none"}
                //     style={{
                //       // fontSize: `${size}rem`,
                //       fontWeight: 800,
                //       fill: "#FFFFFF",
                //     }}
                //   >
                //     {/* {result} */}
                //     {"hi"}
                //   </text>
                // }}
                // @ts-ignore
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
              />
            </Box>
            <Box flex={1} paddingLeft={"40px"}>
              <Box display={"flex"} flexDirection="column">
                {pieChartData.map((item) => {
                  return (
                    <Box
                      key={item.title}
                      paddingBottom={"20px"}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems="center"
                    >
                      <Box
                        height={boxHeight}
                        width={boxHeight}
                        bgcolor={item.color}
                      />
                      <Box flex={1} ml={"6px"} mr={"20px"}>
                        {item.title}
                      </Box>
                      <Box ml={"6px"}>
                        {percentageFromNumber(item.value / totalCount)}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  );
};
