import { Box, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Popover } from "./Popover";
import { CPLegend } from "./CPLegend";
import {
  debugStyle,
  exportAsCSVByRow,
  getColorStringFromKey,
} from "../types/util-types";
import {
  cpBoldSubHeaderStyle,
  cpCardBoxShadow,
  cpLargeCardPadding,
  dashboardColor1,
  dashboardColor2,
  dashboardColor3,
  standardDashboardPadding,
} from "../helpers/constants";
import { CPDownloadButton } from "./CPDownloadButton";
import { useStore } from "../helpers/use-store";

const useStyles = makeStyles((theme) => ({
  block: {
    height: theme.typography.pxToRem(450),
  },
}));

interface Props {
  height: string;
  className?: string;
  demographics?: any;
  onDownload?: Function;
}

export const DemographicsChart: React.FC<Props> = ({
  className,
  demographics = {},
  height = "100%",
  onDownload,
}) => {
  if (!demographics) demographics = {};
  let demographicsLength = Object.keys(demographics).length;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [amountOfUsers, setAmountOfUsers] = React.useState(0);
  const { session } = useStore().ui;
  const color1 = getColorStringFromKey(dashboardColor1, session);
  const color2 = getColorStringFromKey(dashboardColor2, session);
  const color3 = getColorStringFromKey(dashboardColor3, session);

  const onMouseEnter = (data: any, _: any, event: any) => {
    setAmountOfUsers(data.value);
    setAnchorEl(event.target);
  };

  const onMouseLeave = () => {
    setAmountOfUsers(0);
    setAnchorEl(null);
  };

  let demographicsArray: object[] = [];

  Object.keys(demographics).forEach((key, index) => {
    let demographic: any = demographics[key];
    demographicsArray[index] = {
      name: key,
      Male: demographic["Male"] || demographic["male"] || 0,
      Female: demographic["Female"] || demographic["female"] || 0,
      Other: demographic["Other"] || demographic["other"] || 0,
    };
  });

  //readOnly
  let rODemographicsArray: any = demographicsArray;

  return (
    <Box width="100%" height={height}>
      <Paper style={{ height: height, boxShadow: cpCardBoxShadow }}>
        <Box padding={"10px"} paddingLeft="20px" height="100%">
          <Box
            fontSize="1.2rem"
            fontWeight="fontWeightBold"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mb={"10px"}
          >
            <Box style={cpBoldSubHeaderStyle}>Demographics Statistics</Box>
            <CPDownloadButton onDownload={onDownload} />
          </Box>
          <CPLegend
            items={[
              { title: "Male", color: color1 },
              { title: "Female", color: color2 },
              { title: "Other", color: color3 },
            ]}
          />
          <Box height={"60px"}></Box>
          {!demographicsLength && (
            <Box
              mt={"80px"}
              fontSize="2rem"
              fontWeight="fontWeightBold"
              display="flex"
              justifyContent="center"
              height="100%"
            >
              No data at this time
            </Box>
          )}
          {!!demographicsLength && (
            <Box display="flex">
              <ResponsiveContainer width="99%" height={240}>
                <BarChart
                  barCategoryGap="10%"
                  height={240}
                  data={rODemographicsArray}
                >
                  <CartesianGrid strokeDasharray="4 4" vertical={false} />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Bar
                    dataKey="Male"
                    fill={color1}
                    legendType="none"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  />
                  <Bar
                    dataKey="Female"
                    fill={color2}
                    legendType="none"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  />
                  <Bar
                    dataKey="Other"
                    fill={color3}
                    legendType="none"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  />

                  <Tooltip
                    cursor={{ fill: "#000000", fillOpacity: 0.04 }}
                    isAnimationActive={false}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
