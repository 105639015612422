import { TextField, StandardTextFieldProps } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";

interface Props<T = any> extends StandardTextFieldProps {
  name: string;
  label: string;
  formik: FormikProps<T>;
}

export const FormikTextField: React.FC<Props> = ({
  name,
  label,
  formik,
  ...props
}) => {
  const error = !!formik.touched[name] && formik.errors[name];
  return (
    <TextField
      fullWidth
      name={name}
      label={label}
      variant="outlined"
      size="small"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values[name] || ""}
      error={!!error}
      helperText={error}
      {...props}
    />
  );
};
