export const formatSpaces = (string: string): string => {
  return string.split("\\s").join(" ");
};

export const spacesToBackslash = (string: string): string => {
  return string.split(" ").join("\\s");
};

export const splitCamelCase = (
  string: string,
  capitalizeFirst: boolean = false,
): string => {
  let split = string.replace(/([a-z])([A-Z])/g, "$1 $2");
  if (split.length <= 1 || !capitalizeFirst) {
    return split;
  }
  return split.charAt(0).toUpperCase() + split.slice(1);
};

export const capitalizeFirst = (string: string): string => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatCommas = (string: string): string => {
  let num = Number(string);
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return string;
};

export const optionalText = "(OPTIONAL)";
