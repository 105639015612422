import React from "react";
import { PieChart } from "react-minimal-pie-chart";

interface Props {
  data: any;
  onMouseOver: any;
  onMouseOut: any;
}

export const CPPieChart: React.FC<Props> = ({
  data,
  onMouseOver,
  onMouseOut,
}) => {
  return (
    <PieChart
      data={data}
      animate
      label={({ x, y, dx, dy, key, data }: any) => {
        let name = data[key.slice(-1)].title;
        let result = data[key.slice(-1)].value;
        let size = 0.5;
        if (result < 20) size = 0.3;
        if (result < 5) size = 0.2;
        if (result && !isNaN(result)) result = result.toFixed(2) + "%";
        // return `${name}\n${result}`;
        return (
          <text
            x={x}
            y={y}
            dx={dx}
            dy={dy}
            dominant-baseline="central"
            text-anchor="middle"
            pointerEvents={"none"}
            style={{
              fontSize: `${size}rem`,
              fontWeight: 800,
              fill: "#FFFFFF",
            }}
          >
            {result}
          </text>
        );
      }}
      // @ts-ignore
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    />
  );
};
