interface Routes {
  [key: string]: string | (() => string);
}

export const getRoutesValues = (routes: Routes): string[] => {
  return Object.values(routes).map((r) => ("function" === typeof r ? r() : r));
};

export const authRoutes = {
  logIn: `/signin`,
};

export const sponsorRoutes = {
  sponsors: `/sponsors`,
  sponsorOverview: (sponsorName: string = ":sponsorName") =>
    `/sponsors/${sponsorName}`,
  sponsorStadiums: (sponsorName: string = ":sponsorName") =>
    `/sponsors/${sponsorName}/stadiums`,
  sponsorStadiumOverview: (
    sponsorName: string = ":sponsorName",
    stadiumName: string = ":stadiumName"
  ) => `/sponsors/${sponsorName}/stadiums/${stadiumName}`,
  sponsorGameOverview: (
    sponsorName: string = ":sponsorName",
    stadiumName: string = ":stadiumName",
    gameTimestamp: string = ":gameTimestamp"
  ) => `/sponsors/${sponsorName}/stadiums/${stadiumName}/${gameTimestamp}`,
};

export const teamRoutes = {
  teamOverview: (teamId: string = ":teamId") => `/teams/${teamId}`,
};

export const marketplaceRoutes = {
  marketplaceOverview: (teamId: string = ":teamId") => `/marketplace/${teamId}`,
  productDetail: (
    teamId: string = ":teamId",
    productId: string = ":productId"
  ) => `/marketplace/products/${teamId}/${productId}`,
  sweepstakeDetail: (
    teamId: string = ":teamId",
    sweepstakeId: string = ":sweepstakeId"
  ) => `/marketplace/sweepstakes/${teamId}/${sweepstakeId}`,
  auctionDetail: (
    teamId: string = ":teamId",
    auctionId: string = ":auctionId"
  ) => `/marketplace/auctions/${teamId}/${auctionId}`,
};

export const stadiumRoutes = {
  stadiumOverview: (teamId: string = ":teamId") => `/stadiumsByTeam/${teamId}`,
  stadiumGames: (teamId: string = ":teamId") => `/stadiums/${teamId}/games`,
  stadiumGameOverview: (
    teamId: string = ":teamId",
    gameId: string = ":gameId"
  ) => `/stadiums/${teamId}/${gameId}`,
};

export const usersRoutes = {
  users: "/all-users",
  user: (userId: string = ":userId") => `/users/${userId}`,
  userBySponsor: (
    userId: string = ":userId",
    sponsorName: string = ":sponsorName"
  ) => `/users/${userId}/sponsor/${sponsorName}`,
  userByStadium: (
    userId: string = ":userId",
    stadiumName: string = ":stadiumName"
  ) => `/users/${userId}/stadium/${stadiumName}`,
  userByTeam: (userId: string = ":userId", teamId: string = ":teamId") =>
    `/users/${userId}/team/${teamId}`,
  usersByTeam: (teamId: string = ":teamId") => `/usersByTeam/${teamId}`,
  userOverview: (userId: string = ":userId", teamId: string = ":teamId") =>
    `/usersOverview/${teamId}/${userId}`,
  userByStadiumAndGame: (
    userId: string = ":userId",
    stadiumName: string = ":stadiumName",
    gameTimestamp: string = ":gameTimestamp"
  ) => `/users/${userId}/stadium/${stadiumName}/game/${gameTimestamp}`,
};

export const integrationsRoutes = {
  integrationsForTeam: (teamId: string = ":teamId") =>
    `/integrations/${teamId}`,
  cloverForTeam: (teamId: string = ":teamId") =>
    `/integrations/${teamId}/clover`,
  cloverConnectForTeam: `/integrations/clover/connect`,
};

export const orderRoutes = {
  ordersByTeam: (teamId: string = ":teamId") => `/ordersByTeam/${teamId}`,
  orderDetails: (
    teamId: string = ":teamId",
    userId: string = ":userId",
    orderId: string = ":orderId"
  ) => `/orderDetails/${teamId}/${userId}/${orderId}`,
  pickups: (teamId: string = ":teamId") => `/pickups/${teamId}/`,
  pickupEdit: (teamId: string = ":teamId", pickupId: string = ":pickupId") =>
    `/pickupEdit/${teamId}/${pickupId}`,
};

export const notificationRoutes = {
  notificationsByTeam: (teamId: string = ":teamId") =>
    `/notificationsByTeam/${teamId}`,
  notificationDetails: (
    teamId: string = ":teamId",
    notificationId: string = ":notificationId",
    typeIndex: string = ":typeIndex"
  ) => `/notificationsByTeam/${teamId}/${notificationId}/${typeIndex}`,
};

export const usersAccessRoutes = {
  userAccesses: "/users-accesses",
};

export const questionsRoutes = {
  questions: "/questions",
  newQuestion: `/questions-new`,
  questionOverview: (questionId: string = ":questionId") =>
    `/questions/${questionId}`,
};

export const gamesRoutes = {
  games: "/games",
  newGame: `/games-new`,
  gameOverview: (gameId: string = ":gameId") => `/games-edit/${gameId}`,
  gameplay: (gameId: string = ":gameId") => `/games-run/${gameId}`,
  gameplaySend: (gameId: string = ":gameId", sendMode: string = ":sendMode") =>
    `/games-send/${gameId}/${sendMode}`,
};

export const loyaltyRoutes = {
  loyalties: "/loyalties",
  newLoyalty: "/loyalties-new",
  loyaltyCategories: "/loyalties-categories",
  categoryAnalytics: (categoryId: string = ":categoryId") =>
    `/loyalties-category-details/${categoryId}`,
  editLoyalty: (code: string = ":code") => `/loyalties-edit/${code}`,
  detailsLoyalty: (code: string = ":code") => `/loyalties-details/${code}`,
};
