import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Popover } from "./Popover";
import { CPPieChart } from "./CPPieChart";

const useGenderStatisticStyles = makeStyles((theme) => ({
  grey: {
    color: theme.palette.grey["600"],
  },
  chart: {
    height: theme.typography.pxToRem(224),
  },
  legendMale: {
    background: "#28CCFD",
    width: theme.typography.pxToRem(10),
    height: theme.typography.pxToRem(10),
    borderRadius: "50%",
    marginRight: "1rem",
  },
  legendFemale: {
    background: "#FA2D6D",
    width: theme.typography.pxToRem(10),
    height: theme.typography.pxToRem(10),
    borderRadius: "50%",
    marginRight: "1rem",
  },
  legendPreferNotSay: {
    background: "#FFC922",
    width: theme.typography.pxToRem(10),
    height: theme.typography.pxToRem(10),
    borderRadius: "50%",
    marginRight: "1rem",
  },
  popover: {
    top: "-15rem",
  },
}));

interface Props {
  className?: string;
  male?: number;
  female?: number;
  preferNotSay?: number;
  maleAmount?: number;
  femaleAmount?: number;
  preferNotSayAmount?: number;
  showData?: boolean;
}

export const GenderStatistic: React.FC<Props> = ({
  className = "",
  male = 50,
  female = 50,
  preferNotSay = 0,
  maleAmount = 50,
  femaleAmount = 50,
  preferNotSayAmount = 0,
  showData = true,
}) => {
  const classes = useGenderStatisticStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [amountOfUsers, setAmountOfUsers] = useState(0);

  const onMouseOver = (event: any, data: any[], dataIndex: number) => {
    setAmountOfUsers(data[dataIndex].amount);
    setAnchorEl(event.target);
  };

  const onMouseOut = () => {
    setAnchorEl(null);
  };

  return (
    <Paper className={className}>
      <Box p="4rem" height="100%">
        <Box fontSize="1.2rem" fontWeight="fontWeightBold" mb="2.5rem">
          Gender Statistics
        </Box>
        {(!showData || !(male || female || preferNotSay)) && (
          <Box
            fontSize="2rem"
            fontWeight="fontWeightBold"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            No data at this time
          </Box>
        )}
        {showData && !!(male || female || preferNotSay) && (
          <>
            <Box display="flex" justifyContent="center">
              {male || female || preferNotSay ? (
                <div className={classes.chart}>
                  <CPPieChart
                    data={[
                      {
                        title: "Male",
                        value: male,
                        amount: maleAmount,
                        color: "#28CCFD",
                      },
                      {
                        title: "Female",
                        value: female,
                        amount: femaleAmount,
                        color: "#FA2D6D",
                      },
                      {
                        title: "Prefer not say",
                        value: preferNotSay,
                        amount: preferNotSayAmount,
                        color: "#FFC922",
                      },
                    ]}
                    // @ts-ignore
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                  />
                </div>
              ) : null}
              <Box position="relative">
                <Popover
                  id="popover"
                  open={Boolean(anchorEl) && !!amountOfUsers}
                  anchorEl={anchorEl}
                  className={classes.popover}
                >
                  {amountOfUsers} users
                </Popover>
              </Box>
            </Box>
            <Box
              mt="2rem"
              p="0 4rem"
              display="flex"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                fontWeight="fontWeightBold"
                fontSize="1rem"
                alignItems="center"
                marginRight="1rem"
              >
                <div className={classes.legendMale} />
                Male
              </Box>
              {!!preferNotSay && (
                <Box
                  display="flex"
                  fontWeight="fontWeightBold"
                  fontSize="1rem"
                  alignItems="center"
                  marginRight="1rem"
                >
                  <div className={classes.legendPreferNotSay} />
                  Prefer not say
                </Box>
              )}
              <Box
                display="flex"
                fontWeight="fontWeightBold"
                fontSize="1rem"
                alignItems="center"
              >
                <div className={classes.legendFemale} />
                Female
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};
