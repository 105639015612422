import { Box, Grid } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import moment from "moment";
import React, { ReactElement } from "react";
import {
  AutoSizer,
  Column,
  SortDirection,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";
import { SortDirectionType } from "react-virtualized/dist/es/Table";
import { extractDateForSorting } from "../helpers/time-helpers";
import { RowData } from "../types/data-table-types";
import { getTableCellColorFromRowIndex } from "../types/util-types";
import { CPAvatar } from "./CPAvatar";

declare module "@material-ui/core/styles/withStyles" {
  // Augment the BaseCSSProperties so that we can control jss-rtl
  interface BaseCSSProperties {
    /*
     * Used to control if the rule-set should be affected by rtl transformation
     */
    flip?: boolean;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
    },
    alignCenter: {
      justifyContent: "center",
    },
    table: {
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      "& .ReactVirtualized__Table__headerRow": {
        flip: false,
        paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
      },
      "& .ReactVirtualized__Grid:focus": {
        outline: "none",
      },
      "& .ReactVirtualized__Table__headerColumn": {
        outline: "none",
      },
      "& .ReactVirtualized__Table__row": {
        outline: "none",
      },

      // "overflow-y": "hidden!important",
      // "&:hover": {
      //   "overflow-y": "scroll!important",
      // }
      // "&:hover": {
      //   color: "black",
      //   backgroundColor: "black",
      //   outline: "4px solid red",
      //   overflow: "hidden",
      //   // overflow: "scroll",
      // },
    },
    tableRow: {},
    tableRowHover: {
      background: "white",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    head: {
      cursor: "default",
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[600],
      "&:hover": {
        color: theme.palette.grey[900],
      },
    },
    head_sorted: {
      color: theme.palette.grey[900],
    },
    tableCell: {
      fontWeight: theme.typography.fontWeightBold,
      flex: 1,
      padding: "0 25px",
    },
    noClick: {
      // cursor: "initial",
    },
    sort_asc: {
      fontSize: theme.typography.fontSize,
      transform: `rotate(270deg)`,
      transition: "all 0.3s linear",
      marginLeft: theme.spacing(1),
    },
    sort_desc: {
      transform: `rotate(90deg)`,
      transition: "all 0.3s linear",
      marginLeft: theme.spacing(1),
    },
  });

interface ColumnData {
  dataKey: string;
  label: string;
  width: number;
  numeric?: boolean;
  flexGrow?: number;
  //TODO watch out, anything that uses cellRender may sort unexpectedly
  //Maybe implement an override value vs an override rendering that can replace value?
  cellRender?: (row: RowData) => ReactElement;
  disableClickEventBubbling?: boolean;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: ColumnData[];
  rows: RowData[];
  headerHeight?: number;
  onRowClick?: (data: any) => void;
  rowHeight?: number;
  defaultSort?: string;
  defaultSortDirection?: SortDirectionType;
  withChevron: boolean;
  maxRowCount: number;
}

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 66,
  };

  state: { sortDirection: SortDirectionType; sortBy: string } = {
    sortBy: this.props.defaultSort || "",
    sortDirection: this.props.defaultSortDirection || SortDirection.ASC,
  };

  getRowClassName = ({ index }: Row) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer: TableCellRenderer = ({
    cellData,
    columnIndex,
    rowData,
    rowIndex,
    dataKey,
  }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    const { cellRender, disableClickEventBubbling } = columns[columnIndex];
    const inNumeric = !!columns[columnIndex]?.numeric;
    const rowBackground = getTableCellColorFromRowIndex(rowIndex);
    const isNameAndAvatar = dataKey === "nameAndAvatar";
    let finalRender = cellRender ? cellRender(rowData) : cellData;
    if (isNameAndAvatar) {
      if (!cellData) cellData = ["", "", ""];
      let name = cellData[0];
      let avatar = cellData[1];
      let teamId = cellData[2];
      finalRender = (
        <Box display={"flex"} alignItems="center">
          <CPAvatar avatar={avatar} teamId={teamId}></CPAvatar>
          <Box width={"8px"}></Box>
          <Box>{name}</Box>
        </Box>
      );
    }
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
          [classes.alignCenter]: inNumeric,
        })}
        variant="body"
        style={{ height: rowHeight, background: rowBackground }}
        align={columnIndex != null && inNumeric ? "center" : "left"}
      >
        {finalRender}
      </TableCell>
    );
  };

  headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number }) => {
    const { sortBy, sortDirection } = this.state;
    const { headerHeight, columns, classes } = this.props;
    const sorted = columns[columnIndex]?.dataKey === sortBy;
    const inNumeric = !!columns[columnIndex]?.numeric;
    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
          classes.head,
          {
            [classes.head_sorted]: sorted,
            [classes.alignCenter]: inNumeric,
          },
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={inNumeric ? "center" : "left"}
      >
        <span>{label}</span>
        {columns[columnIndex]?.dataKey === sortBy && (
          <ArrowBackIosIcon
            className={clsx(classes.sort_asc, {
              [classes.sort_desc]: sortDirection === "DESC",
            })}
          />
        )}
      </TableCell>
    );
  };

  sort = ({ sortBy, sortDirection }: any) => {
    this.setState({ sortBy, sortDirection });
  };

  render() {
    const { sortBy, sortDirection } = this.state;
    const {
      classes,
      columns,
      rows,
      rowHeight,
      headerHeight,
      maxRowCount,
      withChevron,
      ...tableProps
    } = this.props;
    const sortedRows = [...rows].sort((a, b) => {
      let aVal: string = `${a[sortBy]}`;
      let bVal: string = `${b[sortBy]}`;
      let aNum = Number(aVal);
      let bNum = Number(bVal);
      let aExtractedDate = extractDateForSorting(aVal);
      let bExtractedDate = extractDateForSorting(bVal);
      let aExtractedDateMoment = moment(aExtractedDate);
      let bExtractedDateMoment = moment(bExtractedDate);
      let isNumeric = !isNaN(Number(aVal)) && !isNaN(Number(bVal));
      let isDates = aExtractedDate && bExtractedDate;
      // if (isDates) console.log(`comparing ${aVal} to ${bVal}`);
      if (sortDirection === "DESC") {
        if (isDates)
          return aExtractedDateMoment.unix() - bExtractedDateMoment.unix();
        return isNumeric ? aNum - bNum : bVal.localeCompare(aVal);
      } else {
        if (isDates)
          return bExtractedDateMoment.unix() - aExtractedDateMoment.unix();
        return isNumeric
          ? bNum - aNum
          : `${a[sortBy]}`.localeCompare(`${b[sortBy]}`);
      }
    });
    let emptyHeaderRenderer = () => (
      <TableCell
        component="div"
        variant="head"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
          classes.head,
        )}
        style={{ height: headerHeight }}
        align="right"
      />
    );
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            className={classes.table}
            rowHeight={rowHeight!}
            gridStyle={{ direction: "inherit" }}
            headerHeight={headerHeight!}
            rowGetter={({ index }) => sortedRows[index]}
            rowClassName={this.getRowClassName}
            sort={this.sort}
            sortBy={this.state.sortBy}
            sortDirection={this.state.sortDirection}
            rowCount={Math.min(sortedRows.length, maxRowCount)}
            {...tableProps}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
            {withChevron && (
              <Column
                dataKey=""
                width={50}
                className={classes.flexContainer}
                headerRenderer={emptyHeaderRenderer}
                cellRenderer={() => (
                  <TableCell
                    component="div"
                    variant="body"
                    className={clsx(
                      classes.tableCell,
                      classes.flexContainer,
                      classes.noClick,
                    )}
                    style={{ height: rowHeight }}
                    align="right"
                  >
                    <ChevronRightIcon />
                  </TableCell>
                )}
              />
            )}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

interface Props {
  columns: ColumnData[];
  rows: RowData[];
  defaultSort?: string;
  defaultSortDirection?: SortDirectionType;
  withChevron?: boolean;
  height: string;
  csvTitle?: string;
  maxRowCount?: number;
  extraButton?: any;
  onRowClick?: (rowData: RowData) => void;
}

export const CPSortableTable: React.FC<Props> = ({
  columns,
  rows,
  defaultSort,
  defaultSortDirection,
  height,
  withChevron = false,
  csvTitle = "data",
  extraButton,
  maxRowCount = 10000,
  onRowClick,
}) => {
  let numericHeight = Number(`${height}`.replace("px", ""));
  height = `${numericHeight - 64}px`;
  return (
    <Box style={{ height, width: "100%" }}>
      <Grid container justify="flex-end">
        {extraButton}
      </Grid>

      <VirtualizedTable
        rows={rows}
        columns={columns}
        defaultSort={defaultSort}
        defaultSortDirection={defaultSortDirection}
        withChevron={withChevron}
        onRowClick={({ event, rowData }: any) =>
          onRowClick && onRowClick(rowData)
        }
        maxRowCount={maxRowCount}
      />
    </Box>
  );
};
