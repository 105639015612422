import { makeAutoObservable } from "mobx";
import { TeamItem } from "../../types/teams-types";
import { DropdownOption } from "../../types/util-types";
import { FirebaseConnector } from "../connectors/firebase-connector";

export class TeamsDomainStore {
  private _teams: TeamItem[] = [];
  private _teamOptions: DropdownOption[] = [];

  constructor(public firebaseConnector: FirebaseConnector) {
    makeAutoObservable(this);
  }

  get teams() {
    return this._teams;
  }

  get teamsOptions() {
    return this._teams.map((team) => {
      return {
        label: team.editTeamsName,
        value: team.teamId,
      };
    });
  }

  loadTeams = async (reload: boolean = false) => {
    if (!this._teams.length || reload) {
      this._teams = await this.firebaseConnector.loadTeams();
    }
  };
}
