import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";
import {
  answerNumberPairs as multipleChoiceAnswers,
  answerTypes,
  enterAnswerAnswerType,
  longshotAnswerType,
  multipleChoiceAnswerType,
  questionPointValues,
  QuestionItem,
  questionTimeValues,
  questionTypes,
  thirdAnswerLongshotPoints,
  thirdAnswer,
  fourthAnswer,
  fourthAnswerLongshotPoints,
  fifthAnswerLongshotPoints,
  fifthAnswer,
  firstAnswer,
  firstAnswerLongshotPoints,
  secondAnswerLongshotPoints,
  secondAnswer,
} from "../../../types/questions-types";
import { MultipleChoiceAnswerField } from "./MultipleChoiceAnswerField";
import { FormikTextField } from "../../../components/FormikTextField";
import { FormikDropdown } from "../../../components/FormikDropdown";
import { FormikCheckbox } from "../../../components/FormikCheckbox";
import { DropdownOption } from "../../../types/util-types";
import { positiveIntYup } from "../../../types/formik-types";

const answerRequiredString = "Answer Required";
const pointsRequiredString = "Points Required";

const longshotPointsTest = (
  longshotPointsName: string,
  longshotAnswerName: string,
) => {
  return Yup.number()
    .integer()
    .nullable()
    .test(longshotPointsName, pointsRequiredString, function (this, item) {
      if (
        !!this.parent[longshotAnswerName] &&
        this.parent.answerType === longshotAnswerType
      )
        return !!item && !isNaN(item);
      return true;
    });
};

const multipleChoiceAnswerTest = (
  answerName: string,
  longshotPointsName: string,
) => {
  return Yup.string()
    .nullable()
    .test(answerName, answerRequiredString, function (this, item) {
      if (this.parent.answerType === enterAnswerAnswerType) return true;
      if ([firstAnswer, secondAnswer].includes(answerName)) return !!item;
      if (this.parent[longshotPointsName]) return !!item;
      return true;
    });
};

const validationSchema = Yup.object().shape({
  questionType: Yup.string().required("Question Type required"),
  question: Yup.string().required("Question required"),
  answerType: Yup.string().required("Answer Type required"),
  enterAnswer: positiveIntYup
    .nullable()
    .test("answerType", answerRequiredString, function (this, item) {
      if (this.parent.answerType !== enterAnswerAnswerType) return true;
      return !!item;
    }),
  firstAnswer: multipleChoiceAnswerTest(firstAnswer, firstAnswerLongshotPoints),
  firstAnswerLongshotPoints: longshotPointsTest(
    firstAnswerLongshotPoints,
    firstAnswer,
  ),
  secondAnswer: multipleChoiceAnswerTest(
    secondAnswer,
    secondAnswerLongshotPoints,
  ),
  secondAnswerLongshotPoints: longshotPointsTest(
    secondAnswerLongshotPoints,
    secondAnswer,
  ),
  thirdAnswer: multipleChoiceAnswerTest(thirdAnswer, thirdAnswerLongshotPoints),
  thirdAnswerLongshotPoints: longshotPointsTest(
    thirdAnswerLongshotPoints,
    thirdAnswer,
  ),
  fourthAnswer: multipleChoiceAnswerTest(
    fourthAnswer,
    fourthAnswerLongshotPoints,
  ),
  fourthAnswerLongshotPoints: longshotPointsTest(
    fourthAnswerLongshotPoints,
    fourthAnswer,
  ),
  fifthAnswer: multipleChoiceAnswerTest(fifthAnswer, fifthAnswerLongshotPoints),
  fifthAnswerLongshotPoints: longshotPointsTest(
    fifthAnswerLongshotPoints,
    fifthAnswer,
  ),
  pointValue: positiveIntYup.test(
    "pointValue",
    pointsRequiredString,
    function (this, item) {
      if (this.parent.answerType === longshotAnswerType) return true;
      return !!item;
    },
  ),
  // pointValue: Yup.string().required("Point required"),
  questionTime: Yup.string().required("Time required"),
  questionFolder: Yup.string().required("Folder required"),
});

interface Props {
  onSave: (question: QuestionItem) => void;
  formValues: QuestionItem;
  formRef?: React.RefObject<HTMLButtonElement>;
  folderOptions: DropdownOption[];
}

export const QuestionEditForm: React.FC<Props> = observer(
  ({ onSave, formValues, formRef, folderOptions }) => {
    const formik = useFormik<QuestionItem>({
      enableReinitialize: true,
      initialValues: formValues,
      validationSchema,
      onSubmit: onSave,
    });

    const showMultipleChoice = [
      longshotAnswerType,
      multipleChoiceAnswerType,
    ].includes(formik.values.answerType);
    const isLongshot = longshotAnswerType === formik.values.answerType;
    console.log(`formik.errors: ${JSON.stringify(formik.errors, null, 2)}`);
    return (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box mt={4}>
              <FormikTextField
                name={"question"}
                label={"Question"}
                formik={formik}
              />
            </Box>
            <Box mt={4}>
              <FormikDropdown
                name="questionType"
                label="Question Type"
                optionsWithMatchingLabelAndValue={questionTypes}
                formik={formik}
              />
            </Box>
            <Box mt={4}>
              <FormikDropdown
                name="questionFolder"
                label="Folder"
                options={folderOptions}
                formik={formik}
              />
            </Box>
            <Box mt={4}>
              <FormikDropdown
                name="questionTime"
                label="Time"
                optionsWithMatchingLabelAndValue={questionTimeValues}
                formik={formik}
              />
            </Box>
            <Box mt={4}>
              <FormikCheckbox
                name={"isLivePoll"}
                label={"Live Poll"}
                formik={formik}
              />
              <FormikCheckbox
                name={"isOnTheSpot"}
                label={"On The Spot"}
                formik={formik}
              />
              <FormikCheckbox
                name={"isLookout"}
                label={"Lookout"}
                formik={formik}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mt={4}>
              <FormikDropdown
                name="answerType"
                label="Answer Type"
                optionsWithMatchingLabelAndValue={answerTypes}
                formik={formik}
              />
            </Box>
            {!isLongshot && (
              <Box mt={4}>
                <FormikDropdown
                  name="pointValue"
                  label="Point Value"
                  optionsWithMatchingLabelAndValue={questionPointValues}
                  formik={formik}
                />
              </Box>
            )}
            {formik.values.answerType === enterAnswerAnswerType && (
              <Box mt={4}>
                <FormikTextField
                  name="enterAnswer"
                  label="Enter Answer"
                  formik={formik}
                />
              </Box>
            )}
            {showMultipleChoice && (
              <Box mt={4}>
                {multipleChoiceAnswers.map((answerNumberPair) => (
                  <MultipleChoiceAnswerField
                    key={answerNumberPair[1]}
                    answerNumber={answerNumberPair[0]}
                    answerNumberLongshotPoints={answerNumberPair[1]}
                    formik={formik}
                  />
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
        <Box mt={4} width={200}>
          <Button
            type="submit"
            ref={formRef}
            color="primary"
            variant="contained"
            fullWidth
          >
            Submit Question
          </Button>
        </Box>
      </form>
    );
  },
);
