import { Box } from "@material-ui/core";
import React from "react";
import { cpGray77, dashboardColor1 } from "../helpers/constants";
import { useStore } from "../helpers/use-store";
import { getColorStringFromKey } from "../types/util-types";

interface Props {
  selected: number;
  tabs: any[];
  onTabChange: (index: number) => void;
}

export const CPTabBar: React.FC<Props> = ({ selected, onTabChange, tabs }) => {
  let iconSize = "20px";
  const { session } = useStore().ui;
  let color = getColorStringFromKey(dashboardColor1, session);
  return (
    <Box display={"flex"}>
      {tabs.map((tab, index) => {
        let isSelected = selected === index;
        let tabColor = isSelected ? "#000000" : cpGray77;
        let label = tab["label"] || "";
        let icon = tab["icon"] || "";
        let disabledIcon = tab["disabled_icon"] || "";
        if (disabledIcon && !isSelected) icon = disabledIcon;

        return (
          <Box key={label}>
            <Box
              display={"flex"}
              style={{ cursor: "pointer", color: tabColor }}
              alignItems="center"
              alignContent={"center"}
              width={"114px"}
              height="70px"
              onClick={() => onTabChange(index)}
            >
              <Box flex={1}></Box>
              <Box height={iconSize} width={iconSize}>
                <img
                  src={icon}
                  height={iconSize}
                  color={tabColor}
                  alt="tab_icon"
                />
              </Box>
              <Box width={"10px"}></Box>
              <Box>{label}</Box>
              <Box flex={1}></Box>
            </Box>
            {isSelected && (
              <Box
                width="100%"
                height="4px"
                style={{ backgroundColor: color }}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};
