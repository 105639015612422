import { Loading } from "../../helpers/loading";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";
import { makeAutoObservable } from "mobx";
import {
  UserAccesses,
  userAccessesDefaults,
  UserAccessesForm,
} from "../../types/user-types";

export class UserAccessPageStore {
  private rawTeams: { teamId: string; name: string }[] = [];
  public stadiums: string[] = [];
  public sponsors: string[] = [];
  public userAccesses: UserAccesses = userAccessesDefaults();
  public loading: Loading = Loading.NOT_LOADED;
  public error: string | null = null;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  get teams() {
    return this.rawTeams.map(({ name }) => name);
  }

  get userAccessesForm(): UserAccessesForm {
    const teamId = this.userAccesses?.type?.identifier?.team;
    return {
      userId: this.userAccesses.userId,
      role: this.userAccesses?.type?.role || null,
      stadium: this.userAccesses?.type?.identifier?.stadium || null,
      sponsor: this.userAccesses?.type?.identifier?.sponsor || null,
      team:
        this.rawTeams.find((rawTeam) => rawTeam.teamId === teamId)?.name ||
        null,
    };
  }

  grandAccess = async (accesses: UserAccessesForm) => {
    try {
      const {
        userId,
        role,
        stadium = null,
        sponsor = null,
        team = null,
      } = accesses;
      const user = await this.firebaseConnector.grandAccess({
        userId,
        type: {
          role,
          identifier: {
            stadium,
            sponsor,
            team:
              this.rawTeams.find((rawTeam) => rawTeam.name === team)?.teamId ||
              null,
          },
        },
      });
      this.userAccesses = user;
      this.loading = Loading.SUCCESS;
      this.uiStore.notifications.showSuccess(`Access rights has been updated`);
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
      this.error = "User ID cannot be find";
    }
  };

  loadDictionaries = async () => {
    try {
      this.loading = Loading.LOADING;
      const { stadiums, sponsors, teams } =
        await this.firebaseConnector.loadDictionaries();
      this.stadiums = stadiums;
      this.sponsors = sponsors;
      this.rawTeams = teams;
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };

  loadUser = async (userId: string) => {
    try {
      const user = await this.firebaseConnector.loadUser(userId);
      this.userAccesses = { ...this.userAccesses, ...user };
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
      this.error = "User ID cannot be find";
    }
  };
}
