import React, { ReactElement } from "react";
import { Box, Card, makeStyles, Typography } from "@material-ui/core";
import { formatCommas } from "../helpers/name-helpers";
import clsx from "clsx";
import { debugStyle } from "../types/util-types";
import {
  cpBoldSubHeaderStyle,
  cpCardBoxShadow,
  halfStandardDashboardPadding,
  standardDashboardPadding,
} from "../helpers/constants";
import { CPDownloadButton } from "./CPDownloadButton";

interface Props {
  // title: string;
  children?: ReactElement;
  cardPadding?: string;
}

export const CPCard: React.FC<Props> = ({ children, cardPadding = "" }) => {
  return (
    <Box height={"100%"}>
      <Card
        style={{
          height: "100%",
          width: "100%",
          padding: cardPadding,
          boxShadow: cpCardBoxShadow,
        }}
      >
        {children}
      </Card>
    </Box>
  );
};
