import { makeStyles } from "@material-ui/core";
import React from "react";
import { dashboardColor1 } from "../../../helpers/constants";
import { useStore } from "../../../helpers/use-store";
import { getColorStringFromKey } from "../../../types/util-types";

const useStyles = makeStyles((theme) => ({
  bar: {
    background: "#E5E5E5",
    height: "6px",
    borderRadius: "100px",
  },
  bar_filled: {
    // background: "primary",
  },
  // tab_indicator: {
  //   height: theme.spacing(1),
  //   background: theme.palette.primary.main,
  // },
}));

interface Props {
  min: number;
  max: number;
  cur: number;
}

export const BudgetProgressBar: React.FC<Props> = ({ min, max, cur }) => {
  let percentage = Math.min(((cur - min) / (max - min)) * 100, 100);
  const classes = useStyles();
  const { session } = useStore().ui;
  let color = getColorStringFromKey(dashboardColor1, session);
  return (
    <div className={classes.bar}>
      <div
        style={{
          width: `${percentage}%`,
          height: "6px",
          borderRadius: "100px",
          backgroundColor: color,
        }}
      />
    </div>
  );
};
