import { makeAutoObservable } from "mobx";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";
import { Loading, loadingFunction } from "../../helpers/loading";
import { GameListItem } from "../../types/games-types";
import { gamesRoutes } from "../../app-routes";
import { hMFormat, mdyFormat } from "../../types/formik-types";
import moment from "moment";

type teamNamesByIdMap = {
  [key: string]: string;
};

export class GamesPageStore {
  private searchInput: string = "";
  private _games: GameListItem[] = [];
  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  get games() {
    return this._games
      .filter((game) => {
        const lowerSearchInput = this.searchInput.toLowerCase();
        return (
          game.gameTeamName?.toLowerCase().includes(lowerSearchInput) ||
          game.startTimestamp?.toLowerCase().includes(lowerSearchInput)
        );
      })
      .map((game) => {
        let teamId = game.gameTeam;
        if (teamId) game.gameTeamName = this.teamNamesById[teamId];
        game.startDisplayTime = moment(game.startTimestamp).format(hMFormat);
        game.startDisplayDate = moment(game.startTimestamp).format(mdyFormat);
        return game;
      });
  }

  onEditGame = (gameId: string) => {
    this.uiStore.routerStore.push(gamesRoutes.gameOverview(gameId));
  };

  onRunGame = (gameId: string) => {
    this.uiStore.routerStore.push(gamesRoutes.gameplay(gameId));
  };

  searchGame = (input: string) => {
    this.searchInput = input;
  };

  loadTeams = async () => {
    loadingFunction(
      this,
      async () => {
        const { teamsDomain } = this.uiStore.domainsStore;
        await teamsDomain.loadTeams();
      },
      this.uiStore.notifications,
    );
  };

  loadGames = async () => {
    loadingFunction(
      this,
      async () => {
        this._games = await this.firebaseConnector.loadGames();
      },
      this.uiStore.notifications,
    );
  };

  get teamNamesById() {
    const { teams } = this.uiStore.domainsStore.teamsDomain;
    const result: teamNamesByIdMap = {};
    teams.map((team) => {
      result[team.teamId] = team.editTeamsName;
    });
    return result;
  }
}
