import { Box, Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import { orderRoutes } from "../../app-routes";
import { CPAvatar } from "../../components/CPAvatar";
import { CPCard } from "../../components/CPCard";
import { CPDashboardSpacer } from "../../components/CPDashboardSpacer";
import { MonthlyDailyWeeklyChart } from "../../components/MonthlyDailyWeeklyChart";
import { PageTitle } from "../../components/PageTitle";
import {
  allSeasonsOption,
  attendingLabel,
  cpBoldSubHeaderStyle,
  cpPageTitleStyle,
  membersOnlyLabel,
  standardDashboardPadding,
  watchingLabel,
  withGuestsLabel,
} from "../../helpers/constants";
import {
  errorMessageFromAllTruthy,
  isPromptProtected,
} from "../../helpers/generic-helpers";
import { formatCommas, splitCamelCase } from "../../helpers/name-helpers";
import { formatDate } from "../../helpers/time-helpers";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import {
  SessionUserType,
  UserOverview,
  adminTeamRoles,
} from "../../types/user-types";
import {
  exportAsCSVByCol,
  getAttributeFromObject,
  getTableCellColorFromRowIndex,
  percentageFromNumber,
  setAttributeInObject,
} from "../../types/util-types";
import { UserExpiringTransactionsTable } from "./components/UserExpiringTransactionsTable";
import { UserGamesPlayedTable } from "./components/UserGamesPlayedTable";
import { UserOrdersTable } from "./components/UserOrdersTable";
import { UserRewardsTable } from "./components/UserRewardsTable";
import { UserTransactionsTable } from "./components/UserTransactionsTable";
import { API } from "../../stores/connectors/api-connector";

interface UrlParams {
  userId: string;
  teamId: string;
}

export const UserOverviewPage: React.FC = observer(() => {
  const urlParams = useParams<UrlParams>();
  const { userOverviewPage } = useStore().ui;
  const { routerStore } = useStore();
  console.log(`load USEROVERVIEWPAGE`);

  React.useEffect(() => {
    userOverviewPage.loadUserOverview(urlParams.teamId, urlParams.userId);
  }, [urlParams, userOverviewPage]);

  let userDetails: UserOverview = userOverviewPage.userOverview;
  let userEngagementDetails: any = userOverviewPage.userEngagementDetails;
  let email = userDetails.email;
  let username = userDetails.username;
  let gender = userDetails.gender;
  let birthday = userDetails.birthYear
    ? `${userDetails.birthMonth}/${userDetails.birthDate}/${userDetails.birthYear}`
    : null;
  let platform = userDetails.platform;
  let dateJoined = formatDate(userDetails.dateCreated);
  let avatar = userDetails.avatar;
  let teamId = urlParams.teamId;
  let seasonId = userOverviewPage.seasonId;
  let userId = urlParams.userId;
  let cohortName = userDetails.cohortName;
  let accountId = userDetails.accountId;
  let ticketRepId = userDetails.ticketRepId;

  let personalInformationRowCounter = 0;
  let personalInformationMap: any = {
    email,
    username,
    gender,
    birthday,
    platform,
  };
  let teamDetails = userOverviewPage.teamDetails;
  let collectsGenders = teamDetails["collectsGenders"] !== false;
  let collectsBirthdays = teamDetails["collectsBirthdays"] !== false;
  if (!collectsGenders) personalInformationMap["gender"] = null;
  if (!collectsBirthdays) personalInformationMap["birthday"] = null;
  if (cohortName) personalInformationMap["cohort"] = cohortName;
  if (accountId) personalInformationMap["accountId"] = accountId;
  if (ticketRepId) personalInformationMap["ticketRepId"] = ticketRepId;

  let startingPoints = userDetails.startingPoints;
  let currentPoints = userDetails.currentPoints;
  let totalPointsEarned = userDetails.totalPointsEarned;
  let totalPointsSpent = userDetails.totalPointsSpent;
  let engagementRate = percentageFromNumber(
    userEngagementDetails.engagementRate
  );
  let totalActiveDays = userEngagementDetails.totalActiveDays;
  let currentStreak = userEngagementDetails.currentStreak;
  let longestStreak = userEngagementDetails.consecutiveDays || 0;
  let detailsRowCounter = 0;
  let detailsMap: any = {
    startingPoints,
    currentPoints,
    totalPointsEarned,
    totalPointsSpent,
    engagementRate,
    totalActiveDays,
    currentStreak,
    longestStreak,
  };

  const { session } = useStore().ui;
  const role = session.user?.type?.role;
  const hasAdminAccess = adminTeamRoles.includes(role!);

  let isFrozen = userOverviewPage.isFrozen;
  let lockAccountText = isFrozen ? "Unfreeze Account" : "Freeze Account";

  let promptEditDetails = () => {
    let types: any = {
      "1": "email",
      "2": "fullName",
      "3": "accountId",
      "4": "ticketRepId",
    };
    let typePrompt = ``;
    Object.keys(types).forEach((userIndex: string) => {
      typePrompt += `${userIndex}: ${splitCamelCase(types[userIndex], true)}\n`;
    });
    let typeInt = window.prompt(
      `Please enter which part of the user you want to edit\n ${typePrompt}`
    );
    let type = types[typeInt || "0"];
    if (!type) {
      window.alert("Invalid type. Aborting.");
      return;
    }
    let newVal;
    let secondaryVal;
    if (type === "fullName") {
      newVal = window.prompt(
        `What would you like to change the first name to?`
      );
      secondaryVal = window.prompt(
        `What would you like to change the last name to?`
      );
      if (!newVal || !secondaryVal) {
        window.alert("Invalid name. Aborting.");
        return;
      }
    } else {
      newVal = window.prompt(
        `What would you like to change ${splitCamelCase(type, true)} to?`
      );
    }
    if (newVal) {
      let fullVal = `${newVal} ${secondaryVal || ""}`;
      if (
        window.prompt(
          `You are about to change ${splitCamelCase(
            type
          )} to ${fullVal}- Enter 'y' to continue`
        )
      ) {
        if (type === "fullName") {
          userOverviewPage.editUserDetails(teamId, userId, "firstName", newVal);
          userOverviewPage.editUserDetails(
            teamId,
            userId,
            "lastName",
            secondaryVal
          );
          userOverviewPage.editUserDetails(teamId, userId, "fullName", fullVal);
        } else {
          userOverviewPage.editUserDetails(teamId, userId, type, newVal);
        }
        window.alert("Done!");
      } else {
        window.alert("Aborting");
      }
    } else {
      window.alert("No value found - aborting");
    }
    // let errorMessage = errorMessageFromAllTruthy({userId, teamId});
    // if (errorMessage) {
    //   window.alert(errorMessage);
    //   return;
    // }
    // if (isPromptProtected(`You are about to ${isFrozen ? "Unfreeze" : "Freeze"} this account. `)) {
    //   userOverviewPage.freezeAccount(teamId, userId, !isFrozen);
    // }
  };
  let promptFreezeAccount = () => {
    let errorMessage = errorMessageFromAllTruthy({ userId, teamId });
    if (errorMessage) {
      window.alert(errorMessage);
      return;
    }
    if (
      isPromptProtected(
        `You are about to ${isFrozen ? "Unfreeze" : "Freeze"} this account. `
      )
    ) {
      userOverviewPage.freezeAccount(teamId, userId, !isFrozen);
    }
  };

  let promptPasswordReset = async () => {
    let errorMessage = errorMessageFromAllTruthy({ userId, teamId, email });
    if (errorMessage) {
      window.alert(errorMessage);
      return;
    }
    console.log(`searching for email: ${email}`);
    const response = await API.post<{ data: { result: any } }>(
      "/requestPasswordReset",
      {
        email,
        teamId,
      }
    );
    console.log(JSON.stringify(response));

    let link = getAttributeFromObject(`data/data/link`, response);
    if (link) {
      navigator.clipboard.writeText(link);
      window.alert(`copied reset link to clipboard`);
    } else {
      window.alert(`Unable to create link`);
    }
    // window.alert(JSON.stringify(response.data));
    return response;
  };

  let promptNotification = () => {
    let errorMessage = errorMessageFromAllTruthy({ userId, teamId });
    if (errorMessage) {
      window.alert(errorMessage);
      return;
    }
    let title = window.prompt("Please enter a title");
    if (!title) {
      window.alert("Aborting");
      return;
    }
    let body = window.prompt("Please enter a body");
    if (!body) {
      window.alert("Aborting");
      return;
    }
    if (
      isPromptProtected(
        `You will now send the following notification to this user: \nTitle: ${title}\nBody: ${body}\n`
      )
    ) {
      userOverviewPage.sendNotification(teamId, userId, title, body);
    }
  };

  let promptPointChange = (isSendMode: boolean) => {
    let type = isSendMode ? "send" : "take away";
    let points = window.prompt(
      `Please enter the number of points to ${type}. ${
        isSendMode ? "" : "No negative sign is necessary"
      }`
    );
    let isRefund;
    function askRefund() {
      const response = window.prompt(
        "Is this a refund? Type 'y' if so, and 'n' if not"
      );
      if (response === "y" || response === "n") {
        isRefund = response === "y" || null;
      } else {
        alert("Please enter 'y' for yes or 'n' for no.");
        askRefund();
      }
    }
    askRefund();
    let pointsInt = Number(points);
    if (!pointsInt || pointsInt <= 0) {
      window.alert(
        "Invalid point value. Please make sure you enter a valid, positive, integer. Aborting."
      );
      return;
    }
    let description = window.prompt(
      "Please enter a description (Will be seen by participant in their transactions)"
    );
    if (!description) {
      window.alert("Invalid description. Aborting.");
      return;
    }
    if (pointsInt && description) {
      if (
        window.confirm(
          `You are about to ${type} ${points} points\nDescription: ${description}`
        )
      ) {
        if (!isSendMode) pointsInt = 0 - pointsInt;
        let transaction = {
          timestamp: moment().toISOString(),
          description: `${description}`,
          metaDescription: `data dashboard: ${description}`,
          points: `${pointsInt}`,
          seasonId: seasonId,
          teamId: teamId,
          receipt: "",
          isRefund,
        };
        userOverviewPage.setLoyaltyPointsTransaction(
          userId,
          teamId,
          seasonId,
          transaction
        );
      }
    } else {
      window.alert("Invalid points or description");
    }
  };

  let isTicketScanningOnly =
    userOverviewPage.teamDetails["editTeamsTicketScanningVisibility"] !== false;
  let attendingWatchingDataListOptions = [attendingLabel, watchingLabel];
  let dailyWeeklyMonthlyDataList =
    userOverviewPage.dailyWeeklyMonthlyCheckInData;
  if (isTicketScanningOnly) {
    attendingWatchingDataListOptions = [watchingLabel];
    dailyWeeklyMonthlyDataList = [dailyWeeklyMonthlyDataList[1]];
  }

  let engagementChart = (
    <MonthlyDailyWeeklyChart
      dailyWeeklyMonthlyDataList={
        userOverviewPage.dailyWeeklyMonthlyEngagementData
      }
      title={`Engagement`}
      dataKeyName="Engagement"
      selectedSeasonFilter={allSeasonsOption}
      onlyDaily={true}
      onDownload={async (options: any) => {
        let timeframe = options["timeframe"];
        let timeframeSpecificData =
          userOverviewPage.dailyWeeklyMonthlyEngagementData[0][timeframe] || {};
        let csvKeys: string[] = ["Date"];
        let cols: any = {};
        Object.keys(timeframeSpecificData).forEach((dateKey) => {
          let prevDates = getAttributeFromObject("0", cols) || [];
          prevDates.push(dateKey);
          setAttributeInObject("0", cols, prevDates);
        });
        exportAsCSVByCol(csvKeys, cols, `User Activity (${timeframe})`);
      }}
    />
  );

  let checkInChart = (
    <MonthlyDailyWeeklyChart
      dailyWeeklyMonthlyDataList={dailyWeeklyMonthlyDataList}
      title={`Game Day Check-Ins`}
      dataKeyName="Check-Ins"
      selectedSeasonFilter={allSeasonsOption}
      dataListOptions={attendingWatchingDataListOptions}
      onlyDaily={true}
      onDownload={async (options: any) => {
        let attendingOrWatching = options["dataListSelection"];
        let dataListIndex = options["dataListIndex"];
        let timeframeSpecificData =
          userOverviewPage.dailyWeeklyMonthlyCheckInData[dataListIndex][
            "daily"
          ] || {};
        let csvKeys: string[] = ["Date"];
        let cols: any = {};
        Object.keys(timeframeSpecificData).forEach((dateKey) => {
          let prevDates = getAttributeFromObject("0", cols) || [];
          prevDates.push(dateKey);
          setAttributeInObject("0", cols, prevDates);
        });
        exportAsCSVByCol(
          csvKeys,
          cols,
          `User Activity (${attendingOrWatching})`
        );
      }}
    />
  );

  let scanningChart = (
    <MonthlyDailyWeeklyChart
      dailyWeeklyMonthlyDataList={
        userOverviewPage.dailyWeeklyMonthlyScanningCheckInData
      }
      title={`Game Day Scanned Check-Ins`}
      dataKeyName="Scans"
      selectedSeasonFilter={allSeasonsOption}
      dataListOptions={[membersOnlyLabel, withGuestsLabel]}
      onlyDaily={true}
      onDownload={async (options: any) => {
        let attendingOrWatching = options["dataListSelection"];
        let dataListIndex = options["dataListIndex"];
        let timeframeSpecificData =
          userOverviewPage.dailyWeeklyMonthlyCheckInData[dataListIndex][
            "daily"
          ] || {};
        let csvKeys: string[] = ["Date"];
        let cols: any = {};
        Object.keys(timeframeSpecificData).forEach((dateKey) => {
          let prevDates = getAttributeFromObject("0", cols) || [];
          prevDates.push(dateKey);
          setAttributeInObject("0", cols, prevDates);
        });
        exportAsCSVByCol(
          csvKeys,
          cols,
          `User Activity (${attendingOrWatching})`
        );
      }}
    />
  );

  return (
    <MainLayout
      loading={userOverviewPage.loading}
      firebaseConnector={userOverviewPage.firebaseConnector}
      pageActions={
        <Box display="flex" width="100%">
          <PageTitle title="Members" />
          <Box flex={1}></Box>
          {hasAdminAccess && (
            <Box>
              <Button
                color={!isFrozen ? "primary" : "secondary"}
                variant={!isFrozen ? "contained" : "outlined"}
                fullWidth
                onClick={() => promptEditDetails()}
              >
                {"Edit User"}
              </Button>
            </Box>
          )}
          {hasAdminAccess && (
            <Box ml={"10px"}>
              <Button
                color={!isFrozen ? "primary" : "secondary"}
                variant={!isFrozen ? "contained" : "outlined"}
                fullWidth
                onClick={() => promptFreezeAccount()}
              >
                {lockAccountText}
              </Button>
            </Box>
          )}
          {hasAdminAccess && (
            <Box ml={"10px"}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => promptNotification()}
              >
                Send Notification
              </Button>
            </Box>
          )}
          {hasAdminAccess && (
            <Box ml={"10px"}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => promptPasswordReset()}
              >
                Password Reset
              </Button>
            </Box>
          )}
        </Box>
      }
    >
      <Box>
        <Box height={"520px"} display={"flex"}>
          <Box flex={5} height="100%">
            <CPCard>
              <Box display={"flex"} pt="40px">
                {hasAdminAccess && (
                  <Box
                    flex="3"
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                  >
                    <Box style={cpPageTitleStyle}>{userDetails.name}</Box>
                    <Box mt={"10px"}>Joined: {dateJoined}</Box>
                    <Box height={"24px"}></Box>
                    <CPAvatar size={`120px`} teamId={teamId} avatar={avatar} />
                    <Box mt={"24px"} width={"165px"} height="50px">
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={() => promptPointChange(true)}
                      >
                        Send Points
                      </Button>
                      <Box height={"10px"}></Box>
                      <Button
                        color="secondary"
                        variant="outlined"
                        fullWidth
                        onClick={() => promptPointChange(false)}
                      >
                        Reduce Points
                      </Button>
                    </Box>
                  </Box>
                )}
                <Box
                  height={"304px"}
                  width="1px"
                  bgcolor={"rgba(247, 247, 247, 1)"}
                />
                <Box
                  padding="0px 60px 0px 60px"
                  flex="4"
                  display="flex"
                  flexDirection={"column"}
                >
                  <Box style={cpPageTitleStyle}>Personal Information</Box>
                  {Object.keys(personalInformationMap).map((key: string) => {
                    let value = personalInformationMap[key];
                    if (personalInformationMap[key]) {
                      let bgColor = getTableCellColorFromRowIndex(
                        personalInformationRowCounter
                      );
                      personalInformationRowCounter += 1;
                      return (
                        <Box
                          padding="18px"
                          bgcolor={bgColor}
                          width="100%"
                          height="52px"
                          display="flex"
                        >
                          <Box style={cpBoldSubHeaderStyle}>
                            {splitCamelCase(key, true)}
                          </Box>
                          <Box flex="1" />
                          <Box>{value}</Box>
                        </Box>
                      );
                    }
                    return <Box />;
                  })}
                </Box>
              </Box>
            </CPCard>
          </Box>
          <Box width="16px"></Box>
          <Box flex={3} height="100%">
            <CPCard>
              <Box
                padding="40px 60px 0px 60px"
                flex="4"
                display="flex"
                flexDirection={"column"}
              >
                <Box style={cpPageTitleStyle}>Details</Box>
                {Object.keys(detailsMap).map((key: string) => {
                  let value = detailsMap[key];
                  if (detailsMap[key] || Number(detailsMap[key]) === 0) {
                    let bgColor =
                      getTableCellColorFromRowIndex(detailsRowCounter);
                    detailsRowCounter += 1;
                    return (
                      <Box
                        padding="0 18px 0 18px"
                        bgcolor={bgColor}
                        width="100%"
                        height="52px"
                        display="flex"
                        alignItems={"center"}
                      >
                        <Box style={cpBoldSubHeaderStyle}>
                          {splitCamelCase(key, true)}
                        </Box>
                        <Box flex="1" />
                        <Box style={cpPageTitleStyle}>
                          {formatCommas(value)}
                        </Box>
                      </Box>
                    );
                  }
                  return <Box />;
                })}
              </Box>
            </CPCard>
          </Box>
        </Box>
      </Box>
      <Box mt={"16px"} display={"flex"}>
        <Box flex={1}>{checkInChart}</Box>
        <CPDashboardSpacer />
        <Box flex={1}>
          {isTicketScanningOnly ? scanningChart : engagementChart}
        </Box>
      </Box>
      <CPDashboardSpacer />
      {isTicketScanningOnly ? (
        <Box mb={standardDashboardPadding}>{engagementChart}</Box>
      ) : (
        <></>
      )}

      <Box height={"460px"} display={"flex"}>
        <Box flex={1}>
          <UserTransactionsTable
            height="460px"
            transactions={userOverviewPage.transactionsData || []}
          />
        </Box>
        <Box width={"16px"}></Box>
        <Box flex={1}>
          <UserExpiringTransactionsTable
            height="460px"
            transactions={userOverviewPage.expiringTransactionsData || []}
          />
        </Box>
      </Box>

      <Box width="100%" height={"460px"} mt={"24px"} display="flex">
        <Box flex={1}>
          <UserGamesPlayedTable
            height="460px"
            games={userOverviewPage.gamesAttendedData || []}
          />
        </Box>
        <Box width={"16px"}></Box>
        <Box flex={1}>
          <UserRewardsTable
            height="460px"
            rewards={userOverviewPage.rewardData || []}
          />
        </Box>
      </Box>
      <Box width="100%" mt={"16px"}>
        <UserOrdersTable
          orders={userOverviewPage.orderData || []}
          onRowClick={(order) => {
            routerStore.push(
              orderRoutes.orderDetails(teamId, order.userId, order.orderId)
            );
          }}
        />
      </Box>
      {/* <Box width="100%" mt={10}>
        <AverageActualChart
          data={userOverviewPage.pointsEarnedData}
          title="Points Earned"
          dataKeyName="Points"
        />
      </Box>

      <Box mt={10} width="100%">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <AverageActualChart
              data={userOverviewPage.dailyRewardsData}
              title="Daily (Check in) Rewards"
              dataKeyName="Count"
            />
          </Grid>
        </Grid>
      </Box> */}

      {/* <Box width="100%" mt={10} >
        <UserTransactionsTable
          transactions={userOverviewPage.transactionsData || []}
        />
      </Box>

      <Box width="100%" mt={10} >
        <UserExpiringTransactionsTable
          transactions={userOverviewPage.expiringTransactionsData || []}
        />
      </Box> */}
      {/* <Box height="700px" /> */}
    </MainLayout>
  );
});
