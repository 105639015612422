import { makeAutoObservable } from "mobx";
import { Login } from "../../types/auth-types";
import { SessionUserType, UserAccesses } from "../../types/user-types";
import { getAttributeFromObject } from "../../types/util-types";
import { API } from "../connectors/api-connector";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class SessionStore {
  public isInitialized: boolean = false;
  public user: UserAccesses | null = null;
  public error: string | null = null;
  public teamLogo: any = null;
  public teamInfo: any = {};

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
    firebaseConnector.listenForAuthStateChanged(this.saveUser);
  }

  get userId() {
    return this.user?.userId || "";
  }

  get userTeamId() {
    return this.user?.type?.identifier?.team || "Default Team";
  }

  saveUser = async (user: UserAccesses | null) => {
    this.isInitialized = true;
    console.log(`saveUser...`);
    if (user) {
      this.user = { ...this.user, ...user };
      this.error = null;
      const role = user?.type?.role;
      console.log(`session user = ${user?.email} ${role}`);
      if (
        role === SessionUserType.SPONSOR ||
        role === SessionUserType.STADIUM ||
        role === SessionUserType.CPTEAMADMIN ||
        role === SessionUserType.ADMIN ||
        role === SessionUserType.ORDERS
      ) {
        await this.loadUserColorAndLogo();
        await this.loadTeamLogo();
      } else {
        // console.log(`session role = ${role}`);
      }
    }
    console.log(`saveUser done`);
  };

  loadTeamLogo = async () => {
    const { data } = await API.post<{ teamLogo?: any }>("/getTeamLogo", {
      data: {
        teamId: this.user?.type?.identifier?.team,
      },
    });
    this.teamLogo = data.teamLogo;
  };

  loadUserColorAndLogo = async () => {
    let teamId = this.user?.type?.identifier?.team;
    let teamDetails = await this.firebaseConnector.loadTeamDetails(
      teamId || "",
    );
    let color = getAttributeFromObject(`colors/cPrimary`, teamDetails);
    let logo = getAttributeFromObject(`colors/imageUrls/i4`, teamDetails);
    this.teamInfo = teamDetails;
    if (this.user) {
      this.user = { ...this.user, color, logo };
    }
  };

  logIn = async (login: Login) => {
    console.log("hi");
    // console.log(`${login.email}, ${login.password}`);
    let result = await this.firebaseConnector.auth
      ?.signInWithEmailAndPassword(login.email, login.password)
      .catch((error) => {
        console.log("Found error: " + error);
        this.uiStore.notifications.showError(`${error}`);
      });
    console.log(`result: ${Object.keys(result || {})}`);
  };

  logOut = () => {
    this.firebaseConnector.auth?.signOut();
    this.user = null;
    this.error = null;
  };
}
