import React from "react";
import { Box, Grid } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { PageTitle } from "../../components/PageTitle";
import { useParams } from "react-router";
import {
  btcSendMode,
  GameItem,
  ingameSendMode,
  pregameSendMode,
} from "../../types/games-types";
import { GameplayCard } from "../../components/GameplayCard";
import { gamesRoutes } from "../../app-routes";

export const getDisplayTitle = (game: GameItem) => {
  if (game.awayTeam && game.homeTeam) {
    return `${game.awayTeam} @ ${game.homeTeam}`;
  } else {
    return game.title;
  }
};

export const GameplayPage = observer(() => {
  const { routerStore } = useStore();
  const { gameplayPage } = useStore().ui;
  const { gameId } = useParams<{ gameId: string }>();

  React.useEffect(() => {
    gameplayPage.loadPage(gameId);
  }, [gameId, gameplayPage]);

  const game = gameplayPage.game;

  const onSendModeClick = (sendMode: string) => {
    routerStore.push(gamesRoutes.gameplaySend(gameId, sendMode));
  };

  const onNotificationClick = () => {
    //TODO implement
  };

  const onLeaderboardClick = () => {
    //TODO implement
  };

  return (
    <MainLayout
      loading={gameplayPage.loading}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title={`Gameplay: ${getDisplayTitle(game)}`} />
        </Box>
      }
      firebaseConnector={gameplayPage.firebaseConnector}
    >
      <Grid container spacing={4}>
        <Grid container item spacing={4} xs={8}>
          <Grid item xs={6}>
            <GameplayCard
              title="Pregame"
              onClick={() => onSendModeClick(pregameSendMode)}
            />
          </Grid>
          <Grid item xs={6}>
            <GameplayCard
              title="Ingame"
              onClick={() => onSendModeClick(ingameSendMode)}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={4} xs={4}>
          <Grid item xs={6}>
            <GameplayCard
              title="Notification"
              onClick={onNotificationClick}
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <GameplayCard
              title="Beat The Crowd"
              onClick={() => onSendModeClick(btcSendMode)}
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <GameplayCard
              title="Leaderboard"
              onClick={onLeaderboardClick}
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <GameplayCard
              title="Take The Risk"
              onClick={() => onSendModeClick(pregameSendMode)}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
});
