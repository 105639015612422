import { makeAutoObservable } from "mobx";
import { verifyAllTruthy } from "../../helpers/generic-helpers";
import { Loading, loadingFunction } from "../../helpers/loading";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class StadiumGameOverviewPageStore {
  public loading: Loading = Loading.NOT_LOADED;
  private userDetailsByUserId: any = {};
  public teamId: string = "";
  private stats: any = {};
  private rewardsGiven: any = {};

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  get firstDataBlock() {
    const { members } = this.stats;
    return {
      items: [{ title: "Total Members Engaged", value: members }],
    };
  }

  loadGameOverview = async (teamId: string, gameId: string) => {
    this.teamId = teamId;
    console.log(`loadUserOverview`);
    loadingFunction(
      this,
      async () => {
        this.stats = await this.firebaseConnector.getDatabaseObjAtPath(
          `tracking/gameOverviewByTeam/${teamId}/gameDetails/${gameId}`,
        );
        this.userDetailsByUserId =
          await this.firebaseConnector.loadUsersByTeamIdAsMap(teamId);
        this.rewardsGiven = await this.firebaseConnector.getDatabaseObjAtPath(
          `rewardsGivenByGameIdByTeamId/${teamId}/${gameId}`,
        );
        console.log(`got stats:`, this.stats);
      },
      this.uiStore.notifications,
    );
  };

  get userDemographics(): any {
    return this.stats.demographicsData;
  }

  get mostEngagedMembersData(): any[] {
    let questionsAnsweredByUserId =
      this.stats["questionsAnsweredByUserIdThisGame"] || {};
    let userDetailsByUserId = this.userDetailsByUserId;
    let result: any[] = [];
    Object.keys(questionsAnsweredByUserId).forEach((userId) => {
      let nQuestionsAnswered = questionsAnsweredByUserId[userId];
      let userDetails = userDetailsByUserId[userId];
      if (!verifyAllTruthy({ userDetails })) return;
      let fullName = userDetails["fullName"];
      let nameAndAvatar = [
        userDetails["fullName"] || "",
        userDetails["avatar"],
        this.teamId,
      ];
      let newData = {
        fullName,
        nameAndAvatar,
        nQuestionsAnswered,
        userId,
      };
      result.push(newData);
    });
    return result;
  }

  get rewards(): any[] {
    let rewardsGiven = this.rewardsGiven;
    let result: any[] = [];
    let userDetailsByUserId = this.userDetailsByUserId;
    Object.keys(rewardsGiven || {}).forEach((arbitraryId) => {
      let rewardDetails = rewardsGiven[arbitraryId];
      let userId = rewardDetails["userId"];
      let rewardTitle = rewardDetails["title"];
      let rewardIndex = rewardDetails["rewardIndex"];
      let userDetails = userDetailsByUserId[userId];
      if (!verifyAllTruthy({ userDetails })) return;
      let fullName = userDetails["fullName"];
      let nameAndAvatar = [
        userDetails["fullName"] || "",
        userDetails["avatar"],
        this.teamId,
      ];
      let newData = {
        fullName,
        userId,
        nameAndAvatar,
        rewardTitle,
        rewardIndex,
      };
      result.push(newData);
    });
    return result;
  }
}
