import React from "react";
import clsx from "clsx";
import {
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  InputBase,
  Theme,
  withStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(0),
  },
}));

interface Props {
  className?: string;
  defaultInput?: boolean;
  selected: string | null;
  options?: { value: string; label: string }[];
  optionsWithMatchingLabelAndValue?: string[];
  onChange: (selected: string) => void;
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "7px 26px 7px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }),
)(InputBase);

export const CrowdSelect: React.FC<Props> = ({
  className,
  selected,
  options,
  optionsWithMatchingLabelAndValue,
  defaultInput = false,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <Select
      className={clsx(classes.select, className)}
      placeholder="Select Role"
      variant="outlined"
      value={selected}
      input={defaultInput ? undefined : <BootstrapInput />}
      onChange={({ target }) => onChange(target.value as string)}
    >
      {options?.map(({ label, value }) => {
        return (
          <MenuItem key={value} value={value || undefined}>
            {label}
          </MenuItem>
        );
      })}
      {optionsWithMatchingLabelAndValue?.map((option) => {
        return (
          <MenuItem key={option} value={option || undefined}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
};
