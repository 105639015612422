import React from "react";
import { Box } from "@material-ui/core";
import { FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import {
  longshotAnswerType,
  QuestionItem,
} from "../../../types/questions-types";
import { splitCamelCase } from "../../../helpers/name-helpers";
import { FormikTextField } from "../../../components/FormikTextField";

interface Props {
  formik: FormikProps<QuestionItem>;
  answerNumber: string;
  answerNumberLongshotPoints: string;
}

export const MultipleChoiceAnswerField: React.FC<Props> = observer(
  ({ answerNumber, answerNumberLongshotPoints, formik }) => {
    const showPoints = formik.values.answerType === longshotAnswerType;
    const answerFieldWidth = showPoints ? "85%" : "100%";
    return (
      <Box
        width="100%"
        marginBottom={4}
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box width={answerFieldWidth} key={answerNumber}>
          <FormikTextField
            name={answerNumber}
            label={splitCamelCase(answerNumber, true)}
            formik={formik}
          />
        </Box>
        {showPoints && (
          <Box width="20%" marginLeft={4} key={answerNumberLongshotPoints}>
            <FormikTextField
              name={answerNumberLongshotPoints}
              label="Points"
              formik={formik}
              onChange={({ target }) =>
                formik.setFieldValue(
                  answerNumberLongshotPoints,
                  Math.max(Number(target.value), 0),
                  true,
                )
              }
              type={"number"}
            />
          </Box>
        )}
      </Box>
    );
  },
);
