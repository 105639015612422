import { AppBar, Tabs, Tab, makeStyles } from "@material-ui/core";
import React from "react";
import { tabStyles } from "../../../helpers/style-helpers";

const useStyles = makeStyles((theme) => tabStyles(theme));

interface Props {
  selected: number;
  onTabChange: (index: number) => void;
}

export const CodesBar: React.FC<Props> = ({ selected, onTabChange }) => {
  const classes = useStyles();
  return (
    <AppBar position="static" color="transparent" className={classes.bar}>
      <Tabs
        value={selected}
        onChange={(_, value) => onTabChange(value)}
        textColor="inherit"
        variant="standard"
        classes={{ indicator: classes.tab_indicator }}
      >
        <Tab label="active" className={classes.tab} />
        <Tab label="expired" className={classes.tab} />
        <Tab label="analytics" className={classes.tab} />
      </Tabs>
    </AppBar>
  );
};
