import { Box, IconButton, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";

interface Props {
  avatar: string;
  teamId: string;
  size?: string;
}

export const CPAvatar: React.FC<Props> = ({
  avatar,
  teamId,
  size = "40px",
}) => {
  // return <div></div>;
  return (
    <Box>
      <img
        style={{ height: size, width: size }}
        src={`${`https://firebasestorage.googleapis.com/v0/b/crowdplayfirebase.appspot.com/o/avatars%2F${teamId}%2Ffr${avatar}.png?alt=media`}`}
      />
    </Box>
  );
};
