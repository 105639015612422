export interface StadiumListItem {
  [key: string]: number | string;
  stadiumName: string;
}

export interface StadiumOverviewResponse {
  allUsers: string[];
  dailyEngagement: any;
  userSpecificDailyEngagement: any;
  demographicsData: {};
  games: {};
  engagedMembers: {};
}

export const stadiumOverviewStatsDefaults = (): StadiumOverviewResponse => ({
  allUsers: [],
  dailyEngagement: {},
  userSpecificDailyEngagement: {},
  demographicsData: {},
  games: {},
  engagedMembers: {},
});

export interface StadiumOverviewGame {
  [key: string]: number | string;
  id: string;
  nUser: number;
  name: string;
}

export interface StadiumOverviewUser {
  [key: string]: number | string;
  id: string;
  name: string;
  email: string;
  engTime: number;
  nGames: number;
  points: number;
  prizes: number;
}

export interface StadiumGame {
  [key: string]: number | string;
  id: string;
  name: string;
  engTime: number;
  nUser: number;
}

export interface StadiumGameResponse {
  ages: { [key: string]: number };
  genders: { male: number; female: number; other: number };
  nNew: number;
  nRepeat: number;
  nUser: number;
  nRewards: number;
  name: string;
  sponsors: string[];
  totalCash: number;
  totalEngTime: number;
  totalExitTime: number;
  totalOtherRewards: number;
}

export const stadiumGameResponseDefaults = (): StadiumGameResponse => ({
  ages: {},
  genders: { male: 0, female: 0, other: 0 },
  name: "",
  nNew: 0,
  nRepeat: 0,
  nUser: 0,
  nRewards: 0,
  sponsors: [],
  totalCash: 0,
  totalEngTime: 0,
  totalExitTime: 0,
  totalOtherRewards: 0,
});

export interface StadiumGameUser {
  [key: string]: number | string;
  id: string;
  email: string;
  name: string;
  gender: string;
  type: string;
  age: number;
  engTime: number;
  exitTime: number;
  nAnswer: number;
  prize: number;
}
