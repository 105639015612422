import * as React from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Box, makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { loyaltyRoutes } from "../../../app-routes";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers/use-store";
import { GameListItem } from "../../../types/games-types";

const commonProps = {
  width: 100,
  flex: 1,
};

type CallFunc = (id: string) => void;

const columns = (onEdit: CallFunc, onRun: CallFunc): GridColDef[] => {
  return [
    {
      field: "gameTeamName",
      headerName: "Team",
      width: 350,
    },
    {
      field: "startDisplayTime",
      headerName: "Start Time",
      sortable: true,
      width: 150,
    },
    {
      field: "startDisplayDate",
      headerName: "Start Date",
      sortable: true,
      flex: 1,
    },
    {
      field: "edit",
      headerName: "",
      width: 100,
      // disableClickEventBubbling: true,
      renderHeader: () => <Box />,
      renderCell: (params: GridCellParams) => (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="delete-icon"
        >
          <Typography color="secondary">
            <EditOutlinedIcon
              style={{ cursor: "pointer" }}
              onClick={() => onEdit(params.row.gameId)}
            />
          </Typography>
        </Box>
      ),
    },
    {
      field: "chevron",
      headerName: "",
      width: 100,
      renderHeader: () => <Box />,
      renderCell: (params: GridCellParams) => (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography color="secondary">
            <ChevronRightIcon
              style={{ cursor: "pointer" }}
              onClick={() => onRun(params.row.gameId)}
            />
          </Typography>
        </Box>
      ),
    },
  ];
};

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    border: "transparent",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
      // color: theme.palette.text.color
    },
    "& .MuiDataGrid-colCellWrapper": {
      color: theme.palette.secondary.main,
      background: "transparent",
      // color: theme.palette.text.color
    },
    "& .MuiDataGrid-row": {
      background: theme.palette.common.white,
      marginBottom: theme.spacing(3),
      // color: theme.palette.text.color
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    // "& .ReactVirtualized__Table__headerColumn": {
    //   outline: "none",
    // },
    // "& .ReactVirtualized__Table__row": {
    //   outline: "none",
    // },
  },
}));

interface Props {
  games: GameListItem[];
  onEdit: CallFunc;
  onRun: CallFunc;
  disabled?: boolean;
}

export const GamesTable: React.FC<Props> = observer(
  ({ games, onEdit, onRun, disabled = false }) => {
    const classes = useStyles();
    return (
      <div style={{ height: 500 }}>
        {/* Ask AK this grid only sizes properly for me when the window is resized?*/}
        <DataGrid
          className={classes.grid}
          rowHeight={66}
          rows={games.map((game, id) => ({ id, ...game }))}
          columns={columns(onEdit, onRun)}
          onRowClick={(params) => onRun(params.row.gameId)}
          pageSize={5}
          disableSelectionOnClick
        />
      </div>
    );
  },
);
