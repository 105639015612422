import { Box } from "@material-ui/core";
import React from "react";
import { SortableTable } from "../../../components/SortableTable";
import { TableContainer } from "../../../components/TableContainer";
import { UserOverview } from "../../../types/user-types";

interface Props {
  users: UserOverview[];
  onRowClick?: (user: UserOverview) => void;
}

export const UsersByTeamTable: React.FC<Props> = ({ users, onRowClick }) => {
  let csvKeys: any = [
    "firstName",
    "lastName",
    "username",
    "email",
    "accountId",
    "ticketRepId",
    "birthday",
  ];
  let columns: any = [
    {
      dataKey: "firstName",
      label: "First Name",
      flexGrow: 1,
      width: 60,
    },
    {
      dataKey: "lastName",
      label: "Last Name",
      flexGrow: 1,
      width: 60,
    },
    {
      dataKey: "username",
      label: "Username",
      flexGrow: 2,
      width: 40,
    },
    {
      dataKey: "email",
      label: "Email",
      flexGrow: 2,
      width: 70,
    },
    // {
    //   dataKey: "platform",
    //   label: "Platform",
    //   flexGrow: 2,
    //   width: 70,
    // },
    {
      dataKey: "dateCreatedFriendly",
      label: "Date Created",
      flexGrow: 1,
      width: 50,
    },
    {
      dataKey: "accountId",
      label: "Account Id",
      flexGrow: 1,
      width: 50,
    },
    {
      dataKey: "ticketRepId",
      label: "Ticket Rep Id",
      flexGrow: 1,
      width: 50,
    },
  ];
  columns = columns.concat([
    {
      dataKey: "startingPoints",
      label: "Starting Points",
      numeric: true,
      flexGrow: 1,
      width: 40,
    },
    {
      dataKey: "totalPointsEarned",
      label: "Points Earned",
      numeric: true,
      flexGrow: 1,
      width: 40,
    },
    {
      dataKey: "currentPoints",
      label: "Current Points",
      numeric: true,
      flexGrow: 1,
      width: 40,
    },
    {
      dataKey: "nOrders",
      label: "# of Orders",
      numeric: true,
      flexGrow: 1,
      width: 40,
    },
    {
      dataKey: "status",
      label: "Status",
      flexGrow: 1,
      width: 60,
      cellRender: (props: any) => {
        let status = props.status;
        let isFrozen = status === "frozen";
        let isInactive = status === "inactive";
        let label;
        let bgColor;
        let dotColor;
        if (isFrozen) {
          label = "Frozen";
          bgColor = "rgba(255, 205, 215, 1)";
          dotColor = "rgba(203, 50, 68, 1)";
        } else if (isInactive) {
          label = "Inactive";
          bgColor = "rgba(243, 243, 244, 1)";
          dotColor = "rgba(78, 85, 104, 1)";
        } else {
          label = "Active";
          bgColor = "rgba(212, 249, 217, 1)";
          dotColor = "rgba(86, 191, 101, 1)";
        }
        return (
          <Box
            padding="4px 10px 4px 10px"
            height={"27px"}
            width="90px"
            bgcolor={bgColor}
            borderRadius="100px"
            display={"flex"}
            color={dotColor}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box
              bgcolor={dotColor}
              width="8px"
              height={"8px"}
              borderRadius="100px"
            ></Box>
            <Box flex={1}></Box>
            <Box fontSize="14px" fontWeight={"normal"}>
              {label}
            </Box>
            <Box flex={1}></Box>
          </Box>
        );
      },
    },
  ]);
  csvKeys = csvKeys.concat([
    "startingPoints",
    "totalPointsEarned",
    "currentPoints",
    "nOrders",
    "platform",
    "cohortName",
  ]);
  csvKeys.push("dateCreatedFriendly");
  csvKeys.push("status");
  return (
    <TableContainer>
      <SortableTable
        title="Members"
        height="100vh"
        columns={columns}
        csvKeys={csvKeys}
        rows={users}
        // @ts-ignore
        onRowClick={onRowClick}
        withChevron
      />
    </TableContainer>
  );
};
