import { Box } from "@material-ui/core";
import React from "react";
import { SortableTable } from "../../../components/SortableTable";
import { TableContainer } from "../../../components/TableContainer";

interface Props {
  orders: any[];
  onRowClick: (order: any) => void;
}

export const UserOrdersTable: React.FC<Props> = ({ orders, onRowClick }) => {
  let body =
    orders.length === 0 ? (
      <Box height={50}> No Orders </Box>
    ) : (
      <SortableTable
        title="Orders"
        height={"500px"}
        columns={[
          {
            dataKey: "name",
            label: "Item",
            width: 80,
            flexGrow: 3,
          },
          {
            dataKey: "firstVariantName",
            label: "Variant",
            flexGrow: 3,
            width: 40,
          },
          {
            dataKey: "qty",
            label: "Quantity",
            flexGrow: 1,
            width: 40,
          },
          {
            dataKey: "totalPoints",
            label: "Total Points",
            flexGrow: 1,
            width: 40,
          },
          {
            dataKey: "statusName",
            label: "Status",
            flexGrow: 2,
            width: 40,
          },
          {
            dataKey: "formattedDateOrdered",
            label: "Date Ordered",
            flexGrow: 2,
            width: 40,
          },
          {
            dataKey: "orderNumber",
            label: "Order Number",
            numeric: true,
            flexGrow: 2,
            width: 40,
          },
        ]}
        onRowClick={onRowClick}
        rows={orders}
        // @ts-ignore
      />
    );
  return <TableContainer>{body}</TableContainer>;
};
