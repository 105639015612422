import { makeAutoObservable } from "mobx";
import { orderRoutes } from "../../app-routes";
import { Loading, loadingFunction } from "../../helpers/loading";
import {
  PickupOverview,
  pickupOverviewDefaults,
} from "../../types/order-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class PickupEditPageStore {
  public pickupOverview: PickupOverview = pickupOverviewDefaults();
  public loading: Loading = Loading.NOT_LOADED;
  private _pickup: PickupOverview = pickupOverviewDefaults();
  public seasonId: string = "";

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  loadPickup = async (teamId: string, pickupId: string) => {
    if (!teamId || !pickupId) {
      window.alert("Bad parameters!");
      return;
    }
    loadingFunction(
      this,
      async () => {
        this._pickup = await this.firebaseConnector.loadPickup(
          teamId,
          pickupId
        );
      },
      this.uiStore.notifications
    );
  };

  savePickup = async (
    teamId: string,
    pickup: PickupOverview,
    sendNotification: boolean
  ) => {
    try {
      let success = true;
      if (sendNotification)
        success = await this.firebaseConnector.notifyForPickup(teamId, pickup);
      if (!success) return;
      this.loading = Loading.LOADING;
      await this.firebaseConnector.savePickup(teamId, pickup);
      this.uiStore.notifications.showSuccess("Pickup has been updated");
      this.uiStore.routerStore.push(orderRoutes.pickups(teamId));
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.loading = Loading.ERROR;
      this.uiStore.notifications.showError(`${error}`);
    }
  };

  get pickup() {
    return this._pickup;
  }
}
