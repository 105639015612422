import {
  AppBar,
  Box,
  Button,
  Grid,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { authRoutes, notificationRoutes } from "../../app-routes";
import { PageTitle } from "../../components/PageTitle";
import { isPromptProtected } from "../../helpers/generic-helpers";
import { tabButtonStyles, tabStyles } from "../../helpers/style-helpers";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import {
  notificationTypeDetails,
  PushNotificationOverview,
} from "../../types/notification-types";
import { adminTeamRoles, SessionUserType } from "../../types/user-types";
import { NotificationsByTeamTable } from "./components/NotificationsByTeamTable";
import { SortDirection } from "react-virtualized";

const useTabStyles = makeStyles((theme) => tabStyles(theme));
const useTabButtonStyles = makeStyles((theme) => tabButtonStyles(theme));
interface UrlParams {
  teamId: string;
}

export const NotificationsByTeamPage: React.FC = observer(() => {
  const urlParams = useParams<UrlParams>();
  const { notificationsByTeamPage } = useStore().ui;
  const teamId = urlParams.teamId;
  const { routerStore } = useStore();
  const tabClass = useTabStyles();
  const tabButtonClass = useTabButtonStyles();
  const [selectedStatusTypeIndex, setSelectedStatusTypeIndex] =
    React.useState<number>(
      notificationsByTeamPage.selectedStatusTypeIndex ?? 0,
    );
  const [selectedNotificationTypeIndex, setSelectedNotificationTypeIndex] =
    React.useState<number>(
      notificationsByTeamPage.selectedNotificationTypeIndex ?? 0,
    );

  React.useEffect(() => {
    notificationsByTeamPage.loadNotificationsByTeamId(
      teamId,
      selectedNotificationTypeIndex,
    );
  }, [
    urlParams,
    notificationsByTeamPage,
    selectedStatusTypeIndex,
    selectedNotificationTypeIndex,
  ]);

  let notifications =
    notificationsByTeamPage.filteredNotifications(
      selectedStatusTypeIndex,
      selectedNotificationTypeIndex,
    ) || [];
  let title = `Notifications: ${notifications.length} total`;

  const { session } = useStore().ui;
  const role = session.user?.type?.role;
  if (!adminTeamRoles.includes(role)) return <Redirect to={authRoutes.logIn} />;

  let onDelete = (notification: PushNotificationOverview) => {
    if (isPromptProtected(`You are deleting this notification, `)) {
      notificationsByTeamPage.deleteNotification(
        teamId,
        notification.notificationId,
        selectedNotificationTypeIndex,
      );
    }
  };
  let isHomeGameAlert = selectedNotificationTypeIndex > 0;

  return (
    <MainLayout
      loading={notificationsByTeamPage.loading}
      firebaseConnector={notificationsByTeamPage.firebaseConnector}
      pageActions={
        <Grid container>
          <Grid item xs={7}>
            <PageTitle title={title} />
          </Grid>
          <Grid item xs={3}>
            {/* <TextField style={{ width: "100%" }} label="Search Notifications..." onChange={onSearch} value={notificationsByTeamPage.searchInput} /> */}
          </Grid>
          <Grid item xs={2}>
            <Box ml={25} width={"300px"}>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  let newNotificationId =
                    await notificationsByTeamPage.getNewNotificationId(
                      teamId,
                      notificationTypeDetails[selectedNotificationTypeIndex]
                        .path,
                    );
                  if (newNotificationId)
                    routerStore.push(
                      notificationRoutes.notificationDetails(
                        teamId,
                        newNotificationId,
                        `${selectedNotificationTypeIndex}`,
                      ),
                    );
                }}
              >
                {`New ${notificationTypeDetails[selectedNotificationTypeIndex].label} Notification`}
              </Button>
            </Box>
          </Grid>
        </Grid>
      }
    >
      <Box>
        <AppBar
          position="static"
          color="transparent"
          className={tabButtonClass.bar}
        >
          <Box>Type:</Box>
          <Grid container>
            <Grid item xs={10}>
              <Tabs
                value={selectedNotificationTypeIndex}
                onChange={(_, value) => setSelectedNotificationTypeIndex(value)}
                textColor="inherit"
                variant="standard"
                classes={{ indicator: tabButtonClass.tab_indicator }}
              >
                <Tab
                  label="push notifications"
                  className={
                    selectedNotificationTypeIndex == 0
                      ? tabButtonClass.selectedTab
                      : tabButtonClass.tab
                  }
                />
                <Tab
                  label="home alerts"
                  className={
                    selectedNotificationTypeIndex == 1
                      ? tabButtonClass.selectedTab
                      : tabButtonClass.tab
                  }
                />
                <Tab
                  label="game alerts"
                  className={
                    selectedNotificationTypeIndex == 2
                      ? tabButtonClass.selectedTab
                      : tabButtonClass.tab
                  }
                />
              </Tabs>
            </Grid>
          </Grid>
          <Box>Status:</Box>
          <Grid container>
            <Grid item xs={10}>
              <Tabs
                value={selectedStatusTypeIndex}
                onChange={(_, value) => setSelectedStatusTypeIndex(value)}
                textColor="inherit"
                variant="standard"
                classes={{ indicator: tabButtonClass.tab_indicator }}
              >
                <Tab
                  label="scheduled"
                  className={
                    selectedStatusTypeIndex == 0
                      ? tabButtonClass.selectedTab
                      : tabButtonClass.tab
                  }
                />
                <Tab
                  label="past"
                  className={
                    selectedStatusTypeIndex == 1
                      ? tabButtonClass.selectedTab
                      : tabButtonClass.tab
                  }
                />
              </Tabs>
            </Grid>
          </Grid>
        </AppBar>

        <NotificationsByTeamTable
          notifications={notifications}
          onDelete={onDelete}
          defaultSort={
            isHomeGameAlert ? "notificationStartDateTime" : "notificationDate"
          }
          defaultSortDirection={
            selectedStatusTypeIndex == 0
              ? SortDirection.ASC
              : SortDirection.DESC
          }
          type={selectedNotificationTypeIndex}
          onRowClick={(notification: PushNotificationOverview) => {
            routerStore.push(
              notificationRoutes.notificationDetails(
                teamId,
                notification.notificationId,
                `${selectedNotificationTypeIndex}`,
              ),
            );
          }}
        />
      </Box>
    </MainLayout>
  );
});
