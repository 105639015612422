import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  title?: string;
}

export const PaperTableContainer: React.FC<Props> = ({ title, children }) => {
  return (
    <Paper>
      <Box height={400} padding={10}>
        <Typography variant="h5">{title}</Typography>
        <Box mt={4}>{children}</Box>
      </Box>
    </Paper>
  );
};
