import { makeAutoObservable } from "mobx";
import { gamesRoutes } from "../../app-routes";
import { Loading, loadingFunction } from "../../helpers/loading";
import { GameItem, gameItemDefaults } from "../../types/games-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class GameOverviewPageStore {
  public loading: Loading = Loading.NOT_LOADED;
  domainsStore: any;
  private _game: GameItem = gameItemDefaults();

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  get game() {
    return this._game;
  }

  loadFolders = async () => {
    loadingFunction(
      this,
      async () => {
        const { foldersDomain } = this.uiStore.domainsStore;
        await foldersDomain.loadFolders();
      },
      this.uiStore.notifications,
    );
  };

  loadTeams = async () => {
    loadingFunction(
      this,
      async () => {
        const { teamsDomain } = this.uiStore.domainsStore;
        await teamsDomain.loadTeams();
      },
      this.uiStore.notifications,
    );
  };

  loadRewards = async () => {
    loadingFunction(
      this,
      async () => {
        const { rewardsDomain } = this.uiStore.domainsStore;
        await rewardsDomain.loadRewards();
      },
      this.uiStore.notifications,
    );
  };

  get folderOptions() {
    const { folders } = this.uiStore.domainsStore.foldersDomain;
    return [
      ...folders.map((folder) => {
        return {
          label: folder.folderName,
          value: folder.folderId,
        };
      }),
    ];
  }

  get teamOptions() {
    const { teams } = this.uiStore.domainsStore.teamsDomain;
    return [
      ...teams.map((team) => {
        return {
          label: team.editTeamsName,
          value: team.teamId,
        };
      }),
    ];
  }

  get rewardOptions() {
    const { rewards } = this.uiStore.domainsStore.rewardsDomain;
    return [
      ...rewards.map((reward) => {
        return {
          label: reward.title,
          value: reward.rewardId,
        };
      }),
    ];
  }

  loadGame = async (gameId?: string) => {
    loadingFunction(
      this,
      async () => {
        this._game = await this.firebaseConnector.loadGameById(gameId);
      },
      this.uiStore.notifications,
    );
  };

  saveGame = async (game: GameItem) => {
    loadingFunction(
      this,
      async () => {
        await this.firebaseConnector.saveGame(game);
        this.uiStore.routerStore.push(gamesRoutes.games);
      },
      this.uiStore.notifications,
    );
  };
}
