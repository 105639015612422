import React from "react";
import { NameCell } from "../../../components/NameCell";
import { SortableTable } from "../../../components/SortableTable";
import { TableContainer } from "../../../components/TableContainer";
import { formatDuration } from "../../../helpers/time-helpers";
import { SponsorOverviewUser } from "../../../types/sponsors-types";

interface Props {
  users: SponsorOverviewUser[];
  onRowClick?: (user: SponsorOverviewUser) => void;
}

export const OverviewUsersTable: React.FC<Props> = ({ users, onRowClick }) => {
  return (
    <TableContainer title={`Top Users - Total ${users.length}`}>
      <SortableTable
        height="100vh"
        columns={[
          {
            dataKey: "name",
            label: "Name",
            flexGrow: 2,
            width: 100,
            cellRender: (props) => (
              <NameCell email={props.email} name={props.name} />
            ),
          },
          {
            dataKey: "nGames",
            label: "Game Played",
            numeric: true,
            flexGrow: 1,
            width: 100,
          },
          {
            dataKey: "nImp",
            label: "Impressions",
            numeric: true,
            flexGrow: 1,
            width: 100,
          },
          {
            dataKey: "timeAd",
            label: "Ad View Time",
            numeric: true,
            flexGrow: 1,
            width: 100,
            cellRender: (row) => (
              <span>{formatDuration(row.timeAd as number)}</span>
            ),
          },
        ]}
        rows={users}
        // @ts-ignore
        onRowClick={onRowClick}
        withChevron
      />
    </TableContainer>
  );
};
