import React, { ReactElement } from "react";
import { Box } from "@material-ui/core";

interface Props {
  title?: string | ReactElement;
}

export const TableContainer: React.FC<Props> = ({ title, children }) => {
  return (
    <Box height="100%">
      <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
        {title}
      </Box>
      <Box mt={2}>{children}</Box>
    </Box>
  );
};
