export const tabStyles = (theme: any) => {
  return {
    bar: {
      boxShadow: "none",
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    tab: {
      padding: theme.spacing(5),
      color: theme.palette.primary.main,
    },
    tab_indicator: {
      height: theme.spacing(1),
      background: theme.palette.primary,
    },
  };
};

export const tabButtonStyles = (theme: any) => {
  return {
    bar: {
      boxShadow: "none",
      // borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    tab: {
      borderRadius: 10,
      margin: theme.spacing(5),
      padding: theme.spacing(2),
      color: theme.palette.primary.main,
      // backgroundColor: theme.palette.primary.main,
    },
    selectedTab: {
      borderRadius: 10,
      margin: theme.spacing(5),
      padding: theme.spacing(2),
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
    tab_indicator: {
      height: theme.spacing(1),
      background: "transparent",
    },
  };
};

export const cpDottedRoundedBorder = "5px dotted #dddddd";
export const cpDottedRoundedBorderRadius = "20px";
