interface Props {
  text: string;
}

export const NewlineText: React.FC<Props> = ({ text }) => {
  if (!text) text = "";
  return (
    <div>
      {text.split("\n").map((str) => (
        <p>{str}</p>
      ))}
    </div>
  );
};
