import { makeAutoObservable } from "mobx";
import { Loading, loadingFunction } from "../../helpers/loading";
import { GameItem, gameItemDefaults } from "../../types/games-types";
import {
  QuestionListItem,
  allFoldersOption,
} from "../../types/questions-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class GameplaySendPageStore {
  public loading: Loading = Loading.NOT_LOADED;
  domainsStore: any;
  private _game: GameItem = gameItemDefaults();
  private _questions: QuestionListItem[] = [];
  public selectedFolderName: string = allFoldersOption.label;
  private _isStageMode = true;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  get game() {
    return this._game;
  }

  setIsStageMode(isStageMode: boolean) {
    this._isStageMode = isStageMode;
  }

  get isStageMode() {
    return this._isStageMode;
  }

  loadPage = (gameId: string) => {
    loadingFunction(
      this,
      () =>
        Promise.all([
          this.loadFolders(),
          this.loadTeams(),
          this.loadRewards(),
          this.loadQuestions(),
          this.loadGame(gameId),
        ]),
      this.uiStore.notifications,
    );
  };

  private loadFolders = async () => {
    const { foldersDomain } = this.uiStore.domainsStore;
    await foldersDomain.loadFolders();
  };

  private loadTeams = async () => {
    const { teamsDomain } = this.uiStore.domainsStore;
    await teamsDomain.loadTeams();
  };

  private loadRewards = async () => {
    const { rewardsDomain } = this.uiStore.domainsStore;
    await rewardsDomain.loadRewards();
  };

  private loadQuestions = async () => {
    this._questions = await this.firebaseConnector.loadQuestionsForDisplay();
  };

  private loadGame = async (gameId?: string) => {
    this._game = await this.firebaseConnector.loadGameById(gameId);
  };

  get questions() {
    return this._questions
      .filter(
        (question) =>
          question.questionFolder === this.selectedFolderName ||
          this.selectedFolderName === allFoldersOption.label,
      )
      .map((question) => {
        return question;
      });
  }

  onSelectFolder = (folderName: string) => {
    this.selectedFolderName = folderName;
  };

  get folderOptions() {
    const { folders } = this.uiStore.domainsStore.foldersDomain;
    return [
      allFoldersOption,
      ...folders.map((folder) => {
        return {
          label: folder.folderName,
          value: folder.folderId,
        };
      }),
    ];
  }
}
