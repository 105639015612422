import {
  AppBar,
  Avatar,
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import React from "react";
import { cpDarkBlue } from "../../helpers/constants";
import { useStore } from "../../helpers/use-store";
import { CrowdPlayLogo } from "./../CrowdPlayLogo";
import { HeaderTabs } from "./HeaderTabs";
import { SessionUserType } from "../../types/user-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    icon: {
      color: theme.palette.common.white,
    },
    userIcon: {
      background: theme.palette.common.white,
    },
    teamIcon: {
      background: theme.palette.common.white,
    },
  }),
);

interface Props {
  className?: string;
  promptTeamChange: () => void;
}

export const TopNavigation: React.FC<Props> = observer(
  ({ className, promptTeamChange }) => {
    const { session } = useStore().ui;
    // const classes = useStyles();
    // const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const role = session.user?.type?.role;
    const color = session.user?.color;
    const userIcon = session.user?.logo;
    const teamIcon = session.teamLogo;
    const barColor = color ? `#${color}` : cpDarkBlue;
    const classes = useStyles();
    const isSuperAdmin = session.user?.type?.role === SessionUserType.ADMIN;
    return (
      <AppBar
        position="static"
        className={className}
        style={{ background: barColor }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height={64}
          paddingRight={12}
          paddingLeft={12}
        >
          <Box display="flex" alignItems="center">
            <Box mr={15}>
              <CrowdPlayLogo />
            </Box>
            <HeaderTabs />
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              // className={classes.icon}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              {teamIcon ? (
                <Avatar className={classes.teamIcon} src={teamIcon} />
              ) : userIcon ? (
                <Avatar className={classes.userIcon} src={userIcon} />
              ) : (
                <AccountCircle className={classes.icon} />
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={session.logOut}>Logout</MenuItem>
              {isSuperAdmin && (
                <MenuItem onClick={promptTeamChange}>Change Team</MenuItem>
              )}
            </Menu>
          </Box>
        </Box>
      </AppBar>
    );
  },
);
