import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";

export const CloverConnectPage = observer(() => {
  const { integrationsPageStore, session } = useStore().ui;

  React.useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const teamId = session.user?.type?.identifier?.team;
    const merchantId = params.get('merchant_id');
    const code = params.get('code')

    if (!teamId || !merchantId || !code) {
      return;
    }

    integrationsPageStore.connectClover(teamId, merchantId, code);
  }, [integrationsPageStore, session]);

  return (
    <MainLayout
      loading={integrationsPageStore.loading}
      firebaseConnector={integrationsPageStore.firebaseConnector}
    >
    </MainLayout>
  );
});
