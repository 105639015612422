import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { CPDashboardSpacer } from "../../components/CPDashboardSpacer";
import { CPDataChart } from "../../components/CPDataChart";
import {
  CPMarketplaceDetailBox,
  CPMarketplaceImageScrollerBox,
  CPMarketplaceProductDetailsBox,
  CPMarketplaceLogisticsRow,
} from "../../components/CPMarketplaceComponents";
import {
  cpBoldMainHeaderStyle,
  cpBoxHeaderStyle,
  cpLargeCardHeight,
} from "../../helpers/constants";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { NoMatchPage } from "../NoMatchPage";
import { MonthlyDailyWeeklyChart } from "../../components/MonthlyDailyWeeklyChart";
import {
  exportAsCSVByCol,
  genericDemographicsDownload,
  setColEmailsAndNamesWithUserCount,
} from "../../types/util-types";
import { DemographicsChart } from "../../components/DemographicsChart";
import { combinedObject } from "../../helpers/generic-helpers";
import CPBackdrop from "../../components/CPBackdrop";

const useStyles = makeStyles((theme) => ({
  card: {
    flexBasis: theme.typography.pxToRem(200),
    flex: "1 0 auto",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      flexBasis: theme.typography.pxToRem(280),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface UrlParams {
  teamId: string;
}

export const MarketplaceProductPage = observer(() => {
  const urlParams = useParams<UrlParams>();
  const { session } = useStore().ui;
  const { teamId } = useParams<{ teamId: string }>();
  const { productId } = useParams<{ productId: string }>();
  const { user } = useStore().ui.session;
  const team = user?.type?.identifier?.team;
  if (team !== teamId) {
    //Page not found
    return <NoMatchPage />;
  }
  const { marketplaceProductPage } = useStore().ui;
  const [selectedImageIndex, setSelectedImageIndex] = React.useState<number>(0);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  //Backdrop Logic Start
  const [barBackdropOpen, setBarBackdropOpen] = React.useState<boolean>(false);
  const [barBackdropTitle, setBarBackdropTitle] = React.useState<string>("");
  const [barBackdropUserIds, setBarBackdropUserIds] = React.useState<string[]>(
    []
  );
  const [barBackdropDateKey, setBarBackdropDateKey] =
    React.useState<string>("");
  const [barBackdropSearchTerm, setBarBackdropSearchTerm] =
    React.useState<string>("");
  const [barBackdropOnDownload, setBarBackdropOnDownload] =
    React.useState<any>();
  const [barBackdropOptions, setBarBackdropOptions] = React.useState<any>({});

  const handleBackdrop = (
    userIds: string[],
    dateKey: string,
    title: string,
    func: any,
    options: any
  ) => {
    console.log(`handling backrop`);
    setBarBackdropOpen(true);
    setBarBackdropTitle(title);
    setBarBackdropUserIds(userIds);
    setBarBackdropDateKey(dateKey);
    setBarBackdropSearchTerm("");
    let newOptions = combinedObject(options, { specificDateKey: dateKey });
    setBarBackdropOptions(newOptions);
    setBarBackdropOnDownload(() => {
      return func;
    });
  };
  //Backdrop Logic End

  const { routerStore } = useStore();

  const classes = useStyles();

  const productDetails: any = marketplaceProductPage.product;
  const variantDataChart: any = marketplaceProductPage.variantDataChart;
  const productLogistics: any = marketplaceProductPage.productLogistics || [];

  const onDownloadProductOrders = async (options: any) => {
    let timeframe = options["timeframe"];
    let specificDateKey = options["specificDateKey"];
    let userIdSpecificData =
      (await marketplaceProductPage.getUserIdSpecificProductOrderData(
        timeframe,
        productId
      )) || {};
    console.log(JSON.stringify(userIdSpecificData, null, 2));
    let allUserInfo = await marketplaceProductPage.getAllUserInfo();
    let csvKeys: string[] = [];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      csvKeys.push(`${dateKey} Email`);
      csvKeys.push(`${dateKey} Name`);
      csvKeys.push(`${dateKey} Count`);
    });
    let cols: any = {};
    let colIndexArr = [0];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      setColEmailsAndNamesWithUserCount(
        colIndexArr,
        cols,
        allUserInfo,
        userIdSpecificData[dateKey] || {}
      );
    });
    exportAsCSVByCol(csvKeys, cols, `Product Orders (${timeframe})`);
  };

  React.useEffect(() => {
    marketplaceProductPage.loadOverview(teamId, productId);
  }, [marketplaceProductPage, teamId, urlParams]);

  let variantDataKeys = [
    "type",
    "points",
    "startingQuantity",
    "redeemed",
    "remaining",
  ];

  return (
    <MainLayout
      isOverview={false}
      loading={marketplaceProductPage.loading}
      firebaseConnector={marketplaceProductPage.firebaseConnector}
      pageActions={
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box display="flex">
              <Box style={cpBoldMainHeaderStyle}>Marketplace</Box>
            </Box>
          </Box>
        </div>
      }
    >
      <Box display={"flex"}>
        <CPMarketplaceImageScrollerBox
          productDetails={productDetails}
          selectedImageIndex={selectedImageIndex}
          setSelectedImageIndex={(index: number) =>
            setSelectedImageIndex(index)
          }
        />
        <CPDashboardSpacer />
        <CPMarketplaceProductDetailsBox productDetails={productDetails} />

        <CPDashboardSpacer />
        <CPMarketplaceDetailBox
          children={
            <div>
              <Box style={cpBoxHeaderStyle}>Product Logistics</Box>
              <CPDashboardSpacer />
              {productLogistics.map((logistic: any, index: number) => {
                return (
                  <CPMarketplaceLogisticsRow
                    key={index}
                    index={index}
                    logistic={logistic}
                  />
                );
              })}
            </div>
          }
        />
      </Box>
      <CPDashboardSpacer />
      <CPDataChart
        title="Variants"
        dataKeyList={variantDataKeys}
        data={marketplaceProductPage.variantDataChart}
        flexList={[3, 3, 3, 3, 3]}
        defaultSort="type"
        exportCSVKeys={variantDataKeys}
      />

      <CPDashboardSpacer />
      <Box display={"flex"}>
        <Box flex={1}>
          <Box flex={1}>
            <MonthlyDailyWeeklyChart
              title={"Redemptions"}
              dataKeyName="Product Orders"
              dailyWeeklyMonthlyDataList={
                marketplaceProductPage.dailyWeeklyMonthlyProductOrderData
              }
              onDownload={onDownloadProductOrders}
              //This function needs access to handleBackdrop from above
              handleBackdrop={async (options: any, dateKey: string) => {
                if (!marketplaceProductPage.userDetailsByUserId) {
                  await marketplaceProductPage.getAllUserInfo();
                }
                let timeframe = options["timeframe"];
                let userIdSpecificData =
                  (await marketplaceProductPage.getUserIdSpecificProductOrderData(
                    timeframe,
                    productId
                  )) || {};
                let userIds = Object.keys(userIdSpecificData[dateKey] || {});
                handleBackdrop(
                  userIds,
                  dateKey,
                  "Product Orders",
                  onDownloadProductOrders,
                  options
                );
              }}
            />
          </Box>
        </Box>
        <CPDashboardSpacer />
        <Box flex={1}>
          <Box flex={1}>
            <DemographicsChart
              demographics={marketplaceProductPage.userDemographics}
              height={cpLargeCardHeight}
              onDownload={async () => {
                let demographics =
                  await marketplaceProductPage.getUserIdSpecificDemographics(
                    productId
                  );
                let allUserInfo = await marketplaceProductPage.getAllUserInfo();
                genericDemographicsDownload(demographics, allUserInfo, true);
              }}
            />
          </Box>
        </Box>
      </Box>
      <CPBackdrop
        userDetailsByUserId={marketplaceProductPage.userDetailsByUserId}
        userIds={barBackdropUserIds}
        barBackdropDateKey={barBackdropDateKey}
        barBackdropOpen={barBackdropOpen}
        setBarBackdropOpen={setBarBackdropOpen}
        barBackdropTitle={barBackdropTitle}
        barBackdropSearchTerm={barBackdropSearchTerm}
        barBackdropOnDownload={barBackdropOnDownload}
        barBackdropOptions={barBackdropOptions}
        setBarBackdropSearchTerm={setBarBackdropSearchTerm}
      />
    </MainLayout>
  );
});
