import React from "react";
import { SortableTable } from "../../../components/SortableTable";
import { QuestionListItem } from "../../../types/questions-types";

interface Props {
  questions: QuestionListItem[];
  onRowClick: (rowData: QuestionListItem) => void;
}

export const QuestionsTable: React.FC<Props> = ({ questions, onRowClick }) => {
  return (
    <SortableTable
      height="100%"
      columns={[
        {
          dataKey: "question",
          label: "Question",
          flexGrow: 1,
          width: 100,
        },
        {
          dataKey: "questionType",
          label: "Question Type",
          flexGrow: 1,
          width: 100,
        },
        {
          dataKey: "answerType",
          label: "Answer Type",
          flexGrow: 1,
          width: 100,
        },
      ]}
      rows={questions}
      // @ts-ignore
      onRowClick={onRowClick}
      withChevron
    />
  );
};
