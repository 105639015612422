import { Box, Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React, { useRef } from "react";
import QRCode from "react-qr-code";
import { dashboardColor1 } from "../helpers/constants";
import { exportAsImage } from "../helpers/image-helpers";
import {
  cpDottedRoundedBorder,
  cpDottedRoundedBorderRadius,
} from "../helpers/style-helpers";
import { useStore } from "../helpers/use-store";
import { getColorStringFromKey } from "../types/util-types";

interface Props {
  codeText: string;
  downloadRef: any;
  showBorder?: boolean;
  height?: string;
  size?: number;
}

export const DownloadableQRCode: React.FC<Props> = ({
  codeText,
  downloadRef,
  showBorder,
  height = "2rem",
  size = 100,
}) => {
  const downloadQRCode = async () => {
    if (!codeText) {
      window.alert(`This QR Code is empty (add a code first)`);
      return;
    }
    exportAsImage(downloadRef.current, `${codeText}-qr-code`);
  };

  let border = showBorder ? cpDottedRoundedBorder : "";
  const { session } = useStore().ui;
  let color = getColorStringFromKey(dashboardColor1, session);

  return (
    <Box
      style={{ background: "white", padding: "16px" }}
      width="100%"
      border={border}
      borderRadius={cpDottedRoundedBorderRadius}
      alignContent="center"
      alignItems={"center"}
    >
      <Grid container spacing={0} direction="column" alignItems="center">
        <div
          ref={downloadRef}
          className="HpQrcode"
          style={{ padding: "2px", alignItems: "center" }}
        >
          <QRCode
            height={height}
            size={size}
            id="activeCodeQRCode"
            value={`${codeText}`}
          />
        </div>
        <Button
          fullWidth={false}
          size="large"
          variant="text"
          style={{ color }}
          onClick={downloadQRCode}
        >
          Download
        </Button>
      </Grid>
    </Box>
  );
};
