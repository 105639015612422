import { MenuItem, TextField } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";

interface Props<T = any> {
  name: string;
  label: string;
  options?: { value: string; label: string }[];
  optionsWithMatchingLabelAndValue?: string[];
  formik: FormikProps<T>;
}

export const FormikDropdown: React.FC<Props> = ({
  name,
  label,
  options,
  optionsWithMatchingLabelAndValue,
  formik,
}) => {
  const optionsToUse = options
    ? options
    : optionsWithMatchingLabelAndValue
      ? optionsWithMatchingLabelAndValue
      : null;
  if (!optionsToUse) return null;
  const error = !!formik.touched[name] && formik.errors[name];
  return (
    <TextField
      fullWidth
      name={name}
      label={label}
      variant="outlined"
      size="small"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values[name]}
      error={!!error}
      helperText={error}
      select
    >
      {options?.map(({ label, value }) => {
        return (
          <MenuItem key={value} value={value || undefined}>
            {label}
          </MenuItem>
        );
      })}
      {optionsWithMatchingLabelAndValue?.map((option) => {
        return (
          <MenuItem key={option} value={option || undefined}>
            {option}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
