import { RouterStore, syncHistoryWithStore } from "@ibm/mobx-react-router";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as history from "history";
import { SnackbarProvider } from "notistack";
import { Router } from "react-router";
import { ToastNotifications } from "./components/ToastNotifications";
import { StoreProvider } from "./helpers/store-provider";
import { APP_THEME } from "./helpers/theme";
import { Routing } from "./pages/Routing";
import { RootStore } from "./stores/root-store";

const browserHistory = history.createBrowserHistory();
const routerStore = new RouterStore();
const syncHistory = syncHistoryWithStore(browserHistory, routerStore);

const rootStore = new RootStore(routerStore);

const App = () => {
  return (
    <StoreProvider value={rootStore}>
      <ThemeProvider theme={APP_THEME}>
        <CssBaseline />
        <Router history={syncHistory}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
            <SnackbarProvider hideIconVariant>
              <Routing />
              <ToastNotifications />
            </SnackbarProvider>
          </LocalizationProvider>
        </Router>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
