import { AppBar, Tabs, Tab, makeStyles, Box } from "@material-ui/core";
import React from "react";
import { standardDashboardPadding } from "../helpers/constants";

interface Props {}

export const CPDashboardSpacer: React.FC<Props> = ({}) => {
  return (
    <Box
      width={standardDashboardPadding}
      height={standardDashboardPadding}
    ></Box>
  );
};
