import React, { FocusEventHandler } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface Props {
  className?: string;
  value?: string | null;
  label: string;
  options: string[];
  onChange: (value: string | null) => void;
  onBlur?: FocusEventHandler<any>;
}

export const CrowdAutocomplete: React.FC<Props> = ({
  className,
  value,
  label,
  options,
  onChange,
  onBlur,
}) => {
  return (
    <Autocomplete
      className={className}
      options={options}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
      onChange={(e, value: string | null) => onChange(value)}
      onBlur={onBlur}
      value={value}
    />
  );
};
