import { makeAutoObservable } from "mobx";
import { stadiumRoutes } from "../../app-routes";
import { Loading } from "../../helpers/loading";
import { filterBySeason } from "../../helpers/sort-helpers";
import { StadiumGame } from "../../types/stadiums-types";
import { API } from "../connectors/api-connector";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class StadiumGamesPageStore {
  private searchInput: string = "";
  public _games: StadiumGame[] = [];
  public loading: Loading = Loading.NOT_LOADED;
  public seasonOptions: any = [];

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  games(selectedSeasonFilter: any): StadiumGame[] {
    let games = this._games.filter(({ name }) =>
      name.toLowerCase().includes(this.searchInput.toLowerCase())
    );
    console.log("get games!");
    games = filterBySeason(selectedSeasonFilter, games, "id");
    return games;
  }

  searchStadium = (input: string) => {
    this.searchInput = input;
  };

  onGameClick = (stadiumName: string, game: StadiumGame) => {
    this.uiStore.routerStore.push(
      stadiumRoutes.stadiumGameOverview(stadiumName, game.id)
    );
  };

  loadGames = async (stadiumName: string) => {
    let teamId = this.uiStore.session.userTeamId;
    this.loading = Loading.LOADING;
    try {
      this.seasonOptions =
        await this.firebaseConnector.getSeasonOptions(teamId);
      const games = await API.post<{ data: { games: StadiumGame[] } }>(
        "/stadiumGames",
        {
          data: { stadiumId: stadiumName },
        }
      );
      this._games = games.data.data.games;
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
