import { Box, IconButton, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import { CPText } from "./CPText";
import moment from "moment";

interface Props {
  row: any;
  attribute: any;
}

export const CPDateCellRenderFromDateTime: React.FC<Props> = ({
  row,
  attribute,
}) => {
  let isValidDate = moment(row[attribute]).isValid();
  let displayDate = moment(row[attribute]).format("YY/MM/DD hh:mm A");
  return (
    <div>
      <span>{!isValidDate ? "N/A" : `${displayDate}`}</span>
    </div>
  );
};
