import { Box, Checkbox } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { CPButton } from "../../components/CPButton";
import { CPText } from "../../components/CPText";
import { PageTitle } from "../../components/PageTitle";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { getAttributeFromObject } from "../../types/util-types";

export const LoyaltyCategoryFilterPage = observer(() => {
  const { loyaltiesCategoriesFilterPage } = useStore().ui;

  React.useEffect(() => {
    loyaltiesCategoriesFilterPage.loadCategories();
  }, [loyaltiesCategoriesFilterPage]);

  let categories: any = loyaltiesCategoriesFilterPage.allCategories;

  let changeCategoryName = async (categoryId: any) => {
    let newName = window.prompt("Please enter a new name");
    if (newName && categoryId) {
      await loyaltiesCategoriesFilterPage.changeCategoryName(
        categoryId,
        newName
      );
      await loyaltiesCategoriesFilterPage.loadCategories();
    }
  };

  let toggleFilterStatus = async (categoryId: any) => {
    let enabled = getAttributeFromObject(`${categoryId}/enabled`, categories);
    if (categoryId) {
      await loyaltiesCategoriesFilterPage.changeFilterStatus(
        categoryId,
        !enabled
      );
      await loyaltiesCategoriesFilterPage.loadCategories();
    }
  };
  let categoryIds = Object.keys(categories || {});
  categoryIds.sort((a: string, b: string) => {
    let aName = getAttributeFromObject(`${a}/name`, categories);
    let bName = getAttributeFromObject(`${b}/name`, categories);
    return aName.localeCompare(bName);
  });

  return (
    <MainLayout
      loading={loyaltiesCategoriesFilterPage.loading}
      firebaseConnector={loyaltiesCategoriesFilterPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title={`Filter`} />
          <Box flex={1}></Box>
          <CPButton onClick={async () => {}}>Add New Category</CPButton>
        </Box>
      }
    >
      {categoryIds.map((categoryId: string) => {
        let name =
          getAttributeFromObject(`${categoryId}/name`, categories) || "Testing";
        return (
          <Box
            display={"flex"}
            bgcolor={"white"}
            borderRadius={"4px"}
            mb={"4px"}
            height={"70px"}
            p={"0 30px 0 30px"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <CPText marginTop={0} title={name} />
            <Box flex={1} />
            <CPText marginTop={0} title={"Filter Status"} />
            <Checkbox
              color="primary"
              onChange={() => toggleFilterStatus(categoryId)}
              checked={
                !!getAttributeFromObject(`${categoryId}/enabled`, categories)
              }
              inputProps={{ "aria-label": "secondary checkbox" }}
            />

            <Box width={"50px"} />
            <CPButton onClick={() => changeCategoryName(categoryId)}>
              Edit Name
            </CPButton>
          </Box>
        );
      })}
    </MainLayout>
  );
});
