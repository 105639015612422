import { Box, Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import { usersRoutes } from "../../app-routes";
import { CPSearchBar } from "../../components/CPSearchBar";
import { PageTitle } from "../../components/PageTitle";
import {
  errorMessageFromAllTruthy,
  isPositiveInt,
} from "../../helpers/generic-helpers";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { UserOverview } from "../../types/user-types";
import { UsersByTeamTable } from "./components/UsersByTeamTable";

interface UrlParams {
  teamId: string;
}

export const UsersByTeamPage: React.FC = observer(() => {
  const urlParams = useParams<UrlParams>();
  const { usersByTeamPage } = useStore().ui;
  const teamId = urlParams.teamId;
  const { routerStore } = useStore();
  const [searchValue, setSearchValue] = React.useState<string>("");

  React.useEffect(() => {
    usersByTeamPage.loadUsersByTeamId(teamId);
    usersByTeamPage.searchUser("");
  }, [teamId, urlParams, usersByTeamPage]);

  const onSearch: React.ChangeEventHandler<{ value: string }> =
    React.useCallback(
      (e) => usersByTeamPage.searchUser(e.target.value),
      [usersByTeamPage]
    );

  let users = usersByTeamPage.users || [];
  let title = `Members: ${users.length}`;
  let seasonId = usersByTeamPage.seasonId;

  let promptMassLoad = () => {
    let element = document.querySelector("#massLoadInput");
    element?.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    );
  };

  let clearFileFromMassLoad = () => {
    let element = document.querySelector("#massLoadInput") as HTMLInputElement;
    element.value = "";
  };

  let handleMassLoadCsv = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      clearFileFromMassLoad();
    };
    reader.onload = async ({ target }) => {
      if (!target || !target.result) {
        window.alert("Invalid CSV found");
        return;
      }
      let userIdsByEmail = usersByTeamPage.userIdsByEmail;
      let result = target.result.toString();
      let resultList = result.split("\n");
      if (resultList.length <= 1) {
        console.log("Empty CSV found");
      }
      let headers = `${resultList[0]}`.replaceAll(" ", "");
      let headersList = headers.split(",");
      let hasHeaderDiscrepancy =
        headersList.length !== 3 ||
        !headersList[0].includes("email") ||
        !headersList[1].includes("points") ||
        !headersList[2].includes("description");
      if (hasHeaderDiscrepancy) {
        window.alert(
          "Invalid CSV - Your CSV file must have these three headers, in this order, case sensitive. -> (email) (points) (description)"
        );
        return;
      }

      let functionsToRun: any = [];

      for (let i = 1; i < resultList.length; i++) {
        let row = resultList[i];
        let rowSplit = row.split(",");
        if (rowSplit.length !== 3) {
          window.alert(
            "Improperly formatted data. Make sure there are no commas in any of the emails, points, or descriptions"
          );
          return;
        }
        let email = rowSplit[0];
        let points = rowSplit[1];
        let description = rowSplit[2];
        let errorMessage = errorMessageFromAllTruthy({
          email,
          points,
          description,
        });
        let validPoints = isPositiveInt(points);
        if (errorMessage || !validPoints) {
          window.alert(
            `Improperly formatted data. Make sure you have entered valid emails, points, and descriptions for row ${i}. Points must be a positive integer.`
          );
          return;
        }
        let userId = userIdsByEmail[email];
        if (!userId) {
          window.alert(`Email not found (${email}) in row ${i}. `);
          return;
        }
        let transaction = {
          timestamp: moment().format(),
          description: `${description}`,
          metaDescription: `Mass Load ${description}`,
          points: points,
          teamId: teamId,
          seasonId: seasonId,
        };
        errorMessage = errorMessageFromAllTruthy({ teamId, seasonId });
        if (errorMessage) {
          window.alert(`${errorMessage}`);
          return;
        }
        let path = `loyaltyPoints/${userId}/${teamId}/${seasonId}`;
        // console.log(`will set ${i} ${path} to ${points}`)
        functionsToRun.push(() =>
          usersByTeamPage.setTransaction(path, transaction)
        );
      }
      for (let i = 0; i < functionsToRun.length; i++) {
        functionsToRun[i]();
      }
      // await Promise.all(functionsToRun);
      window.alert("Success!");
    };
    reader.readAsText(file);
  };

  return (
    <MainLayout
      loading={usersByTeamPage.loading}
      firebaseConnector={usersByTeamPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title={title} />
          <Box flex="1"></Box>
          <Box width={250}>
            <CPSearchBar
              onSearch={(e: any) => {
                setSearchValue(e.target.value);
                onSearch(e);
              }}
              label="Search Members"
              value={searchValue}
            />
          </Box>
          <Box width={250} ml={"12px"}>
            <input
              id="massLoadInput"
              style={{ visibility: "hidden", height: 0, width: 0 }}
              type="file"
              accept={".csv"}
              onChange={(event) => {
                let files = event.target.files || [];
                if (files?.length > 0) {
                  let file: File = files[0];
                  handleMassLoadCsv(file);
                }
              }}
            ></input>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={promptMassLoad}
            >
              {"Mass Load Points"}
            </Button>
          </Box>
        </Box>
      }
    >
      <Box>
        <UsersByTeamTable
          onRowClick={(user: UserOverview) => {
            console.log("Click");
            routerStore.push(usersRoutes.userOverview(user.userId, teamId));
          }}
          users={users}
        />
      </Box>
    </MainLayout>
  );
});
