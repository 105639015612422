import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../helpers/use-store";
import { authRoutes } from "../app-routes";

export const PrivateRoute: React.FC<RouteProps> = observer((props) => {
  const { session } = useStore().ui;

  if (!session.isInitialized) return null;
  if (!session.user) return <Redirect to={authRoutes.logIn} />;

  return <Route {...props} />;
});
