import { makeAutoObservable } from "mobx";
import { sponsorRoutes, usersRoutes } from "../../app-routes";
import { Loading } from "../../helpers/loading";
import { spacesToBackslash } from "../../helpers/name-helpers";
import { formatDuration } from "../../helpers/time-helpers";
import {
  sponsorOverviewStatsDefaults,
  SponsorOverviewStats,
  SponsorOverviewUser,
  SponsorOverviewGame,
} from "../../types/sponsors-types";
import { API } from "../connectors/api-connector";
import { UiStore } from "./ui-store";
import { FirebaseConnector } from "../connectors/firebase-connector";

export class SponsorOverviewPageStore {
  public stats: SponsorOverviewStats = sponsorOverviewStatsDefaults();
  public users: SponsorOverviewUser[] = [];
  public games: SponsorOverviewGame[] = [];
  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get firstDataBlock() {
    const { nTotalStadium, nGameSpon, nTotalImp } = this.stats;
    return {
      items: [
        { title: "Total Organizations", value: nTotalStadium },
        { title: "Games Sponsored", value: nGameSpon },
        { title: "Total Impressions", value: nTotalImp },
      ],
    };
  }

  get secondDataBlock() {
    const { nTotalImp, nUsers, totalAdTime } = this.stats;
    return {
      items: [
        {
          title: "Average Impressions",
          value: nUsers && Math.round(nTotalImp / nUsers),
        },
        { title: "Total Unique Viewers", value: nUsers },
        {
          title: "Average Ad View Time Impressions",
          value: nUsers && formatDuration(totalAdTime / nUsers),
        },
      ],
    };
  }

  get genderStats() {
    const { genders } = this.stats;
    const genderUsers = Object.values(genders).reduce((p, n) => p + n, 0);
    return {
      male: Math.round((genders.male / genderUsers) * 100),
      female: Math.round((genders.female / genderUsers) * 100),
      preferNotSay: Math.round((genders.other / genderUsers) * 100),
      maleAmount: genders.male,
      femaleAmount: genders.female,
      preferNotSayAmount: genders.other,
    };
  }

  get demographicStats() {
    return Object.keys(this.stats.ages)
      .sort((a, b) => {
        const aa = a.replace(/[^0-9 ]/g, "").slice(0, 2);
        const bb = b.replace(/[^0-9 ]/g, "").slice(0, 2);
        return Number(aa) - Number(bb);
      })
      .map((key) => {
        return {
          name: key,
          amount: this.stats.ages[key],
        };
      });
  }

  onGameRowClick = (game: SponsorOverviewGame) => {
    const [, , sponsorName] =
      this.uiStore.routerStore.location.pathname.split("/") || [];
    this.uiStore.routerStore.push(
      sponsorRoutes.sponsorGameOverview(sponsorName, game.stadium, game.id)
    );
  };

  onUserRowClick = (user: SponsorOverviewUser) => {
    const [, , sponsorName] =
      this.uiStore.routerStore.location.pathname.split("/") || [];
    this.uiStore.routerStore.push(
      usersRoutes.userBySponsor(user.id, sponsorName)
    );
  };

  loadOverview = async (sponsorName: string, selectedSeason: string) => {
    try {
      this.loading = Loading.LOADING;
      const userId = spacesToBackslash(sponsorName); // this.uiStore.session.user?.userId;

      if (!userId) throw new Error("Error: User id is not specified!");

      const years = selectedSeason.split("/");
      const [stats, users, games] = await Promise.all([
        await API.post<{ data: SponsorOverviewStats }>("/dashboard", {
          data: { userId, years },
        }),
        await API.post<{ data: { users: SponsorOverviewUser[] } }>(
          "/dashboardUsers",
          { data: { userId, years } }
        ),
        await API.post<{ data: { games: SponsorOverviewGame[] } }>(
          "/dashboardGames",
          { data: { userId, years } }
        ),
      ]);
      this.stats = stats.data.data;
      this.users = users.data.data.users;
      this.games = games.data.data.games;
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
