import { makeAutoObservable } from "mobx";
import moment from "moment";
import { globalTestingString } from "../../helpers/constants";
import { verifyAllTruthy } from "../../helpers/generic-helpers";
import { LoyaltyCategory, LoyaltyItem } from "../../types/loyalties-types";
import { API } from "../connectors/api-connector";
import { FirebaseConnector } from "../connectors/firebase-connector";

export class LoyaltiesDomainStore {
  public activeLoyalties: LoyaltyItem[] = [];
  public expiredLoyalties: LoyaltyItem[] = [];
  public featuredLoyalties: any = {};

  constructor(public firebaseConnector: FirebaseConnector) {
    makeAutoObservable(this);
  }

  private get allCodes(): { [key: string]: LoyaltyItem } {
    return [...this.activeLoyalties, ...this.expiredLoyalties].reduce<{
      [key: string]: LoyaltyItem;
    }>((acc, item) => {
      acc[item.key] = item;
      return acc;
    }, {});
  }

  loadLoyalties = async (
    surelyReload: boolean = true,
    userId: string,
    teamId: string
  ) => {
    const shouldReload = surelyReload || !this.activeLoyalties.length;
    if (shouldReload) {
      let allLoyalties: LoyaltyItem[] =
        await this.firebaseConnector.getLoyaltyCodesByTeam(teamId);
      let activeLoyalties: LoyaltyItem[] = [];
      let expiredLoyalties: LoyaltyItem[] = [];
      let featuredLoyalties: any =
        (await this.firebaseConnector.getDatabaseObjAtPath(
          `fanzoneItemsBySectionByTeam/${teamId}/activeRewards`
        )) || {};
      allLoyalties.forEach((value) => {
        let expireDate = moment(value.loyaltyCodeExpirationDate, "MM/DD/YYYY");
        if (moment().isAfter(expireDate)) {
          expiredLoyalties.push(value);
        } else {
          activeLoyalties.push(value);
        }
      });
      // const response = await API.post<{ data: { active: LoyaltyItem[]; expired: LoyaltyItem[] } }>("/loyaltyPrograms", {
      //   data: { userId, teamId },
      // });
      this.activeLoyalties = activeLoyalties;
      this.expiredLoyalties = expiredLoyalties;
      this.featuredLoyalties = featuredLoyalties;
    }
  };

  async getCategories(userId: string, teamId: string) {
    const response = await API.post<{
      data: { categories: LoyaltyCategory[] };
    }>("/loyaltyCategories", {
      data: { userId, teamId },
    });
    let categories = response.data.data.categories;
    return categories;
  }

  async getPointOptions(userId: string, teamId: string) {
    const response = await API.post<{ data: { pointOptions: string[] } }>(
      "/pointOptions",
      {
        data: { userId, teamId },
      }
    );
    let pointOptions = response.data.data.pointOptions;
    return pointOptions;
  }

  async getCurrentSeason(userId: string, teamId: string) {
    const response = await API.post<{ data: { id: string } }>(
      "/currentSeason",
      {
        data: { userId, teamId },
      }
    );
    return response.data.data.id;
  }

  async getAllSeasons(userId: string, teamId: string) {
    const response = await API.post<{
      data: { seasons: Record<string, string> };
    }>("/allSeasons", {
      data: { userId, teamId },
    });
    return response.data.data.seasons;
  }

  getNewLoyaltyId = async (loyalty: LoyaltyItem, teamId: string) => {
    return await this.firebaseConnector.getKeyFromPushedPath(
      `${globalTestingString}/loyaltyCodesByTeam/${teamId}`
    );
  };

  saveOrUpdate = async (
    loyalty: LoyaltyItem,
    teamId: string,
    isCreate: boolean
  ) => {
    if (loyalty.loyaltyCodeCode) {
      let update: any = {};
      const { loyaltyCodeCode } = loyalty;
      let loyaltyId = loyalty.key;
      let existingCode = await this.firebaseConnector.getDatabaseObjAtPath(
        `${globalTestingString}/allLoyaltyCodes/${loyaltyCodeCode}`
      );
      if (existingCode && isCreate) {
        throw new Error(`Code '${loyaltyCodeCode}' is already in use`);
      }
      let date = new Date(Date.now());
      let strDate = date.toISOString();
      loyalty.lastUpdated = strDate;
      // loyalty.loyaltyCodeMainFile = null;
      loyalty.loyaltyCodeIconFile = null;
      loyalty.loyaltyCodeBackgroundFile = null;
      if (!loyalty.loyaltyCodeNRedemptions) loyalty.loyaltyCodeNRedemptions = 0;
      console.log(`loyaltyCode: ${JSON.stringify(update, null, 2)}`);
      this.firebaseConnector.setDatabasePathAsObj(
        `${globalTestingString}/allLoyaltyCodes/${loyaltyCodeCode}`,
        loyaltyId
      );
      this.firebaseConnector.setDatabasePathAsObj(
        `${globalTestingString}/loyaltyCodesByTeam/${teamId}/${loyaltyId}`,
        loyalty
      );
    } else {
      throw new Error(`Please enter a code`);
    }
    return;

    //Need to wrap this using the campaign
    // //TODO deleteme
    // console.log("new stuff!");
    // await API.post<{ data: { success: boolean } }>("/redeemActiveRewardForUser", {
    //   data: {
    //     userId: "ebXLSttupjfoxLkQj0YOa88aqE62",
    //     activeRewardId: "-NAty0-6Eh8zb8Dd3JY2",
    //     teamId: "-M79O3HmDhAs8YN9wa5l",
    //   },
    // });
    // return;

    // console.log("new stuff!");
    // let result = await API.post<{ data: { } }>("/teamConfigDetailsByAPIKey", {
    //   data: {
    //     apiKey: "oakland",
    //   },
    // });
    // console.log(`result: ${JSON.stringify(result, null, 2)}`)
    // return;

    // if (isCreate) {
    //   const { loyaltyCodeTeamId: teamId, loyaltyId: id, ...params } = loyalty;
    //   await API.post<{ data: { success: boolean } }>("/loyaltyCreateNew", {
    //     data: {
    //       userId,
    //       loyaltyId: id,
    //       teamId,
    //       params,
    //     },
    //   });
    // } else {
    //   const { loyaltyCodeTeamId: teamId, loyaltyId: id, ...updates } = loyalty;
    //   await API.post<{ data: { success: boolean } }>("/loyaltyUpdate", {
    //     data: {
    //       userId,
    //       loyaltyId: id,
    //       teamId,
    //       updates: { ...updates, oldCode: updates.loyaltyCodeCode },
    //     },
    //   });
    // }
  };

  createCategory = async (
    categoryName: string,
    userId: string,
    teamId: string
  ) => {
    const response = await API.post<{ data: { success: boolean } }>(
      "/categoryCreate",
      {
        data: {
          userId,
          categoryName: categoryName,
          teamId,
        },
      }
    );
    console.log(`Response: ${response}`);
  };

  searchByCode = (code: string): LoyaltyItem | null => {
    return (
      [...this.activeLoyalties, ...this.expiredLoyalties].find(
        (item) => item.loyaltyCodeCode === code
      ) || null
    );
  };

  deleteCode = (code: string, userId: string, teamId: string) => {
    let loyaltyId = "";
    let teamIds = [teamId];
    let allCodes = this.allCodes;
    if (allCodes) {
      Object.keys(allCodes).forEach((codeId) => {
        let codeDetails = allCodes[codeId];
        let tempCode = codeDetails["loyaltyCodeCode"];
        if (tempCode == code) {
          loyaltyId = codeId;
          window.alert(`Found loyaltyId: ${loyaltyId}`);
        }
      });
      let loyaltyIds = [loyaltyId];
      return API.post("/loyaltyDelete", {
        data: {
          userId,
          teamIds,
          loyaltyIds,
        },
      });
    }
  };

  toggleCodeVisiblity = async (
    codeId: string,
    visibility: boolean,
    teamId: string
  ) => {
    if (!verifyAllTruthy({ codeId, teamId })) return;
    let path = `${globalTestingString}/loyaltyCodesByTeam/${teamId}/${codeId}/loyaltyCodeVisibility`;
    // console.log(`will toggle ${path} to ${visibility}`);
    await this.firebaseConnector.setDatabasePathAsObj(path, !visibility);
  };

  getCodeRedemptions = async (codeId: string, teamId: string) => {
    if (!verifyAllTruthy({ codeId, teamId })) return;
    let path = `tracking/loyaltyCodesUsedByTeam/${teamId}/${codeId}`;
    console.log(`checkin path: ${path}`);
    return await this.firebaseConnector.getDatabaseObjAtPath(path);
  };

  getUsersAbridged = async () => {
    return await this.firebaseConnector.getDatabaseObjAtPath("usersAbridged");
  };
}
