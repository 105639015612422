import React, { ReactElement } from "react";
import clsx from "clsx";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import {
  AutoSizer,
  Column,
  SortDirection,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { SortDirectionType } from "react-virtualized/dist/es/Table";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Box, Button, Grid, Icon, Paper } from "@material-ui/core";
import { extractDateForSorting } from "../helpers/time-helpers";
import moment from "moment";
import {
  CheckCircleOutline,
  CheckCircleOutlineSharp,
} from "@material-ui/icons";
import {
  exportAsCSVByRow,
  getTableCellColorFromRowIndex,
} from "../types/util-types";
import { RowData } from "../types/data-table-types";
import { CPButton } from "./CPButton";
import {
  cpBoldSubHeaderStyle,
  cpCardBoxShadow,
  dashboardColor3,
  dashboardColor4,
  dashboardColor5,
} from "../helpers/constants";
import { CPDownloadButton } from "./CPDownloadButton";
import right_square from "../assets/right_square.png";

declare module "@material-ui/core/styles/withStyles" {
  // Augment the BaseCSSProperties so that we can control jss-rtl
  interface BaseCSSProperties {
    /*
     * Used to control if the rule-set should be affected by rtl transformation
     */
    flip?: boolean;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
    },
    alignCenter: {
      justifyContent: "center",
    },
    table: {
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      "& .ReactVirtualized__Table__headerRow": {
        flip: false,
        paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
      },
      "& .ReactVirtualized__Grid:focus": {
        outline: "none",
      },
      "& .ReactVirtualized__Table__headerColumn": {
        outline: "none",
      },
      "& .ReactVirtualized__Table__row": {
        outline: "none",
      },

      // "overflow-y": "hidden!important",
      // "&:hover": {
      //   "overflow-y": "scroll!important",
      // }
      // "&:hover": {
      //   color: "black",
      //   backgroundColor: "black",
      //   outline: "4px solid red",
      //   overflow: "hidden",
      //   // overflow: "scroll",
      // },
    },
    tableRow: {},
    tableRowHover: {
      background: "white",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    head: {
      cursor: "default",
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[600],
      "&:hover": {
        color: theme.palette.grey[900],
      },
    },
    head_sorted: {
      color: theme.palette.grey[900],
    },
    tableCell: {
      fontWeight: theme.typography.fontWeightBold,
      flex: 1,
      padding: "0 25px",
    },
    noClick: {
      // cursor: "initial",
    },
    sort_asc: {
      fontSize: theme.typography.fontSize,
      transform: `rotate(270deg)`,
      transition: "all 0.3s linear",
      marginLeft: theme.spacing(1),
    },
    sort_desc: {
      transform: `rotate(90deg)`,
      transition: "all 0.3s linear",
      marginLeft: theme.spacing(1),
    },
  });

interface ColumnData {
  dataKey: string;
  label: string;
  width: number;
  numeric?: boolean;
  flexGrow?: number;
  //TODO watch out, anything that uses cellRender may sort unexpectedly
  //Maybe implement an override value vs an override rendering that can replace value?
  cellRender?: (row: RowData) => ReactElement;
  disableClickEventBubbling?: boolean;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: ColumnData[];
  rows: RowData[];
  headerHeight?: number;
  onRowClick?: (data: any) => void;
  rowHeight?: number;
  defaultSort?: string;
  defaultSortDirection?: SortDirectionType;
  withChevron: boolean;
}

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 66,
  };

  state: { sortDirection: SortDirectionType; sortBy: string } = {
    sortBy: this.props.defaultSort || "",
    sortDirection: this.props.defaultSortDirection || SortDirection.ASC,
  };

  getRowClassName = ({ index }: Row) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer: TableCellRenderer = ({
    cellData,
    columnIndex,
    rowData,
    rowIndex,
  }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    const { cellRender, disableClickEventBubbling } = columns[columnIndex];
    const inNumeric = !!columns[columnIndex]?.numeric;
    let backgroundColor = getTableCellColorFromRowIndex(rowIndex);
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
          [classes.alignCenter]: inNumeric,
        })}
        variant="body"
        style={{ height: rowHeight, backgroundColor }}
        align={columnIndex != null && inNumeric ? "center" : "left"}
      >
        {cellRender ? cellRender(rowData) : cellData}
      </TableCell>
    );
  };

  headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number }) => {
    const { sortBy, sortDirection } = this.state;
    const { headerHeight, columns, classes } = this.props;
    const sorted = columns[columnIndex]?.dataKey === sortBy;
    const inNumeric = !!columns[columnIndex]?.numeric;
    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
          classes.head,
          {
            [classes.head_sorted]: sorted,
            [classes.alignCenter]: inNumeric,
          },
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={inNumeric ? "center" : "left"}
      >
        <span>{label}</span>
        {columns[columnIndex]?.dataKey === sortBy && (
          <ArrowBackIosIcon
            className={clsx(classes.sort_asc, {
              [classes.sort_desc]: sortDirection === "DESC",
            })}
          />
        )}
      </TableCell>
    );
  };

  sort = ({ sortBy, sortDirection }: any) => {
    this.setState({ sortBy, sortDirection });
  };

  render() {
    const { sortBy, sortDirection } = this.state;
    const {
      classes,
      columns,
      rows,
      rowHeight,
      headerHeight,
      withChevron,
      ...tableProps
    } = this.props;
    const sortedRows = [...rows].sort((a, b) => {
      let aVal: string = `${a[sortBy]}`;
      let bVal: string = `${b[sortBy]}`;
      let aNum = Number(aVal);
      let bNum = Number(bVal);
      let aExtractedDate = extractDateForSorting(aVal);
      let bExtractedDate = extractDateForSorting(bVal);
      let aExtractedDateMoment = moment(aExtractedDate);
      let bExtractedDateMoment = moment(bExtractedDate);
      let isNumeric = !isNaN(Number(aVal)) && !isNaN(Number(bVal));
      let isDates = aExtractedDate && bExtractedDate;
      // if (isDates) console.log(`comparing ${aVal} to ${bVal}`);
      if (sortDirection === "DESC") {
        if (isDates)
          return aExtractedDateMoment.unix() - bExtractedDateMoment.unix();
        return isNumeric ? aNum - bNum : bVal.localeCompare(aVal);
      } else {
        if (isDates)
          return bExtractedDateMoment.unix() - aExtractedDateMoment.unix();
        return isNumeric
          ? bNum - aNum
          : `${a[sortBy]}`.localeCompare(`${b[sortBy]}`);
      }
    });
    let emptyHeaderRenderer = () => (
      <TableCell
        component="div"
        variant="head"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
          classes.head,
        )}
        style={{ height: headerHeight }}
        align="right"
      />
    );
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            className={classes.table}
            rowHeight={rowHeight!}
            gridStyle={{ direction: "inherit" }}
            headerHeight={headerHeight!}
            rowGetter={({ index }) => sortedRows[index]}
            rowClassName={this.getRowClassName}
            sort={this.sort}
            sortBy={this.state.sortBy}
            sortDirection={this.state.sortDirection}
            rowCount={sortedRows.length}
            {...tableProps}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
            {withChevron && (
              <Column
                dataKey=""
                width={75}
                className={classes.flexContainer}
                headerRenderer={emptyHeaderRenderer}
                cellRenderer={({ rowIndex }) => {
                  let backgroundColor = getTableCellColorFromRowIndex(rowIndex);
                  return (
                    <TableCell
                      component="div"
                      variant="body"
                      className={clsx(
                        classes.tableCell,
                        classes.flexContainer,
                        classes.noClick,
                      )}
                      style={{ height: rowHeight, backgroundColor }}
                      align="center"
                    >
                      <img height="35px" src={right_square}></img>
                    </TableCell>
                  );
                }}
              />
            )}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

interface Props {
  columns: ColumnData[];
  rows: RowData[];
  defaultSort?: string;
  defaultSortDirection?: SortDirectionType;
  withChevron?: boolean;
  height?: number | string;
  csvKeys?: string[];
  csvTitle?: string;
  title?: string;
  onRowClick?: (rowData: RowData) => void;
}

export const SortableTable: React.FC<Props> = ({
  columns,
  rows,
  defaultSort,
  defaultSortDirection,
  height,
  withChevron = false,
  csvKeys,
  csvTitle = "data",
  title = "",
  onRowClick,
}) => {
  if (!height) height = Math.min(4, rows.length) * 66 + 48 + 33; //66 is row height. 33 peeks the next cell so it is visible

  return (
    <Paper
      style={{
        height,
        width: "100%",
        padding: "10px",
        boxShadow: cpCardBoxShadow,
      }}
    >
      <Box
        display={"flex"}
        flexDirection="column"
        style={{ height, width: "100%" }}
      >
        {(title || csvKeys) && (
          <Box height={"50px"} display="flex" justifyContent={"space-between"}>
            <Box padding={"12px"} style={cpBoldSubHeaderStyle}>
              {title}
            </Box>
            {csvKeys && (
              <CPDownloadButton
                onDownload={() => exportAsCSVByRow(csvKeys, rows, csvTitle)}
              />
            )}
          </Box>
        )}

        <Box padding="14px" height="100%" width="100%">
          <VirtualizedTable
            rows={rows}
            columns={columns}
            defaultSort={defaultSort}
            defaultSortDirection={defaultSortDirection}
            withChevron={withChevron}
            onRowClick={({ event, rowData }: any) =>
              onRowClick && onRowClick(rowData)
            }
          />
        </Box>
      </Box>
    </Paper>
  );
};
