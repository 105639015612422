import { makeAutoObservable } from "mobx";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";
import { API } from "../connectors/api-connector";
import { IntegrationListItem } from "../../types/integration-types";
import { Loading } from "../../helpers/loading";

export class IntegrationsPageStore {
  private searchInput: string = "";
  private _integrations: IntegrationListItem[] = [];
  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  searchUser = (input: string) => {
    this.searchInput = input;
  };

  get integrations() {
    return this._integrations.filter(
      (item) =>
        !!Object.values(item).filter((v) =>
          `${v}`.toLowerCase().includes(this.searchInput)
        ).length
    );
  }

  connectClover = async (teamId: string, merchantId: string, code: string) => {
    this.loading = Loading.LOADING;

    try {
      let params = new URLSearchParams();
      params.set("team_id", teamId);
      params.set("merchant_id", merchantId);
      params.set("code", code);
      window.location.href = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/cloverOAuthCallback?${params.toString()}`;

      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };

  loadIntegrations = async () => {
    try {
      this.loading = Loading.LOADING;
      this._integrations = await this.firebaseConnector.loadAllIntegrations();
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
