import { Box, Card, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

interface Props {
  title: string;
  onClick: () => void;
  size?: "small" | "large";
}

export const GameplayCard: React.FC<Props> = ({
  title,
  onClick,
  size = "large",
}) => {
  const isLarge = size === "large";
  const marginLeft = 4;
  return (
    //Ask AK => any good way to add a primary colored border, or different bg color, on hover action?
    <Card style={{ cursor: "pointer", borderRadius: 12 }} onClick={onClick}>
      <Box
        display={"flex"}
        justifyContent="space-between"
        flexDirection={"column"}
        marginLeft={marginLeft}
        height={isLarge ? 300 : 142}
      >
        <Box
          marginTop={3}
          fontSize={isLarge ? 28 : 16}
          fontWeight="fontWeightBold"
        >
          {title}
        </Box>
        {/* <Box textAlign="right" >
          <ChevronRightIcon />
        </Box> */}
      </Box>
    </Card>
  );
};
