import React from "react";
import { DateCell } from "../../../components/DateCell";
import { SortableTable } from "../../../components/SortableTable";
import { formatDuration } from "../../../helpers/time-helpers";
import { StadiumGame } from "../../../types/stadiums-types";

interface Props {
  games: StadiumGame[];
  onRowClick?: (stadium: StadiumGame) => void;
}

export const StadiumsGamesTable: React.FC<Props> = ({ games, onRowClick }) => {
  return (
    <SortableTable
      height="100vh"
      columns={[
        {
          dataKey: "name",
          label: "Game",
          flexGrow: 2,
          width: 100,
          cellRender: (row) => <span>{`${row.name}`.replace("@", "vs")}</span>,
        },
        {
          dataKey: "id",
          label: "Date",
          numeric: true,
          flexGrow: 1,
          width: 100,
          cellRender: (row) => <DateCell date={`${row.id}`} />,
        },
        {
          dataKey: "nUser",
          label: "Total CrowdPlay Users",
          numeric: true,
          flexGrow: 1,
          width: 100,
        },
        {
          dataKey: "engTime",
          label: "Average Engagement Time",
          numeric: true,
          flexGrow: 1,
          width: 100,
          cellRender: (row) => (
            <span>
              {formatDuration(
                (row.nUser as number) &&
                  (row.engTime as number) / (row.nUser as number),
              )}
            </span>
          ),
        },
      ]}
      rows={games}
      // @ts-ignore
      onRowClick={onRowClick}
      withChevron
    />
  );
};
