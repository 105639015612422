import { makeAutoObservable } from "mobx";
import { infinitySymbol } from "../../helpers/constants";
import { Loading, loadingFunction } from "../../helpers/loading";
import { intValOrZero } from "../../helpers/number-helpers";
import { getVariantNameFromCombination } from "../../helpers/order-helpers";
import { getAttributeFromObject } from "../../types/util-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";
import { DailyWeeklyMonthlyData } from "../../types/chart-types";

export class MarketplaceProductPageStore {
  public loading: Loading = Loading.NOT_LOADED;
  public product: any = {};
  public variantDataChart: any = [];
  public teamCategories: any = {};
  public teamCollections: any = {};
  public productLogistics: any = [];
  private productOrdersByTimeframe: any = {};
  public userDetailsByUserId: any;
  private _teamId: string = "";
  private productOrdersDataUserIdSpecific: any = {};
  private demographicsData: any = {};
  private userSpecificDemographicsData: any;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get userDemographics() {
    return this.demographicsData;
  }

  getUserIdSpecificDemographics = async (productId: string) => {
    if (this.userSpecificDemographicsData)
      return this.userSpecificDemographicsData;
    this.userSpecificDemographicsData =
      await this.firebaseConnector.getDatabaseObjAtPath(
        `tracking/demographicsDataUserIdSpecificByProductIdByTeam/${this._teamId}/${productId}`
      );
    return this.userSpecificDemographicsData;
  };

  get dailyWeeklyMonthlyProductOrderData(): DailyWeeklyMonthlyData[] {
    return [this.productOrdersByTimeframe];
  }

  getAllUserInfo = async () => {
    if (this.userDetailsByUserId) return this.userDetailsByUserId;
    this.userDetailsByUserId =
      await this.firebaseConnector.getDatabaseObjAtPath(
        `userDetailsByUserIdByTeam/${this._teamId}`
      );
    return this.userDetailsByUserId;
  };

  getUserIdSpecificProductOrderData = async (
    timeframe: string,
    productId: string
  ) => {
    if (this.productOrdersDataUserIdSpecific[timeframe])
      return this.productOrdersDataUserIdSpecific[timeframe];
    let path = `tracking/${timeframe}ProductOrdersUserIdSpecificByTeam/${this._teamId}/${productId}`;
    this.productOrdersDataUserIdSpecific[timeframe] =
      await this.firebaseConnector.getDatabaseObjAtPath(path);
    console.log(
      `setting ${timeframe} product orders data to ${JSON.stringify(this.productOrdersDataUserIdSpecific[timeframe], null, 2)}`
    );
    return this.productOrdersDataUserIdSpecific[timeframe];
  };

  resetValues = () => {
    this.productLogistics = [];
    this.variantDataChart = [];
  };

  loadOverview = async (teamId: string, productId: string) => {
    this._teamId = teamId;
    this.resetValues();
    loadingFunction(
      this,
      async () => {
        this.teamCategories = await this.firebaseConnector.getDatabaseObjAtPath(
          `marketplaceCategoriesByTeam/${teamId}`
        );
        this.teamCollections =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `marketplaceCollectionsByTeam/${teamId}`
          );
        this.product = await this.firebaseConnector.getDatabaseObjAtPath(
          `marketplaceProductsByTeam/${teamId}/${productId}`
        );
        let currentQuantity: any = 0;
        let totalRedemptions = 0;
        let totalStartingQuantity: any = 0;
        let variantQtyRedeemed = this.product["variantQtyRedeemed"] || {};
        let variants = this.product["variants"];
        Object.keys(variants || {}).forEach((variantId: any) => {
          let variant = variants[variantId];
          //Data variant chart
          let variantStartingQuantity = variant["qty"];
          let variantRemaining;
          if (variantStartingQuantity == "") {
            totalStartingQuantity = infinitySymbol;
            variantStartingQuantity = infinitySymbol;
            variantRemaining = infinitySymbol;
          } else {
            variantRemaining =
              intValOrZero(variantStartingQuantity) -
              intValOrZero(variantQtyRedeemed[variantId]);
          }
          this.variantDataChart.push({
            type: getVariantNameFromCombination(variant["combination"]),
            points: variant["points"],
            startingQuantity: variantStartingQuantity,
            redeemed: variantQtyRedeemed[variantId] || 0,
            remaining: variantRemaining,
          });
          //product logistics
          let quantityRedeemed = variantQtyRedeemed[variantId];
          totalRedemptions += intValOrZero(quantityRedeemed);
          if (totalStartingQuantity != infinitySymbol) {
            totalStartingQuantity += intValOrZero(variantStartingQuantity);
          }
        });
        if (totalStartingQuantity != infinitySymbol) {
          currentQuantity = totalStartingQuantity - totalRedemptions;
        } else {
          currentQuantity = infinitySymbol;
        }
        let productType = getAttributeFromObject(
          "productType/label",
          this.product
        );
        let categories = (this.product["categories"] || [])
          .map((categoryId: any) =>
            getAttributeFromObject("name", this.teamCategories[categoryId])
          )
          .join(", ");
        let collections = (this.product["collections"] || [])
          .map((collectionId: any) =>
            getAttributeFromObject("name", this.teamCollections[collectionId])
          )
          .join(", ");
        let points = this.product["points"];
        let officialProductId = this.product["officialProductId"];
        this.productLogistics.push({
          label: "Current Quantity",
          value: currentQuantity,
        });
        this.productLogistics.push({
          label: "Starting Quantity",
          value: totalStartingQuantity,
        });
        this.productLogistics.push({
          label: "Total Redemptions",
          value: totalRedemptions,
        });
        this.productLogistics.push({
          label: "Product Type",
          value: productType,
        });
        this.productLogistics.push({
          label: "Categories",
          value: categories,
        });
        this.productLogistics.push({
          label: "Price",
          value: points,
        });
        this.productLogistics.push({
          label: "Collections",
          value: collections,
        });
        this.productLogistics.push({
          label: "Product Id",
          value: officialProductId,
        });

        this.productOrdersByTimeframe["daily"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/dailyProductOrdersByTeam/${teamId}/${productId}`
          );
        this.productOrdersByTimeframe["weekly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/weeklyProductOrdersByTeam/${teamId}/${productId}`
          );
        this.productOrdersByTimeframe["monthly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/monthlyProductOrdersByTeam/${teamId}/${productId}`
          );

        this.demographicsData =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/demographicsDataByProductIdByTeam/${teamId}/${productId}`
          );
      },
      this.uiStore.notifications
    );
  };
}
