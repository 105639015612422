import React from "react";
import { PaperTableContainer } from "../../../components/PaperTableContainer";
import { SortableTable } from "../../../components/SortableTable";
import { formatDuration } from "../../../helpers/time-helpers";
import { SponsorStadiumGameCategory } from "../../../types/sponsors-types";

interface Props {
  categories: SponsorStadiumGameCategory[];
  onRowClick?: (category: SponsorStadiumGameCategory) => void;
}

export const SponsorGameCategoriesTable: React.FC<Props> = ({
  categories,
  onRowClick,
}) => {
  return (
    <PaperTableContainer title="Category Breakdown">
      <SortableTable
        columns={[
          {
            dataKey: "category",
            label: "Category",
            flexGrow: 1,
            width: 100,
          },
          {
            dataKey: "totalAdTime",
            label: "Ad View Time",
            flexGrow: 1,
            width: 100,
            cellRender: (row) => (
              <span>{formatDuration(row.totalAdTime as number)}</span>
            ),
          },
          {
            dataKey: "nImp",
            label: "Total Impressions",
            flexGrow: 1,
            width: 100,
          },
        ]}
        rows={categories}
        // @ts-ignore
        onRowClick={onRowClick}
      />
    </PaperTableContainer>
  );
};
