import { Box, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    backgroundColor: "transparent",
    color: "black",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Adjust to center content vertically
    height: "100%",
  },
  productName: {
    textAlign: "center",
    paddingTop: "2px",
    paddingBottom: "4px",
    paddingLeft: "10px",
    paddingRight: "10px",
    justifyContent: "center",
    display: "-webkit-box", // Use this display type to enable line clamping
    "-webkit-line-clamp": 3, // Limit text to 3 lines
    "-webkit-box-orient": "vertical", // Required with -webkit-line-clamp
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal", //
    marginTop: "auto", // Pushes the text to occupy the space at the bottom if it's less than 3 lines
    marginBottom: "auto",
    height: "60px",
    // transition: "all 0.3s ease",
  },
  productImage: {
    width: "143px",
    height: "143px",
    backgroundColor: "white",
  },
}));

interface Props {
  imageUrl: string;
  name: string;
  tooltipDetails: any;
}

export const CPProductThumbnail: React.FC<Props> = ({
  imageUrl,
  name,
  tooltipDetails,
}) => {
  const classes = useStyles();
  const { currentQuantity, startingQuantity, totalRedemptions } =
    tooltipDetails;
  return (
    //On hover, add centered text
    <Tooltip
      placement="top"
      title={
        <div style={{ lineHeight: "24px", whiteSpace: "pre-wrap" }}>
          <div>{`Current Quantity: ${currentQuantity}`}</div>
          <div>{`Starting Quantity: ${startingQuantity}`}</div>
          <div>{`Total Redemptions: ${totalRedemptions}`}</div>
        </div>
      }
    >
      <Box width={"250px"} height={"250px"} className={classes.parentContainer}>
        <Box bgcolor={"white"} pt={"20px"} pb={"20px"} pl={"50px"} pr={"50px"}>
          <img className={classes.productImage} src={imageUrl} />
        </Box>
        <Box className={classes.productName}>{name}</Box>
      </Box>
    </Tooltip>
  );
};
