import { DesktopDatePicker } from "@mui/x-date-pickers";
import { FormikProps } from "formik";
import moment from "moment";
import React from "react";
import { mdyFormat } from "../types/formik-types";
import { Box, StandardTextFieldProps } from "@material-ui/core";
import { CPFormikErrorWrapper } from "./CPFormikErrorWrapper";

interface Props<T = any> {
  name: string;
  label: string;
  formik: FormikProps<T>;
}

export const FormikDatePicker: React.FC<Props> = ({ name, label, formik }) => {
  const handleActiveDateChange = (date: Date | null) => {
    formik.setFieldValue(name, moment(date).format(mdyFormat), true);
  };
  const error = !!formik.touched[name] && formik.errors[name];
  return (
    <CPFormikErrorWrapper
      child={
        <DesktopDatePicker
          format={"MM/dd/yyyy"}
          label={label}
          value={
            formik.values[name]
              ? moment(formik.values[name], mdyFormat).toDate()
              : null
          }
          onChange={handleActiveDateChange}
        />
      }
      errorText={error}
    />
  );

  // return (
  //   <Box>
  //     <DesktopDatePicker
  //       format={"MM/dd/yyyy"}
  //       label={label}
  //       value={
  //         formik.values[name]
  //           ? moment(formik.values[name], mdyFormat).toDate()
  //           : null
  //       }
  //       onChange={handleActiveDateChange}
  //     />
  //     <CPErrorText title={error} />
  //   </Box>
  // );
};
