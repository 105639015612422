import { makeAutoObservable } from "mobx";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";
import { UserListItem } from "../../types/user-types";
import { Loading } from "../../helpers/loading";

export class AllUsersPageStore {
  private searchInput: string = "";
  private _users: UserListItem[] = [];
  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  searchUser = (input: string) => {
    this.searchInput = input;
  };

  get users() {
    return this._users.filter(
      (user) =>
        !!Object.values(user).filter((v) =>
          `${v}`.toLowerCase().includes(this.searchInput),
        ).length,
    );
  }

  loadUsers = async () => {
    try {
      this.loading = Loading.LOADING;
      this._users = await this.firebaseConnector.loadAllUsers();
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
