import { Box } from "@material-ui/core";
import React from "react";

interface Props {
  title: string;
  marginTop?: number;
  fontSize?: number;
  color?: string;
}

export const CPText: React.FC<Props> = ({
  title,
  marginTop = 3,
  fontSize = 16,
  color,
}) => {
  return (
    <Box marginTop={marginTop} fontSize={fontSize} color={color}>
      {title}
    </Box>
  );
};
