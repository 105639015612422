import React, { ReactElement } from "react";
import { Box, Card, Typography } from "@material-ui/core";
import { formatCommas } from "../helpers/name-helpers";
import { CPCard } from "./CPCard";

interface Props {
  className?: string;
  title: string;
  value?: string | number;
  child?: ReactElement;
}

export const DataCard: React.FC<Props> = ({
  className,
  title,
  value,
  child,
}) => {
  return (
    <CPCard>
      <Box p="2rem" height="100%" fontWeight="fontWeightBold">
        <Typography component="div" color="secondary">
          <Box fontWeight="fontWeightBold" fontSize={14}>
            {title}
          </Box>
        </Typography>
        <Box marginTop={3} fontSize={34}>
          {child}
          {value && formatCommas(`${value}`)}
        </Box>
      </Box>
    </CPCard>
  );
};
