import moment from "moment";
import * as Yup from "yup";

export const positiveIntYup = Yup.number().positive().integer();
export const positiveOrZeroIntYup = Yup.number().moreThan(0).integer();
export const mdyFormat = "MM/DD/YYYY";
export const hMFormat = "hh:mm a";

export const todayOrFutureTest = (date: string) => {
  return Yup.string()
    .required("Date required")
    .test(date, "Date cannot be earlier than today.", function (item) {
      return !!item && moment(item, mdyFormat) >= moment();
    });
};
