import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Popover } from "./Popover";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";

interface Props {
  className?: string;
  statistics?: any[];
}

export const DemographicStatistics: React.FC<Props> = ({
  className,
  statistics = [],
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [amountOfUsers, setAmountOfUsers] = React.useState(0);

  const onMouseEnter = (data: any, _: any, event: any) => {
    setAmountOfUsers(data.amount);
    setAnchorEl(event.target);
  };

  const onMouseLeave = () => {
    setAmountOfUsers(0);
    setAnchorEl(null);
  };

  return (
    <Box width="100%" height="28rem">
      <Paper className={className}>
        <Box p="4rem" height="100%">
          <Box
            fontSize="1.2rem"
            fontWeight="fontWeightBold"
            mb="2.5rem"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <div>Demographic Statistics</div>
          </Box>
          {!statistics.length && (
            <Box
              fontSize="2rem"
              fontWeight="fontWeightBold"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              No data at this time
            </Box>
          )}
          {!!statistics.length && (
            <Box display="flex">
              <BarChart
                barCategoryGap="25%"
                width={500}
                height={300}
                data={statistics}
              >
                <CartesianGrid strokeDasharray="4 4" vertical={false} />
                <XAxis dataKey="name" />
                <YAxis />
                <Bar
                  dataKey="amount"
                  fill="#000000"
                  legendType="none"
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
              </BarChart>
            </Box>
          )}
          <Box position="relative">
            <Popover
              id="popover-demographic"
              open={Boolean(anchorEl) && !!amountOfUsers}
              anchorEl={anchorEl}
            >
              {amountOfUsers} users
            </Popover>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
