import { Box, Paper, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import moment from "moment";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatCommas } from "../helpers/name-helpers";
import { ChartProgressBar } from "./ChartProgressBar";
import { CPTooltip } from "./CPTooltip";

import left_square from "../assets/left_square.png";
import right_square from "../assets/right_square.png";
import {
  cpBoldSubHeaderStyle,
  cpCardBoxShadow,
  cpLargeCardHeight,
  dashboardColor1,
} from "../helpers/constants";
import { sleep } from "../helpers/generic-helpers";
import { clamp } from "../helpers/number-helpers";
import { useStore } from "../helpers/use-store";
import { DailyWeeklyMonthlyData } from "../types/chart-types";
import {
  copyObject,
  getAttributeFromObject,
  getColorStringFromKey,
  percentageFromNumber,
} from "../types/util-types";
import { CPDownloadButton } from "./CPDownloadButton";

interface Props {
  className?: string;
  dailyWeeklyMonthlyDataList: DailyWeeklyMonthlyData[];
  dataListOptions?: string[];
  dataKeyName?: string;
  title: string;
  debugLog?: boolean;
  onDownload?: Function;
  handleBackdrop?: Function;
  usersCount?: any;
  selectedSeasonFilter?: any;
  onlyDaily?: boolean;
  //A filter on random data points that shouldn't have been there in the first place
  ignoreLimit?: number;
}

export const MonthlyDailyWeeklyChart: React.FC<Props> = ({
  className,
  dailyWeeklyMonthlyDataList,
  dataListOptions,
  dataKeyName = "value",
  title,
  debugLog = false,
  onDownload,
  handleBackdrop,
  usersCount,
  selectedSeasonFilter,
  onlyDaily = false,
  ignoreLimit,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tooltipDate, setTooltipDateAmount] = React.useState("");
  const [tooltipAmount, setTooltipAmount] = React.useState("");
  const [tooltipPercentage, setTooltipPercentage] = React.useState("");
  const [timeframe, setTimeframe] = React.useState<string>("daily");
  //TODO will have to change filter system probably based on a map of timeframe and other data list options
  const [dailyIndexFilter, setDailyIndexFilter] = React.useState<number>(-1);
  const [weeklyIndexFilter, setWeeklyIndexFilter] = React.useState<number>(-1);
  const [monthlyIndexFilter, setMonthlyIndexFilter] =
    React.useState<number>(-1);
  const [indexFilterByKey, setIndexFilterByKey] = React.useState<any>({});
  const [dataListIndex, setDataListIndex] = React.useState<number>(0);
  const { session, notifications } = useStore().ui;
  const color = getColorStringFromKey(dashboardColor1, session);

  let pointsShownCount = 10;
  let dailyWeeklyMonthlyData: DailyWeeklyMonthlyData =
    dailyWeeklyMonthlyDataList[dataListIndex || 0];

  const getSeasonMoment = (seasonDate: string) => {
    if (!seasonDate) {
      return null;
    }
    return moment(seasonDate);
  };

  let seasonStartMoment: any;
  let seasonEndMoment: any;
  if (selectedSeasonFilter) {
    seasonStartMoment = getSeasonMoment(selectedSeasonFilter["startDate"]);
    seasonEndMoment = getSeasonMoment(selectedSeasonFilter["endDate"]);
  }

  const onClickBar = async (data: any, _: any, event: any) => {
    console.log(`click`, event);
    if (event.target && handleBackdrop) {
      notifications.showSuccess("Request received, please wait a moment.");
      await sleep(50);
      let dateKey = data["dateKey"];
      let options = getCurrentDataOptions();
      await handleBackdrop(options, dateKey);
    }
  };

  const onMouseEnter = (data: any, _: any, event: any) => {
    if (event.target) {
      setAnchorEl(event.target);
      let dateKey = data["dateKey"];
      if (usersCount) {
        let totalUsersAtEvent = getAttributeFromObject(
          `${timeframe}/${dateKey}`,
          usersCount
        );
        setTooltipPercentage(percentageFromNumber(Number(totalUsersAtEvent)));
      }
      setTooltipAmount(`${formatCommas(data[dataKeyName])}`);
      setTooltipDateAmount(data.name);
    }
  };

  const onMouseLeave = () => {
    setTooltipAmount("");
    setTooltipDateAmount("");
    setAnchorEl(null);
  };

  const handleTimeframeChange = (event: any, newTimeframe: string) => {
    setAnchorEl(null);
    if (newTimeframe) setTimeframe(newTimeframe);
  };

  const chartStateKey = () => {
    return `${timeframe}_${dataListIndex}`;
  };

  const setClampedIndexFilter = (newVal: number) => {
    newVal = clamp(newVal, 0, cDKeys.length - pointsShownCount);
    let tempIndexFilterByKey = copyObject(indexFilterByKey);
    let key = chartStateKey();
    tempIndexFilterByKey[key] = newVal;
    setIndexFilterByKey(tempIndexFilterByKey);
    // if (timeframe=="daily") setDailyIndexFilter(newVal);
    // if (timeframe=="weekly") setWeeklyIndexFilter(newVal);
    // if (timeframe=="monthly") setMonthlyIndexFilter(newVal);
  };

  let currentData: any = dailyWeeklyMonthlyData[timeframe] || {};

  let isValidData = !!Object.keys(currentData).length;
  let currentArray: any[] = [];
  let parsingFormat = "YYYY_MM_DD";
  let displayFormat = "MMM DD, YY";
  if (timeframe === "monthly") {
    parsingFormat = "YYYY_MM";
    displayFormat = "MMM, YY";
  }

  let cDKeys = Object.keys(currentData);
  if (seasonStartMoment && seasonEndMoment) {
    cDKeys = cDKeys.filter((a) => {
      return moment(a, parsingFormat).isBetween(
        seasonStartMoment,
        seasonEndMoment
      );
    });
  }
  cDKeys = cDKeys.sort((a, b) => {
    return a.localeCompare(b);
  });
  let cAIndex = 0;
  let tempIndexFilter = -1;
  let currentIndexFilter: any = indexFilterByKey[chartStateKey()];
  if (!isNaN(currentIndexFilter))
    tempIndexFilter = Number(`${currentIndexFilter}`);
  // if (timeframe=="daily") tempIndexFilter = dailyIndexFilter;
  // if (timeframe=="weekly") tempIndexFilter = weeklyIndexFilter;
  // if (timeframe=="monthly") tempIndexFilter = monthlyIndexFilter;
  if (tempIndexFilter == -1) {
    tempIndexFilter = Math.max(0, cDKeys.length - pointsShownCount);
  }
  tempIndexFilter = clamp(tempIndexFilter, 0, cDKeys.length - 1);

  for (
    let i = tempIndexFilter;
    i < Math.min(cDKeys.length, tempIndexFilter + pointsShownCount);
    i++
  ) {
    let key = cDKeys[i];
    let value: any = currentData[key];
    if (ignoreLimit !== undefined && value < ignoreLimit) continue;
    let percentage;

    if (usersCount) {
      let totalUsersAtEvent = getAttributeFromObject(
        `${timeframe}/${key}`,
        usersCount
      );
      percentage = percentageFromNumber(
        Number(value) / Number(totalUsersAtEvent)
      );
    }
    currentArray[cAIndex] = {
      key: key,
      name: `${moment(key, parsingFormat).format(displayFormat)}`,
      [dataKeyName]: value || 0,
      dateKey: key,
      percentage,
    };
    cAIndex++;
  }
  let rOArray: any = currentArray;
  // let rOArray: ReadonlyArray<object> = currentArray;
  // let rOArray: ReadonlyArray<object> = currentArray;

  const ChartToggle: React.FC<ChartToggleProps> = ({ direction }) => {
    let isLeft = direction == "left";
    let disabled =
      (isLeft && tempIndexFilter == 0) ||
      (!isLeft && tempIndexFilter + pointsShownCount >= cDKeys.length);
    return (
      <Typography color="secondary">
        <img
          src={isLeft ? left_square : right_square}
          style={{
            cursor: "pointer",
            height: "30px",
            opacity: disabled ? 0.5 : 1,
          }}
          onClick={(event) => {
            if (disabled) return;
            let change = pointsShownCount * (isLeft ? -1 : 1);
            let newVal = tempIndexFilter + change;
            console.log(`changing ${tempIndexFilter} to ${newVal}`);
            setClampedIndexFilter(newVal);
          }}
        />
      </Typography>
    );
  };

  let getCurrentDataOptions = () => {
    let dataListSelection = "";
    if (dataListOptions) dataListSelection = dataListOptions[dataListIndex];
    let options = {
      timeframe,
      dataListSelection,
      dataListIndex,
    };
    return options;
  };

  let currentDataDownload;
  if (onDownload) {
    currentDataDownload = () => {
      let options = getCurrentDataOptions();
      onDownload(options);
    };
  }

  return (
    <Paper style={{ boxShadow: cpCardBoxShadow, height: cpLargeCardHeight }}>
      <Box padding={"10px"} paddingLeft="20px" height="100%" width={"100%"}>
        <Box
          fontSize="1.2rem"
          fontWeight="fontWeightBold"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={"10px"}
        >
          <Box style={cpBoldSubHeaderStyle}>{title}</Box>
          <CPDownloadButton onDownload={currentDataDownload} />
        </Box>
        {
          <Box
            fontSize="1.2rem"
            fontWeight="fontWeightBold"
            mb="2.5rem"
            height="50px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            paddingRight={"10px"}
          >
            {!onlyDaily && (
              <ToggleButtonGroup
                value={timeframe}
                onChange={handleTimeframeChange}
                aria-label="timeframe"
                exclusive={true}
              >
                <ToggleButton value="daily" aria-label="italic">
                  Daily
                </ToggleButton>
                <ToggleButton value="weekly" aria-label="italic">
                  Weekly
                </ToggleButton>
                <ToggleButton value="monthly" aria-label="bold">
                  Monthly
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            {dataListOptions && (
              <ToggleButtonGroup
                value={dataListIndex}
                onChange={(s, index) => {
                  if (index !== null) setDataListIndex(index);
                }}
                aria-label="timeframe"
                exclusive={true}
              >
                {dataListOptions.map((s, i) => {
                  return (
                    <ToggleButton
                      key={`key-${s}`}
                      value={i}
                      aria-label="italic"
                    >
                      {s}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            )}
          </Box>
        }
        {!isValidData && (
          <Box
            fontSize="2rem"
            fontWeight="fontWeightBold"
            display="flex"
            paddingTop={"80px"}
            justifyContent="center"
            alignItems="center"
          >
            No data at this time
          </Box>
        )}
        {!!isValidData && (
          <Box paddingRight={"10px"}>
            <ResponsiveContainer height={250} width="99%">
              <BarChart barCategoryGap="25%" data={rOArray}>
                <CartesianGrid strokeDasharray="4 4" vertical={false} />
                <XAxis dataKey={"name"} />
                <YAxis />

                <Bar
                  dataKey={dataKeyName}
                  fill={color}
                  legendType="none"
                  onClick={onClickBar}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
                <Tooltip
                  content={<CPTooltip />}
                  cursor={{ fill: "#000000", fillOpacity: 0.04 }}
                  isAnimationActive={false}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        )}
        {/* {isValidData && (
        )} */}
        {isValidData && (
          <Box
            fontSize="1.2rem"
            fontWeight="fontWeightBold"
            display="flex"
            alignItems="center"
            width="100%"
            paddingLeft={"60px"}
            paddingRight="10px"
            paddingTop="10px"
          >
            <Box width={"100%"}>
              <ChartProgressBar
                cur={tempIndexFilter}
                onChange={(e: any, value: any) => setClampedIndexFilter(value)}
                spread={pointsShownCount}
                length={cDKeys.length}
              />
            </Box>
            <Box display="flex" justifyContent={"space-around"}>
              <Box width={"10px"}></Box>
              <ChartToggle direction="left" />
              <Box width={"5px"}></Box>
              <ChartToggle direction="right" />
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

interface ChartToggleProps {
  direction: string;
}
