import { isNumber } from "./number-helpers";

export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const isPositiveInt = (input: any) => {
  let number = Number(input);
  return Number.isInteger(number) && number > 0;
};

export const printErrorMessageFromAllTruthy = (vals: any) => {
  let errorMessage = errorMessageFromAllTruthy(vals);
  if (errorMessage) console.log(errorMessage);
  return errorMessage;
};

export const alertErrorMessageFromAllTruthy = (vals: any) => {
  let errorMessage = errorMessageFromAllTruthy(vals);
  if (errorMessage) window.alert(errorMessage);
  return errorMessage;
};

export const errorMessageFromAllTruthy = (vals: any) => {
  let errorMessage;
  let keys = Object.keys(vals);
  for (let i = 0; i < keys.length; i += 1) {
    if (!vals[keys[i]]) {
      console.log(`Bad ${keys[i]}`);
      if (!errorMessage) errorMessage = [];
      errorMessage.push(`Bad ${keys[i]}. `);
    }
  }
  return errorMessage;
};

export const verifyAllTruthy = (vals: any) => {
  let allDefined = true;
  let keys = Object.keys(vals);
  for (let i = 0; i < keys.length; i += 1) {
    if (!vals[keys[i]]) {
      // console.log(`Bad ${keys[i]}`);
      allDefined = false;
    }
  }

  return allDefined;
};

export const combinedObject = (result: any, object: any) => {
  // console.log(`combinedObject comparing`)
  // console.log(`RESULT: ${JSON.stringify(result)}`)
  // console.log(`OBJECT: ${JSON.stringify(object)}`)
  Object.keys(object).forEach((key) => {
    let validKey = key as keyof typeof result;
    if (result[validKey] == null) {
      result[validKey] = object[key];
    } else if (isNumber(result[validKey])) {
      result[validKey] = result[validKey] + object[key];
    } else if (typeof result[validKey] === "object") {
      result[validKey] = combinedObject(result[validKey], object[key]);
    }
  });
  return result;
};

export const mapOfUserIdsByPossibleValuesOfKeyInObject = (
  key: string,
  object: any,
  defafultValue: any = null,
) => {
  let result: any = {};
  Object.keys(object).forEach((userId: string) => {
    let objectDetails = object[userId] || {};
    let possibleValue = objectDetails[key] || defafultValue;
    if (possibleValue) {
      let prevList = result[possibleValue] || [];
      prevList.push(userId);
      result[possibleValue] = prevList;
    } else {
      console.log(`could not find ${key} in`, objectDetails);
    }
  });
  console.log(`totalLength: ${Object.keys(object).length}`);
  return result;
};

export const isPromptProtected = (message: string) => {
  let result = window.prompt(`${message}enter 'yes' to confirm.`) === "yes";
  if (!result) window.alert("Invalid confirmation. Now aborting.");
  return result;
};
