import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { useParams } from "react-router-dom";
import { StadiumGame } from "../../types/stadiums-types";
import { StadiumsGamesTable } from "./components/StadiumsGamesTable";
import { PageTitle } from "../../components/PageTitle";
import { CrowdSelect } from "../../components/CrowdSelect";
import { allSeasonsOption } from "../../helpers/constants";
import { CPDropdown } from "../../components/CPDropdown";

export const StadiumGamesPage = observer(() => {
  const { stadiumName } = useParams<{ stadiumName: string }>();
  const { stadiumGamesPage } = useStore().ui;

  React.useEffect(() => {
    stadiumGamesPage.loadGames(stadiumName);
  }, [stadiumGamesPage, stadiumName]);

  let seasonOptions = stadiumGamesPage.seasonOptions;
  const [selectedSeasonFilter, setSelectedSeasonFilter] =
    React.useState<any>(allSeasonsOption);

  const onSearch: React.ChangeEventHandler<{ value: string }> =
    React.useCallback(
      (e) => stadiumGamesPage.searchStadium(e.target.value),
      [stadiumGamesPage],
    );

  const onRowClick = React.useCallback(
    (stadium: StadiumGame) => {
      stadiumGamesPage.onGameClick(stadiumName, stadium);
    },
    [stadiumName, stadiumGamesPage],
  );

  return (
    <MainLayout
      loading={stadiumGamesPage.loading}
      firebaseConnector={stadiumGamesPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Grid container>
            <Grid item xs={10}>
              <PageTitle title="Games" />
            </Grid>
            <Grid item xs={2}>
              <Box width={250}>
                <TextField
                  style={{ width: "100%" }}
                  label="Search Games..."
                  onChange={onSearch}
                />
              </Box>
            </Grid>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <CPDropdown
                options={seasonOptions}
                selectedOptionFilter={selectedSeasonFilter}
                setSelectedOptionFilter={setSelectedSeasonFilter}
                allOption={allSeasonsOption}
              />
            </Grid>
          </Grid>
        </Box>
      }
    >
      <StadiumsGamesTable
        games={stadiumGamesPage.games(selectedSeasonFilter) || {}}
        onRowClick={onRowClick}
      />
    </MainLayout>
  );
});
