import {
  AppBar,
  Box,
  Button,
  Grid,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { orderRoutes } from "../../app-routes";
import { PageTitle } from "../../components/PageTitle";
import { isPromptProtected } from "../../helpers/generic-helpers";
import { tabButtonStyles, tabStyles } from "../../helpers/style-helpers";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { PickupOverview } from "../../types/order-types";
import { PickupsByTeamTable } from "./components/PickupsByTeamTable";

const useTabStyles = makeStyles((theme) => tabStyles(theme));
const useTabButtonStyles = makeStyles((theme) => tabButtonStyles(theme));
interface UrlParams {
  teamId: string;
}

export const PickupsByTeamPage: React.FC = observer(() => {
  const urlParams = useParams<UrlParams>();
  const { pickupsByTeamPage } = useStore().ui;
  const teamId = urlParams.teamId;
  const { routerStore } = useStore();
  const tabClass = useTabStyles();
  const tabButtonClass = useTabButtonStyles();
  const [selectedPickupPeriodIndex, setSelectedPickupPeriodIndex] =
    React.useState<number>(0);

  React.useEffect(() => {
    pickupsByTeamPage.loadPickupsByTeamId(teamId, selectedPickupPeriodIndex);
  }, [urlParams, pickupsByTeamPage, selectedPickupPeriodIndex]);

  let promptDelete = async (pickup: PickupOverview) => {
    if (isPromptProtected(`You are about to delete this pickup.`)) {
      await pickupsByTeamPage.deletePickup(teamId, pickup);
      pickupsByTeamPage.loadPickupsByTeamId(teamId, selectedPickupPeriodIndex);
    }
  };

  return (
    <MainLayout
      loading={pickupsByTeamPage.loading}
      firebaseConnector={pickupsByTeamPage.firebaseConnector}
      pageActions={
        <Grid container>
          <Grid item xs={12}>
            <PageTitle title={"Edit Pickup Dates"} />
          </Grid>
        </Grid>
      }
    >
      <Box>
        <AppBar
          position="static"
          color="transparent"
          className={tabButtonClass.bar}
        >
          <Grid container>
            <Grid item xs={11}>
              <Tabs
                value={selectedPickupPeriodIndex}
                onChange={(_, value) => setSelectedPickupPeriodIndex(value)}
                textColor="inherit"
                variant="standard"
                classes={{ indicator: tabButtonClass.tab_indicator }}
              >
                <Tab
                  label="Upcoming"
                  className={
                    selectedPickupPeriodIndex == 0
                      ? tabButtonClass.selectedTab
                      : tabButtonClass.tab
                  }
                />
                <Tab
                  label="Past"
                  className={
                    selectedPickupPeriodIndex == 1
                      ? tabButtonClass.selectedTab
                      : tabButtonClass.tab
                  }
                />
              </Tabs>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  let newPickupId =
                    await pickupsByTeamPage.getNewPickupId(teamId);
                  if (newPickupId)
                    routerStore.push(
                      orderRoutes.pickupEdit(teamId, newPickupId),
                    );
                }}
              >
                Add a pickup
              </Button>
            </Grid>
          </Grid>
        </AppBar>
        <PickupsByTeamTable
          pickups={pickupsByTeamPage._filteredPickups}
          onDelete={(pickup: any) => {
            promptDelete(pickup);
          }}
          onRowClick={(pickup: PickupOverview) => {
            routerStore.push(orderRoutes.pickupEdit(teamId, pickup.pickupId));
          }}
        />
      </Box>
    </MainLayout>
  );
});
