import React from "react";
import { SortableTable } from "../../../components/SortableTable";
import { SponsorStadium } from "../../../types/sponsors-types";

interface Props {
  stadiums: SponsorStadium[];
  onRowClick?: (stadium: SponsorStadium) => void;
}

export const SponsorStadiumsTable: React.FC<Props> = ({
  stadiums,
  onRowClick,
}) => {
  return (
    <SortableTable
      height="100vh"
      columns={[
        {
          dataKey: "stadiumName",
          label: "Stadium",
          flexGrow: 2,
          width: 100,
        },
        {
          dataKey: "nGameSpon",
          label: "Games Sponsored",
          numeric: true,
          flexGrow: 1,
          width: 100,
        },
        {
          dataKey: "nImp",
          label: "Total Impressions",
          numeric: true,
          flexGrow: 1,
          width: 100,
        },
        {
          dataKey: "nUsers",
          label: "Total Unique Users",
          numeric: true,
          flexGrow: 1,
          width: 100,
        },
      ]}
      rows={stadiums}
      // @ts-ignore
      onRowClick={onRowClick}
      withChevron
    />
  );
};
