import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import moment from "moment";
import randomize from "randomatic";
import React, { useRef } from "react";
import * as Yup from "yup";
import { CPColorPicker } from "../../../components/CPColorPicker";
import { CPInfoButton } from "../../../components/CPInfoButton";
import { CPText } from "../../../components/CPText";
import { DownloadableQRCode } from "../../../components/DownloadableQRCode";
import { optionalText } from "../../../helpers/name-helpers";
import { todayOrFutureTest } from "../../../types/formik-types";
import { LoyaltyItem } from "../../../types/loyalties-types";
import { getAttributeFromObject } from "../../../types/util-types";
import { FileUploader } from "./FileUploader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginTop: 0,
      width: "100%",
    },
  })
);

const validationSchema = (allCodes: string[], loyalty: LoyaltyItem) => {
  return Yup.object().shape({
    loyaltyCodeTitle: Yup.string()
      .min(3, "Value should be at least 3 characters")
      .required("Title required"),
    loyaltyCodeCode: Yup.string()
      .matches(
        /^[a-zA-Z0-9]*$/,
        "Value should consist of alphanumeric characters"
      )
      .min(4, "Value must be at least 4 characters")
      // .max(6, "Value must be 6 characters")
      .test("In Use", "Code is already in use", function (this, item) {
        if (allCodes[this.parent.loyaltyCodeCode] && !loyalty.key) return false;
        return true;
      })
      .required(),
    loyaltyCodeActiveDate: Yup.string().required("Active date required"),
    loyaltyCodeCategory: Yup.string().required(),
    loyaltyCodeDescription: Yup.string().required().notRequired(),
    loyaltyCodeTextColor: Yup.string().required().notRequired(),
    loyaltyCodeExpirationDate: todayOrFutureTest("loyaltyCodeExpirationDate"),
    // seasonId: Yup.string().required("Season required (Reach out to CrowdPlay if no season is available)"),
    loyaltyCodeLink: Yup.string()
      .transform((value, originalValue) => (!value ? null : originalValue))
      .matches(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        "Value is not valid."
      ),
    loyaltyCodePoints: Yup.number()
      .min(0, "Value should be at least 0")
      .required(),
    loyaltyCodeRedemptionFormat: Yup.string().required(
      "Redemption Format Required"
    ),
    loyaltyCodeGeofence: Yup.object().when("loyaltyCodeRedemptionFormat", {
      is: (value: any) => value === "geofence",
      then: Yup.object().required("Must have a geofence"),
      otherwise: Yup.object().nullable(),
    }),
    loyaltyCodeVisibility: Yup.boolean(),
    loyaltyCodeButtonName: Yup.string(),
    loyaltyCodeIsOneTimeCode: Yup.boolean(),
    loyaltyCodeIsExclusive: Yup.boolean(),
    // loyaltyCodeMainFileUrl: Yup.string().nullable().notRequired(),
    // loyaltyCodeMainFile: Yup.mixed().nullable().notRequired(),
    loyaltyCodeBackgroundFileUrl: Yup.string().nullable().notRequired(),
    loyaltyCodeBackgroundFile: Yup.mixed().nullable().notRequired(),
    loyaltyCodeIconFileUrl: Yup.string().nullable().notRequired(),
    loyaltyCodeIconFile: Yup.mixed().nullable().notRequired(),
  });
};

interface Props {
  onSave: (loyalty: LoyaltyItem) => void;
  onCreateCategory: (categoryTitle: string) => void;
  formValues: LoyaltyItem;
  formRef?: React.RefObject<HTMLButtonElement>;
  categoryOptions: any;
  pointOptions: string[];
  allSeasons: Record<string, string>;
  allCodes: string[];
}

const redemptionFormatOptions = [
  {
    label: "Instant Points",
    value: "instantPoints",
  },
  {
    label: "Scan Or Enter",
    value: "scanOrEnter",
  },
  {
    label: "Scan Only",
    value: "scan",
  },
  {
    label: "Enter Only",
    value: "enter",
  },
  {
    label: "Geofence",
    value: "geofence",
  },
];

export const LoyaltyEditForm: React.FC<Props> = observer(
  ({
    onSave,
    formValues,
    formRef,
    onCreateCategory,
    categoryOptions,
    pointOptions,
    allSeasons,
    allCodes,
  }) => {
    const formik = useFormik<LoyaltyItem>({
      enableReinitialize: true,
      initialValues: formValues,
      validationSchema: validationSchema(allCodes, formValues),
      onSubmit: onSave,
    });
    // console.log(`formik: ${JSON.stringify(formik, null, 2)}`);
    // console.log(`initialValues: ${JSON.stringify(formValues, null, 2)}`);

    const downloadRef = useRef();

    const handleExpirationDateChange = (date: Date | null) => {
      formik.setFieldValue(
        "loyaltyCodeExpirationDate",
        moment(date).format("MM/DD/YYYY"),
        true
      );
    };
    const handleActiveDateChange = (date: Date | null) => {
      formik.setFieldValue(
        "loyaltyCodeActiveDate",
        moment(date).format("MM/DD/YYYY"),
        true
      );
    };

    const onFileChange = React.useCallback(
      async (file: File | null, name: string, fileName: string) => {
        if (!formik.values[name] && file) {
          console.log("setting file...");
          formik.setFieldValue(fileName, file, true);
        }
        if (file === null) {
          console.log("file was null");
          formik.setValues(
            { ...formik.values, [name]: null, [fileName]: null },
            true
          );
        }
      },
      [formik]
    );

    const classes = useStyles();

    return (
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            flexGrow={1}
          >
            <Box display="flex" width="100%" marginBottom={4}>
              <TextField
                className={classes.input}
                name="loyaltyCodeTitle"
                label="Title"
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loyaltyCodeTitle}
                error={
                  !!formik.touched.loyaltyCodeTitle &&
                  !!formik.errors.loyaltyCodeTitle
                }
                helperText={formik.errors.loyaltyCodeTitle}
              />
              <CPInfoButton
                title={`Enter the title for this Active Reward. \n\nNote: Your fans will see this title if “Show In Program” is enabled (the check box at the bottom of this page). `}
              />
            </Box>
            <Box
              width="100%"
              marginBottom={4}
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <TextField
                className={classes.input}
                name="loyaltyCodeCategory"
                label="Category"
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loyaltyCodeCategory}
                error={
                  !!formik.touched.loyaltyCodeCategory &&
                  !!formik.errors.loyaltyCodeCategory
                }
                helperText={formik.errors.loyaltyCodeCategory}
                select
              >
                {Object.keys(categoryOptions || {}).map((categoryId: any) => {
                  let name = getAttributeFromObject(
                    `${categoryId}/name`,
                    categoryOptions
                  );
                  return (
                    <MenuItem key={categoryId} value={categoryId}>
                      {name}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Box ml={2}>
                <Button
                  size="large"
                  variant="text"
                  color="primary"
                  onClick={() => {
                    let newCategory = window.prompt(
                      "Please enter a category name"
                    );
                    if (newCategory) {
                      onCreateCategory(newCategory as string);
                    }
                  }}
                >
                  Generate
                </Button>
              </Box>
              <CPInfoButton
                title={`Select (or create) the category this Active Reward corresponds with. This will help with data collection.`}
              />
            </Box>
            <Box
              width="100%"
              marginBottom={4}
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <TextField
                className={classes.input}
                name="loyaltyCodeCode"
                label="Code"
                variant="outlined"
                size="small"
                disabled={!!formValues.loyaltyCodeCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loyaltyCodeCode}
                error={
                  !!formik.touched.loyaltyCodeCode &&
                  !!formik.errors.loyaltyCodeCode
                }
                helperText={formik.errors.loyaltyCodeCode}
              />
              <Box ml={2}>
                <Button
                  size="large"
                  variant="text"
                  color="primary"
                  disabled={!!formValues.loyaltyCodeCode}
                  onClick={() => {
                    formik.setFieldValue(
                      "loyaltyCodeCode",
                      randomize("0A", 6),
                      true
                    );
                  }}
                >
                  Generate
                </Button>
              </Box>
              <CPInfoButton
                title={`Enter the Code for this Active Reward. Once you have input a Code, we'll generate a QR Code that your fans can scan. Fans can either enter the Code manually or Scan the QR Code to earn points. \n\nNote: The Code must consist of at least 4 characters/numbers.`}
              />
            </Box>
            {/* <Box width="100%" marginBottom={4} display="flex" alignItems="flex-start" justifyContent="space-between">
              <TextField
                className={classes.input}
                name="currentSeason"
                label="Season"
                variant="outlined"
                size="small"
                disabled={true}
                value={`${allSeasons[formik.values.seasonId]}`}
              />
            </Box> */}
            <Box width="100%" display="flex" marginBottom={4}>
              <DatePicker
                className={classes.input}
                format="MM/dd/yyyy"
                label="Active Date"
                value={
                  formik.values.loyaltyCodeActiveDate
                    ? moment(
                        formik.values.loyaltyCodeActiveDate,
                        "MM/DD/YYYY"
                      ).toDate()
                    : null
                }
                onChange={handleActiveDateChange}
              />
              <CPInfoButton
                title={`Select the date you'd like this Active Reward to go live. `}
              />
            </Box>
            <Box width="100%" display="flex" marginBottom={4}>
              <DatePicker
                className={classes.input}
                format="MM/dd/yyyy"
                label="Expiration Date"
                value={
                  formik.values.loyaltyCodeExpirationDate
                    ? moment(
                        formik.values.loyaltyCodeExpirationDate,
                        "MM/DD/YYYY"
                      ).toDate()
                    : null
                }
                onChange={handleExpirationDateChange}
                disablePast
              />
              <CPInfoButton
                title={`Select the date you'd like this Active Reward to Expire. Once the Active Reward Expires, fans will no longer be able to earn points for this Active Reward.`}
              />
            </Box>
            <Box width="100%" display="flex" marginBottom={4}>
              <TextField
                className={classes.input}
                name="loyaltyCodePoints"
                label="Points"
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loyaltyCodePoints}
                error={
                  !!formik.touched.loyaltyCodePoints &&
                  !!formik.errors.loyaltyCodePoints
                }
                helperText={formik.errors.loyaltyCodePoints}
                select
              >
                {pointOptions.map((pointOption) => {
                  return (
                    <MenuItem key={pointOption} value={pointOption}>
                      {pointOption}
                    </MenuItem>
                  );
                })}
              </TextField>
              <CPInfoButton
                title={`Select the amount of Points you'd like fans to earn when completing this Active Reward.`}
              />
            </Box>
            <Box width="100%" display="flex" marginBottom={4}>
              <TextField
                className={classes.input}
                name="loyaltyCodeRedemptionFormat"
                label="Redemption Format"
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loyaltyCodeRedemptionFormat}
                error={
                  !!formik.touched.loyaltyCodeRedemptionFormat &&
                  !!formik.errors.loyaltyCodeRedemptionFormat
                }
                helperText={formik.errors.loyaltyCodeRedemptionFormat}
                select
              >
                {redemptionFormatOptions.map((redemptionFormatOption) => {
                  const value = redemptionFormatOption["value"];
                  const label = redemptionFormatOption["label"];
                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </TextField>
              <CPInfoButton
                title={`Select the format for how you’d like fans to earn their points. 

Instant Points: Only applies if you have chosen to input a “Link” and “Button Name.” Fans will earn points instantly through visiting a weblink. As soon as fan taps on the Button Name they will earn points. For example, if the Link is directing fans to a video, and fans tap on the Button Name “Watch Now” they will earn points instantly.

Scan or Enter: Gives fans the choice of entering the code manually or scanning a QR code for their points

Scan Only: Fans can only scan a QR code to earn their points. 

Enter Only: Fans can only enter the code manually to earn their points

Geofence: Fans need to be physically present to earn points

`}
              />
            </Box>
            {formik.values.loyaltyCodeRedemptionFormat === "geofence" && (
              <Box
                width="100%"
                marginBottom={4}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                alignContent={"center"}
              >
                <Box whiteSpace="nowrap">
                  <Button
                    size="large"
                    variant="text"
                    color="primary"
                    onClick={() => {
                      window.open(`https://geojson.io`, "_blank");
                    }}
                  >
                    Create Geofence
                  </Button>
                </Box>
                <Box width={"20px"} />
                <Box
                  width={"200px"}
                  //Align this box vertically center
                >
                  <input
                    id="massLoadInput"
                    type="file"
                    accept={".geojson"}
                    onChange={(event) => {
                      let files = event.target.files || [];
                      if (files.length > 0) {
                        let file = files[0];
                        const reader = new FileReader();

                        reader.onload = (e) => {
                          let coordinatesArray;
                          const text = `${e.target?.result}`;
                          console.log(`found text: ${text}`);

                          try {
                            const json = JSON.parse(text);
                            const features = json.features;
                            let loyaltyCodeGeofence;
                            // features[0].geometry.coordinates;
                            if (
                              features.length > 0 &&
                              features[0].geometry.type === "Polygon"
                            ) {
                              let coordinatesArray = getAttributeFromObject(
                                `0/geometry/coordinates/0`,
                                features
                              );
                              if (coordinatesArray)
                                loyaltyCodeGeofence = {
                                  coordinates: coordinatesArray,
                                };
                            } else {
                              window.alert(
                                "No polygon feature found or invalid GeoJSON"
                              );
                            }
                            formik.setFieldValue(
                              "loyaltyCodeGeofence",
                              loyaltyCodeGeofence || {},
                              true
                            );
                          } catch (err) {
                            window.alert(`Error parsing GeoJSON: ${err}`);
                          }
                        };

                        reader.onerror = (e) => {
                          window.alert(`Error reading file: ${e}`);
                        };

                        reader.readAsText(file);
                      }
                    }}
                  />
                </Box>
                <Box width={"20px"} />
                <TextField
                  className={classes.input}
                  name="loyaltyCodeGeofence"
                  label={`Geofence`}
                  variant="outlined"
                  size="small"
                  contentEditable={false}
                  disabled={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={`${(formik.values.loyaltyCodeGeofence || {})["coordinates"] ? "Geofence uploaded" : "No Geofence Found"}`}
                  error={
                    !!formik.touched.loyaltyCodeGeofence &&
                    !!formik.errors.loyaltyCodeGeofence
                  }
                  helperText={formik.errors.loyaltyCodeGeofence}
                />

                <CPInfoButton
                  title={`Upload the geofence this active reward needs to be redeemed within
Instructions : 
1) Click CREATE GEOFENCE to go to geojson.io
2) In the new link, if prompted with "recover your map from the last time you edited?", click "cancel"
3) Pan and zoom to the desired area of the geofence
4) Press 'p' on the keyboard
5) Click all points of the shape of the geofence. For the last point (to close the shape), press 'p' or 'enter' on your keyboard
6) Optional - if you wish to restart, click "New" on the top left, and "cancel" again if prompted with recovery
7) At the top left of the screen, go to "Save" -> "GeoJSON" to download the file 
8) Upload that file using the "Choose File" button here
                  `}
                />
              </Box>
            )}
            <Box
              width="100%"
              marginBottom={4}
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <TextField
                className={classes.input}
                name="loyaltyCodeLink"
                label={`Link ${optionalText}`}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loyaltyCodeLink}
                error={
                  !!formik.touched.loyaltyCodeLink &&
                  !!formik.errors.loyaltyCodeLink
                }
                helperText={formik.errors.loyaltyCodeLink}
              />
              <Box ml={2} whiteSpace="nowrap">
                <Button
                  size="large"
                  variant="text"
                  color="primary"
                  onClick={() => {
                    if (
                      !formik.errors.loyaltyCodeLink &&
                      !!formik.touched.loyaltyCodeLink &&
                      !!formik.values.loyaltyCodeLink
                    ) {
                      window.open(
                        `http://${formik.values.loyaltyCodeLink}`,
                        "_blank"
                      );
                    }
                  }}
                >
                  Test Link
                </Button>
              </Box>
              <CPInfoButton
                title={`This section is OPTIONAL.\n\nInsert a weblink if you'd like to direct your fans to a link.\n\nNote: In order for your fans to access this link, you must fill in details for the “Button Name” in the next section.`}
              />
            </Box>
            <Box width="100%" display="flex" marginBottom={4}>
              <TextField
                className={classes.input}
                name="loyaltyCodeButtonName"
                label={`Button Name ${optionalText}`}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loyaltyCodeButtonName}
                error={
                  !!formik.touched.loyaltyCodeButtonName &&
                  !!formik.errors.loyaltyCodeButtonName
                }
                helperText={formik.errors.loyaltyCodeButtonName}
              />
              <CPInfoButton
                title={`This section is OPTIONAL and only applies if you have chosen to fill in the “Link” section.\n\nYou can name the Link Button here. For example, if the Link you have entered is directing fans to a video, the Button Name might be titled “Watch Now” or “Watch Here.”`}
              />
            </Box>
            <Box width="100%" display="flex" marginBottom={4}>
              <TextField
                className={classes.input}
                name="loyaltyCodeDescription"
                label={`Description ${optionalText}`}
                variant="outlined"
                size="small"
                multiline
                rows={6}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loyaltyCodeDescription}
                error={
                  !!formik.touched.loyaltyCodeDescription &&
                  !!formik.errors.loyaltyCodeDescription
                }
                helperText={formik.errors.loyaltyCodeDescription}
              />
              <CPInfoButton
                title={`This section is OPTIONAL.\n\nEnter a description for this Active Reward. The description should tell your fans what this Active Reward is and what to do to earn.`}
              />
            </Box>

            {formik.values.loyaltyCodeIsExclusive && (
              <Box
                width="100%"
                marginBottom={4}
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <CPColorPicker
                  name="loyaltyCodeTextColor"
                  label={"Border Color"}
                  formik={formik}
                />
                <CPInfoButton
                  title={`OPTIONAL: Select a text color for this Exclusive Active Reward.`}
                />
              </Box>
            )}
            <Box width="100%" display="flex">
              <FormControlLabel
                control={
                  <Checkbox
                    name="loyaltyCodeVisibility"
                    color="primary"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.loyaltyCodeVisibility}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Show In Program"
              />
              <CPInfoButton
                title={`Check this box if you'd like this Active Reward to be visible to your fans inside the program. Fans can still earn points for this Active Reward regardless of if this section is enabled.`}
              />
            </Box>
            <Box width="100%" display="flex">
              <FormControlLabel
                control={
                  <Checkbox
                    name="loyaltyCodeIsOneTimeCode"
                    color="primary"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.loyaltyCodeIsOneTimeCode}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="One Time Code"
              />
              <CPInfoButton
                title={`By checking this box, only one fan can earn points for this Active Reward.\n\nTypically, One Time Codes are used for contests and other unique initiatives. For example, a 50/50 raffle and only one winner receives this code for points.`}
              />
            </Box>
            {/* <Box width="100%" display="flex">
              <FormControlLabel
                control={
                  <Checkbox
                    name="isInstant"
                    color="primary"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.isInstant}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Instant Points"
              />

              <CPInfoButton title={`This section only applies if you have chosen to input a Link and Button Name. 
              \nCheck this box if you'd like fans to earn points instantly through visiting a weblink. As soon as fans tap on the Button Name they will earn points. 
              \nFor example, if the Link is directing fans to a video, and fans tap on the Button Name “Watch Now” they will earn points instantly.`}/>
            </Box> */}
            <Box width="100%" display="flex">
              <FormControlLabel
                control={
                  <Checkbox
                    name="loyaltyCodeIsExclusive"
                    color="primary"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.loyaltyCodeIsExclusive}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Exclusive"
              />

              <CPInfoButton
                title={`Check this box if you’d like to promote this Active Reward. This will place this Active Reward at the top of the section. NOTE: The “Show In Program” check box must also be enabled.`}
              />
            </Box>
          </Box>
          <Box marginLeft={10}>
            {/* <CPText title="Main" />
            <Box width="100%" display="flex">
              <FileUploader
                onFileChange={(file) =>
                  onFileChange(
                    file,
                    "loyaltyCodeMainFileUrl",
                    "loyaltyCodeMainFile"
                  )
                }
                fileUrl={formik.values.loyaltyCodeMainFileUrl}
              />
              <CPInfoButton title={`Add an Image for this Active Reward.`} />
            </Box> */}

            <CPText title="Background (Optional)" />
            <Box width="100%" display="flex">
              <FileUploader
                onFileChange={(file) =>
                  onFileChange(
                    file,
                    "loyaltyCodeBackgroundFileUrl",
                    "loyaltyCodeBackgroundFile"
                  )
                }
                fileUrl={formik.values.loyaltyCodeBackgroundFileUrl}
              />
              <CPInfoButton
                title={`OPTIONAL: Upload a background for this Exclusive Active Reward.`}
              />
            </Box>
            {formik.values.loyaltyCodeIsExclusive && (
              <>
                <CPText title="Icon (Optional)" />
                <Box width="100%" display="flex">
                  <FileUploader
                    onFileChange={(file) =>
                      onFileChange(
                        file,
                        "loyaltyCodeIconFileUrl",
                        "loyaltyCodeIconFile"
                      )
                    }
                    fileUrl={formik.values.loyaltyCodeIconFileUrl}
                  />
                  <CPInfoButton
                    title={`OPTIONAL: You can change the icon of this Active Reward by uploading a new one. If you do not upload a new icon this Active Reward will default to the team logo as its icon.`}
                  />
                </Box>
              </>
            )}

            <CPText title="QR Code" />
            <Box width="100%" display="flex">
              <Box width="100%">
                <DownloadableQRCode
                  codeText={`${formik.values.loyaltyCodeCode}`}
                  downloadRef={downloadRef}
                  showBorder={true}
                />
              </Box>
              <CPInfoButton
                title={`Download this QR Code and distribute it to your fans. Once they scan, they will earn Points for this Active Reward. `}
              />
            </Box>
          </Box>
        </Box>
        <button style={{ display: "none" }} type="submit" ref={formRef} />
      </form>
    );
  }
);
