import React from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { Button, makeStyles, Theme } from "@material-ui/core";
import { applyDebugStyles } from "../../../helpers/debug-helpers";
import { StyleRules, withStyles } from "@material-ui/styles";
import {
  cpDottedRoundedBorder,
  cpDottedRoundedBorderRadius,
} from "../../../helpers/style-helpers";
import { nonWhiteSpace } from "html2canvas/dist/types/css/syntax/parser";

const useStyles = makeStyles((theme: Theme) => {
  const styles = {
    root: {
      minHeight: "200px",
      border: cpDottedRoundedBorder,
      borderRadius: cpDottedRoundedBorderRadius,
      width: "30rem",
      alignItems: "center",
      justifyContent: "center",
    },
    text: {
      width: "100%",
    },
    text_container: {
      marginTop: theme.spacing(10),
    },
    text_container_hide: {
      display: "none",
    },
    preview_container: {
      display: "block",
      width: "100%",
      height: "11.5rem",
      padding: theme.spacing(1),
      margin: 0,
      "& .MuiDropzonePreviewList-image": {
        width: "100%",
        height: "100%",
      },
      "& .MuiGrid-grid-xs-4": {
        display: "block",
        maxWidth: "100%",
      },
    },
    item: {
      display: "block",
      width: "100%",
      height: "100%",
      padding: 0,
    },
    image: {
      width: "100%",
      height: "100%",
    },
    button: {
      marginTop: "1rem",
      width: theme.typography.pxToRem(200),
    },
  };
  // applyDebugStyles(styles, true);
  return styles;
});
interface Props {
  onFileChange: (file: File | null) => void;
  fileUrl: string | null;
}

export const FileUploader: React.FC<Props> = ({ onFileChange, fileUrl }) => {
  const [hasFile, setIsFile] = React.useState(!!fileUrl);
  const initialFiles = React.useMemo(
    () => (fileUrl ? [fileUrl] : []),
    [fileUrl],
  );

  const onChange = React.useCallback(
    (files: File[]) => {
      const file = files[0] || null;
      onFileChange(file);
      file ? setIsFile(true) : setIsFile(false);
    },
    [onFileChange],
  );

  const classes = useStyles();
  return (
    <pre>
      <DropzoneArea
        style={{ "white-space": "pre-wrap", height: "1000px" }}
        key={fileUrl || undefined}
        classes={{
          root: classes.root,
          text: classes.text,
          textContainer: !hasFile
            ? classes.text_container
            : classes.text_container_hide,
        }}
        previewGridClasses={{
          container: classes.preview_container,
          item: classes.item,
          image: classes.image,
        }}
        filesLimit={1}
        initialFiles={initialFiles}
        onChange={onChange}
        acceptedFiles={["image/jpeg", "image/png"]}
        maxFileSize={2000000}
        showAlerts={false}
        getDropRejectMessage={(rejectedFile: File, acceptedFiles: string[]) => {
          return `Accept only: ${acceptedFiles.join(", ")} and size not more than 2mb`;
        }}
        dropzoneText={`Drop an image here, or...\n`}
        // @ts-ignore
        Icon={() => (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Select Image
          </Button>
        )}
      />
    </pre>
  );
};
