import moment from "moment";

const weekdayMap: { [key: string]: string } = {
  n1: "Monday",
  n2: "Tuesday",
  n3: "Wednesday",
  n4: "Thursday",
  n5: "Friday",
  n6: "Saturday",
  n7: "Sunday",
};

const monthMap: { [key: string]: string } = {
  n0: "January",
  n1: "February",
  n2: "March",
  n3: "April",
  n4: "May",
  n5: "June",
  n6: "July",
  n7: "August",
  n8: "September",
  n9: "October",
  n10: "November",
  n11: "December",
};

export const formattedDataFromMonth = (data: any) => {
  if (!data) data = {};
  let result: any = {};
  Object.keys(data)
    .sort((a, b) => {
      const aa = a.replace(/[^0-9 ]/g, "");
      const bb = b.replace(/[^0-9 ]/g, "");
      return Number(aa) - Number(bb);
    })
    .forEach((key) => {
      result[key] = {
        name: monthMap[key],
        value: data[key],
      };
    });
  return result;
};

export const formattedDataFromWeekday = (data: any) => {
  if (!data) data = {};
  let result: any = {};
  Object.keys(data)
    .sort((a, b) => {
      const aa = a.replace(/[^0-9 ]/g, "");
      const bb = b.replace(/[^0-9 ]/g, "");
      return Number(aa) - Number(bb);
    })
    .forEach((key) => {
      result[key] = {
        name: weekdayMap[key],
        value: data[key],
      };
    });
  return result;
};

export const formattedDataFromMonthAndYear = (data: any) => {
  if (!data) data = {};
  let result: any = {};
  Object.keys(data)
    .sort((a, b) => {
      let aMoment: moment.Moment = getDateFromDateKey(a);
      let bMoment: moment.Moment = getDateFromDateKey(b);
      return aMoment.isBefore(bMoment) ? 1 : -1;
    })
    .forEach((key) => {
      let keySplit = key.split("_");
      let name = key;
      if (keySplit.length === 2) {
        let monthDisplay = monthMap[`n${Number(keySplit[1])}`].substring(0, 3);
        let yearDisplay = keySplit[0].substring(2, 4);
        name = `${monthDisplay} '${yearDisplay}`;
      }
      result[key] = {
        name: name,
        value: data[key],
      };
    });
  return result;
};

export const formattedDataFromDate = (data: any, debugLog: boolean = false) => {
  // if (debugLog) console.log(`formattedDataFromDate for ${JSON.stringify(data)}`)
  if (!data) data = {};
  let result: any = {};
  Object.keys(data)
    .sort((a, b) => {
      let aMoment: moment.Moment = getDateFromDateKey(a);
      let bMoment: moment.Moment = getDateFromDateKey(b);
      return aMoment.isBefore(bMoment) ? 1 : -1;
    })
    .forEach((key) => {
      let keySplit = key.split("_");
      let name = key;
      if (keySplit.length === 3) {
        let dayDisplay = keySplit[2];
        // console.log(`keySplit[0] ${keySplit[0]}`);
        let month = monthMap[`n${Number(keySplit[1])}`] || "unk";
        let monthDisplay = month.substring(0, 3);
        let yearDisplay = keySplit[0].substr(2, 4);
        name = `${monthDisplay} ${dayDisplay} '${yearDisplay}`;
      }
      result[key] = {
        name: name,
        value: data[key],
      };
    });
  return result;
};

export const getDateFromDateKey = (date: string) => {
  let dateArr: string[] = date.split("_");
  if (dateArr.length < 2) {
    console.log("Bad date");
    return moment();
  }
  let year = dateArr[0];
  let month = Number(dateArr[1]) + 1;
  let day = "1";
  if (dateArr.length === 3) day = dateArr[2];

  return moment(`${year}/${month}/${day}`);
};

export const getAverageData = (originalData: any, countData: any) => {
  if (!originalData) originalData = {};
  if (!countData) countData = {};
  let result: any = {};
  Object.keys(originalData).forEach((nKey) => {
    let count = (countData[nKey] && Number(countData[nKey])) || 1;
    let originalValue = (originalData[nKey] && Number(originalData[nKey])) || 0;
    // console.log(`Sum: ${originalValue} / Total: ${count}`)
    result[nKey] = Math.floor((1.0 * originalValue) / count);
  });
  return result;
};
