import React from "react";
import { Box, Typography } from "@material-ui/core";
import { cpPageTitleStyle } from "../helpers/constants";

interface Props {
  title: string;
  subTitle?: string;
}

export const PageTitle: React.FC<Props> = ({ title, subTitle }) => {
  return (
    <Box display="flex" alignItems="center" style={cpPageTitleStyle}>
      <Box>
        {title}
        {subTitle ? <span>&nbsp;-&nbsp;</span> : ""}
      </Box>
      <Typography component="div" color="secondary">
        <Box>{subTitle}</Box>
      </Typography>
    </Box>
  );
};
