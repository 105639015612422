import moment from "moment";

export const filterByCriteria = (
  items: any,
  criteriaList: any,
  searchInput: any
) => {
  return items.filter((item: any) => {
    let lowerSearch = searchInput.toLowerCase();

    for (let i = 0; i < criteriaList.length; i++) {
      let criteria = criteriaList[i];
      let element = item[criteria] || "";
      let lowerCaseElement = `${element}`.toLowerCase();
      if (lowerCaseElement.includes(lowerSearch)) return true;
    }
    return false;
  });
};

export const filterBySeason = (
  selectedSeasonFilter: any,
  object: any,
  timestampKey: string
) => {
  if (selectedSeasonFilter["startDate"] && selectedSeasonFilter["endDate"]) {
    let startDate = moment(selectedSeasonFilter["startDate"]);
    let endDate = moment(selectedSeasonFilter["endDate"]);
    // console.log(`filtering by ${startDate} and ${endDate}`);
    object = object.filter((item: any) => {
      let orderDate = moment(item[timestampKey] || "");
      return orderDate.isAfter(startDate) && orderDate.isBefore(endDate);
    });
  }
  return object;
};
