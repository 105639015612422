import { makeAutoObservable } from "mobx";
import { Loading, loadingFunction } from "../../helpers/loading";
import { filterByCriteria } from "../../helpers/sort-helpers";
import { UserOverview, userOverviewDefaults } from "../../types/user-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class UsersByTeamPageStore {
  public userOverview: UserOverview = userOverviewDefaults();
  public loading: Loading = Loading.NOT_LOADED;
  private _users: UserOverview[] = [];
  public seasonId: string = "";
  public teamDetails: any = {};
  private searchInput: string = "";

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  searchUser = (input: string) => {
    this.searchInput = input;
  };

  loadUsersByTeamId = async (teamId: string) => {
    loadingFunction(
      this,
      async () => {
        this.seasonId = await this.firebaseConnector.getCurrentSeasonId(teamId);
        this._users = await this.firebaseConnector.loadUsersByTeamId(
          teamId,
          this.seasonId
        );
        this.teamDetails = await this.firebaseConnector.loadTeamDetails(teamId);
      },
      this.uiStore.notifications
    );
  };

  setTransaction = async (path: string, transaction: any) => {
    let pushedKey = await this.firebaseConnector.getKeyFromPushedPath(path);
    if (!pushedKey) {
      window.alert("Bad pushedKey");
      return;
    }
    console.log(`Got pushedKey: ${pushedKey}`);
    return this.firebaseConnector.setDatabasePathAsObj(
      `${path}/${pushedKey}`,
      transaction
    );
  };

  get users() {
    let criteriaList = [
      "userId",
      "email",
      "fullName",
      "username",
      "accountId",
      "ticketRepId",
    ];
    return filterByCriteria(this._users, criteriaList, this.searchInput);
  }

  get userIdsByEmail() {
    let result: any = {};
    this._users.forEach((userOverview: UserOverview) => {
      let userEmail = userOverview.email;
      if (result[userEmail]) {
        window.alert(
          "There is an issue with the user database - please try again later"
        );
        return null;
      }
      result[userEmail] = userOverview.userId;
    });
    return result;
  }
}
