import { makeAutoObservable } from "mobx";
import moment from "moment";
import { Loading, loadingFunction } from "../../helpers/loading";
import { PickupOverview } from "../../types/order-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class PickupsByTeamPageStore {
  public loading: Loading = Loading.NOT_LOADED;
  private _pickups: PickupOverview[] = [];
  public _filteredPickups: PickupOverview[] = [];

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  loadPickupsByTeamId = async (
    teamId: string,
    selectedPickupPeriodIndex: number,
  ) => {
    loadingFunction(
      this,
      async () => {
        this._pickups =
          await this.firebaseConnector.loadPickupsByTeamId(teamId);
        this._filteredPickups = this.filteredPickups(selectedPickupPeriodIndex);
      },
      this.uiStore.notifications,
    );
  };

  filteredPickups(selectedPickupPeriodIndex: number) {
    let pickups = this._pickups.filter((item: PickupOverview) => {
      let pickupDateMoment: moment.Moment = moment(item.pickupDate);
      if (selectedPickupPeriodIndex === 1) {
        return pickupDateMoment.isBefore(moment.now());
      } else {
        return pickupDateMoment.isAfter(moment.now());
      }
    });
    return pickups;
  }

  getNewPickupId = async (teamId: string) => {
    if (!teamId) return "";
    let newPickupId =
      (await this.firebaseConnector.getKeyFromPushedPath(
        `pickupsByTeam/${teamId}`,
      )) || "";
    return newPickupId;
  };

  deletePickup = async (teamId: string, pickup: PickupOverview) => {
    let pickupId = pickup.pickupId;
    if (pickupId) {
      await this.firebaseConnector.setDatabasePathAsObj(
        `pickupsByTeam/${teamId}/${pickupId}`,
        null,
      );
    }
  };
}
