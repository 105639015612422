import React, { ReactElement } from "react";
import { Box, Card, makeStyles, Typography } from "@material-ui/core";
import { formatCommas } from "../helpers/name-helpers";
import clsx from "clsx";
import {
  debugStyle,
  getStyleFromSpacingList,
  getTableCellColorFromRowIndex,
} from "../types/util-types";
import {
  cpBoldSubHeaderStyle,
  cpBoxSubHeaderStyle,
  cpCardBoxShadow,
  cpGrayF7,
  halfStandardDashboardPadding,
  standardDashboardPadding,
} from "../helpers/constants";
import { CPDownloadButton } from "./CPDownloadButton";
import { CPText } from "./CPText";
import { combinedObject } from "../helpers/generic-helpers";
import { CPButton } from "./CPButton";
import { CPCard } from "./CPCard";
import { CPImageScroller } from "./CPImageScroller";

interface Props {
  children: React.ReactElement;
}

export const CPMarketplaceDetailBox: React.FC<Props> = ({ children }) => {
  return (
    <Box flex={1} height={"520px"}>
      <CPCard cardPadding="24px" children={children} />
    </Box>
  );
};

export const ProductDetailsHeader = ({ text }: { text: string }) => {
  return (
    <Box mt={"20px"} mb={"10px"} style={cpBoxSubHeaderStyle}>
      {text}
    </Box>
  );
};

export const ProductDetailsTextBox = ({ text }: { text: string }) => {
  return (
    <Box
      style={{
        backgroundColor: cpGrayF7,
        fontSize: "12px",
        padding: "12px",
        maxHeight: "110px",
        overflowY: "auto",
      }}
    >
      {text}
    </Box>
  );
};

export const CPMarketplaceLogisticsRow = ({
  index,
  logistic,
}: {
  index: number;
  logistic: any;
}) => {
  let backgroundColor = getTableCellColorFromRowIndex(index + 1);
  return (
    <Box
      display={"flex"}
      p={"18px"}
      fontSize={"12px"}
      style={{
        background: backgroundColor,
        fontWeight: "bold",
      }}
    >
      <Box>{logistic.label}</Box>
      <Box flex={1} />
      <Box
        style={{
          color: logistic.color,
        }}
      >
        {logistic.value}
      </Box>
    </Box>
  );
};

export const CPMarketplaceImageScrollerBox = ({
  productDetails,
  selectedImageIndex,
  setSelectedImageIndex,
}: {
  productDetails: any;
  selectedImageIndex: number;
  setSelectedImageIndex: (index: number) => void;
}) => {
  return (
    <CPMarketplaceDetailBox
      children={
        <div>
          <CPImageScroller
            imageUrls={productDetails["imageUrls"] || []}
            selectedIndex={selectedImageIndex}
            onSelectedIndexChange={(index: number) =>
              setSelectedImageIndex(index)
            }
          />
        </div>
      }
    />
  );
};

export const CPMarketplaceProductDetailsBox = ({
  productDetails,
}: {
  productDetails: any;
}) => {
  return (
    <CPMarketplaceDetailBox
      children={
        <div>
          <Box style={cpBoxSubHeaderStyle}>Product Details</Box>
          <ProductDetailsHeader text="Name" />
          <ProductDetailsTextBox text={productDetails["name"]} />
          <ProductDetailsHeader text="Description" />
          <ProductDetailsTextBox text={productDetails["description"]} />
          <ProductDetailsHeader text="Details" />
          <ProductDetailsTextBox text={productDetails["details"]} />
        </div>
      }
    />
  );
};
