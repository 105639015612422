import { Box, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  name: string | number;
  email: string | number;
}

export const NameCell: React.FC<Props> = ({ name, email }) => {
  return (
    <div>
      <div>{name}</div>
      <Typography component="div" color="primary">
        <Box fontWeight="fontWeightBold">{email}</Box>
      </Typography>
    </div>
  );
};
