import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { stadiumRoutes, usersRoutes } from "../../app-routes";
import { CPDashboardSpacer } from "../../components/CPDashboardSpacer";
import { CPDataChart } from "../../components/CPDataChart";
import { CustomizableDataCardBlock } from "../../components/CustomizableDataCardBlock";
import { DemographicsChart } from "../../components/DemographicsChart";
import { MonthlyDailyWeeklyChart } from "../../components/MonthlyDailyWeeklyChart";
import { PageTitle } from "../../components/PageTitle";
import { cpLargeCardHeight, cpSmallCardHeight } from "../../helpers/constants";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { exportAsCSVByCol, setColEmailsAndNames } from "../../types/util-types";

export const StadiumOverviewPage = observer(() => {
  const { teamId } = useParams<{ teamId: string }>();
  const { stadiumOverviewPage } = useStore().ui;
  const { routerStore } = useStore();

  React.useEffect(() => {
    stadiumOverviewPage.loadOverview(teamId);
  }, [stadiumOverviewPage]);

  let collectsBirthdays =
    stadiumOverviewPage.teamDetails["collectsBirthdays"] !== false;
  let collectsGenders =
    stadiumOverviewPage.teamDetails["collectsGenders"] !== false;

  const onDownloadEngagement = async (options: any) => {
    let specificDateKey = options["specificDateKey"];
    let userIdSpecificData =
      await stadiumOverviewPage.userSpecificDailyEngagement;
    let allUserInfo = await stadiumOverviewPage.getAllUserInfo();
    let csvKeys: string[] = [];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      csvKeys.push(`${dateKey} Email`);
      csvKeys.push(`${dateKey} Name`);
    });
    let cols: any = {};
    let colIndexArr = [0];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      setColEmailsAndNames(
        dateKey,
        colIndexArr,
        cols,
        userIdSpecificData,
        allUserInfo,
      );
    });
    exportAsCSVByCol(csvKeys, cols, `Game Engagement`);
  };

  const goToUser = (rowData: any) => {
    let userId = rowData["userId"];
    if (userId) routerStore.push(usersRoutes.userOverview(userId, teamId));
  };

  const goToGame = (rowData: any) => {
    let gameId = rowData["gameId"];
    if (gameId)
      routerStore.push(stadiumRoutes.stadiumGameOverview(teamId, gameId));
  };

  return (
    <MainLayout
      loading={stadiumOverviewPage.loading}
      firebaseConnector={stadiumOverviewPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title="Games" />
        </Box>
      }
    >
      <Box flex={1} height={"160px"}>
        <CustomizableDataCardBlock
          {...stadiumOverviewPage.dataBlock}
          itemsPerRow={1}
          height={cpSmallCardHeight}
        />
      </Box>
      <Box display={"flex"}>
        <Box flex={1}>
          <MonthlyDailyWeeklyChart
            dailyWeeklyMonthlyDataList={
              stadiumOverviewPage.dailyWeeklyMonthlyEngagementData
            }
            title="Game Engagement"
            onlyDaily={true}
            dataKeyName="Members"
            onDownload={onDownloadEngagement}
          />
        </Box>
        <CPDashboardSpacer />
        <Box flex={1} height={cpLargeCardHeight}>
          <DemographicsChart
            demographics={stadiumOverviewPage.userDemographics}
            height={cpLargeCardHeight}
            // onDownload={ async () => {
            //   let demographics = await teamOverviewPage.getUserIdSpecificDemographics();
            //   let allUserInfo = await teamOverviewPage.getAllUserInfo();
            //   genericDemographicsDownload(demographics, allUserInfo);
            // } }
          />
        </Box>
      </Box>
      <CPDashboardSpacer />
      <Box display={"flex"}>
        <Box flex={1}>
          <CPDataChart
            title="Games"
            dataKeyList={["subtext", "date", "members"]}
            exportCSVKeys={["subtext", "date", "members"]}
            separateLabelMap={{ subtext: "Game" }}
            data={stadiumOverviewPage.gamesData}
            flexList={[5, 3, 3]}
            defaultSort="date"
            onRowClick={goToGame}
          />
        </Box>
        <CPDashboardSpacer />
        <Box flex={1}>
          <CPDataChart
            title="Top Most Engaged Members"
            dataKeyList={["nameAndAvatar", "nQuestionsAnswered"]}
            separateLabelMap={{
              nameAndAvatar: "Name",
              nQuestionsAnswered: "Questions Answered",
            }}
            data={stadiumOverviewPage.mostEngagedMembers}
            flexList={[5, 1]}
            onRowClick={goToUser}
            defaultSort="nQuestionsAnswered"
            exportCSVKeys={["fullName", "nQuestionsAnswered"]}
          />
        </Box>
      </Box>
      <CPDashboardSpacer />
      <CPDashboardSpacer />
    </MainLayout>
  );
});
