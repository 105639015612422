import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { SortDirectionType } from "react-virtualized";
import trash from "../../../assets/trash.png";
import { SortableTable } from "../../../components/SortableTable";
import { TableContainer } from "../../../components/TableContainer";
import { PushNotificationOverview } from "../../../types/notification-types";
import { CPDateCellRenderFromDateTime } from "../../../components/CPDateCellRenderFromDateTime";

interface Props {
  defaultSort: string;
  defaultSortDirection?: SortDirectionType;
  notifications: PushNotificationOverview[];
  onRowClick?: (notification: PushNotificationOverview) => void;
  onDelete?: any;
  type: number;
}

export const NotificationsByTeamTable: React.FC<Props> = ({
  notifications,
  onRowClick,
  defaultSort,
  defaultSortDirection,
  onDelete,
  type,
}) => {
  let defaultWidth = 100;
  let isHomeGame = type > 0;
  let csvKeys = ["notificationTitle", "notificationBody"];
  if (isHomeGame) {
    csvKeys.push("notificationStartDateTime");
    csvKeys.push("notificationEndDateTime");
  } else {
    csvKeys.push("notificationDate");
  }
  return (
    <TableContainer>
      <SortableTable
        // @ts-ignore
        onRowClick={onRowClick}
        height="100vh"
        defaultSort={defaultSort}
        defaultSortDirection={defaultSortDirection}
        csvKeys={["notificationTitle", "notificationBody", "notificationDate"]}
        columns={[
          {
            dataKey: "notificationTitle",
            label: "Title",
            flexGrow: 12,
            width: defaultWidth,
          },
          {
            dataKey: "notificationBody",
            label: "Body",
            flexGrow: 10,
            width: defaultWidth,
          },
          //Add object if is home game
          ...(isHomeGame
            ? [
                {
                  dataKey: "notificationStartDateTime",
                  label: "Start Date",
                  flexGrow: 10,
                  width: defaultWidth,
                  cellRender: (row: any) => {
                    return (
                      <CPDateCellRenderFromDateTime
                        row={row}
                        attribute={"notificationStartDateTime"}
                      />
                    );
                  },
                },
                {
                  dataKey: "notificationEndDateTime",
                  label: "End Date",
                  flexGrow: 10,
                  width: defaultWidth,
                  cellRender: (row: any) => {
                    return (
                      <CPDateCellRenderFromDateTime
                        row={row}
                        attribute={"notificationEndDateTime"}
                      />
                    );
                  },
                },
                {
                  dataKey: "notificationVisibility",
                  label: "Enabled",
                  flexGrow: 10,
                  width: defaultWidth,
                  cellRender: (row: any) => {
                    return (
                      <Typography>
                        {row.notificationVisibility ? "Yes" : "No"}
                      </Typography>
                    );
                  },
                },
              ]
            : []),
          ...(!isHomeGame
            ? [
                {
                  dataKey: "notificationDate",
                  label: "Date",
                  numeric: true,
                  flexGrow: 10,
                  width: defaultWidth,
                  cellRender: (row: any) => {
                    return (
                      <CPDateCellRenderFromDateTime
                        row={row}
                        attribute={"notificationDate"}
                      />
                    );
                  },
                },
              ]
            : []),

          {
            dataKey: "delete",
            label: "",
            flexGrow: 1,
            width: defaultWidth,
            disableClickEventBubbling: true,
            cellRender: (props) => {
              return (
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className="delete-icon"
                >
                  <Typography color="secondary">
                    <img
                      src={trash}
                      style={{ cursor: "pointer", height: "20px" }}
                      onClick={(event) => {
                        onDelete(props);
                        event.stopPropagation();
                      }}
                    />
                  </Typography>
                </Box>
              );
            },
          },
        ]}
        rows={notifications}
        // withChevron
      />
    </TableContainer>
  );
};
