import {
  Box,
  Button,
  Grid,
  makeStyles,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import React, { Props } from "react";
import { MainLayout } from "../../layouts/MainLayout";
import { useStore } from "../../helpers/use-store";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PageTitle } from "../../components/PageTitle";
import { DataCardBlock } from "../../components/DataCardBlock";
import { OrdersByTeamTable } from "./components/OrdersByTeamTable";
import { OrderOverview } from "../../types/order-types";
import { orderRoutes, usersRoutes } from "../../app-routes";
import { HeaderedBox } from "../../components/HeaderedBox";
import {
  getStatusLabelFromStatus,
  getTextColorFromStatus,
} from "../../helpers/order-helpers";
import { formatDate } from "../../helpers/time-helpers";
import { NewlineText } from "../../components/NewLineText";

interface UrlParams {
  teamId: string;
  userId: string;
  orderId: string;
}

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.typography.pxToRem(20),
  },
}));

export const OrderDetailsPage: React.FC = observer(() => {
  const urlParams = useParams<UrlParams>();
  const { orderDetailsPage } = useStore().ui;
  const teamId = urlParams.teamId;
  const orderId = urlParams.orderId;
  const userId = urlParams.userId;
  const { routerStore } = useStore();

  React.useEffect(() => {
    orderDetailsPage.loadOrder(teamId, userId, orderId);
  }, [urlParams, orderDetailsPage]);

  let order = orderDetailsPage.order;
  let friendlyId = order.friendlyId;
  let title = `Order ${friendlyId}`;
  let imageSize = 135;
  let defMT = 2;
  const classes = useStyles();
  const disabledTextFieldParams: TextFieldProps = {
    className: classes.input,
    variant: "outlined",
    size: "small",
    disabled: true,
    fullWidth: true,
  };

  let promptStatusChange = (isApproveMode: boolean) => {
    let type = isApproveMode ? "approve" : "reject";
    if (window.confirm(`Are you sure you want to ${type} this order`)) {
      setOrderStatus(isApproveMode ? 1 : 2);
    }
  };

  let setOrderStatus = (newStatus: Number) => {
    orderDetailsPage.setOrderStatus(teamId, userId, orderId, newStatus);
  };

  return (
    <MainLayout
      loading={orderDetailsPage.loading}
      firebaseConnector={orderDetailsPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Grid container item xs={8}>
            <PageTitle title={title} />
          </Grid>
          <Grid container item xs={4} justify="flex-end">
            <Grid item>
              <Box mr={6}>
                <Button
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  onClick={() => promptStatusChange(false)}
                  disabled={order.status == "2"}
                >
                  Reject Order
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => promptStatusChange(true)}
                  disabled={order.status == "1" || order.status == "2"}
                >
                  Approve Order
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid container item xs={8}>
          <Box width={"100%"}>
            <Box>
              <HeaderedBox
                title="Product Details"
                child={
                  <>
                    {
                      <Grid container>
                        <Grid item>
                          <Box width={imageSize} height={imageSize} mr={10}>
                            <img
                              style={{ width: "100%" }}
                              src={`${order.imageUrl}`}
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <div>
                            <Box fontWeight="fontWeightBold">
                              {order.productName}
                            </Box>
                            <Box mt={defMT}>Variant: {order.variantName}</Box>
                          </div>
                        </Grid>
                      </Grid>
                    }
                  </>
                }
              />
            </Box>
            <Box mt={2}>
              <HeaderedBox
                title="Order Information"
                child={
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        name="Order Number"
                        label="Order Number"
                        value={`${order.friendlyId}`}
                        {...disabledTextFieldParams}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="Tracking Number"
                        label="Tracking Number"
                        value={order.trackingNumber || "N/A"}
                        {...disabledTextFieldParams}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Box>
            <Box mt={2}>
              <HeaderedBox
                title="Order Status"
                child={
                  <Box height={70}>
                    {getStatusLabelFromStatus(order.status)}
                  </Box>
                }
              />
            </Box>
          </Box>
        </Grid>
        <Grid container item xs={4}>
          <Box width={"100%"}>
            <HeaderedBox
              title="Order Details"
              child={
                <>
                  <Grid container>
                    <Grid item xs={8}>
                      <TextField
                        name="Participant"
                        label="Participant"
                        value={`${order.userFullName}`}
                        {...disabledTextFieldParams}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Box ml={2} mt={5}>
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          onClick={() =>
                            routerStore.push(
                              usersRoutes.userOverview(order.userId, teamId),
                            )
                          }
                        >
                          Info
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item></Grid>
                  </Grid>
                  <TextField
                    name="Contact Information"
                    label="Contact Information"
                    value={`${order.email}`}
                    {...disabledTextFieldParams}
                  />
                  <TextField
                    name="Total Points"
                    label="Total Points"
                    value={`${order.orderTotal}`}
                    {...disabledTextFieldParams}
                  />
                  <TextField
                    name="Quantity"
                    label="Quantity"
                    value={`${order.orderItemCount}`}
                    {...disabledTextFieldParams}
                  />
                  <TextField
                    name="Date Ordered"
                    label="Date Ordered"
                    value={formatDate(order.orderDate)}
                    {...disabledTextFieldParams}
                  />
                </>
              }
            />
            {order.pickupId ? (
              <Box mt={defMT}>
                <HeaderedBox
                  title="Pick Up Details"
                  child={
                    <>
                      <NewlineText text={order.pickupName} />
                    </>
                  }
                />
              </Box>
            ) : (
              <Box mt={defMT}>
                <HeaderedBox
                  title="Shipping Address"
                  child={
                    <>
                      <NewlineText text={order.addressName} />
                    </>
                  }
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </MainLayout>
  );
});
