export interface UserListItem {
  [key: string]: string;
  name: string;
  userName: string;
  userId: string;
  email: string;
}

export enum SessionUserType {
  ADMIN = "ADMIN",
  ORDERS = "ORDERS",
  STADIUM = "STADIUM",
  CPTEAMADMIN = "CPTEAMADMIN",
  SPONSOR = "SPONSOR",
}

export const coreTeamRoles: any = [
  SessionUserType.ADMIN,
  SessionUserType.STADIUM,
  SessionUserType.CPTEAMADMIN,
];

export const adminTeamRoles: any = [
  SessionUserType.ADMIN,
  SessionUserType.CPTEAMADMIN,
];

export interface UserOverview {
  [key: string]: any;
  id: string;
  userId: string;
  name: string;
  cohortId: string;
  cohortName: string;
  //This is the last platform signed into
  platform: string;
  accountId: string;
  ticketRepId: string;
  fullName: string;
  firstName: string;
  lastName: string;
  birthday: string;
  age: number;
  status: "frozen" | "inactive" | "active";
  dateCreated: string;
  dateCreatedFriendly: string;
  birthDate: number;
  birthMonth: number;
  birthYear: number;
  email: string;
  gender: string;
  photo: number;
  engTime: number;
  exitTime: number;
  nGames: number;
  reward: {
    cash: number;
    gift: number;
    point: number;
  };
  startingPoints: number;
  currentPoints: number;
  nOrders: number;
  orders: {};
  rewards: {};
  totalPointsEarned: number;
  totalPointsSpent: number;
  engagementRate: number;
  totalPointsEarnedByDate: {};
  totalPointsEarnedByMonth: {};
  totalPointsEarnedByMonthAndYear: {};
  totalPointsEarnedByWeekday: {};
  totalPointsEarnedMonthsSeen: {};
  totalPointsEarnedWeekdaysSeen: {};
  dailyRewardRedemptionsByDate: {};
  dailyRewardRedemptionsByMonth: {};
  dailyRewardRedemptionsByMonthAndYear: {};
  dailyRewardRedemptionsByWeekday: {};
  dailyRewardRedemptionsMonthsSeen: {};
  dailyRewardRedemptionsWeekdaysSeen: {};
  checkInAttendsByDate: {};
  checkInViewsByDate: {};
}

export const userOverviewDefaults = (): UserOverview => ({
  id: "",
  userId: "",
  name: "",
  cohortId: "",
  cohortName: "",
  platform: "",
  accountId: "",
  ticketRepId: "",
  fullName: "",
  firstName: "",
  lastName: "",
  birthday: "",
  age: 0,
  status: "active",
  dateCreated: "",
  dateCreatedFriendly: "",
  birthDate: 0,
  birthMonth: 0,
  birthYear: 0,
  email: "",
  gender: "",
  photo: 0,
  engTime: 0,
  exitTime: 0,
  nGames: 0,
  reward: {
    cash: 0,
    gift: 0,
    point: 0,
  },
  startingPoints: 0,
  currentPoints: 0,
  orders: {},
  rewards: {},
  nOrders: 0,
  totalPointsEarned: 0,
  totalPointsSpent: 0,
  engagementRate: 0,
  totalPointsEarnedByDate: {},
  totalPointsEarnedByMonth: {},
  totalPointsEarnedByMonthAndYear: {},
  totalPointsEarnedByWeekday: {},
  totalPointsEarnedMonthsSeen: {},
  totalPointsEarnedWeekdaysSeen: {},
  dailyRewardRedemptionsByDate: {},
  dailyRewardRedemptionsByMonth: {},
  dailyRewardRedemptionsByMonthAndYear: {},
  dailyRewardRedemptionsByWeekday: {},
  dailyRewardRedemptionsMonthsSeen: {},
  dailyRewardRedemptionsWeekdaysSeen: {},
  checkInAttendsByDate: {},
  checkInViewsByDate: {},
});

export interface UserAccesses {
  userId: string;
  email: string;
  fullName: string;
  type?: {
    role?: SessionUserType | null;
    identifier?: {
      stadium: string | null;
      sponsor: string | null;
      team: string | null;
    };
  };
  color?: string;
  logo?: string;
}

export const userAccessesDefaults = (): UserAccesses => ({
  userId: "",
  email: "",
  fullName: "",
});

export interface UserAccessesForm {
  userId: string;
  role: SessionUserType | null;
  stadium: string | null;
  sponsor: string | null;
  team: string | null;
}

export const userAccessesFormDefaults = (): UserAccessesForm => ({
  userId: "",
  role: null,
  stadium: null,
  sponsor: null,
  team: null,
});
