import { makeAutoObservable } from "mobx";
import { Loading, loadingFunction } from "../../helpers/loading";
import { DomainsStore } from "../domains";
import { UiStore } from "./ui-store";

import { FirebaseConnector } from "../connectors/firebase-connector";

export class LoyaltiesCategoriesFilterPageStore {
  public loading: Loading = Loading.NOT_LOADED;
  public allCategories: any[] = [];

  constructor(
    private uiStore: UiStore,
    private domainsStore: DomainsStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  get userId() {
    return this.uiStore.session.userId;
  }

  get teamId() {
    return this.uiStore.session.userTeamId;
  }

  loadCategories = async () => {
    let teamId = this.teamId;
    loadingFunction(
      this,
      async () => {
        this.allCategories =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `activeRewardCategoriesByTeam/${teamId}`,
          )) || {};
      },
      this.uiStore.notifications,
    );
  };

  changeCategoryName = async (categoryId: string, newName: string) => {
    await this.firebaseConnector.setDatabasePathAsObj(
      `activeRewardCategoriesByTeam/${this.teamId}/${categoryId}/name`,
      newName,
    );
    await this.loadCategories();
  };
  changeFilterStatus = async (categoryId: string, newStatus: boolean) => {
    await this.firebaseConnector.setDatabasePathAsObj(
      `activeRewardCategoriesByTeam/${this.teamId}/${categoryId}/enabled`,
      newStatus,
    );
    await this.loadCategories();
  };
}
