import { makeAutoObservable } from "mobx";
import { RouterStore } from "@ibm/mobx-react-router";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { DomainsStore } from "../domains";
import { AllUsersPageStore } from "./all-users-page-store";
import { LoyaltiesCategoriesPageStore } from "./loyalties-categories-page-store";
import { LoyaltiesPageStore } from "./loyalties-page-store";
import { LoyaltyEditPageStore } from "./loyalty-edit-page-store";
import { NotificationsStore } from "./notifications-store";
import { SessionStore } from "./session-store";
import { SponsorGameOverviewPageStore } from "./sponsor-game-overview-page-store";
import { SponsorOverviewPageStore } from "./sponsor-overview-page-store";
import { SponsorStadiumOverviewPageStore } from "./sponsor-stadium-overview-page-store";
import { SponsorStadiumsPageStore } from "./sponsor-stadiums-page-store";
import { SponsorsPageStore } from "./sponsors-page-store";
import { StadiumGameOverviewPageStore } from "./stadium-game-overview-page-store";
import { StadiumGamesPageStore } from "./stadium-games-page-store";
import { StadiumOverviewPageStore } from "./stadium-overview-page-store";
import { StadiumsPageStore } from "./stadiums-page-store";
import { UserAccessPageStore } from "./user-accesses-page-store";
import { UserOverviewPageOldStore } from "./user-overview-page-old-store";
import { QuestionsPageStore } from "./questions-page-store";
import { QuestionOverviewPageStore } from "./question-overview-page-store";
import { GamesPageStore } from "./games-page-store";
import { GameOverviewPageStore } from "./game-overview-page-store";
import { GameplayPageStore } from "./gameplay-page-store";
import { GameplaySendPageStore } from "./gameplay-send-page-store";
import { UsersByTeamPageStore } from "./user-by-team-page-store";
import { OrdersByTeamPageStore } from "./orders-by-team-page-store";
import { OrderDetailsPageStore } from "./order-details-page-store";
import { NotificationsByTeamPageStore } from "./notifications-by-team-page-store";
import { NotificationDetailsPageStore } from "./notification-details-page-store";
import { TeamOverviewPageStore } from "./team-overview-page-store";
import { UserOverviewPageStore } from "./user-overview-page-store";
import { PickupsByTeamPageStore } from "./pickups-by-team-page-store";
import { PickupEditPageStore } from "./pickup-edit-page-store";
import { LoyaltiesCategoriesFilterPageStore } from "./loyalties-categories-filter-page-store";
import { MarketplaceOverviewPageStore } from "./marketplace-overview-page-store";
import { MarketplaceProductPageStore } from "./marketplace-product-page-store";
import { MarketplaceSweepstakePageStore } from "./marketplace-sweepstake-page-store";
import { MarketplaceAuctionPageStore } from "./marketplace-auction-page-store";
import { IntegrationsPageStore } from "./integrations-page-store";

export class UiStore {
  notifications: NotificationsStore;
  session: SessionStore;
  sponsorsPage: SponsorsPageStore;
  sponsorsOverviewPage: SponsorOverviewPageStore;
  stadiumOverviewPage: StadiumOverviewPageStore;
  sponsorStadiumsPage: SponsorStadiumsPageStore;
  stadiumGamesPage: StadiumGamesPageStore;
  sponsorStadiumOverviewPage: SponsorStadiumOverviewPageStore;
  sponsorGameOverviewPage: SponsorGameOverviewPageStore;
  userOverviewPageOld: UserOverviewPageOldStore;
  userOverviewPage: UserOverviewPageStore;
  usersByTeamPage: UsersByTeamPageStore;
  ordersByTeamPage: OrdersByTeamPageStore;
  orderDetailsPage: OrderDetailsPageStore;
  notificationsByTeamPage: NotificationsByTeamPageStore;
  notificationDetailsPage: NotificationDetailsPageStore;
  pickupEditPage: PickupEditPageStore;
  pickupsByTeamPage: PickupsByTeamPageStore;
  stadiumsPage: StadiumsPageStore;
  stadiumGameOverviewPage: StadiumGameOverviewPageStore;
  integrationsPageStore: IntegrationsPageStore;

  teamOverviewPage: TeamOverviewPageStore;

  marketplaceOverviewPage: MarketplaceOverviewPageStore;
  marketplaceProductPage: MarketplaceProductPageStore;
  marketplaceSweepstakePage: MarketplaceSweepstakePageStore;
  marketplaceAuctionPage: MarketplaceAuctionPageStore;

  allUsersPage: AllUsersPageStore;

  userAccessPage: UserAccessPageStore;

  loyaltiesCategoriesPage: LoyaltiesCategoriesPageStore;
  loyaltiesCategoriesFilterPage: LoyaltiesCategoriesFilterPageStore;
  loyaltiesPage: LoyaltiesPageStore;
  loyaltyEditPage: LoyaltyEditPageStore;

  questionsPage: QuestionsPageStore;
  questionOverviewPage: QuestionOverviewPageStore;

  gamesPage: GamesPageStore;
  gameOverviewPage: GameOverviewPageStore;
  gameplayPage: GameplayPageStore;
  gameplaySendPage: GameplaySendPageStore;

  domainsStore: DomainsStore;

  constructor(public routerStore: RouterStore) {
    const firebaseConnector = new FirebaseConnector();
    this.domainsStore = new DomainsStore(firebaseConnector);
    this.notifications = new NotificationsStore(this);
    this.session = new SessionStore(this, firebaseConnector);
    this.sponsorsPage = new SponsorsPageStore(this, firebaseConnector);
    this.sponsorsOverviewPage = new SponsorOverviewPageStore(
      this,
      firebaseConnector
    );
    this.sponsorStadiumsPage = new SponsorStadiumsPageStore(
      this,
      firebaseConnector
    );
    this.sponsorStadiumOverviewPage = new SponsorStadiumOverviewPageStore(
      this,
      firebaseConnector
    );
    this.sponsorGameOverviewPage = new SponsorGameOverviewPageStore(
      this,
      firebaseConnector
    );

    this.stadiumsPage = new StadiumsPageStore(this, firebaseConnector);
    this.stadiumOverviewPage = new StadiumOverviewPageStore(
      this,
      firebaseConnector
    );
    this.stadiumGamesPage = new StadiumGamesPageStore(this, firebaseConnector);
    this.stadiumGameOverviewPage = new StadiumGameOverviewPageStore(
      this,
      firebaseConnector
    );

    this.teamOverviewPage = new TeamOverviewPageStore(this, firebaseConnector);

    this.marketplaceOverviewPage = new MarketplaceOverviewPageStore(
      this,
      firebaseConnector
    );
    this.marketplaceProductPage = new MarketplaceProductPageStore(
      this,
      firebaseConnector
    );
    this.marketplaceSweepstakePage = new MarketplaceSweepstakePageStore(
      this,
      firebaseConnector
    );
    this.marketplaceAuctionPage = new MarketplaceAuctionPageStore(
      this,
      firebaseConnector
    );

    this.userOverviewPage = new UserOverviewPageStore(this, firebaseConnector);
    this.userOverviewPageOld = new UserOverviewPageOldStore(
      this,
      firebaseConnector
    );
    this.usersByTeamPage = new UsersByTeamPageStore(this, firebaseConnector);

    this.allUsersPage = new AllUsersPageStore(this, firebaseConnector);
    this.ordersByTeamPage = new OrdersByTeamPageStore(this, firebaseConnector);
    this.orderDetailsPage = new OrderDetailsPageStore(this, firebaseConnector);
    this.notificationsByTeamPage = new NotificationsByTeamPageStore(
      this,
      firebaseConnector
    );
    this.notificationDetailsPage = new NotificationDetailsPageStore(
      this,
      firebaseConnector
    );
    this.pickupEditPage = new PickupEditPageStore(this, firebaseConnector);
    this.pickupsByTeamPage = new PickupsByTeamPageStore(
      this,
      firebaseConnector
    );

    this.userAccessPage = new UserAccessPageStore(this, firebaseConnector);

    this.loyaltiesCategoriesPage = new LoyaltiesCategoriesPageStore(
      this,
      this.domainsStore,
      firebaseConnector
    );
    this.loyaltiesCategoriesFilterPage = new LoyaltiesCategoriesFilterPageStore(
      this,
      this.domainsStore,
      firebaseConnector
    );
    this.loyaltiesPage = new LoyaltiesPageStore(
      this,
      this.domainsStore,
      firebaseConnector
    );
    this.loyaltyEditPage = new LoyaltyEditPageStore(
      this,
      this.domainsStore,
      firebaseConnector
    );

    this.questionsPage = new QuestionsPageStore(this, firebaseConnector);
    this.questionOverviewPage = new QuestionOverviewPageStore(
      this,
      firebaseConnector
    );

    this.gamesPage = new GamesPageStore(this, firebaseConnector);
    this.gameOverviewPage = new GameOverviewPageStore(this, firebaseConnector);
    this.gameplayPage = new GameplayPageStore(this, firebaseConnector);
    this.gameplaySendPage = new GameplaySendPageStore(this, firebaseConnector);
    this.integrationsPageStore = new IntegrationsPageStore(
      this,
      firebaseConnector
    );

    makeAutoObservable(this);
  }
}
