import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { SortDirection } from "react-virtualized";
import { usersRoutes } from "../../app-routes";
import { CPDashboardSpacer } from "../../components/CPDashboardSpacer";
import { CPDataChart } from "../../components/CPDataChart";
import { CustomizableDataCardBlock } from "../../components/CustomizableDataCardBlock";
import { DemographicsChart } from "../../components/DemographicsChart";
import {
  cpBoldSubHeaderStyle,
  cpLargeCardHeight,
  cpSmallCardHeight,
} from "../../helpers/constants";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";

export const StadiumGameOverviewPage = observer(() => {
  const { teamId, gameId } = useParams<{
    teamId: string;
    gameId: string;
  }>();
  const { stadiumGameOverviewPage } = useStore().ui;
  const { routerStore } = useStore();

  React.useEffect(() => {
    stadiumGameOverviewPage.loadGameOverview(teamId, gameId);
  }, [teamId, gameId, stadiumGameOverviewPage]);

  const goToUser = (rowData: any) => {
    let userId = rowData["userId"];
    if (userId) routerStore.push(usersRoutes.userOverview(userId, teamId));
  };

  return (
    <MainLayout
      loading={stadiumGameOverviewPage.loading}
      firebaseConnector={stadiumGameOverviewPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex">
            <Box style={cpBoldSubHeaderStyle}>Games</Box>
          </Box>
        </Box>
      }
    >
      <Box flex={1} height={"160px"}>
        <CustomizableDataCardBlock
          {...stadiumGameOverviewPage.firstDataBlock}
          itemsPerRow={1}
          height={cpSmallCardHeight}
        />
      </Box>
      <Box display={"flex"}>
        <Box flex={1}>
          <CPDataChart
            height="940px"
            title="Top Most Engaged Members"
            dataKeyList={["nameAndAvatar", "nQuestionsAnswered"]}
            separateLabelMap={{
              nameAndAvatar: "Name",
              nQuestionsAnswered: "Questions Answered",
            }}
            data={stadiumGameOverviewPage.mostEngagedMembersData}
            flexList={[5, 1]}
            defaultSort="nQuestionsAnswered"
            exportCSVKeys={["fullName", "nQuestionsAnswered"]}
            onRowClick={goToUser}
          />
        </Box>
        <CPDashboardSpacer />
        <Box flex={1}>
          <Box>
            <Box height={cpLargeCardHeight}>
              <DemographicsChart
                demographics={stadiumGameOverviewPage.userDemographics}
                height={cpLargeCardHeight}
              />
            </Box>
            <CPDashboardSpacer />
            <Box height={cpLargeCardHeight}>
              <CPDataChart
                title="Winners"
                dataKeyList={["nameAndAvatar", "rewardTitle"]}
                separateLabelMap={{
                  nameAndAvatar: "Name",
                  rewardTitle: "Prize",
                }}
                exportCSVKeys={["fullName", "rewardTitle"]}
                data={stadiumGameOverviewPage.rewards}
                flexList={[5, 3, 3]}
                defaultSort="rewardIndex"
                defaultSortDirection={SortDirection.DESC}
                onRowClick={goToUser}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <CPDashboardSpacer />
      <CPDashboardSpacer />
    </MainLayout>
  );
});
