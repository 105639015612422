import { makeAutoObservable } from "mobx";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";
import { Loading, loadingFunction } from "../../helpers/loading";
import {
  allFoldersOption,
  FolderItem,
  QuestionListItem,
} from "../../types/questions-types";
import { questionsRoutes } from "../../app-routes";

export class QuestionsPageStore {
  private searchInput: string = "";
  private _questions: QuestionListItem[] = [];
  private _folders: FolderItem[] = [];
  public selectedFolderName: string = allFoldersOption.label;
  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  get questions() {
    return this._questions
      .filter(
        (question) =>
          question.question
            .toLowerCase()
            .includes(this.searchInput.toLowerCase()) &&
          (question.questionFolder === this.selectedFolderName ||
            this.selectedFolderName === allFoldersOption.label),
      )
      .map((question) => ({
        answerType: question.answerType,
        question: question.question,
        questionId: question.questionId,
        questionFolder: question.questionFolder,
        questionType: question.questionType,
      }));
  }

  get getFolderOptions() {
    return [
      allFoldersOption,
      ...this._folders.map((folder: FolderItem) => {
        return {
          label: folder.folderName,
          value: folder.folderId,
        };
      }),
    ];
  }

  createFolder = (folderName: string) => {
    loadingFunction(
      this,
      async () => {
        await this.firebaseConnector.createFolder(folderName);
        await this.loadFolders(true);
      },
      this.uiStore.notifications,
    );
  };

  onSelectFolder = (folderName: string) => {
    this.selectedFolderName = folderName;
  };

  onRowClick = ({ questionId }: QuestionListItem) => {
    this.uiStore.routerStore.push(questionsRoutes.questionOverview(questionId));
  };

  searchQuestion = (input: string) => {
    this.searchInput = input;
  };

  loadQuestionsForDisplay = async () => {
    loadingFunction(
      this,
      async () => {
        this._questions =
          await this.firebaseConnector.loadQuestionsForDisplay();
      },
      this.uiStore.notifications,
    );
  };

  loadFolders = async (reload?: boolean) => {
    reload = !!reload;
    loadingFunction(
      this,
      async () => {
        const { foldersDomain } = this.uiStore.domainsStore;
        await foldersDomain.loadFolders(reload);
        this._folders = foldersDomain.folders;
      },
      this.uiStore.notifications,
    );
  };
}
