import React from "react";
import { Box, Button, capitalize, Grid } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { PageTitle } from "../../components/PageTitle";
import { useParams } from "react-router";
import { GameItem } from "../../types/games-types";
import { SendQuestionsTable } from "./components/SendQuestionsTable";
import { CrowdSelect } from "../../components/CrowdSelect";

export const GameplaySendPage = observer(() => {
  const { gameplaySendPage } = useStore().ui;
  const { gameId } = useParams<{ gameId: string }>();
  const { sendMode } = useParams<{ sendMode: string }>();

  React.useEffect(() => {
    gameplaySendPage.loadPage(gameId);
  }, [gameId, gameplaySendPage]);

  const game = gameplaySendPage.game;
  const isStageMode = gameplaySendPage.isStageMode;

  const setSelectedFolderName = (selected: string) => {
    gameplaySendPage.onSelectFolder(selected);
  };

  const setIsStageMode = (stageMode: boolean) => {
    gameplaySendPage.setIsStageMode(stageMode);
  };

  return (
    <MainLayout
      loading={gameplaySendPage.loading}
      firebaseConnector={gameplaySendPage.firebaseConnector}
      pageActions={
        <Box display="flex" alignItems="space-between" width="100%">
          <Grid container>
            <Grid item container md={6} spacing={2}>
              <Grid item>
                <PageTitle title={capitalize(sendMode)} />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color={isStageMode ? "primary" : "secondary"}
                  onClick={() => setIsStageMode(true)}
                >
                  Stage Questions
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color={isStageMode ? "secondary" : "primary"}
                  onClick={() => setIsStageMode(false)}
                >
                  Push Questions
                </Button>
              </Grid>
            </Grid>
            <Grid item container md={6} spacing={2}>
              <Box width="100%" textAlign="right">
                <CrowdSelect
                  options={gameplaySendPage.folderOptions}
                  selected={gameplaySendPage.selectedFolderName}
                  onChange={setSelectedFolderName}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
    >
      <Box height="40rem">
        <SendQuestionsTable
          questions={gameplaySendPage.questions}
          onRowClick={console.log}
        />
      </Box>
    </MainLayout>
  );
});
