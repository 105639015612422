import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";
import { TopNavigation } from "../components/TopNavigation";
import { Loading } from "../helpers/loading";
import { DashboardImageHeader } from "../pages/teams/components/DashboardImageHeader";
import { FirebaseConnector } from "../stores/connectors/firebase-connector";
import { useStore } from "../helpers/use-store";
import { teamRoutes } from "../app-routes";
import { copyObject } from "../types/util-types";
import { sleep } from "../helpers/generic-helpers";

const useStyles = makeStyles((theme) => ({
  top_nav: {
    marginBottom: theme.typography.pxToRem(40),
  },
  page_actions: {
    marginBottom: theme.typography.pxToRem(25),
  },
}));

interface Props {
  pageActions?: ReactNode;
  loading?: Loading;
  isOverview?: boolean;
  firebaseConnector: FirebaseConnector;
}

export const MainLayout: React.FC<Props> = ({
  pageActions,
  loading = Loading.NOT_LOADED,
  children,
  isOverview = false,
  firebaseConnector,
}) => {
  const classes = useStyles();
  const { session, routerStore } = useStore().ui;
  const promptTeamChange = async () => {
    let allTeams: any = await firebaseConnector.getDatabaseObjAtPath(`teams`);
    let counter = 1;
    let prompt = "Select the number of the team you want to switch to: \n";
    console.log(`allTeams`, allTeams);
    let teamIds: any = [];
    Object.keys(allTeams).forEach((teamId) => {
      let team: any = allTeams[teamId];
      let shouldShowWeb = team.shouldShowWeb;
      if (!shouldShowWeb) {
        return;
      }
      teamIds.push(teamId);
      prompt += `${counter}. ${team.editTeamsName}\n`;
      counter++;
    });
    let teamNumber = window.prompt(prompt);
    let isValidNumber =
      teamNumber &&
      !isNaN(parseInt(teamNumber)) &&
      parseInt(teamNumber) <= Object.keys(allTeams).length;
    let userId = session.user?.userId;
    if (!userId) {
      window.alert("No user id found");
      return;
    }
    if (!isValidNumber) {
      window.alert("Invalid team number");
      return;
    }
    let teamId = teamIds[parseInt(`${teamNumber}`) - 1];
    let stadium = allTeams[teamId].editTeamsStadium;
    if (!stadium) {
      window.alert("No stadium found");
      return;
    }
    if (session.user && session.user.type && session.user.type.identifier) {
      console.log(`setting session.user!.type!.identifier!.team to`, teamId);
      let path = `users/${userId}/type/identifier`;
      await firebaseConnector.setDatabasePathAsObj(`${path}/team`, teamId);
      await firebaseConnector.setDatabasePathAsObj(`${path}/stadium`, stadium);
      window.location.href = window.location.origin;
    }
  };
  return (
    <Box height="100%" paddingBottom={4} style={{ backgroundColor: "#F7F7F7" }}>
      <TopNavigation
        className={isOverview ? "" : classes.top_nav}
        promptTeamChange={promptTeamChange}
      />
      {isOverview && <DashboardImageHeader />}
      <Box height="100%" marginLeft={10} marginRight={10}>
        {pageActions && (
          <Box className={classes.page_actions}>{pageActions}</Box>
        )}
        <Box height="100%">
          {loading === Loading.LOADING ? (
            <Box
              height="calc(100vh - 250px)"
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size="6rem" />
            </Box>
          ) : (
            children
          )}
        </Box>
      </Box>
    </Box>
  );
};
