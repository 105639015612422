import { Box } from "@material-ui/core";
import React from "react";
import { Color } from "react-color";

interface Props {
  items: { title: string; color: Color }[];
}

export const CPLegend: React.FC<Props> = ({ items = [] }) => {
  let boxHeight = "8px";

  return (
    <Box display={"flex"}>
      {items.map((item) => {
        return (
          <Box
            key={item.title}
            display={"flex"}
            justifyContent={"center"}
            alignItems="center"
          >
            <Box height={boxHeight} width={boxHeight} bgcolor={item.color} />
            <Box ml={"6px"} mr={"20px"}>
              {item.title}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
