import React, { ReactElement } from "react";
import { Box, Card, makeStyles, Typography } from "@material-ui/core";
import { formatCommas } from "../helpers/name-helpers";
import clsx from "clsx";
import { debugStyle } from "../types/util-types";
import {
  cpBoldSubHeaderStyle,
  cpCardBoxShadow,
  halfStandardDashboardPadding,
  standardDashboardPadding,
} from "../helpers/constants";
import { CPDownloadButton } from "./CPDownloadButton";

interface Props {
  title: string;
  value?: string | number;
  child?: ReactElement;
  itemsPerRow: number;
  flex?: number;
  cumulativeFlex?: number;
  onDownload?: Function;
}

const useStyles = makeStyles((theme) => ({
  card: (props: any) => {
    let itemsPerRow = props.itemsPerRow || 4;
    // let flexBasis = `100% - ${standardDashboardPadding}`;
    // console.log(`flexBasis: ${flexBasis}`)
    let flexBasis = 100;
    // let flexBasis = 100-(itemsPerRow-1)*standardCardPaddingPercentage;
    let flex = props.flex || 1;
    flexBasis *= flex / itemsPerRow;
    let paddingTop;
    let paddingRight;
    let paddingLeft;
    let isTopRow = props.cumulativeFlex <= itemsPerRow;
    if (!isTopRow) paddingTop = standardDashboardPadding;
    let isFarRight = props.cumulativeFlex % itemsPerRow == 0;
    if (!isFarRight) paddingRight = halfStandardDashboardPadding;
    let isFarLeft = (props.cumulativeFlex - flex) % itemsPerRow == 0;
    if (!isFarLeft) paddingLeft = halfStandardDashboardPadding;
    return {
      flexBasis: `${flexBasis}%`,
      paddingTop: paddingTop,
      paddingRight: paddingRight,
      paddingLeft: paddingLeft,
    };
  },
}));

export const CustomizableDataCard: React.FC<Props> = ({
  title,
  value,
  child,
  itemsPerRow,
  flex,
  cumulativeFlex,
  onDownload,
}) => {
  let props = { itemsPerRow, flex, cumulativeFlex };
  const classes = useStyles(props);
  return (
    <Box className={classes.card}>
      <Card
        style={{
          height: "100%",
          width: "100%",
          paddingTop: "10px",
          paddingLeft: "20px",
          paddingRight: "10px",
          boxShadow: cpCardBoxShadow,
        }}
      >
        <Box height="100%" fontWeight="fontWeightBold">
          <Box>
            <Box height="35px">
              <Typography component="div">
                <Box
                  display={"flex"}
                  justifyContent="space-between"
                  fontWeight="fontWeightBold"
                  fontSize={14}
                >
                  <Box pt={"10px"} style={cpBoldSubHeaderStyle}>
                    {title}
                  </Box>
                  <CPDownloadButton onDownload={onDownload} />
                </Box>
              </Typography>
            </Box>
            <Box height={"15px"}></Box>
            <Box fontSize={34}>
              {child}
              {value && formatCommas(`${value}`)}
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
