import { createMuiTheme } from "@material-ui/core";

export const primaryColorString = "#000000";
export const secondaryColorString = "#444444";
export const transparentColorString = "rgba(0,0,0,0)";
// export const primaryColorString = "#40D0FC";
// export const secondaryColorString = "#757575";

export const APP_THEME = createMuiTheme({
  palette: {
    primary: {
      main: primaryColorString,
    },
    secondary: {
      main: secondaryColorString,
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "#FFF",
      },
      text: {
        color: "#FFF",
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
        body: {
          padding: 0,
        },
      },
    },
  },
  spacing: (factor) => `${0.25 * factor}rem`,
});
