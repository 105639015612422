import React from "react";
import logo from "../assets/logo.png";

interface Props {
  className?: string;
  onClick?: () => void;
}

export const CrowdPlayLogo: React.FC<Props> = ({ className, onClick }) => {
  return (
    <img
      src={logo}
      className={className}
      onClick={onClick}
      width="100"
      height="34"
    />
  );
};
