import { Box } from "@material-ui/core";
import React from "react";

interface Props {}

export const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Box>
  );
};
