import { Box, IconButton, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";

interface Props {
  selectedIndex: number;
  imageUrls: string[];
  onSelectedIndexChange: (index: number) => void;
}

export const CPImageScroller: React.FC<Props> = ({
  selectedIndex,
  imageUrls,
  onSelectedIndexChange,
}) => {
  if (imageUrls.length < selectedIndex) {
    return <div></div>;
  }
  // Big image on top of whatever is selected. Grid of options underneath of thumbnails, whatever gets clicked on gets displayed in the big image.
  return (
    <Box>
      <Box>
        <img
          style={{ height: "300px", width: "300px" }}
          src={imageUrls[selectedIndex]}
        />
      </Box>
      <Box>
        {imageUrls.map((imageUrl, index) => {
          let isSelected = index === selectedIndex;
          return (
            <Tooltip title={`Option ${index + 1}`} key={index}>
              <IconButton
                onClick={() => onSelectedIndexChange(index)}
                style={{
                  padding: "0px",
                  border: `2px solid ${isSelected ? "red" : "transparent"}`,
                  borderRadius: "0px",
                }}
              >
                <img style={{ height: "50px", width: "50px" }} src={imageUrl} />
              </IconButton>
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
};
