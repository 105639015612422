import {
  Box,
  Checkbox,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import * as Yup from "yup";
import { CPText } from "../../../components/CPText";
import { FormikDatePicker } from "../../../components/FormikDatePicker";
import { FormikTextField } from "../../../components/FormikTextField";
import { PickupOverview } from "../../../types/order-types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginTop: 0,
      width: "100%",
    },
  }),
);

const validationSchema = (pickup: PickupOverview) => {
  return Yup.object().shape({
    pickupLocation: Yup.string().required("Location required"),
    pickupDate: Yup.string().required("Date required"),
    pickupStart: Yup.string().required("Start required"),
    pickupEnd: Yup.string().required("End required"),
  });
};

interface Props {
  onSave: (pickup: PickupOverview, sendNotification: boolean) => void;
  formValues: PickupOverview;
  formRef?: React.RefObject<HTMLButtonElement>;
}

export const PickupEditForm: React.FC<Props> = observer(
  ({ onSave, formValues, formRef }) => {
    const [sendNotification, setSendNotification] = useState(false);

    const formik = useFormik<PickupOverview>({
      enableReinitialize: true,
      initialValues: formValues,
      validationSchema: validationSchema(formValues),
      onSubmit: (overview: PickupOverview) => {
        onSave(overview, sendNotification);
      },
    });

    return (
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            flexGrow={1}
          >
            <Box display="flex" width="100%" marginBottom={4}>
              <FormikTextField
                name="pickupLocation"
                label="Location"
                formik={formik}
              />
            </Box>
            <Box width="100%" display="flex" marginBottom={4}>
              <FormikDatePicker
                name="pickupDate"
                label="Date"
                formik={formik}
              />
            </Box>
            <Box width="100%" display="flex" marginBottom={4}>
              <FormikTextField
                name="pickupStart"
                label="Start"
                formik={formik}
              />
            </Box>
            <Box
              width="100%"
              marginBottom={4}
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <FormikTextField name="pickupEnd" label="End" formik={formik} />
            </Box>
            <Box
              width="100%"
              marginBottom={4}
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <FormikTextField
                name="pickupAdditionalDetails"
                label="Additional Details (Optional)"
                formik={formik}
              />
            </Box>
            <Box
              width="100%"
              marginBottom={4}
              display="flex"
              alignItems="flex-start"
              alignContent={"center"}
            >
              <Checkbox
                name={"notifyUsers"}
                color="primary"
                onChange={(e, checked: boolean) => setSendNotification(checked)}
                checked={sendNotification}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <CPText title="Notify Users about this change" />
            </Box>
          </Box>
        </Box>
        <button style={{ display: "none" }} type="submit" ref={formRef} />
      </form>
    );
  },
);
