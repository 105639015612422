import { Box } from "@material-ui/core";
import React from "react";
import { cpPositionedStyle } from "../../../helpers/constants";
import { useStore } from "../../../helpers/use-store";
import {
  firstNameFromFullName,
  getAttributeFromObject,
} from "../../../types/util-types";

interface Props {}

export const DashboardImageHeader: React.FC<Props> = ({}) => {
  const { session } = useStore().ui;
  let firstName = firstNameFromFullName(session.user?.fullName || "guest");
  let iD1 = getAttributeFromObject(`imageUrls/iD1`, session.teamInfo);
  let teamName = getAttributeFromObject(`editTeamsName`, session.teamInfo);
  return (
    <Box width="100%" height="220px" color={"white"} position="relative">
      <Box style={cpPositionedStyle}>
        <img
          width={"100%"}
          height="200px"
          src={iD1}
          style={{ objectFit: "cover" }}
        />
      </Box>
      <Box
        style={{
          position: "absolute",
          top: "50px",
          left: 0,
          right: 0,
          textAlign: "center",
        }}
      >
        <Box>{`Welcome back ${firstName}`}</Box>
        <Box style={{ fontSize: "40px", fontWeight: "bolder" }}>{teamName}</Box>
      </Box>
    </Box>
  );
};
