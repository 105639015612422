import { makeAutoObservable } from "mobx";
import { FolderItem } from "../../types/questions-types";
import { FirebaseConnector } from "../connectors/firebase-connector";

export class FoldersDomainStore {
  private _folders: FolderItem[] = [];

  constructor(public firebaseConnector: FirebaseConnector) {
    makeAutoObservable(this);
  }

  get folders() {
    return this._folders;
  }

  get foldersOptions() {
    return this._folders.map((folder) => {
      return {
        label: folder.folderName,
        value: folder.folderId,
      };
    });
  }

  loadFolders = async (reload: boolean = false) => {
    if (!this._folders.length || reload) {
      this._folders = await this.firebaseConnector.loadFolders();
    }
  };
}
