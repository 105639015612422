import { FirebaseConnector } from "../connectors/firebase-connector";
import { FoldersDomainStore } from "./folders-domain";
import { LoyaltiesDomainStore } from "./loyalties-domain";
import { RewardsDomainStore } from "./rewards-domain";
import { TeamsDomainStore } from "./teams-domain";

export class DomainsStore {
  public foldersDomain: FoldersDomainStore;
  public teamsDomain: TeamsDomainStore;
  public rewardsDomain: RewardsDomainStore;
  public loyaltiesDomain: LoyaltiesDomainStore;

  constructor(firebaseConnector: FirebaseConnector) {
    this.foldersDomain = new FoldersDomainStore(firebaseConnector);
    this.teamsDomain = new TeamsDomainStore(firebaseConnector);
    this.rewardsDomain = new RewardsDomainStore(firebaseConnector);
    this.loyaltiesDomain = new LoyaltiesDomainStore(firebaseConnector);
  }
}
