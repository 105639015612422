import { Checkbox, FormControlLabel } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";

interface Props<T = any> {
  name: string;
  label: string;
  formik: FormikProps<T>;
}

export const FormikCheckbox: React.FC<Props> = ({ name, label, formik }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          color="primary"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          checked={formik.values[name]}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      }
      label={label}
    />
  );
};
