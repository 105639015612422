export const clamp = (num: number, min: number, max: number) => {
  return Math.max(min, Math.min(num, max));
};

export const isInteger = (input: any) => {
  let integer = parseInt(`${input}`);
  let float = parseFloat(`${input}`);
  if (float !== integer) return false;
  if (!isNaN(integer)) return true;
  return false;
};

export const isNumber = (input: any) => {
  let float = parseFloat(`${input}`);
  if (!isNaN(float)) return true;
  return false;
};

export const intValOrZero = (input: any) => {
  return parseInt(`${input}`) || 0;
};
