import { Box, IconButton, TextField, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";

interface Props {
  hidden: boolean;
  child: any;
}

export const CPHiddenDiv: React.FC<Props> = ({ hidden, child }) => {
  if (hidden) return <div></div>;
  return (
    <div
      style={{
        opacity: `${hidden ? "0" : "1"}`,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: hidden ? -1 : 1,
      }}
    >
      {child}
    </div>
  );
};
