import { makeAutoObservable } from "mobx";
import { gamesRoutes } from "../../app-routes";
import { Loading, loadingFunction } from "../../helpers/loading";
import { GameItem, gameItemDefaults } from "../../types/games-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class GameplayPageStore {
  public loading: Loading = Loading.NOT_LOADED;
  domainsStore: any;
  private _game: GameItem = gameItemDefaults();

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  get game() {
    return this._game;
  }

  loadPage = (gameId: string) => {
    loadingFunction(
      this,
      () => Promise.all([this.loadGame(gameId)]),
      this.uiStore.notifications,
    );
  };

  private loadGame = async (gameId?: string) => {
    this._game = await this.firebaseConnector.loadGameById(gameId);
  };

  saveGame = async (game: GameItem) => {
    loadingFunction(
      this,
      async () => {
        await this.firebaseConnector.saveGame(game);
        this.uiStore.routerStore.push(gamesRoutes.games);
      },
      this.uiStore.notifications,
    );
  };
}
