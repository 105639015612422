import React from "react";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { useParams } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { DataCardBlock } from "../../components/DataCardBlock";
import { SponsorCategoriesTable } from "./components/SponsorCategoriesTable";
import { SponsorGamesTable } from "./components/SponsorGamesTable";
import { GenderDemographicsStatistics } from "../../components/GenderDemographicsStatics";
import { SponsorGame } from "../../types/sponsors-types";

export const SponsorStadiumOverviewPage = observer(() => {
  const { sponsorName, stadiumName } = useParams<{
    sponsorName: string;
    stadiumName: string;
  }>();
  const { sponsorStadiumOverviewPage } = useStore().ui;

  React.useEffect(() => {
    sponsorStadiumOverviewPage.loadStadiumOverview(sponsorName, stadiumName);
  }, [sponsorName, sponsorStadiumOverviewPage, stadiumName]);

  const onGameRowClick = React.useCallback(
    (game: SponsorGame) => {
      sponsorStadiumOverviewPage.onGameRowClick(sponsorName, stadiumName, game);
    },
    [sponsorStadiumOverviewPage, sponsorName, stadiumName],
  );

  return (
    <MainLayout
      loading={sponsorStadiumOverviewPage.loading}
      firebaseConnector={sponsorStadiumOverviewPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title={sponsorName} subTitle={stadiumName} />
        </Box>
      }
    >
      <DataCardBlock {...sponsorStadiumOverviewPage.dataBlock} />
      <Box marginTop={4} marginBottom={4}>
        <GenderDemographicsStatistics
          genderStats={sponsorStadiumOverviewPage.genderStats}
          demographicStats={sponsorStadiumOverviewPage.demographicStats}
        />
      </Box>
      {!!sponsorStadiumOverviewPage.categories.length && (
        <SponsorCategoriesTable
          categories={sponsorStadiumOverviewPage.categories}
        />
      )}
      {!!sponsorStadiumOverviewPage.games.length && (
        <Box marginTop={4}>
          <SponsorGamesTable
            games={sponsorStadiumOverviewPage.games}
            onRowClick={onGameRowClick}
          />
        </Box>
      )}
    </MainLayout>
  );
});
