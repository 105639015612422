import {
  Box,
  Button,
  IconButton,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import {
  ChromePicker,
  CirclePicker,
  Color,
  ColorResult,
  CompactPicker,
  HuePicker,
  MaterialPicker,
} from "react-color";
import React, { useState } from "react";
import { FormikProps } from "formik";
import { CPText } from "./CPText";
import { CPDisplayTextField } from "./CPDisplayTextField";
import { FormikTextField } from "./FormikTextField";

interface Props<T = any> {
  name: string;
  label: string;
  formik: FormikProps<T>;
}

export const CPColorPicker: React.FC<Props> = ({ name, label, formik }) => {
  const [open, setOpen] = useState(false);
  const error = !!formik.touched[name] && formik.errors[name];
  let color = formik.values[name];
  let buttonSize = "40px";

  let toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <Box display={"flex"}>
      <FormikTextField name={name} label={label} formik={formik} />
      <Box height={buttonSize} width={buttonSize}>
        <Button
          onClick={toggleOpen}
          style={{
            backgroundColor: color,
            border: "1px solid black",
            height: "100%",
            width: "100%",
          }}
        />
      </Box>
      {open && (
        <Box ml={10}>
          <Box
            tabIndex={-1}
            onBlur={() => {
              //Janky way to close the popup on outside clicks
              // toggleOpen();
            }}
          >
            <ChromePicker
              color={color}
              onChange={(newColor: ColorResult) => {
                formik.setFieldValue(name, newColor.hex);
              }}
              onChangeComplete={(newColor: ColorResult) => {
                formik.setFieldValue(name, newColor.hex);
              }}
            />
          </Box>

          <Box mt={4} />
          <Button
            onClick={() => setOpen(false)}
            color="primary"
            variant="contained"
            fullWidth
          >
            Done
          </Button>
        </Box>
      )}
    </Box>
  );
};
