import { NotificationsStore } from "../stores/ui/notifications-store";

export enum Loading {
  NOT_LOADED,
  LOADING,
  SUCCESS,
  ERROR,
}

export async function loadingFunction(
  context: { loading: Loading },
  callback: () => Promise<any>,
  notifications: NotificationsStore,
) {
  try {
    context.loading = Loading.LOADING;
    await callback();
    context.loading = Loading.SUCCESS;
  } catch (error: any) {
    console.log(`Error from loading function: ${error.message}`);
    notifications.showError(error.message);
    context.loading = Loading.ERROR;
  }
}
