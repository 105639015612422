import React from "react";
import { observer } from "mobx-react-lite";
import { MainLayout } from "../../layouts/MainLayout";
import { useStore } from "../../helpers/use-store";
import { Box, TextField } from "@material-ui/core";
import { SortableTable } from "../../components/SortableTable";
import { TableContainer } from "../../components/TableContainer";
import { usersRoutes } from "../../app-routes";
import { PageTitle } from "../../components/PageTitle";

export const AllUsersPage = observer(() => {
  const { allUsersPage, routerStore } = useStore().ui;

  React.useEffect(() => {
    allUsersPage.loadUsers();
  }, [allUsersPage]);

  const onSearch: React.ChangeEventHandler<{ value: string }> =
    React.useCallback(
      (e) => allUsersPage.searchUser(e.target.value),
      [allUsersPage],
    );
  return (
    <MainLayout
      loading={allUsersPage.loading}
      firebaseConnector={allUsersPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title="" />
          <Box width={250}>
            <TextField
              style={{ width: "100%" }}
              label="Search user..."
              onChange={onSearch}
            />
          </Box>
        </Box>
      }
    >
      <Box height="40rem">
        <TableContainer title={`Total Users - ${allUsersPage.users.length}`}>
          <SortableTable
            height="100vh"
            columns={[
              {
                dataKey: "fullName",
                label: "Name",
                flexGrow: 1,
                width: 100,
              },
              {
                dataKey: "username",
                label: "Username",
                flexGrow: 1,
                width: 100,
              },
              {
                dataKey: "userId",
                label: "User Id",
                flexGrow: 1,
                width: 100,
              },
              {
                dataKey: "email",
                label: "Email",
                flexGrow: 1,
                width: 100,
              },
            ]}
            rows={allUsersPage.users}
            // @ts-ignore
            onRowClick={({ userId }) =>
              routerStore.push(usersRoutes.user(`${userId}`))
            }
            withChevron
          />
        </TableContainer>
      </Box>
    </MainLayout>
  );
});
