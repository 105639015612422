import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/auth";

import "firebase/functions";
import moment from "moment";
import { allOptionValue, globalTestingString } from "../../helpers/constants";
import {
  combinedObject,
  isPromptProtected,
} from "../../helpers/generic-helpers";
import {
  getAddressNameFromShippingData,
  getFirstVariantNameFromCombination,
  getPickupDateFromPickupData,
  getPickupNameFromPickupData,
  getStatusLabelFromStatus,
  getVariantNameFromCombination,
} from "../../helpers/order-helpers";
import {
  formatDate,
  getMomentFromYMD,
  localizedMoment,
  noTimezoneNow,
} from "../../helpers/time-helpers";
import { hMFormat, mdyFormat } from "../../types/formik-types";
import { GameItem, gameItemDefaults } from "../../types/games-types";
import { LoyaltyItem, loyaltyItemDefaults } from "../../types/loyalties-types";
import {
  OrderOverview,
  PickupOverview,
  orderOverviewDefaults,
  pickupOverviewDefaults,
} from "../../types/order-types";
import {
  FolderItem,
  QuestionItem,
  questionItemDefaults,
} from "../../types/questions-types";
import {
  TeamOverviewResponse,
  teamOverviewStatsDefaults,
} from "../../types/teams-types";
import {
  SessionUserType,
  UserAccesses,
  UserListItem,
  UserOverview,
  userOverviewDefaults,
} from "../../types/user-types";
import {
  firstNameFromFullName,
  getAttributeFromObject,
  lastNameFromFullName,
} from "../../types/util-types";
import { IntegrationListItem } from "../../types/integration-types";

type UserAuthCallback = (user: UserAccesses | null) => void;

interface GrantAccess {
  userId: string;
  type: {
    role: SessionUserType | null;
    identifier: {
      stadium: string | null;
      sponsor: string | null;
      team: string | null;
    };
  };
}

const fbQuestions = "refactor/questions";
const fbFolders = "refactor/folders";
const fbGames = "refactor/games";

export class FirebaseConnector {
  private storage: firebase.storage.Storage;
  private db: firebase.database.Database;
  public auth: firebase.auth.Auth;

  constructor() {
    const app = firebase.initializeApp({
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    });
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
  }

  async getDatabaseObjAtPath(path: string): Promise<any> {
    return (await this.db?.ref(`${path}`).once("value"))?.val();
  }

  async setDatabasePathAsObj(path: string, val: any): Promise<void> {
    return await this.db?.ref(`${path}`).set(val);
  }

  async getKeyFromPushedPath(path: string) {
    return (await this.db?.ref(`${path}`).push())?.key;
  }

  async getCurrentSeasonId(teamId: string) {
    const seasonSnapshot = await this.db
      ?.ref(`teams/${teamId}/editTeamsCurrentSeason`)
      .once("value");
    return `${seasonSnapshot?.val()}`;
  }

  async getSeasonOptions(teamId: string) {
    let seasonOptions: any[] = [];
    if (teamId) {
      let seasons =
        (await this.getDatabaseObjAtPath(`seasonsByTeam/${teamId}`)) || {};
      Object.keys(seasons).forEach((key: any) => {
        // console.log(`dealing with season: ${key}`);
        let objValue = seasons[key];
        let label = objValue["seasonName"] || "";
        let value = objValue["key"] || "";
        let endDate = objValue["seasonEndDate"] || "";
        let startDate = objValue["seasonStartDate"] || "";
        let seasonOption = {
          label,
          value,
          endDate,
          startDate,
        };
        seasonOptions.push(seasonOption);
      });
    } else {
      console.log(`Bad ${teamId}`);
    }
    // console.log(`seasonOptions: ${JSON.stringify(seasonOptions)}`);
    return seasonOptions;
  }

  async getPickupOptions(teamId: string) {
    let pickupOptions: any[] = [];
    if (teamId) {
      let pickups =
        (await this.getDatabaseObjAtPath(`pickupsByTeam/${teamId}`)) || {};
      Object.keys(pickups).forEach((key: any) => {
        let objValue = pickups[key];
        let date = objValue["pickupDate"] || "";
        let start = objValue["pickupStart"] || "";
        let end = objValue["pickupEnd"] || "";
        let location = objValue["pickupLocation"] || "";
        let label = `${date}, ${start}-${end}, ${location}`;
        let value = key;
        let pickupOption = {
          label,
          value,
        };
        pickupOptions.push(pickupOption);
      });
    } else {
      console.log(`Bad ${teamId}`);
    }
    // console.log(`pickupOptions: ${JSON.stringify(pickupOptions)}`);
    return pickupOptions;
  }

  async getTeamName(teamId: string) {
    const teamNameSnapshot = await this.db
      ?.ref(`teams/${teamId}/editTeamsName`)
      .once("value");
    return `${teamNameSnapshot?.val()}`;
  }

  async listenForAuthStateChanged(onAuth: UserAuthCallback) {
    const that = this;
    this.auth?.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const snapshot = await that.db
          ?.ref(`users/${authUser.uid}`)
          ?.once("value");
        const dbUser = snapshot?.val();
        const role = dbUser?.type?.role;
        if (
          dbUser &&
          (role === SessionUserType.SPONSOR ||
            role === SessionUserType.ADMIN ||
            role === SessionUserType.STADIUM ||
            role === SessionUserType.CPTEAMADMIN ||
            role === SessionUserType.ORDERS)
        ) {
          return onAuth({
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
            ...dbUser,
          });
        }
        return onAuth(null);
      }
      return onAuth(null);
    });
  }

  async loadDictionaries() {
    const stadiumsSnapshot = await this.db?.ref(`stadium_index`).once("value");
    const sponsorsSnapshot = await this.db?.ref(`sponsors_index`).once("value");
    const teamsSnapshot = await this.db?.ref(`teams`).once("value");
    const stadiums = stadiumsSnapshot?.val();
    const sponsors = sponsorsSnapshot?.val();
    const teams = teamsSnapshot?.val();
    return {
      stadiums: Object.keys(stadiums),
      sponsors: Object.keys(sponsors),
      teams: Object.keys(teams).map((teamId) => ({
        teamId,
        name: teams[teamId].editTeamsName as string,
      })),
    };
  }

  async loadQuestionsForDisplay() {
    const questionsSnapshot = await this.db?.ref(fbQuestions).once("value");
    const questions = questionsSnapshot?.val();
    return Object.keys(questions).map((questionId) => {
      let question = questions[questionId];
      return {
        answerType: question.answerType,
        question: question.question,
        questionId,
        questionFolder: question.questionFolder,
        questionType: question.questionType,
      };
    });
  }

  async loadQuestions() {
    const questionsSnapshot = await this.db?.ref(fbQuestions).once("value");
    const questions = questionsSnapshot?.val();
    return Object.keys(questions).map((questionId) => {
      return questions[questionId];
    });
  }

  async loadQuestionById(questionId?: string) {
    if (!questionId) {
      return questionItemDefaults();
    }
    const questionSnapshot = await this.db
      ?.ref(`${fbQuestions}/${questionId}`)
      .once("value");
    return questionSnapshot?.val();
  }

  async saveQuestion(question: QuestionItem) {
    const questionId = question.questionId || (await this.getNewQuestionId());
    if (!questionId) throw new Error("Couldn't get questionId");
    question.questionId = questionId;
    await this.db
      ?.ref(`${fbQuestions}/${question.questionId}`)
      .update(question);
  }

  async getNewQuestionId() {
    let resultKey = (await this.db?.ref(`${fbQuestions}/`).push())?.key;
    return resultKey ? resultKey : "";
  }

  async loadFolders() {
    const foldersSnapshot = await this.db?.ref(`${fbFolders}`).once("value");
    const folders = foldersSnapshot?.val();
    if (!folders) return [];
    return Object.keys(folders).map((folderId) => {
      return {
        folderId: folderId,
        folderName: folders[folderId],
      };
    });
  }

  async createFolder(folderName: string) {
    const folders: FolderItem[] = await this.loadFolders();
    let folderNameExists = false;
    folders.map((folder: FolderItem) => {
      if (folderName === folder.folderName) {
        folderNameExists = true;
      }
    });
    if (folderNameExists) {
      throw new Error(`Folder Name '${folderName}' already exists`);
    } else {
      await this.db?.ref(`${fbFolders}`).push().set(folderName);
    }
  }

  async saveGame(game: GameItem) {
    const gameId = game.gameId || (await this.getNewGameId());
    if (!gameId) throw new Error("Couldn't get gameId");
    game.gameId = gameId;
    this.setGameTimestampsFromTimeAndDate(game);
    const previewImagePath = `imageRefactor/games/previewImages/${gameId}`;
    const secondaryImagePath = `imageRefactor/games/secondaryImages/${gameId}`;
    const gameBackgroundImagePath = `imageRefactor/games/backgroundImages/${gameId}`;
    await this.uploadFileFromObjectAndSetUrl(
      game,
      "previewImageFile",
      "previewImageUrl",
      previewImagePath
    );
    await this.uploadFileFromObjectAndSetUrl(
      game,
      "secondaryImageFile",
      "secondaryImageUrl",
      secondaryImagePath
    );
    await this.uploadFileFromObjectAndSetUrl(
      game,
      "gameBackgroundImageFile",
      "gameBackgroundImageUrl",
      gameBackgroundImagePath
    );
    console.log(`game: ${JSON.stringify(game, null, 2)}`);
    await this.db?.ref(`${fbGames}/${game.gameId}`).update(game);
  }

  async getNewGameId() {
    let resultKey = (await this.db?.ref(`${fbGames}/`).push())?.key;
    return resultKey ? resultKey : "";
  }

  setGameTimestampsFromTimeAndDate = (game: GameItem) => {
    game.startTimestamp = moment(
      `${game.gameDate} ${game.startTime}`
    ).toISOString();
    game.openTimestamp = moment(
      `${game.gameDate} ${game.openTime}`
    ).toISOString();
    game.startTime = null;
    game.openTime = null;
    game.gameDate = null;
  };

  setGameTimesAndDatesFromTimestamps = (game: GameItem) => {
    game.startTime = moment(`${game.startTimestamp}`).format(hMFormat);
    game.openTime = moment(`${game.openTimestamp}`).format(hMFormat);
    game.gameDate = moment(`${game.startTimestamp}`).format(mdyFormat);
    console.log(`Have set gameDate to ${game.gameDate}`);
  };

  async loadTeams() {
    const teamsSnapshot = await this.db?.ref(`teams`).once("value");
    const teams = teamsSnapshot?.val() || {};
    return Object.keys(teams).map((teamId) => {
      let team = teams[teamId];
      team.teamId = teamId;
      return team;
    });
  }

  async loadRewards() {
    const rewardsSnapshot = await this.db?.ref(`allRewards`).once("value");
    const rewards = rewardsSnapshot?.val() || {};
    return Object.keys(rewards).map((rewardId) => {
      let reward = rewards[rewardId];
      reward.rewardId = rewardId;
      return reward;
    });
  }

  async loadGames() {
    const gamesSnapshot = await this.db?.ref(`${fbGames}`).once("value");
    const games = gamesSnapshot?.val() || {};
    return Object.keys(games).map((gameId) => {
      let game = games[gameId];
      return game;
    });
  }

  async loadGameById(gameId?: string) {
    if (!gameId) {
      return gameItemDefaults();
    }
    const gameSnapshot = await this.db
      ?.ref(`${fbGames}/${gameId}`)
      .once("value");
    const game = gameSnapshot?.val();
    this.setGameTimesAndDatesFromTimestamps(game);
    return game;
  }

  async loadUser(userId: string): Promise<UserAccesses> {
    const snapshot = await this.db?.ref(`users/${userId}`).once("value");
    const { games, prizes, questionsAnswered, ...user } = snapshot?.val();
    return user;
  }

  async loadAllUsers(): Promise<UserListItem[]> {
    const snapshot = await this.db?.ref(`users`).once("value");
    return Object.values(snapshot?.val() || {})
      .map(({ games, prizes, questionsAnswered, ...user }: any) => user)
      .slice(2);
  }

  async loadAllIntegrations(): Promise<IntegrationListItem[]> {
    return [];
  }

  async loadUsersByTeamId(
    teamId: string,
    seasonId: string,
    filterIds?: string[]
  ): Promise<UserOverview[]> {
    const snapshot = await this.db?.ref(`usersByTeam/${teamId}`).once("value");
    const ghostUsersVal =
      (await this.db?.ref(`ghostUsers/`).once("value"))?.val() || {};

    const cohortsVal =
      (await this.db?.ref(`cohortsByTeam/${teamId}/`).once("value"))?.val() ||
      {};
    const userArrayRefs: any[] = [];
    const userOverviewArrayRefs: any[] = [];
    const seasonTicketUsersRefs: any[] = [];
    // const redemptionsByTeamByUserRefs: any[] = [];
    const ordersByTeamByUserRefs: any[] = [];
    // const loyaltyPointsByUserRefs: any[] = [];
    const result: UserOverview[] = [];
    Object.keys(snapshot?.val() || {}).forEach((userId) => {
      userArrayRefs.push(
        this.db
          ?.ref(`userDetailsByUserIdByTeam/${teamId}/${userId}`)
          .once("value")
      );
      // userArrayRefs.push(this.db?.ref(`users/${userId}`).once("value"));
      userOverviewArrayRefs.push(
        this.db
          ?.ref(`tracking/userOverviewByTeam/${teamId}/${userId}`)
          .once("value")
      );
    });
    const userResults: firebase.database.DataSnapshot[] =
      await Promise.all(userArrayRefs);
    const userOverviewResults: firebase.database.DataSnapshot[] =
      await Promise.all(userOverviewArrayRefs);
    // console.log(`userResults length first: ${userResults.length}`);
    userResults.forEach((snapshot, index) => {
      let userId = snapshot.key;
      if (userId === null) {
        return;
      }
      if (!ghostUsersVal[userId]) {
        let userData = snapshot?.val();
        let userOverview = userOverviewDefaults();
        userOverview = userData;

        let tempUserResult: UserOverview;
        tempUserResult = userOverviewDefaults();
        Object.values(userOverviewResults[index].val() || {}).forEach(
          (seasonData: any) => {
            tempUserResult = combinedObject(tempUserResult, seasonData);
            // if (userId === "01Gl8tfNZSSQuynNAeYzy4hPUKE3") {
            //   console.log(`Reached ${JSON.stringify(tempUserResult, null, 2)}`);
            // }
          }
        );

        if (userOverview["isInactive"]) tempUserResult.status = "inactive";
        if (userOverview["isFrozen"]) tempUserResult.status = "frozen";
        let nonActiveStatus = ["inactive", "frozen"];
        if (filterIds && !filterIds.includes(userId)) return;
        tempUserResult.userId = userId;
        tempUserResult.fullName = userOverview.fullName;
        tempUserResult.email = userOverview.email;
        tempUserResult.gender = userOverview.gender;
        tempUserResult.accountId = userOverview.accountId;
        tempUserResult.ticketRepId = userOverview.ticketRepId;
        let cohortId = userOverview.cohortId;
        tempUserResult.cohortName = getAttributeFromObject(
          `${cohortId}/organizationName`,
          cohortsVal
        );
        let dateCreated = userOverview.dateCreated;
        let dateCreatedFriendly = "";
        if (!dateCreated) {
          dateCreatedFriendly = "unknown";
        } else if (dateCreated.length > 11) {
          dateCreatedFriendly = dateCreated.substring(0, 10);
        }
        tempUserResult.dateCreatedFriendly = dateCreatedFriendly;
        tempUserResult.nOrders = (
          Object.keys(tempUserResult.orders) ?? []
        ).length;
        tempUserResult.firstName = firstNameFromFullName(
          `${userOverview.fullName}`
        );
        tempUserResult.lastName = lastNameFromFullName(
          `${userOverview.fullName}`
        );
        tempUserResult.platform = userOverview.platform;
        if (userOverview.birthYear) {
          tempUserResult.birthday = `${userOverview.birthMonth}/${userOverview.birthDate}/${userOverview.birthYear}`;
        } else {
          tempUserResult.birthday = "unknown";
        }
        // userOverview.nRedemptions = redemptionsByTeamByUserResults[index]?.val() || 0;
        // userOverview.currentPoints = loyaltyPointsUserResults[index]?.val() || 0;
        // userOverview.nOrders = Object.keys(ordersByTeamByUserResults[index]?.val() || {}).length;
        result.push(tempUserResult);
      }
    });
    return result;
  }

  async loadUsersByTeamIdAsMap(teamId: string): Promise<any> {
    const usersByUserId = await this.getDatabaseObjAtPath(
      `userDetailsByUserIdByTeam/${teamId}`
    );
    const ghostUsersVal = await this.getDatabaseObjAtPath("ghostUsers");
    let result: any = {};
    Object.keys(usersByUserId).forEach((userId) => {
      if (!ghostUsersVal[userId]) result[userId] = usersByUserId[userId];
    });
    return result;
  }

  triggerTeamOverviewDataCalculations(teamId: string, seasonId: string) {
    //TODO Might have to make all seasons trigger often?
    if (seasonId != allOptionValue) {
      this.triggerIfNotTriggeredRecently(
        `calculation/dailyRewardRedemptionsByTeam/${teamId}/${seasonId}`
      );
      this.triggerIfNotTriggeredRecently(
        `calculation/totalPointsDistributedByTeam/${teamId}/${seasonId}`
      );
      this.triggerIfNotTriggeredRecently(
        `calculation/marketplaceOrdersByTeam/${teamId}/${seasonId}`
      );
    }
    this.triggerIfNotTriggeredRecently(`calculation/activeRewardsData/`);
    this.triggerIfNotTriggeredRecently(
      `calculation/signupsAndDailyUsersCount/${teamId}`
    );
    this.triggerIfNotTriggeredRecently(
      `calculation/userActivityMetrics/${teamId}`
    );
    this.triggerIfNotTriggeredRecently(`calculation/ordersByTeam/${teamId}`);
    this.triggerIfNotTriggeredRecently(
      `calculation/checkInScanningAttendsByTeam/${teamId}`
    );
    this.triggerIfNotTriggeredRecently(
      `calculation/checkInAttendsByTeam/${teamId}`
    );
    this.triggerIfNotTriggeredRecently(
      `calculation/checkInViewsByTeam/${teamId}`
    );
    this.triggerIfNotTriggeredRecently(
      `calculation/activeRewardsDataByTeam/${teamId}`
    );
    this.triggerIfNotTriggeredRecently(
      `calculation/gameDetailsByTeam/${teamId}`
    );
    this.triggerIfNotTriggeredRecently(
      `calculation/gameRewardsByTeam/${teamId}`
    );
    this.triggerIfNotTriggeredRecently(
      `calculation/gamesAttendedByUserByTeam/${teamId}`
    );
    this.triggerIfNotTriggeredRecently(`calculation/sweepstakes/${teamId}`);
    this.triggerIfNotTriggeredRecently(`calculation/auctions/${teamId}`);
    this.triggerIfNotTriggeredRecently(`calculation/products/${teamId}`);
    // this.db?.ref(`calculation/dailyRewardRedemptionsByTeam/${teamId}/${seasonId}`).set(timestamp)
    // this.db?.ref(`calculation/totalPointsDistributedByTeam/${teamId}/${seasonId}`).set(timestamp)
    // this.db?.ref(`calculation/marketplaceOrdersByTeam/${teamId}/${seasonId}`).set(timestamp)
  }

  async triggerIfNotTriggeredRecently(path: string) {
    let now = moment();
    let pathValue = (await this.db?.ref(`${path}`).once("value"))?.val();
    let timestamp = pathValue && moment(`${pathValue}`);
    let diff = Math.abs(now.diff(timestamp, "minutes"));
    let minInDay = 1440;
    let minUntilAllowTrigger = minInDay;
    if (!pathValue || !timestamp || diff > minUntilAllowTrigger) {
      this.db?.ref(`${path}`).set(now.toISOString());
    } else {
      // console.log(`Not triggering ${path}`)
    }
  }

  async loadTeamOverview(
    teamId: string,
    seasonId: string
  ): Promise<TeamOverviewResponse> {
    if (!seasonId) return teamOverviewStatsDefaults();
    let result: TeamOverviewResponse;
    if (seasonId == allOptionValue) {
      console.log("Loading for all seasons");
      let allSeasonData =
        (
          await this.db?.ref(`tracking/teamOverview/${teamId}/`).once("value")
        )?.val() ?? {};
      result = teamOverviewStatsDefaults();
      Object.values(allSeasonData).forEach((seasonData: any) => {
        result = combinedObject(result, seasonData);
      });
    } else {
      const snapshot = await this.db
        ?.ref(`tracking/teamOverview/${teamId}/${seasonId}`)
        .once("value");
      result = (snapshot && snapshot.val()) || teamOverviewStatsDefaults();
    }
    return result;
  }

  async loadTeamDetails(teamId: string): Promise<any> {
    if (!teamId) return {};
    let teamDetails =
      (await this.db?.ref(`teams/${teamId}/`).once("value"))?.val() ?? {};
    return teamDetails;
  }

  async loadSeasonlessTeamOverview(
    teamId: string
  ): Promise<TeamOverviewResponse> {
    if (!teamId) return teamOverviewStatsDefaults();
    const snapshot = await this.db
      ?.ref(`tracking/seasonlessTeamOverview/${teamId}/`)
      .once("value");
    let result: TeamOverviewResponse =
      (snapshot && snapshot.val()) || teamOverviewStatsDefaults();
    return result;
  }

  async loadEngagementStatsByUserIdByTeam(teamId: string): Promise<any> {
    if (!teamId) return {};
    const snapshot = await this.db
      ?.ref(`tracking/engagementStatsByUserIdByTeam/${teamId}`)
      .once("value");
    return snapshot?.val() || {};
  }

  async loadLoyaltyPointsByTeam(teamId: string): Promise<any> {
    if (!teamId) return {};
    const snapshot = await this.db
      ?.ref(`tracking/currentPointsByUserIdByTeam/${teamId}`)
      .once("value");
    return snapshot?.val() || {};
  }

  async setOrderStatus(
    teamId: string,
    userId: string,
    orderId: string,
    newStatus: Number
  ) {
    if (teamId && userId && orderId && newStatus) {
      this.db
        ?.ref(`ordersByTeamByUser/${teamId}/${userId}/${orderId}/status`)
        .set(`${newStatus}`);
    } else {
      console.log(`Bad ${teamId}/${userId}/${orderId}/${newStatus}/`);
    }
  }

  async loadOrder(
    teamId: string,
    userId: string,
    orderId: string
  ): Promise<OrderOverview> {
    let orderOverview: OrderOverview = orderOverviewDefaults();
    const orderData =
      (
        await this.db
          ?.ref(`ordersByTeamByUser/${teamId}/${userId}/${orderId}`)
          .once("value")
      )?.val() || {};
    let shippingId = orderData["shippingDetailsId"];
    const shippingData =
      (
        await this.db
          ?.ref(`shippingDetailsByUser/${userId}/${shippingId}/`)
          .once("value")
      )?.val() || {};
    let pickupId = orderData["pickupId"] || "nonexistent";
    const pickupData =
      (
        await this.db?.ref(`pickupsByTeam/${teamId}/${pickupId}/`).once("value")
      )?.val() || {};
    const userData =
      (await this.db?.ref(`users/${userId}`).once("value"))?.val() || {};
    const productId = orderData["productId"];
    orderOverview = orderData;
    orderOverview.userId = userId || "";
    orderOverview.orderId = orderId || "";
    orderOverview.userFullName = userData["fullName"] || "";
    orderOverview.email = userData["email"] || "";
    if (productId) {
      const product =
        (
          await this.db
            ?.ref(`marketplaceProductsByTeam/${teamId}/${productId}`)
            .once("value")
        )?.val() || {};
      let variantGenKey = orderOverview.variantGenKey;
      let options = product["options"];
      orderOverview.productName = product["name"] || "";
      let variants = product["variants"] || {};
      let variant = variants[variantGenKey] || {};
      let imageUrls: string[] = product["imageUrls"] || [];
      orderOverview.imageUrl =
        variant["url"] || (imageUrls.length > 0 && imageUrls[0]) || "";
      orderOverview.variantName = getVariantNameFromCombination(
        variant["combination"]
      );
      orderOverview.firstVariantName = getFirstVariantNameFromCombination(
        variant["combination"]
      );
      orderOverview.addressName = getAddressNameFromShippingData(shippingData);
      orderOverview.pickupName = getPickupNameFromPickupData(pickupData);
    }
    console.log(`loadOrder: ${orderOverview.friendlyId}`);
    return orderOverview;
  }

  async savePickup(teamId: string, pickup: PickupOverview): Promise<void> {
    let pickupId = pickup.pickupId;
    if (pickupId) {
      await this.db?.ref(`pickupsByTeam/${teamId}/${pickupId}`).update(pickup);
    } else {
      window.alert("Bad pickupId~");
    }
  }

  async notifyForPickup(
    teamId: string,
    pickup: PickupOverview
  ): Promise<boolean> {
    if (
      !isPromptProtected(
        "Are you sure you want to send a notification to ALL users who have selected this pickup date? "
      )
    )
      return false;
    let pickupId = pickup.pickupId;
    let now = moment().toISOString();
    if (pickupId) {
      await this.db
        ?.ref(`pickupChangedNotificationsByTeam/${teamId}/${pickupId}`)
        .set(now);
    }
    return true;
  }

  async loadPickup(teamId: string, pickupId: string): Promise<PickupOverview> {
    let pickupOverview: PickupOverview = pickupOverviewDefaults();
    const pickupData =
      (
        await this.db?.ref(`pickupsByTeam/${teamId}/${pickupId}`).once("value")
      )?.val() || {};
    pickupOverview = pickupData;
    pickupOverview.pickupId = pickupId || "";
    return pickupOverview;
  }

  async loadProductsByTeamId(teamId: string) {
    return (
      (
        await this.db?.ref(`marketplaceProductsByTeam/${teamId}/`).once("value")
      )?.val() || {}
    );
  }

  async loadOrdersByTeamId(
    teamId: string,
    seasonId: string
  ): Promise<OrderOverview[]> {
    let marketplaceOrdersData: any;
    let walletConversionAccounts: any = await this.getDatabaseObjAtPath(
      `walletConversionAccountConnectionsByTeam/${teamId}/`
    );
    if (seasonId == allOptionValue) {
      let allSeasonData =
        (
          await this.db?.ref(`marketplaceOrdersByTeam/${teamId}/`).once("value")
        )?.val() ?? {};
      marketplaceOrdersData = orderOverviewDefaults();
      Object.values(allSeasonData).forEach((seasonData: any) => {
        marketplaceOrdersData = combinedObject(
          marketplaceOrdersData,
          seasonData
        );
      });
    } else {
      const snapshot = await this.db
        ?.ref(`marketplaceOrdersByTeam/${teamId}/${seasonId}`)
        .once("value");
      marketplaceOrdersData =
        (snapshot && snapshot.val()) || orderOverviewDefaults();
    }

    const pickupData =
      (await this.db?.ref(`pickupsByTeam/${teamId}/`).once("value"))?.val() ||
      {};
    const ordersData =
      (
        await this.db?.ref(`ordersByTeamByUser/${teamId}`).once("value")
      )?.val() || {};
    const productsData =
      (
        await this.db?.ref(`marketplaceProductsByTeam/${teamId}`).once("value")
      )?.val() || {};
    const userArrayRefs: any[] = [];
    const result: OrderOverview[] = [];
    Object.keys(marketplaceOrdersData).forEach((orderId, index) => {
      let userId = marketplaceOrdersData[orderId]["userId"];
      userArrayRefs.push(this.db?.ref(`users/${userId}`).once("value"));
    });
    const userResults: firebase.database.DataSnapshot[] =
      await Promise.all(userArrayRefs);

    Object.keys(marketplaceOrdersData).forEach((orderId, index) => {
      let userId = marketplaceOrdersData[orderId]["userId"];
      let usersOrders = ordersData[userId] || {};
      let userData = userResults[index]?.val() || {};
      if (!usersOrders[orderId]) return;
      // Object.keys(usersOrders)
      // .forEach((orderId) => {
      let orderOverview = orderOverviewDefaults();
      orderOverview = usersOrders[orderId] || {};
      // let orderDateLocalized = localizedMoment(orderOverview.orderDate || "");
      // let yearMonthDateString = `${orderDateLocalized.year()}_${orderDateLocalized.month()}_${orderDateLocalized.date()}`;
      let orderDate = localizedMoment(
        orderOverview.orderDate || ""
      ).toLocaleString();
      orderOverview.orderDate = orderDate;
      orderOverview.formattedOrderDate = formatDate(orderDate);
      orderOverview.statusName = getStatusLabelFromStatus(orderOverview.status);
      orderOverview.userId = userId || "";
      orderOverview.orderId = orderId || "";
      orderOverview.userFullName = userData["fullName"] || "";

      let walletConversionId = orderOverview["walletConversionId"];
      if (walletConversionId) {
        let walletConversionAccount: any =
          getAttributeFromObject(
            `${userId}/${walletConversionId}`,
            walletConversionAccounts
          ) || {};
        console.log(
          `walletConversionAccount: ${JSON.stringify(walletConversionAccount)}`
        );
        console.log(`orderOverview: ${JSON.stringify(orderOverview)}`);
        orderOverview.walletConversionEmail = walletConversionAccount["email"];
        orderOverview.walletConversionFirstName =
          walletConversionAccount["firstName"];
        orderOverview.walletConversionLastName =
          walletConversionAccount["lastName"];
        orderOverview.pointsConverted = orderOverview["dailyOrderTotal"];
        orderOverview.walletCurrencyAmount = orderOverview["walletCurrency"];
      }
      orderOverview.email = userData["email"] || "";
      orderOverview.pickupDate = getPickupDateFromPickupData(
        pickupData[orderOverview.pickupId] || {}
      );

      let productId = orderOverview["productId"];
      if (productId) {
        let product = productsData[productId] || {};
        let variants = product["variants"] || {};
        let variantGenKey = orderOverview.variantGenKey;
        let variant = variants[variantGenKey] || {};
        orderOverview.productName = product["name"] || "";

        // console.log(`1) orderId: ${orderId}`);
        // console.log(`variant: ${JSON.stringify(variant)}`);
        orderOverview.variantName = getVariantNameFromCombination(
          variant["combination"]
        );

        // console.log(`2) orderId: ${orderId}`);
        orderOverview.firstVariantName = getFirstVariantNameFromCombination(
          variant["combination"]
        );

        // console.log(`3) orderId: ${orderId}`);
      }
      if (orderOverview.productName) {
        result.push(orderOverview);
      } else {
        console.log(
          `Could not find ${orderId} ${JSON.stringify(orderOverview)}`
        );
      }
      // } catch(error) {
      //   console.log(`error: ${error} with ${orderId}`);
      // }
    });

    result.sort((orderA, orderB) => {
      return moment(orderA["orderDate"]).isBefore(orderB["orderDate"]) ? 1 : -1;
    });

    return result;
  }

  async loadPickupsByTeamId(teamId: string): Promise<PickupOverview[]> {
    const pickupsData =
      (await this.db?.ref(`pickupsByTeam/${teamId}`).once("value"))?.val() ||
      {};
    const result: PickupOverview[] = [];
    Object.keys(pickupsData).forEach((pickupId, index) => {
      let pickupOverview = pickupOverviewDefaults();
      pickupOverview = pickupsData[pickupId] || {};
      let pickupDate = localizedMoment(
        pickupOverview.pickupDate || ""
      ).toLocaleString();
      pickupOverview.pickupDate = pickupDate;
      pickupOverview.formattedPickupDate = formatDate(pickupDate);

      if (pickupOverview.pickupDate) {
        result.push(pickupOverview);
      } else {
        console.log(
          `Could not find ${pickupId} ${JSON.stringify(pickupOverview)}`
        );
      }
    });

    result.sort((pickupA, pickupB) => {
      return moment(pickupA["pickupDate"]).isBefore(pickupB["pickupDate"])
        ? 1
        : -1;
    });

    return result;
  }

  async grandAccess({ userId, type }: GrantAccess) {
    await this.db
      ?.ref(`users/${userId}/type`)
      .set({ role: type.role, identifier: type.identifier });
    return this.loadUser(userId);
  }

  async uploadFileFromObjectAndSetUrl(
    object: any,
    fileName: string,
    urlName: string,
    path: string
  ) {
    const file: File | null = object[fileName];
    const imageFirebaseUrl = await this.uploadImageFile(file, path);
    if (imageFirebaseUrl) {
      object[urlName] = imageFirebaseUrl;
      object[fileName] = null;
    }
  }

  async uploadImageFile(file: File | null, path: string) {
    if (!file) return null;
    const storageRef = this.storage?.ref(path);
    if (storageRef) {
      await storageRef.put(file);
      return await storageRef.getDownloadURL();
    }
    return null;
  }

  async loadUserOverview(
    userId: string,
    teamId: string,
    seasonId: string
  ): Promise<UserOverview> {
    if (!teamId || !userId) return userOverviewDefaults();

    let result: UserOverview;
    result = userOverviewDefaults();
    console.log(`loadUserOverview with seasonId: ${seasonId}`);
    if (seasonId && seasonId !== allOptionValue) {
      // const userOverviewSnapshot = await this.db?.ref(`tracking/userOverviewByTeam/${teamId}/${userId}/${seasonId}`).once("value");
      // const userCheckInViewsByDate = ((await this.db?.ref(`tracking/checkInViewsByTeamByUserByDate/${teamId}/${userId}/${seasonId}`).once("value"))?.val() || {});
      // const userCheckInAttendsByDate = ((await this.db?.ref(`tracking/checkInAttendsByTeamByUserByDate/${teamId}/${userId}/${seasonId}`).once("value"))?.val() || {});
      // result = (userOverviewSnapshot && userOverviewSnapshot.val()) || userOverviewDefaults();
      // result = combinedObject(result, {"checkInViewsByDate": userCheckInViewsByDate})
      // result = combinedObject(result, {"checkInAttendsByDate": userCheckInAttendsByDate})
    } else {
      const userOverviewSnapshotAllSeasons =
        (
          await this.db
            ?.ref(`tracking/userOverviewByTeam/${teamId}/${userId}/`)
            .once("value")
        )?.val() || {};
      const userCheckInViewsByDate =
        (
          await this.db
            ?.ref(
              `tracking/checkInViewsByTeamByUserByDate/${teamId}/${userId}/`
            )
            .once("value")
        )?.val() || {};
      const userCheckInAttendsByDate =
        (
          await this.db
            ?.ref(
              `tracking/checkInAttendsByTeamByUserByDate/${teamId}/${userId}/`
            )
            .once("value")
        )?.val() || {};
      Object.keys(userOverviewSnapshotAllSeasons).forEach((seasonId: any) => {
        let seasonData = userOverviewSnapshotAllSeasons[seasonId] || {};
        let seasonCheckInViewData = userCheckInViewsByDate[seasonId] || {};
        let seasonCheckInAttendData = userCheckInAttendsByDate[seasonId] || {};
        result = combinedObject(result, seasonData);
        result = combinedObject(result, {
          checkInViewsByDate: seasonCheckInViewData,
        });
        result = combinedObject(result, {
          checkInAttendsByDate: seasonCheckInAttendData,
        });
      });
    }

    const userVal =
      (
        await this.db
          ?.ref(`userDetailsByUserIdByTeam/${teamId}/${userId}`)
          .once("value")
      )?.val() || {};

    let cohortId = userVal["cohortId"] || "";
    const cohortName =
      (
        await this.db
          ?.ref(`cohortsByTeam/${teamId}/${cohortId}/organizationName`)
          .once("value")
      )?.val() || "";
    //Takes care of avatar, dateCreated, email, startingPoints, username, rewards, dailyRewardRedemptions (all 5 types),
    result.email = userVal["email"] || "";
    result.dateCreated = userVal["dateCreated"] || "";
    result.name = userVal["fullName"] || "";
    result.gender = userVal["gender"] || "";
    result.birthDate = userVal["birthDate"] || "";
    result.birthMonth = userVal["birthMonth"] || "";
    result.birthYear = userVal["birthYear"] || "";
    result.platform = userVal["platform"] || "";
    result.accountId = userVal["accountId"] || "";
    result.ticketRepId = userVal["ticketRepId"] || "";
    result.cohortName = cohortName;
    return result;
  }

  async loadOrdersFromUser(
    userId: string,
    teamId: string,
    orders: any
  ): Promise<any[]> {
    if (!teamId || !userId || !orders || !Object.keys(orders).length) return [];
    const userOrdersVal =
      (
        await this.db
          ?.ref(`ordersByTeamByUser/${teamId}/${userId}/`)
          .once("value")
      )?.val() || {};
    const productsVal =
      (
        await this.db?.ref(`marketplaceProductsByTeam/${teamId}/`).once("value")
      )?.val() || {};
    let result: any[] = [];
    Object.keys(orders).forEach((orderId) => {
      let orderDetails = userOrdersVal[orderId] || {};
      let productId = orderDetails["productId"] || "";
      let variantGenKey = orderDetails["variantGenKey"];
      if (productId && variantGenKey) {
        let product = productsVal[productId];
        if (product) {
          let variants = product["variants"] || {};
          let variant = variants[variantGenKey] || {};
          let dateOrdered = localizedMoment(
            orderDetails["orderDate"] || ""
          ).toISOString();
          result.push({
            name: product["name"] || "",
            firstVariantName:
              getVariantNameFromCombination(variant["combination"]) || "",
            qty: orderDetails["orderItemCount"] || 0,
            totalPoints: orderDetails["orderTotal"] || 0,
            status: orderDetails["status"] || 0,
            statusName:
              getStatusLabelFromStatus(orderDetails["status"]) || "N/A",
            dateOrdered,
            formattedDateOrdered: formatDate(dateOrdered),
            orderNumber: orderDetails["friendlyId"] || "",
            userId: userId || "",
            orderId: orderId || "",
          });
        }
      }
    });
    result.reverse();
    return result;
  }

  async loadRewardsFromUser(
    userId: string,
    teamId: string
    // rewards: any
  ): Promise<any[]> {
    if (
      !teamId ||
      !userId
      // || !rewards || !Object.keys(rewards).length
    )
      return [];
    const userDetails =
      (await this.db?.ref(`users/${userId}/`).once("value"))?.val() || {};
    let username = userDetails["username"];
    const userRewards =
      (
        await this.db
          ?.ref(`rewardsGivenByTeam/${teamId}/users/${username}/`)
          .once("value")
      )?.val() || {};
    let rewardsData = combinedObject(
      (await this.db?.ref(`allRewards/`).once("value"))?.val() || {},
      (
        await this.db?.ref(`gameRewardsByTeam/${teamId}`).once("value")
      )?.val() || {}
    );

    let result: any[] = [];
    // console.log(`found ${Object.keys(userRewards).length} rewards`);
    Object.keys(userRewards).forEach((personalRewardId) => {
      // Object.keys(rewards).forEach((personalRewardId) => {
      // let rewardId = rewards[personalRewardId] || "";
      let personalRewardDetails = userRewards[personalRewardId] || {};
      let rewardId = personalRewardDetails["rewardId"] || "";
      // let rewardId = userRewards[personalRewardId] || "";
      if (rewardId) {
        let rewardDetails = rewardsData[rewardId] || {};
        // let personalRewardDetails = userRewards[personalRewardId] || {};

        result.push({
          name: rewardDetails["title"] || "",
          status: personalRewardDetails["used"] ? "Used" : "Unused",
          timestamp: personalRewardDetails["timestamp"] || "",
        });
      }
    });
    result.reverse();
    return result;
  }

  async loadTransactionsFromUser(
    userId: string,
    teamId: string
  ): Promise<any[]> {
    if (!teamId || !userId) return [];
    const allSeasonTransactions =
      (
        await this.db?.ref(`loyaltyPoints/${userId}/${teamId}/`).once("value")
      )?.val() || {};
    let transactions: any = {};
    Object.values(allSeasonTransactions).forEach((seasonalTransactions) => {
      transactions = combinedObject(transactions, seasonalTransactions);
    });
    let result: any[] = [];
    Object.keys(transactions).forEach((transactionId) => {
      let transactionDetails = transactions[transactionId] || {};
      let description = transactionDetails["description"] || "";
      if (
        !description &&
        transactionDetails["teamA"] &&
        transactionDetails["teamB"]
      ) {
        description = `${transactionDetails["teamA"]} vs ${transactionDetails["teamB"]}`;
      }

      result.push({
        description,
        points: transactionDetails["points"] || 0,
        timestamp:
          transactionDetails["timestamp"] || transactionDetails["time"] || 0,
      });
    });
    result.reverse();
    return result;
  }

  async loadExpiringTransactionsFromUser(
    userId: string,
    teamId: string
  ): Promise<any[]> {
    if (!teamId || !userId) return [];
    let testingString = "";
    // `testing/`;
    const transactions =
      (
        await this.db
          ?.ref(
            `${testingString}expiringPointsByMonthWithoutAdjustment/${teamId}/${userId}/`
          )
          .once("value")
      )?.val() || {};
    const monthsUntilExpiration =
      (
        await this.db
          ?.ref(`teams/${teamId}/monthsUntilExpiration/`)
          .once("value")
      )?.val() || 12;
    // const allSeasonTransactions = (await this.db?.ref(`${testingString}expiringTransactionsByUser/${userId}/${teamId}/`).once("value"))?.val() || {};
    // console.log(`path: ${`expiringTransactionsByUser/${userId}/${teamId}/${seasonId}`}`)
    let result: any[] = [];
    let expiringSoonByDate: any = {};
    let now = noTimezoneNow();
    console.log(`Now: ${now}`);
    Object.keys(transactions).forEach((monthKey) => {
      let points = transactions[monthKey];
      let year = parseInt(monthKey.substring(0, 4));
      let month = parseInt(monthKey.substring(5));
      let expirationDate = noTimezoneNow();
      expirationDate.set("year", year);
      expirationDate.set("month", month + monthsUntilExpiration); // April
      expirationDate.set("date", 1);
      let dateKey = `${expirationDate.year()}_${expirationDate.month()}_${expirationDate.date()}`;
      if (expirationDate.isAfter(now) && points) {
        expiringSoonByDate[dateKey] = points;
      }
    });

    let expiringSoonKeys = Object.keys(expiringSoonByDate);
    console.log(`expiringSoonByDateKeys: ${expiringSoonKeys}`);
    console.log(`expiringSoonByDateKeys length: ${expiringSoonKeys.length}`);
    if (expiringSoonKeys.length > 0) {
      expiringSoonKeys.sort((a: any, b: any) => {
        let aDT = getMomentFromYMD(a);
        let bDT = getMomentFromYMD(b);
        return aDT?.isAfter(bDT) ? 1 : -1;
      });
      expiringSoonKeys.forEach((dateKey: any) => {
        console.log(`for key: ${dateKey}`);
        let points = expiringSoonByDate[dateKey];
        result.push({
          points: points,
          date: dateKey,
        });
      });
    }
    // result.reverse();
    return result;
  }

  async getAllLoyaltyCodes() {
    return (
      (
        await this.db
          ?.ref(`${globalTestingString}/allLoyaltyCodes/`)
          .once("value")
      )?.val() || {}
    );
  }

  async getLoyaltyCodesByTeam(teamId: string): Promise<LoyaltyItem[]> {
    let loyaltyCodesByTeam =
      (
        await this.db
          ?.ref(`${globalTestingString}/loyaltyCodesByTeam/${teamId}`)
          .once("value")
      )?.val() || {};
    let trackingLoyaltyCodesByTeam =
      (
        await this.db
          ?.ref(`${globalTestingString}/tracking/loyaltyCodesByTeam/${teamId}`)
          .once("value")
      )?.val() || {};
    let result: LoyaltyItem[] = [];
    Object.keys(loyaltyCodesByTeam).forEach((key: any) => {
      let value = loyaltyCodesByTeam[key] || {};
      let trackingValue = trackingLoyaltyCodesByTeam[key] || {};
      let loyaltyItem: LoyaltyItem = loyaltyItemDefaults();
      loyaltyItem = value;
      loyaltyItem.loyaltyCodeNRedemptions =
        trackingValue.loyaltyCodeNRedemptions;
      console.log(`setting codeId to ${key}`);
      loyaltyItem.loyaltyCodeId = key;
      result.push(loyaltyItem);
    });
    return result;
  }

  setLoyaltyPointsTransaction(
    userId: string,
    teamId: string,
    seasonId: string,
    transaction: any
  ) {
    if (userId && teamId && seasonId && transaction) {
      let transactionKey = this.db
        ?.ref(`loyaltyPoints/${userId}/${teamId}/${seasonId}`)
        .push(transaction).key;
      this.db?.ref(`rewardPoints/${userId}/${transactionKey}`).set(transaction);
    }
  }
}
