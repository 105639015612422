import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { SortDirection } from "react-virtualized";
import { orderRoutes } from "../../app-routes";
import { ButtonLink } from "../../components/ButtonLink";
import { CPDropdown } from "../../components/CPDropdown";
import { CPDropdownNew } from "../../components/CPDropdownNew";
import { CPSearchBar } from "../../components/CPSearchBar";
import { PageTitle } from "../../components/PageTitle";
import {
  allPickupsOption,
  allSeasonsOption,
  cpBoldSubHeaderStyle,
  cpGrayD7,
} from "../../helpers/constants";
import { unableToContinueStatuses } from "../../helpers/order-helpers";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { OrderOverview } from "../../types/order-types";
import { SessionUserType, coreTeamRoles } from "../../types/user-types";
import { OrdersByTeamTable } from "./components/OrdersByTeamTable";

interface UrlParams {
  teamId: string;
}

export const OrdersByTeamPage: React.FC = observer(() => {
  const urlParams = useParams<UrlParams>();
  const { ordersByTeamPage, session } = useStore().ui;
  const role = session.user?.type?.role;
  const teamId = urlParams.teamId;
  const { routerStore } = useStore();
  const [selectedStatusTypeIndex, setSelectedStatusTypeIndex] =
    React.useState<number>(ordersByTeamPage.selectedStatusTypeIndex ?? 0);
  const [selectedProductTypeIndex, setSelectedProductTypeIndex] =
    React.useState<number>(ordersByTeamPage.selectedProductTypeIndex ?? 0);
  const [selectedFulfillmentTypeIndex, setSelectedFulfillmentTypeIndex] =
    React.useState<number>(ordersByTeamPage.selectedFulfillmentTypeIndex ?? 0);
  const [selectedPickupFilter, setSelectedPickupFilter] = React.useState<any>(
    ordersByTeamPage.selectedPickupFilter
  );
  const [selectedSeasonFilter, setSelectedSeasonFilter] = React.useState<any>(
    ordersByTeamPage.selectedSeasonFilter
  );

  React.useEffect(() => {
    ordersByTeamPage.loadOrdersByTeamId(
      teamId,
      selectedSeasonFilter,
      setSelectedSeasonFilter,
      selectedStatusTypeIndex,
      selectedProductTypeIndex,
      selectedFulfillmentTypeIndex,
      selectedPickupFilter,
      role
    );
  }, [
    role,
    urlParams,
    ordersByTeamPage,
    selectedSeasonFilter,
    // selectedStatusTypeIndex, selectedProductTypeIndex, selectedFulfillmentTypeIndex, selectedPickupFilter
  ]);

  let seasonOptions = ordersByTeamPage.seasonOptions;
  let pickupOptions = ordersByTeamPage.pickupOptions;

  const onSearch: React.ChangeEventHandler<{ value: string }> =
    React.useCallback(
      (e) => ordersByTeamPage.searchOrder(e.target.value),
      [ordersByTeamPage]
    );

  let orders =
    ordersByTeamPage.getOrders(
      selectedStatusTypeIndex,
      selectedProductTypeIndex,
      selectedFulfillmentTypeIndex,
      selectedPickupFilter
    ) || {};
  let title = `Orders: ${orders.length} total`;

  const hasFullAccess = coreTeamRoles.includes(role!);

  if (!hasFullAccess) {
    if (selectedProductTypeIndex !== 2) setSelectedProductTypeIndex(2);
    if (selectedFulfillmentTypeIndex !== 2) setSelectedFulfillmentTypeIndex(2);
  }

  let promptStatusChange = (isApproveMode: boolean, order: any) => {
    let type = isApproveMode ? "approve" : "reject";
    let previousStatus = order["status"];
    console.log(`previousStatus: ${previousStatus}`);
    if (unableToContinueStatuses.includes(`${previousStatus}`)) {
      window.alert(`Unable to ${type}: Already approved or rejected`);
      return;
    }
    if (window.confirm(`Are you sure you want to ${type} this order`)) {
      setOrderStatus(isApproveMode ? 1 : 2, order);
    }
  };

  let setOrderStatus = async (newStatus: Number, order: any) => {
    // console.log(`orderKeys: ${}`)
    let userId = order["userId"];
    let orderId = order["orderId"];
    console.log(
      `Will set order status for ${newStatus}, ${teamId}, ${userId}, ${orderId}`
    );
    await ordersByTeamPage.setOrderStatus(teamId, userId, orderId, newStatus);
    ordersByTeamPage.loadOrdersByTeamId(
      teamId,
      selectedSeasonFilter,
      setSelectedSeasonFilter,
      selectedStatusTypeIndex,
      selectedProductTypeIndex,
      selectedFulfillmentTypeIndex,
      selectedPickupFilter,
      role
    );
  };
  let statusOptionLabels = ["Awaiting Approval", "Approved", "Rejected", "All"];
  let statusOptions: any[] = [];
  statusOptionLabels.forEach((label, index) => {
    statusOptions.push({
      label,
      value: index,
    });
  });
  let productTypeOptions: any[] = [];
  let productTypes = ordersByTeamPage.productTypes;
  let counter = 0;
  for (let i = 0; i < productTypes.length; i++) {
    let value = productTypes[i];
    let label = value["label"];
    let visibility = value["visibility"] !== false;
    if (!visibility) {
      continue;
    }
    productTypeOptions.push({
      label,
      value: counter + 1, //since we insert 'all' later
    });
    counter += 1;
  }
  productTypeOptions.splice(0, 0, { label: "All", value: 0 });
  // console.log(`productTypeOptions: `, productTypeOptions)
  let fulfillmentOptionLabels = ["All", "Shipping", "Pickup"];
  let fulfillmentOptions: any[] = [];
  fulfillmentOptionLabels.forEach((label, index) => {
    fulfillmentOptions.push({
      label,
      value: index,
    });
  });
  let optionSpacing = "10px";
  let optionWidth = "15%";
  let variantSort =
    selectedProductTypeIndex === 2 || selectedProductTypeIndex === 3;
  let variableCsvKeys;
  if (selectedProductTypeIndex === 7) {
    variableCsvKeys = [
      "userFullName",
      "email",
      "walletConversionEmail",
      "walletConversionFirstName",
      "walletConversionLastName",
      "pointsConverted",
      "walletCurrencyAmount",
      "formattedOrderDate",
    ];
  }

  return (
    <MainLayout
      loading={ordersByTeamPage.loading}
      firebaseConnector={ordersByTeamPage.firebaseConnector}
      pageActions={
        <Box display="flex" alignItems="center">
          <PageTitle title={title} />
          <Box style={{ flexGrow: 1 }}></Box>
          <CPDropdown
            options={seasonOptions}
            selectedOptionFilter={selectedSeasonFilter}
            setSelectedOptionFilter={setSelectedSeasonFilter}
            allOption={allSeasonsOption}
          />
          <Box width="12px"></Box>
          <Box width="500px">
            {" "}
            <CPSearchBar
              label="Search Orders..."
              onSearch={onSearch}
              value={ordersByTeamPage.searchInput}
            />{" "}
          </Box>
          <Box width="12px"></Box>
          <Box width="150px">
            <ButtonLink variant="contained" to={orderRoutes.pickups(teamId)}>
              Pickup Dates
            </ButtonLink>
          </Box>
        </Box>
      }
    >
      <Box>
        <Box display={"flex"} mt={"50px"}>
          <Box width={optionWidth}>
            <Box style={cpBoldSubHeaderStyle}>Status:</Box>
            <CPDropdownNew
              options={statusOptions}
              selectedOptionIndex={selectedStatusTypeIndex}
              onChange={(index: number) => {
                setSelectedStatusTypeIndex(index);
              }}
            />
          </Box>

          {hasFullAccess && (
            <>
              <Box width={optionSpacing} />
              <Box width={optionWidth}>
                <Box style={cpBoldSubHeaderStyle}>Product Type:</Box>
                <CPDropdownNew
                  options={productTypeOptions}
                  selectedOptionIndex={selectedProductTypeIndex}
                  onChange={(index: number) => {
                    setSelectedProductTypeIndex(index);
                  }}
                />
              </Box>
              <Box width={optionSpacing} />
              <Box width={optionWidth}>
                <Box style={cpBoldSubHeaderStyle}>Fulfillment Type:</Box>
                <CPDropdownNew
                  options={fulfillmentOptions}
                  selectedOptionIndex={selectedFulfillmentTypeIndex}
                  onChange={(index: number) => {
                    setSelectedFulfillmentTypeIndex(index);
                  }}
                />
              </Box>
              {/* <Box style={cpBoldSubHeaderStyle}>Fulfillment:</Box>
            <AppBar position="static" color="transparent" className={tabButtonClass.bar}>
              <Grid container>
                <Grid item xs={12}>
                  <Tabs
                    value={selectedFulfillmentTypeIndex}
                    onChange={(_, value) => setSelectedFulfillmentTypeIndex(value)}
                    textColor="inherit"
                    variant="standard"
                    classes={{ indicator: tabButtonClass.tab_indicator }}
                  >
                    <Tab label="All" className={selectedFulfillmentTypeIndex == 0 ? tabButtonClass.selectedTab : tabButtonClass.tab} />
                    <Tab label="Shipping" className={selectedFulfillmentTypeIndex == 1 ? tabButtonClass.selectedTab : tabButtonClass.tab} />
                    <Tab label="Pickup" className={selectedFulfillmentTypeIndex == 2 ? tabButtonClass.selectedTab : tabButtonClass.tab} />
                  </Tabs>
                </Grid>
              </Grid>
            </AppBar> */}
            </>
          )}
        </Box>

        {selectedFulfillmentTypeIndex === 2 && (
          <>
            <Box
              m={"20px 0 20px 0"}
              width="100%"
              height="2px"
              bgcolor={cpGrayD7}
            ></Box>
            <Box display="flex" alignItems={"center"}>
              <Box style={cpBoldSubHeaderStyle}>Pick Up Date:</Box>
              <Box width={"10px"} />
              <CPDropdown
                options={pickupOptions}
                selectedOptionFilter={selectedPickupFilter}
                setSelectedOptionFilter={setSelectedPickupFilter}
                allOption={allPickupsOption}
              />
            </Box>
            <Box height="10px"></Box>
          </>
        )}
        <OrdersByTeamTable
          orders={orders}
          onFirstIconTap={(order: any) => {
            promptStatusChange(false, order);
          }}
          onSecondIconTap={(order: any) => {
            promptStatusChange(true, order);
          }}
          defaultSortDirection={
            variantSort ? SortDirection.DESC : SortDirection.ASC
          }
          defaultSort={variantSort ? "firstVariantName" : ""}
          onRowClick={(order: OrderOverview) => {
            routerStore.push(
              orderRoutes.orderDetails(teamId, order.userId, order.orderId)
            );
          }}
          csvKeys={variableCsvKeys}
        />
      </Box>
    </MainLayout>
  );
});
