import { Box, IconButton, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import { CrowdSelect } from "./CrowdSelect";

interface Props {
  options: any;
  setSelectedOptionFilter: any;
  selectedOptionFilter: any;
  allOption?: any;
}

export const CPDropdown: React.FC<Props> = ({
  options,
  setSelectedOptionFilter,
  selectedOptionFilter,
  allOption,
}) => {
  if (!selectedOptionFilter) selectedOptionFilter = allOption;
  let _options: any = [...options];
  if (allOption) _options.splice(0, 0, allOption);
  return (
    <Box>
      <CrowdSelect
        options={_options}
        selected={selectedOptionFilter["value"]}
        onChange={(value) => {
          console.log(`You picked value: ${value}`);
          let newSelectedSeasonFilter = allOption;
          Object.keys(_options).forEach((key) => {
            if (_options[key]["value"] == value) {
              newSelectedSeasonFilter = _options[key];
            }
          });
          if (!newSelectedSeasonFilter)
            console.log(
              `Couldn't find value ${value} in options: ${_options}`,
              _options,
            );
          console.log(
            `Setting newSelectedSeasonFilter: ${JSON.stringify(newSelectedSeasonFilter)}`,
          );
          setSelectedOptionFilter(newSelectedSeasonFilter);
        }}
      />
    </Box>
  );
};
