import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { CPDashboardSpacer } from "../../components/CPDashboardSpacer";
import {
  CPMarketplaceDetailBox,
  CPMarketplaceImageScrollerBox,
  CPMarketplaceLogisticsRow,
  CPMarketplaceProductDetailsBox,
} from "../../components/CPMarketplaceComponents";
import {
  cpBoldMainHeaderStyle,
  cpBoxHeaderStyle,
} from "../../helpers/constants";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { NoMatchPage } from "../NoMatchPage";
import { CPDataChart } from "../../components/CPDataChart";
import { usersRoutes } from "../../app-routes";
import { MonthlyDailyWeeklyChart } from "../../components/MonthlyDailyWeeklyChart";
import {
  exportAsCSVByCol,
  setColEmailsAndNamesWithUserCount,
} from "../../types/util-types";
import CPBackdrop from "../../components/CPBackdrop";
import { combinedObject } from "../../helpers/generic-helpers";

const useStyles = makeStyles((theme) => ({
  card: {
    flexBasis: theme.typography.pxToRem(200),
    flex: "1 0 auto",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      flexBasis: theme.typography.pxToRem(280),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface UrlParams {
  teamId: string;
}

export const MarketplaceAuctionPage = observer(() => {
  const urlParams = useParams<UrlParams>();
  const { session, routerStore } = useStore().ui;
  const { teamId } = useParams<{ teamId: string }>();
  const { auctionId } = useParams<{ auctionId: string }>();
  const { user } = useStore().ui.session;
  const team = user?.type?.identifier?.team;
  if (team !== teamId) {
    //Page not found
    return <NoMatchPage />;
  }
  const { marketplaceAuctionPage } = useStore().ui;
  const [selectedImageIndex, setSelectedImageIndex] = React.useState<number>(0);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  //Backdrop Logic Start
  const [barBackdropOpen, setBarBackdropOpen] = React.useState<boolean>(false);
  const [barBackdropTitle, setBarBackdropTitle] = React.useState<string>("");
  const [barBackdropUserIds, setBarBackdropUserIds] = React.useState<string[]>(
    []
  );
  const [barBackdropDateKey, setBarBackdropDateKey] =
    React.useState<string>("");
  const [barBackdropSearchTerm, setBarBackdropSearchTerm] =
    React.useState<string>("");
  const [barBackdropOnDownload, setBarBackdropOnDownload] =
    React.useState<any>();
  const [barBackdropOptions, setBarBackdropOptions] = React.useState<any>({});

  const handleBackdrop = (
    userIds: string[],
    dateKey: string,
    title: string,
    func: any,
    options: any
  ) => {
    console.log(`handling backrop`);
    setBarBackdropOpen(true);
    setBarBackdropTitle(title);
    setBarBackdropUserIds(userIds);
    setBarBackdropDateKey(dateKey);
    setBarBackdropSearchTerm("");
    let newOptions = combinedObject(options, { specificDateKey: dateKey });
    setBarBackdropOptions(newOptions);
    setBarBackdropOnDownload(() => {
      return func;
    });
  };
  //Backdrop Logic End

  const classes = useStyles();

  const productDetails: any = marketplaceAuctionPage.product;
  const auctionLogistics: any = marketplaceAuctionPage.auctionLogistics || [];
  const userBids: any = marketplaceAuctionPage.userBids || [];

  React.useEffect(() => {
    marketplaceAuctionPage.loadOverview(teamId, auctionId);
  }, [marketplaceAuctionPage, teamId, urlParams]);

  const onDownloadBids = async (options: any) => {
    let timeframe = options["timeframe"];
    let specificDateKey = options["specificDateKey"];
    let userIdSpecificData =
      (await marketplaceAuctionPage.getUserIdSpecificBidData(
        timeframe,
        auctionId
      )) || {};
    let allUserInfo = await marketplaceAuctionPage.getAllUserInfo();
    let csvKeys: string[] = [];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      csvKeys.push(`${dateKey} Email`);
      csvKeys.push(`${dateKey} Name`);
      csvKeys.push(`${dateKey} Count`);
    });
    let cols: any = {};
    let colIndexArr = [0];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      setColEmailsAndNamesWithUserCount(
        colIndexArr,
        cols,
        allUserInfo,
        userIdSpecificData[dateKey] || {}
      );
    });
    exportAsCSVByCol(csvKeys, cols, `Auction Bids (${timeframe})`);
  };

  return (
    <MainLayout
      isOverview={false}
      loading={marketplaceAuctionPage.loading}
      firebaseConnector={marketplaceAuctionPage.firebaseConnector}
      pageActions={
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box display="flex">
              <Box style={cpBoldMainHeaderStyle}>Auction Details</Box>
            </Box>
          </Box>
        </div>
      }
    >
      <Box display={"flex"}>
        <CPMarketplaceImageScrollerBox
          productDetails={productDetails}
          selectedImageIndex={selectedImageIndex}
          setSelectedImageIndex={(index: number) =>
            setSelectedImageIndex(index)
          }
        />
        <CPDashboardSpacer />
        <CPMarketplaceProductDetailsBox productDetails={productDetails} />
        <CPDashboardSpacer />
        <CPMarketplaceDetailBox
          children={
            <div>
              <Box style={cpBoxHeaderStyle}>Auction Logistics</Box>
              <div>
                {auctionLogistics.map((logistic: any, index: number) => {
                  return (
                    <CPMarketplaceLogisticsRow
                      key={index}
                      index={index}
                      logistic={logistic}
                    />
                  );
                })}
              </div>
            </div>
          }
        />
      </Box>
      <CPDashboardSpacer />
      <Box display={"flex"}>
        <Box flex={1}>
          <CPDataChart
            title={"Bids"}
            data={userBids}
            flexList={[1, 1]}
            dataKeyList={["name", "bid"]}
            onRowClick={(rowData: any) => {
              routerStore.push(
                usersRoutes.userOverview(rowData.userId, teamId)
              );
            }}
          />
        </Box>
        <CPDashboardSpacer />
        <Box flex={1}>
          <Box flex={1}>
            <MonthlyDailyWeeklyChart
              title={"History"}
              dataKeyName="Bids"
              dailyWeeklyMonthlyDataList={
                marketplaceAuctionPage.dailyWeeklyMonthlyBidData
              }
              onDownload={onDownloadBids}
              handleBackdrop={async (options: any, dateKey: string) => {
                if (!marketplaceAuctionPage.userDetailsByUserId) {
                  await marketplaceAuctionPage.getAllUserInfo();
                }
                let timeframe = options["timeframe"];
                let userIdSpecificData =
                  (await marketplaceAuctionPage.getUserIdSpecificBidData(
                    timeframe,
                    auctionId
                  )) || {};
                let userIds = Object.keys(userIdSpecificData[dateKey] || {});
                handleBackdrop(
                  userIds,
                  dateKey,
                  "Bids",
                  onDownloadBids,
                  options
                );
              }}
            />
          </Box>
        </Box>
      </Box>
      <CPBackdrop
        userDetailsByUserId={marketplaceAuctionPage.userDetailsByUserId}
        userIds={barBackdropUserIds}
        barBackdropDateKey={barBackdropDateKey}
        barBackdropOpen={barBackdropOpen}
        setBarBackdropOpen={setBarBackdropOpen}
        barBackdropTitle={barBackdropTitle}
        barBackdropSearchTerm={barBackdropSearchTerm}
        barBackdropOnDownload={barBackdropOnDownload}
        barBackdropOptions={barBackdropOptions}
        setBarBackdropSearchTerm={setBarBackdropSearchTerm}
      />
    </MainLayout>
  );
});
