import { makeAutoObservable } from "mobx";
import { inProgressString, infinitySymbol } from "../../helpers/constants";
import { Loading, loadingFunction } from "../../helpers/loading";
import { intValOrZero } from "../../helpers/number-helpers";
import { getVariantNameFromCombination } from "../../helpers/order-helpers";
import { getAttributeFromObject } from "../../types/util-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";
import moment from "moment";
import { mdyFormat } from "../../types/formik-types";
import { DailyWeeklyMonthlyData } from "../../types/chart-types";

export class MarketplaceSweepstakePageStore {
  public loading: Loading = Loading.NOT_LOADED;
  public product: any = {};
  public sweepstakeLogistics: any = [];
  public userTicketRedemptions: any = [];
  private sweepstakeTicketRedemptions: any = {};
  public userDetailsByUserId: any;
  private _teamId: string = "";
  private ticketRedemptionDataUserIdSpecific: any = {};

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  resetValues = () => {
    this.sweepstakeLogistics = [];
    this.userTicketRedemptions = [];
  };

  get dailyWeeklyMonthlyTicketRedemptionData(): DailyWeeklyMonthlyData[] {
    return [this.sweepstakeTicketRedemptions];
  }

  getAllUserInfo = async () => {
    if (this.userDetailsByUserId) return this.userDetailsByUserId;
    this.userDetailsByUserId =
      await this.firebaseConnector.getDatabaseObjAtPath(
        `userDetailsByUserIdByTeam/${this._teamId}`
      );
    return this.userDetailsByUserId;
  };

  getUserIdSpecificTicketCountData = async (
    timeframe: string,
    sweepstakeId: string
  ) => {
    if (this.ticketRedemptionDataUserIdSpecific[timeframe])
      return this.ticketRedemptionDataUserIdSpecific[timeframe];
    let path = `tracking/${timeframe}SweepstakeCountsByTeamUserIdSpecific/${this._teamId}/${sweepstakeId}`;
    this.ticketRedemptionDataUserIdSpecific[timeframe] =
      await this.firebaseConnector.getDatabaseObjAtPath(path);
    return this.ticketRedemptionDataUserIdSpecific[timeframe];
  };

  loadOverview = async (teamId: string, sweepstakeId: string) => {
    this._teamId = teamId;
    this.resetValues();
    loadingFunction(
      this,
      async () => {
        let sweepstake = await this.firebaseConnector.getDatabaseObjAtPath(
          `marketplaceSweepstakesByTeam/${teamId}/${sweepstakeId}`
        );
        let productId = sweepstake["productId"];
        this.product = await this.firebaseConnector.getDatabaseObjAtPath(
          `marketplaceProductsByTeam/${teamId}/${productId}`
        );
        let ticketCountsByUserId =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `sweepstakeTicketCountByTeamBySweepstakeIdByUser/${teamId}/${sweepstakeId}`
          )) || {};
        let ticketsRedeemed = 0;
        let userDetailsByUserId =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `userDetailsByUserIdByTeam/${teamId}`
          );
        Object.keys(ticketCountsByUserId).forEach((userId: any) => {
          let userTicketCount = intValOrZero(`${ticketCountsByUserId[userId]}`);
          let fullName =
            getAttributeFromObject(`${userId}/fullName`, userDetailsByUserId) ||
            userId;
          this.userTicketRedemptions.push({
            userId: userId,
            name: fullName,
            ticketsRedeemed: userTicketCount,
          });
          ticketsRedeemed += intValOrZero(ticketCountsByUserId[userId]);
        });
        this.sweepstakeLogistics.push({
          label: "Tickets Redeemed",
          value: ticketsRedeemed,
        });
        let startDate = moment(sweepstake["activeDateTime"]).format(mdyFormat);
        let endDate = moment(sweepstake["expirationDateTime"]).format(
          mdyFormat
        );
        this.sweepstakeLogistics.push({
          label: "Start Date",
          value: startDate,
        });
        this.sweepstakeLogistics.push({
          label: "End Date",
          value: endDate,
        });
        let ticketPrice = intValOrZero(`${sweepstake["ticketCost"]}`);
        let pointsSpent = ticketsRedeemed * ticketPrice;
        this.sweepstakeLogistics.push({
          label: "Ticket Price",
          value: `${ticketPrice} Points`,
        });
        this.sweepstakeLogistics.push({
          label: "Points Spent",
          value: pointsSpent,
        });
        let winningUserId = sweepstake["winningUserId"];
        let winner = winningUserId
          ? await this.firebaseConnector.getDatabaseObjAtPath(
              `userDetailsByUserIdByTeam/${teamId}/${winningUserId}/fullName`
            )
          : "None";
        let color;
        if (moment().isBefore(endDate)) {
          winner = inProgressString;
          color = "orange";
        }

        this.sweepstakeLogistics.push({
          label: "Winner",
          value: winner,
          color: color,
        });

        this.sweepstakeTicketRedemptions["daily"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/dailySweepstakeCountsByTeam/${teamId}/${sweepstakeId}`
          );
        this.sweepstakeTicketRedemptions["weekly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/weeklySweepstakeCountsByTeam/${teamId}/${sweepstakeId}`
          );
        this.sweepstakeTicketRedemptions["monthly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/monthlySweepstakeCountsByTeam/${teamId}/${sweepstakeId}`
          );
      },
      this.uiStore.notifications
    );
  };
}
