import { Box, IconButton, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";

interface Props {
  title: string;
}

export const CPInfoButton: React.FC<Props> = ({ title }) => {
  return (
    <Box>
      <Tooltip
        title={
          <div style={{ lineHeight: "24px", whiteSpace: "pre-wrap" }}>
            <h1>{title}</h1>
          </div>
        }
      >
        <Box m={2} alignContent="bottom">
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};
