import { AppBar, Tabs, Tab, makeStyles, Slider, Box } from "@material-ui/core";
import React from "react";
import {
  cpGray77,
  cpGrayD7,
  cpGrayF7,
  cpPositionedStyle,
  dashboardColor1,
} from "../helpers/constants";
import { combinedObject } from "../helpers/generic-helpers";
import { useStore } from "../helpers/use-store";
import { getColorStringFromKey } from "../types/util-types";

const useStyles = makeStyles((theme) => ({
  bar: {
    background: cpGrayD7,
    height: "6px",
    borderRadius: "100px",
  },
  rail: {
    background: cpGrayD7,
    height: 8,
    borderRadius: 4,
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "transparent",
  },
  thumb: {
    width: (props: any) => props.width,
    height: 8,
    backgroundColor: "transparent",
    // backgroundColor: cpGray77,
    borderRadius: "100px",
    marginLeft: (props: any) => props.marginLeft,
    marginTop: "0px",
    // marginLeft: "0px",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
}));

interface Props {
  length: number;
  spread: number;
  cur: number;
  onChange: any;
}

export const ChartProgressBar: React.FC<Props> = ({
  length,
  spread,
  cur,
  onChange,
}) => {
  let effectiveLength = length - spread;

  // let marginPercentage = Math.min((cur / effectiveLength) * 100, 100)
  let widthPercentage = Math.min((spread / length) * 100, 100);
  // if (widthPercentage + marginPercentage > 100) widthPercentage = 100 - marginPercentage;
  let width = `${widthPercentage}%`;
  // console.log(`length, spread, cur, width: ${length}, ${spread}, ${cur}, ${width}`)
  // console.log(`widthPercentage, marginPercentage: ${widthPercentage}, ${marginPercentage}`)
  let marginLeft;
  if (length <= 1) {
    marginLeft = "0px";
  } else {
    marginLeft = `${(cur / (length - spread)) * (100 - widthPercentage)}%`;
    // console.log(`${-(cur/(length-spread))} = ${cur}/${length-spread}`)
  }
  // marginLeft = "0px";
  // width="10px";
  const classes = useStyles({
    length,
    cur,
    onChange,
    width,
    marginLeft: "0px",
  });
  // const classes = useStyles({length, cur, onChange, width, marginLeft});
  return (
    <Box position={"relative"}>
      <Box style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
        <Box
          width={width}
          marginLeft={marginLeft}
          height="8px"
          borderRadius={"100px"}
          bgcolor={cpGray77}
        ></Box>
      </Box>
      <Slider
        style={{ position: "absolute", top: "-13px", left: 0, right: 0 }}
        classes={{
          rail: classes.rail,
          track: classes.track,
          thumb: classes.thumb,
          active: classes.active,
        }}
        max={length - 1}
        value={cur}
        onChange={onChange}
        aria-labelledby="drag-slider"
      />
    </Box>
  );
  // return <CustomSlider length={length} cur={cur} onChange={onChange} width={}/>
};

// interface SliderProps {
//   length: number;
//   cur: number;
//   onChange: any;
//   width: string;
// }

// export const CustomSlider: React.FC<SliderProps> = ({ length, cur, onChange, width }) => {
//   const classes = useStyles({length, cur, onChange, width, marginRight});
//   return (<Slider
//   classes={{
//     rail: classes.rail,
//     track: classes.track,
//     thumb: classes.thumb,
//     active: classes.active,
//   }}
//   max={length-1}
//   value={cur}
//   onChange={onChange}
//   aria-labelledby="drag-slider"
// />)
// }
