import React from "react";
import { Box, Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { DataCardBlock } from "../../components/DataCardBlock";
import { PageTitle } from "../../components/PageTitle";
import { useParams } from "react-router-dom";
import { SponsorGameCategoriesTable } from "./components/SponsorGameCategoriesTable";
import { SponsorGameUsersTable } from "./components/SponsorGameUsersTable";
import { GenderDemographicsStatistics } from "../../components/GenderDemographicsStatics";
import { formatDate } from "../../helpers/time-helpers";

export const SponsorGameOverviewPage = observer(() => {
  const { sponsorName, stadiumName, gameTimestamp } = useParams<{
    sponsorName: string;
    stadiumName: string;
    gameTimestamp: string;
  }>();
  const { sponsorGameOverviewPage } = useStore().ui;

  React.useEffect(() => {
    sponsorGameOverviewPage.loadGameOverview(
      sponsorName,
      stadiumName,
      gameTimestamp,
    );
  }, [gameTimestamp, sponsorGameOverviewPage, sponsorName, stadiumName]);

  return (
    <MainLayout
      loading={sponsorGameOverviewPage.loading}
      firebaseConnector={sponsorGameOverviewPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle
            title={sponsorName}
            subTitle={`${stadiumName} - ${formatDate(gameTimestamp)}`}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={sponsorGameOverviewPage.downloadReport}
          >
            Download report
          </Button>
        </Box>
      }
    >
      <DataCardBlock {...sponsorGameOverviewPage.firstDataBlock} />
      <DataCardBlock {...sponsorGameOverviewPage.secondDataBlock} />
      <Box marginTop={2} marginBottom={2}>
        <GenderDemographicsStatistics
          genderStats={sponsorGameOverviewPage.genderStats}
          demographicStats={sponsorGameOverviewPage.demographicStats}
        />
      </Box>
      {!!sponsorGameOverviewPage.categories.length && (
        <SponsorGameCategoriesTable
          categories={sponsorGameOverviewPage.categories}
        />
      )}
      {!!sponsorGameOverviewPage.users.length && (
        <Box marginTop={8}>
          <SponsorGameUsersTable
            users={sponsorGameOverviewPage.users}
            onRowClick={sponsorGameOverviewPage.onUserRowClick}
          />
        </Box>
      )}
    </MainLayout>
  );
});
