import { LoyaltyEditForm } from "./components/LoyaltyEditForm";

import React, { useRef } from "react";
import { MainLayout } from "../../layouts/MainLayout";
import { observer } from "mobx-react-lite";
import { Box, Button } from "@material-ui/core";
import { PageTitle } from "../../components/PageTitle";
import { useParams } from "react-router-dom";
import { useStore } from "../../helpers/use-store";
import { loyaltyItemDefaults } from "../../types/loyalties-types";

export const LoyaltyEditPage = observer(() => {
  const { code } = useParams<{ code?: string }>();

  const { loyaltyEditPage, session } = useStore().ui;

  React.useEffect(() => {
    if (code) loyaltyEditPage.loadByCode(code, false);
  }, [code, loyaltyEditPage]);

  React.useEffect(() => {
    loyaltyEditPage.loadCategoryOptions();
    loyaltyEditPage.loadPointOptions();
    loyaltyEditPage.loadCurrentSeasonId();
    loyaltyEditPage.getAllSeasons();
    loyaltyEditPage.getAllCodes();
  }, [loyaltyEditPage]);

  const formRef = React.createRef<HTMLButtonElement>();

  let formValues = code
    ? loyaltyEditPage.editableLoyalty
    : { ...loyaltyItemDefaults() };
  if (!formValues.loyaltyCodeSeasonId)
    formValues.loyaltyCodeSeasonId = loyaltyEditPage.seasonId;
  return (
    <MainLayout
      loading={loyaltyEditPage.loading}
      firebaseConnector={loyaltyEditPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title={`Active Reward`} />
          <Box display="flex" alignItems="center">
            <Box width={5} />
            <Box width={180}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => {
                  // code && formRef?.current ? loyaltyEditPage.saveCode(formRef?.current) :
                  formRef?.current?.click();
                }}
              >
                {"Finalize"}
              </Button>
            </Box>
          </Box>
        </Box>
      }
    >
      <LoyaltyEditForm
        formValues={formValues}
        onSave={loyaltyEditPage.saveCode}
        onCreateCategory={loyaltyEditPage.createCategory}
        categoryOptions={loyaltyEditPage.categoryOptions}
        pointOptions={loyaltyEditPage.pointOptions}
        allSeasons={loyaltyEditPage.allSeasons}
        formRef={formRef}
        allCodes={loyaltyEditPage.allCodes}
      />
    </MainLayout>
  );
});
