import { Box, Paper } from "@material-ui/core";
import { Tooltip } from "recharts";

interface Props {
  active?: any;
  payload?: any;
  label?: any;
}

export const CPTooltip: React.FC<Props> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let count = payload[0].value;
    let dataKey = payload[0].dataKey;
    let percentage = payload[0].payload["percentage"];
    return (
      <Paper>
        <Box width={"125p"} height="90px" p={"16px"}>
          <Box>{label}</Box>
          <Box>{`${dataKey}: ${count}`}</Box>
          {percentage && <Box>{percentage}</Box>}
        </Box>
      </Paper>
    );
  }

  return null;
};
