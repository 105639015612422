import { Box, Paper, TableCell } from "@material-ui/core";
import React from "react";
import { TableContainer } from "./TableContainer";
import { SortableTable } from "./SortableTable";
import { CPSortableTable } from "./CPSortableTable";
import {
  cpBoldSubHeaderStyle,
  cpCardBoxShadow,
  cpLargeCardHeight,
  cpLargeCardPadding,
} from "../helpers/constants";
import { splitCamelCase } from "../helpers/name-helpers";
import { CPDownloadButton } from "./CPDownloadButton";
import { exportAsCSVByRow } from "../types/util-types";
import { SortDirectionType } from "react-virtualized";

interface Props {
  title: string;
  data: any[];
  csvTitle?: string;
  exportCSVKeys?: string[];
  flexList: number[];
  dataKeyList: string[];
  separateLabelMap?: any;
  displayFunctionMap?: any;
  defaultSort?: string;
  defaultSortDirection?: SortDirectionType;
  onRowClick?: (rowData: any) => void;
  height?: string;
}

export const CPDataChart: React.FC<Props> = ({
  title,
  data,
  flexList,
  dataKeyList,
  separateLabelMap = {},
  displayFunctionMap = {},
  defaultSort,
  defaultSortDirection,
  onRowClick,
  exportCSVKeys,
  csvTitle = "",
  height,
}) => {
  if (!height) height = cpLargeCardHeight;
  let columns: any = [];
  dataKeyList.forEach((value, index) => {
    let label = separateLabelMap[value] || splitCamelCase(value, true);
    let cellRender;
    if (displayFunctionMap[value]) {
      cellRender = (row: any) => {
        let displayValue = displayFunctionMap[value](row[value]);
        return <span>{displayValue}</span>;
      };
    }
    columns.push({
      dataKey: value,
      label,
      flexGrow: flexList[index],
      width: 50,
      cellRender,
    });
  });

  return (
    <Paper
      style={{
        height,
        padding: "10px",
        paddingLeft: cpLargeCardPadding,
        paddingBottom: "0px",
        overflow: "hidden",
        boxShadow: cpCardBoxShadow,
      }}
    >
      <Box
        fontSize="1.2rem"
        fontWeight="fontWeightBold"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={"10px"}
      >
        <Box style={cpBoldSubHeaderStyle}>{title}</Box>
        <Box height={"30px"}></Box>
        {exportCSVKeys && (
          <CPDownloadButton
            onDownload={() =>
              exportAsCSVByRow(exportCSVKeys, data, csvTitle || title)
            }
          />
        )}
      </Box>
      <Box paddingRight="10px">
        <CPSortableTable
          height={height}
          maxRowCount={50}
          defaultSort={defaultSort}
          defaultSortDirection={defaultSortDirection}
          columns={columns}
          onRowClick={onRowClick}
          rows={data}
        />
      </Box>
    </Paper>
  );
};
