import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import close_black from "../assets/close_black.png";
import { useStore } from "../helpers/use-store";

interface Props {
  onClick?: Function;
}

export const CPCloseButton: React.FC<Props> = ({ onClick }) => {
  const { notifications } = useStore().ui;
  if (!onClick) return <div></div>;
  return (
    <Box height={"35px"}>
      <Typography color="secondary">
        <img
          src={close_black}
          style={{ cursor: "pointer", height: "35px" }}
          onClick={() => {
            onClick();
          }}
        />
      </Typography>
    </Box>
  );
};
