import { PieChartProps } from "react-minimal-pie-chart";

export interface LoyaltyItem {
  [key: string]: any;
  loyaltyCodeId: string;
  lastUpdated: string;
  loyaltyCodeActiveDate: string;
  loyaltyCodeCategory: string;
  loyaltyCodeCode: string;
  loyaltyCodeSeasonId: string;
  loyaltyCodeDescription: string;
  loyaltyCodeExpirationDate: string;
  loyaltyCodeLink: string;
  loyaltyCodePoints: string;
  loyaltyCodeNRedemptions: number | null;
  loyaltyCodeTitle: string;
  loyaltyCodeVisibility: boolean;
  loyaltyCodeIsOneTimeCode: boolean;
  loyaltyCodeIsExclusive: boolean;
  loyaltyCodeRedemptionFormat: string;
  loyaltyCodeTextColor: string;
  loyaltyCodeButtonName: string;
  //Old
  // loyaltyCodeFileUrl: string | null;
  //New
  loyaltyCodeMainFileUrl: string | null;
  loyaltyCodeBackgroundFileUrl: string | null;
  loyaltyCodeIconFileUrl: string | null;
  //Old
  // loyaltyCodeFile?: File | null;
  //New
  loyaltyCodeMainFile?: File | null;
  loyaltyCodeBackgroundFile?: File | null;
  loyaltyCodeIconFile?: File | null;
}

export const loyaltyItemDefaults = (): LoyaltyItem => ({
  lastUpdated: "",
  loyaltyCodeId: "",
  loyaltyCodeLink: "",
  loyaltyCodePoints: "",
  loyaltyCodeRedemptionFormat: "",
  loyaltyCodeNRedemptions: 0,
  loyaltyCodeSeasonId: "",
  loyaltyCodeTitle: "",
  loyaltyCodeActiveDate: "",
  loyaltyCodeCategory: "",
  loyaltyCodeCode: "",
  loyaltyCodeDescription: "",
  loyaltyCodeExpirationDate: "",
  loyaltyCodeVisibility: false,
  loyaltyCodeIsOneTimeCode: false,
  loyaltyCodeIsExclusive: false,
  // isInstant: false,
  loyaltyCodeTextColor: "",
  loyaltyCodeButtonName: "",
  //Old
  // loyaltyCodeFileUrl: null,
  //New
  loyaltyCodeMainFileUrl: null,
  loyaltyCodeBackgroundFileUrl: null,
  loyaltyCodeIconFileUrl: null,
  //Old
  // loyaltyCodeFile: null,
  //New
  loyaltyCodeMainFile: null,
  loyaltyCodeBackgroundFile: null,
  loyaltyCodeIconFile: null,
});

export interface LoyaltyCategory {
  id: string;
  title: string;
}

export interface CategoryListItem {
  [key: string]: string;
  id: string;
  title: string;
  count: string;
}

export const categoryListItemDefaults = (): CategoryListItem => ({
  id: "",
  title: "",
  count: "0",
});

export interface CategoryPieItem extends PieChartProps {
  id: string;
  title: string;
  value: string;
  amount: string;
  color: string;
}

export interface LoyaltyAnaltyicsResponse {
  nCodes: number;
  nCodeRedemptions: number;
  categoryNameByCategoryId: Record<string, string>;
  hiddenCategoryIds: string[];
  nDailyRewardRedemptionsByWeekday: Record<string, number>;
  nDailyRewardRedemptionsByMonth: Record<string, number>;
  nTotalCodeRedemptionsByWeekday: Record<string, number>;
  nTotalCodeRedemptionsByMonth: Record<string, number>;
  nRedemptionsByCategory: { [key: string]: number };
  hottestCategory: string;
  nDailyRewardRedemptions: number;
  totalPointsRedeemed: number;
  totalPointsRedeemedByCodes: number;
  //Same thing, just a bandaid for the broken above.
  totalPointsFromRedemptions: number;
}

export const loyaltyAnalyticsStatsDefaults = (): LoyaltyAnaltyicsResponse => ({
  nCodes: 0,
  nCodeRedemptions: 0,
  categoryNameByCategoryId: {},
  hiddenCategoryIds: [],
  nDailyRewardRedemptionsByWeekday: {},
  nDailyRewardRedemptionsByMonth: {},
  nTotalCodeRedemptionsByWeekday: {},
  nTotalCodeRedemptionsByMonth: {},
  nRedemptionsByCategory: {},
  hottestCategory: "",
  nDailyRewardRedemptions: 0,
  totalPointsRedeemed: 0,
  totalPointsRedeemedByCodes: 0,
  //Same thing, just a bandaid for the broken above.
  totalPointsFromRedemptions: 0,
});

export interface CategoryAnalyticsResponse {
  nCodes: number;
  nCodeRedemptions: number;
  totalPointsRedeemed: number;
  genders: {
    male: number;
    female: number;
    other: number;
  };
  ages: { [key: string]: number };
}

export const categoryAnalyticsStatsDefaults =
  (): CategoryAnalyticsResponse => ({
    nCodes: 0,
    nCodeRedemptions: 0,
    totalPointsRedeemed: 0,
    genders: {
      male: 0,
      female: 0,
      other: 0,
    },
    ages: {},
  });
