import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import { CrowdSelect } from "./CrowdSelect";
import {
  Box,
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  InputBase,
  Theme,
  withStyles,
} from "@material-ui/core";

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "7px 26px 7px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

interface Props {
  options: any[];
  selectedOptionIndex: number;
  placeholder?: string;
  onChange: Function;
}

export const CPDropdownNew: React.FC<Props> = ({
  options,
  onChange,
  selectedOptionIndex,
  placeholder = "",
}) => {
  return (
    <Box mt={2} width="100%">
      <Select
        style={{ padding: 0, width: "100%" }}
        placeholder={placeholder}
        variant="outlined"
        value={options[selectedOptionIndex]["value"]}
        input={<BootstrapInput />}
        onChange={({ target }) => onChange(target.value)}
      >
        {options?.map(({ label, value }) => {
          return (
            <MenuItem key={label} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};
