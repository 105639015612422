import { makeAutoObservable } from "mobx";
import { allOptionValue } from "../../helpers/constants";
import { Loading, loadingFunction } from "../../helpers/loading";
import { filterByCriteria, filterBySeason } from "../../helpers/sort-helpers";
import { OrderOverview, orderOverviewDefaults } from "../../types/order-types";
import { SessionUserType } from "../../types/user-types";
import { getSeasonFilterFromSeasonId } from "../../types/util-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class OrdersByTeamPageStore {
  public orderOverview: OrderOverview = orderOverviewDefaults();
  public loading: Loading = Loading.NOT_LOADED;
  private _orders: OrderOverview[] = [];
  // public _filteredOrders: OrderOverview[] = [];
  public seasonId: string = "";
  public searchInput: string = "";
  private _products: any = [];
  public seasonOptions: any = [];
  public pickupOptions: any = [];
  public selectedSeasonFilter: string = "";
  public selectedStatusTypeIndex: any;
  public selectedProductTypeIndex: any;
  public selectedFulfillmentTypeIndex: any;
  public selectedPickupFilter: any;
  public _productTypes: any[] = [];
  private role: any = null;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  searchOrder = (input: string) => {
    this.searchInput = input;
  };

  loadOrdersByTeamId = async (
    teamId: string,
    seasonFilter: any,
    setSelectedSeasonFilter: any,
    selectedStatusTypeIndex: number,
    selectedProductTypeIndex: number,
    selectedFulfillmentTypeIndex: number,
    selectedPickupFilter: any,
    role: any
  ) => {
    loadingFunction(
      this,
      async () => {
        this.seasonOptions =
          await this.firebaseConnector.getSeasonOptions(teamId);
        this.pickupOptions =
          await this.firebaseConnector.getPickupOptions(teamId);
        this._productTypes = await this.firebaseConnector.getDatabaseObjAtPath(
          "marketplaceProductTypes"
        );
        if (!this.seasonId || !seasonFilter) {
          this.seasonId =
            await this.firebaseConnector.getCurrentSeasonId(teamId);
        } else {
          this.seasonId = seasonFilter.value;
        }
        if (!seasonFilter || seasonFilter.value != this.seasonId) {
          let defaultSeasonFilter = getSeasonFilterFromSeasonId(
            this.seasonOptions,
            this.seasonId
          );
          setSelectedSeasonFilter(defaultSeasonFilter);
          seasonFilter = defaultSeasonFilter;
        }
        this._orders = await this.firebaseConnector.loadOrdersByTeamId(
          teamId,
          this.seasonId
        );
        this._products =
          await this.firebaseConnector.loadProductsByTeamId(teamId);
        this.selectedSeasonFilter = seasonFilter;
        // this.selectedStatusTypeIndex = selectedStatusTypeIndex;
        // this.selectedProductTypeIndex = selectedProductTypeIndex;
        // this.selectedFulfillmentTypeIndex = selectedFulfillmentTypeIndex;
        // this.selectedPickupFilter = selectedPickupFilter;
        this.role = role;
      },
      this.uiStore.notifications
    );
  };

  get productTypes(): any[] {
    return this._productTypes || [];
  }

  getOrders = (
    selectedStatusTypeIndex: any,
    selectedProductTypeIndex: any,
    selectedFulfillmentTypeIndex: any,
    selectedPickupFilter: any
  ) => {
    let isOrdersRole = this.role === SessionUserType.ORDERS;
    let criteriaList = ["email", "userFullName", "friendlyId"];
    if (!this.selectedSeasonFilter) {
      return [];
    }

    let orders = this._orders.filter((item: any) => {
      if (selectedStatusTypeIndex === 3) return true;
      let statusBySelected = [3, 1, 2];
      let status = statusBySelected[selectedStatusTypeIndex];
      return item["status"] == status;
    });
    orders = orders.filter((item: any) => {
      if (selectedProductTypeIndex === 0 || isOrdersRole) return true;
      let productTypeBySelected = ["", 0, 1, 2, 3, 4, 5, 6, 7];
      //We add 3 because we depredacted the first 3 types
      let productType =
        Number(productTypeBySelected[selectedProductTypeIndex]) + 3;
      let productId = item["productId"];
      let productDetails = this._products[productId] || {};
      let itemProductType =
        productDetails["productType"] && productDetails["productType"]["value"];
      return itemProductType === productType;
    });
    orders = orders.filter((item: any) => {
      if (selectedFulfillmentTypeIndex === 0) return true;
      if (selectedFulfillmentTypeIndex === 1) return !item["pickupId"];
      if (selectedFulfillmentTypeIndex === 2) {
        //Pickups
        return (
          !!item["pickupId"] &&
          (!selectedPickupFilter ||
            selectedPickupFilter["value"] == allOptionValue ||
            item["pickupId"] == selectedPickupFilter["value"])
        );
      }
    });
    orders = filterBySeason(this.selectedSeasonFilter, orders, "orderDate");
    return filterByCriteria(orders, criteriaList, this.searchInput);
  };

  setOrderStatus = async (
    teamId: string,
    userId: string,
    orderId: string,
    newStatus: Number
  ) => {
    await this.firebaseConnector.setOrderStatus(
      teamId,
      userId,
      orderId,
      newStatus
    );
  };
}
