import React from "react";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { PageTitle } from "../../components/PageTitle";
import { QuestionEditForm } from "./components/QuestionEditForm";
import { useParams } from "react-router";

export const QuestionOverviewPage = observer(() => {
  const { questionOverviewPage } = useStore().ui;
  const { questionId } = useParams<{ questionId?: string }>();

  React.useEffect(() => {
    console.log("QuestionOverviewPage useEffect");
    questionOverviewPage.loadFolders();
    questionOverviewPage.loadQuestion(questionId);
  }, [questionId]);

  const formRef = React.createRef<HTMLButtonElement>();
  return (
    <MainLayout
      loading={questionOverviewPage.loading}
      firebaseConnector={questionOverviewPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title="Question Overview" />
          <Box width={250}></Box>
        </Box>
      }
    >
      <QuestionEditForm
        formValues={questionOverviewPage.question}
        onSave={questionOverviewPage.saveQuestion}
        formRef={formRef}
        folderOptions={questionOverviewPage.folderOptions}
      />
    </MainLayout>
  );
});
