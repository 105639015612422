import { Box, Typography } from "@material-ui/core";
import React from "react";
import { SortDirectionType } from "react-virtualized";
import check_square from "../../../assets/check_square.png";
import close_square from "../../../assets/close_square.png";
import { SortableTable } from "../../../components/SortableTable";
import { TableContainer } from "../../../components/TableContainer";
import {
  getTextColorFromStatus,
  unableToContinueStatuses,
} from "../../../helpers/order-helpers";
import { OrderOverview } from "../../../types/order-types";

interface Props {
  defaultSort: string;
  defaultSortDirection?: SortDirectionType;
  orders: OrderOverview[];
  onRowClick?: any;
  onFirstIconTap?: any;
  onSecondIconTap?: any;
  csvKeys?: any;
}

export const OrdersByTeamTable: React.FC<Props> = ({
  orders,
  onRowClick,
  defaultSort,
  defaultSortDirection,
  onFirstIconTap: onApprove,
  onSecondIconTap: onReject,
  csvKeys,
}) => {
  let defaultWidth = 100;
  csvKeys = csvKeys || [
    "userFullName",
    "email",
    "productName",
    "firstVariantName",
    "orderItemCount",
    //  "orderTotal",
    "statusName",
    "formattedOrderDate",
    "friendlyId",
    "pickupDate",
  ];
  return (
    <TableContainer>
      <SortableTable
        title="Orders"
        onRowClick={onRowClick}
        height="100vh"
        defaultSort={defaultSort}
        defaultSortDirection={defaultSortDirection}
        csvKeys={csvKeys}
        columns={[
          {
            dataKey: "userFullName",
            label: "Name",
            flexGrow: 12,
            width: defaultWidth,
          },
          {
            dataKey: "email",
            label: "Email",
            flexGrow: 10,
            width: defaultWidth,
          },
          {
            dataKey: "productName",
            label: "Item",
            numeric: true,
            flexGrow: 10,
            width: defaultWidth,
          },
          {
            dataKey: "firstVariantName",
            label: "Variant",
            numeric: true,
            flexGrow: 5,
            width: defaultWidth,
          },
          {
            dataKey: "orderItemCount",
            label: "Qty",
            numeric: true,
            flexGrow: 1,
            width: defaultWidth,
          },
          // {
          //   dataKey: "orderTotal",
          //   label: "Total",
          //   numeric: true,
          //   flexGrow: 1,
          //   width: defaultWidth,
          // },
          {
            dataKey: "statusName",
            label: "Status",
            numeric: true,
            flexGrow: 1,
            width: defaultWidth,
            cellRender: (row) => {
              let color = getTextColorFromStatus(row.status);
              return (
                <span style={{ color: color }}>{`${row.statusName}`}</span>
              );
            },
          },
          {
            dataKey: "formattedOrderDate",
            label: "Ordered",
            numeric: true,
            flexGrow: 2,
            width: defaultWidth,
          },
          {
            dataKey: "friendlyId",
            label: "Order No.",
            numeric: true,
            flexGrow: 2,
            width: defaultWidth,
          },
          {
            dataKey: "reject",
            label: "",
            flexGrow: 1,
            width: defaultWidth,
            disableClickEventBubbling: true,
            cellRender: (props) => {
              if (
                !onApprove ||
                unableToContinueStatuses.includes(`${props["status"]}`)
              )
                return <></>;
              return (
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className="delete-icon"
                >
                  <Typography color="secondary">
                    <img
                      src={close_square}
                      style={{ cursor: "pointer", height: "30px" }}
                      onClick={(event) => {
                        onApprove(props);
                        event.stopPropagation();
                      }}
                    />
                  </Typography>
                </Box>
              );
            },
          },
          {
            dataKey: "approve",
            label: "",
            flexGrow: 1,
            width: defaultWidth,
            disableClickEventBubbling: true,
            cellRender: (props) => {
              if (
                !onReject ||
                unableToContinueStatuses.includes(`${props["status"]}`)
              )
                return <></>;
              return (
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className="delete-icon"
                >
                  <Typography color="secondary">
                    <img
                      src={check_square}
                      style={{ cursor: "pointer", height: "30px" }}
                      onClick={(event) => {
                        onReject(props);
                        event.stopPropagation();
                      }}
                    />
                  </Typography>
                </Box>
              );
            },
          },
        ]}
        rows={orders}
        // withChevron
      />
    </TableContainer>
  );
};
