import { makeAutoObservable } from "mobx";
import moment from "moment";
import { Loading, loadingFunction } from "../../helpers/loading";
import { localizedMoment } from "../../helpers/time-helpers";
import {
  HomeGameNotificationOverview,
  PushNotificationOverview as PushNotificationOverview,
  pushNotificationOverviewDefaults,
  notificationTypeDetails,
  homeGameNotificationOverviewDefaults,
} from "../../types/notification-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class NotificationsByTeamPageStore {
  public notificationOverview: PushNotificationOverview =
    pushNotificationOverviewDefaults();
  public loading: Loading = Loading.NOT_LOADED;
  private _pushNotifications: PushNotificationOverview[] = [];
  private _gameNotifications: HomeGameNotificationOverview[] = [];
  private _homeNotifications: HomeGameNotificationOverview[] = [];
  public selectedStatusTypeIndex: any;
  public selectedNotificationTypeIndex: any;
  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  loadNotificationsByTeamId = async (teamId: string, typeIndexInt: number) => {
    loadingFunction(
      this,
      async () => {
        let path = notificationTypeDetails[typeIndexInt].path;
        let notificationData =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `${path}/${teamId}`,
          )) || {};
        this._homeNotifications = [];
        this._gameNotifications = [];
        this._pushNotifications = [];
        let isHomeGameNotification = typeIndexInt > 0;
        Object.keys(notificationData).forEach((notificationId, index) => {
          let notificationOverview: any = isHomeGameNotification
            ? pushNotificationOverviewDefaults()
            : homeGameNotificationOverviewDefaults();
          notificationOverview = notificationData[notificationId] || {};
          notificationOverview.notificationId = notificationId || "";
          if (isHomeGameNotification) {
            if (typeIndexInt == 1) {
              this._homeNotifications.push(notificationOverview);
            } else {
              this._gameNotifications.push(notificationOverview);
            }
          } else {
            let notificationDate = localizedMoment(
              notificationOverview.notificationScheduledDateTime ||
                notificationOverview.notificationSentDateTime,
            ).toISOString();
            notificationOverview.notificationDate = notificationDate;
            this._pushNotifications.push(notificationOverview);
          }
        });

        this._pushNotifications.sort((notificationA, notificationB) => {
          return moment(notificationA["notificationDate"]).isBefore(
            notificationB["notificationDate"],
          )
            ? 1
            : -1;
        });
      },
      this.uiStore.notifications,
    );
  };

  filteredNotifications(
    selectedStatusTypeIndex: number,
    selectedNotificationTypeIndex: number,
  ) {
    let notifications: any =
      selectedNotificationTypeIndex == 0
        ? this._pushNotifications
        : selectedNotificationTypeIndex == 1
          ? this._homeNotifications
          : this._gameNotifications;
    let isHomeGameNotification = selectedNotificationTypeIndex > 0;

    let result = notifications.filter((item: any) => {
      let isPast;
      if (isHomeGameNotification) {
        isPast =
          item.notificationEndDate &&
          moment(item.notificationEndDate).isAfter(moment())
            ? false
            : true;
      } else {
        isPast = item.notificationSentDateTime || !item.notificationIsScheduled;
      }

      if (selectedStatusTypeIndex === 1) {
        return isPast;
      } else {
        return !isPast;
      }
    });
    return result;
  }

  getNewNotificationId = async (teamId: string, path: string) => {
    if (!teamId) return "";
    let newNotificationId =
      (await this.firebaseConnector.getKeyFromPushedPath(
        `${path}/${teamId}`,
      )) || "";
    return newNotificationId;
  };

  deleteNotification = async (
    teamId: string,
    notificationId: string,
    typeIndexInt: number,
  ) => {
    if (teamId && notificationId)
      await this.firebaseConnector.setDatabasePathAsObj(
        `${notificationTypeDetails[typeIndexInt].path}/${teamId}/${notificationId}`,
        null,
      );
    await this.loadNotificationsByTeamId(teamId, typeIndexInt);
  };
}
