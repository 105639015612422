import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { DemographicStatistics } from "./DemographicStatistics";
import { GenderStatistic } from "./GenderStatistics";

const useStyles = makeStyles((theme) => ({
  block: {
    height: theme.typography.pxToRem(450),
  },
}));

interface Props {
  collectsGenders?: boolean;
  collectsBirthdays?: boolean;
  genderStats: {
    male?: number;
    female?: number;
    preferNotSay?: number;
    maleAmount?: number;
    femaleAmount?: number;
    preferNotSayAmount?: number;
    showData?: boolean;
  };
  demographicStats: { name: string; amount: number }[];
}

export const GenderDemographicsStatistics: React.FC<Props> = ({
  genderStats,
  demographicStats,
  collectsGenders,
  collectsBirthdays,
}) => {
  const classes = useStyles();
  if (collectsGenders === false && collectsBirthdays === false) return <Box />;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
      width="100%"
    >
      {collectsGenders !== false && (
        <Box flexGrow={1} flexBasis={10} mr={1}>
          <GenderStatistic className={classes.block} {...genderStats} />
        </Box>
      )}
      {collectsBirthdays !== false && (
        <Box flexGrow={1} flexBasis={10} ml={1}>
          <DemographicStatistics
            className={classes.block}
            statistics={demographicStats}
          />
        </Box>
      )}
    </Box>
  );
};
