import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { PageTitle } from "../../components/PageTitle";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { useParams } from "react-router-dom";
import { GenderDemographicsStatistics } from "../../components/GenderDemographicsStatics";
import { DataCardBlock } from "../../components/DataCardBlock";
import { LoyaltiesTable } from "./components/LoyaltiesTable";
import { CustomizableDataCardBlock } from "../../components/CustomizableDataCardBlock";
import { standardDashboardPadding } from "../../helpers/constants";
import { CPDashboardSpacer } from "../../components/CPDashboardSpacer";
import { CPButton } from "../../components/CPButton";

export const LoyaltiesCategoryPage = observer(() => {
  const { categoryId } = useParams<{ categoryId?: string }>();

  const { loyaltiesCategoriesPage } = useStore().ui;
  const { loyaltiesPage } = useStore().ui;

  React.useEffect(() => {
    loyaltiesPage.loadLoyalties();
  }, [loyaltiesPage]);

  React.useEffect(() => {
    if (categoryId) loyaltiesCategoriesPage.loadLoyalties(false);
    if (categoryId) loyaltiesCategoriesPage.loadCategory(categoryId);

    console.log("Got categoryId " + categoryId);
  }, [categoryId, loyaltiesCategoriesPage]);

  return (
    <MainLayout
      loading={loyaltiesCategoriesPage.loading}
      firebaseConnector={loyaltiesCategoriesPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle
            title={`Category Details (${
              loyaltiesPage.categoryNameByCategoryId[categoryId || ""]
            })`}
          />
          <Box flex={1}></Box>
          <CPButton
            onClick={async () => {
              let newName = window.prompt("Please enter a new name");
              if (newName && categoryId) {
                await loyaltiesCategoriesPage.changeCategoryName(
                  categoryId,
                  newName,
                );
                await loyaltiesPage.loadLoyalties();
              }
            }}
          >
            Change Category Name
          </CPButton>
        </Box>
      }
    >
      <Box marginTop={5} />
      <CustomizableDataCardBlock
        {...loyaltiesCategoriesPage.categorySpecificDataBlock(categoryId || "")}
        height={"149px"}
        itemsPerRow={3}
      />
      <CPDashboardSpacer />
      <LoyaltiesTable
        loyalties={loyaltiesCategoriesPage.filteredLoyalties(
          categoryId ? categoryId : "",
        )}
        disabled={false}
      />
    </MainLayout>
  );
});
