import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect } from "react-router";
import { useStore } from "../../helpers/use-store";
import { AuthLayout } from "../../layouts/AuthLayout";
import { LoginForm } from "./components/LoginForm";

export const LoginPage = observer(() => {
  const { session } = useStore().ui;

  console.log(`session.user?.userId`, session.user?.userId);
  if (!!session.user?.userId) return <Redirect to="/" />;
  // TODO add error message
  return (
    <AuthLayout>
      <LoginForm onLogin={session.logIn} />
    </AuthLayout>
  );
});
