import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import export_square from "../assets/export_square.png";
import { useStore } from "../helpers/use-store";

interface Props {
  onDownload?: Function;
}

export const CPDownloadButton: React.FC<Props> = ({ onDownload }) => {
  const { notifications } = useStore().ui;
  if (!onDownload) return <div></div>;
  return (
    <Box height={"30px"}>
      <Typography color="secondary">
        <img
          src={export_square}
          style={{ cursor: "pointer", height: "30px" }}
          onClick={() => {
            onDownload();
            notifications.showSuccess(
              "Download request received, please wait a moment.",
            );
          }}
        />
      </Typography>
    </Box>
  );
};
