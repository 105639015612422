export interface SponsorListItem {
  [key: string]: number | string;
  sponsorName: string;
}

export interface SponsorOverviewStats {
  nTotalStadium: number;
  nGameSpon: number;
  nTotalImp: number;
  nUsers: number;
  totalAdTime: number;
  genders: {
    male: number;
    female: number;
    other: number;
  };
  ages: { [key: string]: number };
}

export interface SponsorOverviewStatsResponse {
  data: SponsorOverviewStats;
}

export const sponsorOverviewStatsDefaults = (): SponsorOverviewStats => ({
  nTotalStadium: 0,
  nGameSpon: 0,
  nTotalImp: 0,
  nUsers: 0,
  totalAdTime: 0,
  genders: {
    male: 0,
    female: 0,
    other: 0,
  },
  ages: {},
});

export interface SponsorOverviewUser {
  [key: string]: number | string;
  id: string;
  email: string;
  name: string;
  nGames: number;
  nImp: number;
  timeAd: number;
}
export interface SponsorGameUser {
  [key: string]: number | string;
  id: string;
  email: string;
  gender: string;
  name: string;
  type: string;
  age: number;
  nClick: number;
  nImp: number;
  timeEng: number;
  timeView: number;
}

export interface SponsorOverviewGame {
  [key: string]: number | string;
  id: string;
  category: string;
  nTotalImp: number;
  name: string;
  stadium: string;
}
export interface SponsorStadium {
  [key: string]: number | string;
  nGameSpon: number;
  nImp: number;
  nUsers: number;
  stadiumName: string;
}

export interface SponsorStadiumCategory {
  [key: string]: number | string;
  category: string;
  nGames: number;
  nImp: number;
  nUsers: number;
}
export interface SponsorStadiumGame {
  [key: string]: number | string;
  category: string;
  id: string;
  nImp: number;
  nUsers: number;
  name: string;
}

export interface SponsorGameCategory {
  [key: string]: number | string;
  category: string;
  exist: number;
  nImp: number;
  totalAdTime: number;
}

export interface SponsorGame {
  [key: string]: number | string;
  category: string;
  id: string;
  nImp: number;
  nUsers: number;
  name: string;
}

export interface SponsorStadiumResponse {
  ages: { [key: string]: number };
  categories: {
    category: string;
    nGames: number;
    nImp: number;
    nUsers: number;
  }[];
  games: {
    category: string;
    id: string;
    nImp: number;
    nUsers: number;
    name: string;
  }[];
  genders: {
    male: number;
    female: number;
    other: number;
  };
  nGameSpon: number;
  nReached: number;
  nTotalImp: number;
  timeView: number;
}

export const sponsorStadiumResponseDefaults = (): SponsorStadiumResponse => ({
  ages: {},
  categories: [],
  games: [],
  genders: {
    male: 0,
    female: 0,
    other: 0,
  },
  nGameSpon: 0,
  nReached: 0,
  nTotalImp: 0,
  timeView: 0,
});

export interface SponsorStadiumGameResponse {
  ages: { [key: string]: number };
  categories: {
    category: string;
    nImp: number;
    totalAdTime: number;
    exist: number;
  }[];
  genders: { male: number; female: number; other: number };
  nTotalClickThroughs: number;
  nTotalImp: number;
  nUsers: number;
  name: string;
  totalAdTime: number;
  totalEngagementTime: number;
}

export const sponsorStadiumGameResponseDefaults =
  (): SponsorStadiumGameResponse => ({
    ages: {},
    categories: [],
    genders: {
      male: 0,
      female: 0,
      other: 0,
    },
    nTotalClickThroughs: 0,
    nTotalImp: 0,
    nUsers: 0,
    name: "",
    totalAdTime: 0,
    totalEngagementTime: 0,
  });
export interface SponsorStadiumGameCategory {
  [key: string]: number | string;
  category: string;
  exist: number;
  nImp: number;
  totalAdTime: number;
}
