import React from "react";
import { DateCell } from "../../../components/DateCell";
import { SortableTable } from "../../../components/SortableTable";
import { TableContainer } from "../../../components/TableContainer";
import { SponsorGame } from "../../../types/sponsors-types";

interface Props {
  games: SponsorGame[];
  onRowClick?: (game: SponsorGame) => void;
}

export const SponsorGamesTable: React.FC<Props> = ({ games, onRowClick }) => {
  return (
    <TableContainer title={`Total Games - ${games.length}`}>
      <SortableTable
        columns={[
          {
            dataKey: "name",
            label: "Game",
            flexGrow: 1,
            width: 100,
            cellRender: (row) => (
              <span>{`${row.name}`.replace("@", "vs")}</span>
            ),
          },
          {
            dataKey: "id",
            label: "Date",
            flexGrow: 1,
            width: 100,
            cellRender: (row) => <DateCell date={`${row.id}`} />,
          },
          {
            dataKey: "nImp",
            label: "Total Impressions",
            flexGrow: 1,
            width: 100,
          },
          {
            dataKey: "nUsers",
            label: "Total Unique Users",
            flexGrow: 1,
            width: 100,
          },
        ]}
        rows={games}
        // @ts-ignore
        onRowClick={onRowClick}
      />
    </TableContainer>
  );
};
