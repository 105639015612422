import { Route, Switch } from "react-router";
import {
  authRoutes,
  gamesRoutes,
  loyaltyRoutes,
  marketplaceRoutes,
  notificationRoutes,
  orderRoutes,
  questionsRoutes,
  sponsorRoutes,
  stadiumRoutes,
  teamRoutes,
  usersAccessRoutes,
  usersRoutes,
  integrationsRoutes
} from "../app-routes";
import { PrivateRoute } from "../components/PrivateRoute";
import { NoMatchPage } from "./NoMatchPage";
import { RootPage } from "./RootPage";
import { GamesPage } from "./games";
import { GameplayPage } from "./games/GameplayPage";
import { GameplaySendPage } from "./games/GameplaySendPage";
import { LoginPage } from "./login/LoginPage";
import { LoyaltyEditPage } from "./loyalty";
import { LoyaltiesCategoryPage } from "./loyalty/LoyaltiesCategoryPage";
import { LoyaltiesPage } from "./loyalty/LoyaltiesPage";
import { LoyaltyCategoryFilterPage } from "./loyalty/LoyaltyCategoryFilterPage";
import { LoyaltyDetailsPage } from "./loyalty/LoyaltyDetailsPage";
import { MarketplaceAuctionPage } from "./marketplace/MarketplaceAuctionPage";
import { MarketplaceOverviewPage } from "./marketplace/MarketplaceOverviewPage";
import { MarketplaceProductPage } from "./marketplace/MarketplaceProductPage";
import { MarketplaceSweepstakePage } from "./marketplace/MarketplaceSweepstakePage";
import { NotificationDetailsPage } from "./notifications/NotificationDetails";
import { NotificationsByTeamPage } from "./notifications/NotificationsByTeam";
import { OrderDetailsPage } from "./orders/OrderDetails";
import { OrdersByTeamPage } from "./orders/OrdersByTeam";
import { PickupEditPage } from "./orders/PickupEditPage";
import { PickupsByTeamPage } from "./orders/PickupsByTeam";
import { QuestionOverviewPage, QuestionsPage } from "./questions";
import { SponsorGameOverviewPage } from "./sponsors/SponsorGameOverviewPage";
import { SponsorOverviewPage } from "./sponsors/SponsorOverviewPage";
import { SponsorStadiumOverviewPage } from "./sponsors/SponsorStadiumOverviewPage";
import { SponsorStadiumsPage } from "./sponsors/SponsorStadiumsPage";
import { SponsorsPage } from "./sponsors/SponsorsPage";
import { StadiumGameOverviewPage } from "./stadiums/StadiumGameOverviewPage";
import { StadiumGamesPage } from "./stadiums/StadiumGamesPage";
import { StadiumOverviewPage } from "./stadiums/StadiumOverviewPage";
import { TeamOverviewPage } from "./teams/TeamOverviewPage";
import { AllUsersPage } from "./users/AllUsersPage";
import { UserAccessPage } from "./users/UserAccessPage";
import { UserOverviewPage } from "./users/UserOverviewPage";
import { UserOverviewPageOld } from "./users/UserOverviewPageOld";
import { UsersByTeamPage } from "./users/UsersByTeamPage";
import { IntegrationsPage } from "./integrations/IntegrationsPage";
import { CloverConnectPage } from "./integrations/CloverConnectPage";

export const Routing = () => {
  return (
    <>
      <Switch>
        <PrivateRoute path="/" exact component={RootPage} />
        <Route path={authRoutes.logIn} exact component={LoginPage} />
        <PrivateRoute
          path={sponsorRoutes.sponsors}
          exact
          component={SponsorsPage}
        />
        <PrivateRoute
          path={sponsorRoutes.sponsorOverview()}
          exact
          component={SponsorOverviewPage}
        />
        <PrivateRoute
          path={sponsorRoutes.sponsorStadiums()}
          exact
          component={SponsorStadiumsPage}
        />
        <PrivateRoute
          path={sponsorRoutes.sponsorStadiumOverview()}
          exact
          component={SponsorStadiumOverviewPage}
        />
        <PrivateRoute
          path={sponsorRoutes.sponsorGameOverview()}
          exact
          component={SponsorGameOverviewPage}
        />
        <PrivateRoute
          path={sponsorRoutes.sponsorGameOverview()}
          exact
          component={SponsorGameOverviewPage}
        />

        <PrivateRoute
          path={teamRoutes.teamOverview()}
          exact
          component={TeamOverviewPage}
        />

        <PrivateRoute
          path={marketplaceRoutes.marketplaceOverview()}
          exact
          component={MarketplaceOverviewPage}
        />

        <PrivateRoute
          path={marketplaceRoutes.productDetail()}
          exact
          component={MarketplaceProductPage}
        />

        <PrivateRoute
          path={marketplaceRoutes.sweepstakeDetail()}
          exact
          component={MarketplaceSweepstakePage}
        />

        <PrivateRoute
          path={marketplaceRoutes.auctionDetail()}
          exact
          component={MarketplaceAuctionPage}
        />

        {/* <PrivateRoute path={stadiumRoutes.stadiums} exact component={StadiumsPage} /> */}
        <PrivateRoute
          path={stadiumRoutes.stadiumOverview()}
          exact
          component={StadiumOverviewPage}
        />
        <PrivateRoute
          path={stadiumRoutes.stadiumGames()}
          exact
          component={StadiumGamesPage}
        />
        <PrivateRoute
          path={stadiumRoutes.stadiumGameOverview()}
          exact
          component={StadiumGameOverviewPage}
        />

        <PrivateRoute path={usersRoutes.users} exact component={AllUsersPage} />
        <PrivateRoute
          path={usersRoutes.user()}
          exact
          component={UserOverviewPageOld}
        />
        <PrivateRoute
          path={usersRoutes.userBySponsor()}
          exact
          component={UserOverviewPageOld}
        />
        <PrivateRoute
          path={usersRoutes.userByStadium()}
          exact
          component={UserOverviewPageOld}
        />
        <PrivateRoute
          path={usersRoutes.userByStadiumAndGame()}
          exact
          component={UserOverviewPageOld}
        />
        <PrivateRoute
          path={usersRoutes.userByTeam()}
          exact
          component={UserOverviewPageOld}
        />
        <PrivateRoute
          path={usersRoutes.usersByTeam()}
          exact
          component={UsersByTeamPage}
        />
        <PrivateRoute
          path={usersRoutes.userOverview()}
          exact
          component={UserOverviewPage}
        />

        <PrivateRoute
          path={usersAccessRoutes.userAccesses}
          exact
          component={UserAccessPage}
        />

        <PrivateRoute
          path={orderRoutes.ordersByTeam()}
          exact
          component={OrdersByTeamPage}
        />
        <PrivateRoute
          path={orderRoutes.orderDetails()}
          exact
          component={OrderDetailsPage}
        />
        <PrivateRoute
          path={orderRoutes.pickups()}
          exact
          component={PickupsByTeamPage}
        />
        <PrivateRoute
          path={orderRoutes.pickupEdit()}
          exact
          component={PickupEditPage}
        />

        <PrivateRoute
          path={notificationRoutes.notificationsByTeam()}
          exact
          component={NotificationsByTeamPage}
        />
        <PrivateRoute
          path={notificationRoutes.notificationDetails()}
          exact
          component={NotificationDetailsPage}
        />

        <PrivateRoute
          path={loyaltyRoutes.loyalties}
          exact
          component={LoyaltiesPage}
        />
        <PrivateRoute
          path={loyaltyRoutes.newLoyalty}
          exact
          component={LoyaltyEditPage}
        />
        <PrivateRoute
          path={loyaltyRoutes.loyaltyCategories}
          exact
          component={LoyaltyCategoryFilterPage}
        />
        <PrivateRoute
          path={loyaltyRoutes.categoryAnalytics()}
          exact
          component={LoyaltiesCategoryPage}
        />
        <PrivateRoute
          path={loyaltyRoutes.editLoyalty()}
          exact
          component={LoyaltyEditPage}
        />
        <PrivateRoute
          path={loyaltyRoutes.detailsLoyalty()}
          exact
          component={LoyaltyDetailsPage}
        />

        <PrivateRoute
          path={questionsRoutes.questions}
          exact
          component={QuestionsPage}
        />
        <PrivateRoute
          path={questionsRoutes.newQuestion}
          exact
          component={QuestionOverviewPage}
        />
        <PrivateRoute
          path={questionsRoutes.questionOverview()}
          exact
          component={QuestionOverviewPage}
        />

        <PrivateRoute path={gamesRoutes.games} exact component={GamesPage} />
        <PrivateRoute
          path={gamesRoutes.gameplay()}
          exact
          component={GameplayPage}
        />
        <PrivateRoute
          path={gamesRoutes.gameplaySend()}
          exact
          component={GameplaySendPage}
        />

        <PrivateRoute path={integrationsRoutes.integrationsForTeam()} exact component={IntegrationsPage} />
        <PrivateRoute path={integrationsRoutes.cloverForTeam()} exact component={GamesPage} />
        <PrivateRoute path={integrationsRoutes.cloverConnectForTeam} exact component={CloverConnectPage} />

        <Route path="*">
          <NoMatchPage />
        </Route>
      </Switch>
    </>
  );
};
