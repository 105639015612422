import { Box } from "@material-ui/core";
import React from "react";
import { SortableTable } from "../../../components/SortableTable";
import { TableContainer } from "../../../components/TableContainer";

interface Props {
  games: any[];
  height: string;
}

export const UserGamesPlayedTable: React.FC<Props> = ({ games, height }) => {
  let body = (
    <SortableTable
      title={`Games Played`}
      height={height}
      defaultSort="gameTime"
      columns={[
        {
          dataKey: "gameName",
          label: "Game",
          width: 80,
          flexGrow: 5,
        },
        {
          dataKey: "gameTime",
          label: "Date",
          flexGrow: 1,
          width: 40,
        },
      ]}
      rows={games}
      // @ts-ignore
    />
  );
  return <TableContainer>{body}</TableContainer>;
};
