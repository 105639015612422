import { RouterStore } from "@ibm/mobx-react-router";
import { UiStore } from "../stores/ui/ui-store";

export class RootStore {
  public routerStore: RouterStore;
  public ui: UiStore;

  constructor(routerStore: RouterStore) {
    this.routerStore = routerStore;
    this.ui = new UiStore(routerStore);
  }
}
