import { Box, Typography } from "@material-ui/core";
import React from "react";
import { ButtonLink } from "../components/ButtonLink";

export const NoMatchPage = () => {
  return (
    <Box
      height="100vh"
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography color="primary" component="div" variant="h1">
        <Box fontWeight="bold">404</Box>
      </Typography>
      <Typography color="secondary" component="div" variant="h5">
        Page is not found
      </Typography>
      <Box mt={4}>
        <ButtonLink variant="text" to="/">
          Back to home page
        </ButtonLink>
      </Box>
    </Box>
  );
};
