import { makeAutoObservable } from "mobx";
import { Loading } from "../../helpers/loading";
import { spacesToBackslash } from "../../helpers/name-helpers";
import { SponsorStadium } from "../../types/sponsors-types";
import { API } from "../connectors/api-connector";
import { UiStore } from "./ui-store";
import { sponsorRoutes } from "../../app-routes";
import { FirebaseConnector } from "../connectors/firebase-connector";

export class SponsorStadiumsPageStore {
  private searchInput: string = "";
  public _stadiums: SponsorStadium[] = [];
  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get stadiums() {
    return this._stadiums.filter(({ stadiumName }) =>
      stadiumName.includes(this.searchInput)
    );
  }

  searchStadium = (input: string) => {
    this.searchInput = input;
  };

  onStadiumClick = (sponsorName: string, stadium: SponsorStadium) => {
    this.uiStore.routerStore.push(
      sponsorRoutes.sponsorStadiumOverview(sponsorName, stadium.stadiumName)
    );
  };

  loadStadiums = async (sponsorName: string) => {
    this.loading = Loading.LOADING;
    try {
      const stadiums = await API.post<{ data: { stadiums: SponsorStadium[] } }>(
        "/stadiums",
        {
          data: { userId: spacesToBackslash(sponsorName) },
        }
      );
      this._stadiums = stadiums.data.data.stadiums;
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
