export interface OrderOverview {
  [key: string]: any;
  id: string;
  orderId: string;
  userId: string;
  productId: string;
  seasonId: string;
  variantGenKey: string;
  userFullName: string;
  walletConversionEmail: string;
  walletConversionFirstName: string;
  walletConversionLastName: string;
  pointsConverted: string;
  walletCurrencyAmount: string;
  addressName: string;
  email: string;
  productName: string;
  variantName: string;
  firstVariantName: string;
  orderTotal: number;
  orderItemCount: number;
  status: string;
  statusName: string;
  orderDate: string;
  formattedOrderDate: string;
  friendlyId: string;
  trackingNumber: string;
  imageUrl: string;
  pickupId: string;
  pickupName: string;
  pickupDate: string;
}

export const orderOverviewDefaults = (): OrderOverview => ({
  id: "",
  orderId: "",
  userId: "",
  productId: "",
  seasonId: "",
  variantGenKey: "",
  userFullName: "",
  walletConversionEmail: "",

  walletConversionFirstName: "",
  walletConversionLastName: "",
  pointsConverted: "",
  walletCurrencyAmount: "",
  addressName: "",
  email: "",
  productName: "",
  variantName: "",
  firstVariantName: "",
  orderTotal: 0,
  orderItemCount: 0,
  status: "",
  statusName: "",
  orderDate: "",
  formattedOrderDate: "",
  friendlyId: "",
  trackingNumber: "",
  imageUrl: "",
  pickupId: "",
  pickupName: "",
  pickupDate: "",
});
export interface PickupOverview {
  [key: string]: any;
  id: string;
  pickupId: string;
  pickupDate: string;
  formattedPickupDate: string;
  pickupStart: string;
  pickupEnd: string;
  pickupLocation: string;
  pickupAdditionalDetails: string;
}

export const pickupOverviewDefaults = (): PickupOverview => ({
  id: "",
  pickupId: "",
  pickupDate: "",
  formattedPickupDate: "",
  pickupStart: "",
  pickupEnd: "",
  pickupLocation: "",
  pickupAdditionalDetails: "",
});
