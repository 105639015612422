import { makeAutoObservable } from "mobx";
import moment from "moment";
import { getAverageData } from "../../helpers/chart-helpers";
import {
  alertErrorMessageFromAllTruthy,
  combinedObject,
  errorMessageFromAllTruthy,
} from "../../helpers/generic-helpers";
import { Loading, loadingFunction } from "../../helpers/loading";
import { UserOverview, userOverviewDefaults } from "../../types/user-types";
import {
  convertTruesToOnes,
  getAttributeFromObject,
} from "../../types/util-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";
import { formatDate } from "../../helpers/time-helpers";

export class UserOverviewPageStore {
  public userOverview: UserOverview = userOverviewDefaults();
  public userEngagementDetails: any = {};
  public loading: Loading = Loading.NOT_LOADED;
  public seasonId: string = "";
  public teamDetails: any = {};
  public engagementDataByTimeframe: any = {};
  // public engagementRate: string = "";
  private allGames: any = {};
  private gamesAttended: any = {};
  private checkInViews: any = {};
  private checkInAttends: any = {};
  private checkInScanningAttendsCount: any = {};
  private checkInScanningAttendsCountWithGuests: any = {};
  private orders: any[] = [];
  private rewards: any[] = [];
  private transactions: any[] = [];
  private expiringTransactions: any[] = [];
  public isFrozen: boolean = false;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  loadCurrentSeasonId = async (teamId: string) => {
    this.seasonId = await this.firebaseConnector.getCurrentSeasonId(teamId);
  };

  loadUserOverview = async (teamId: string, userId: string) => {
    console.log(`loadUserOverview`);
    loadingFunction(
      this,
      async () => {
        await this.loadCurrentSeasonId(teamId);
        this.userOverview = await this.firebaseConnector.loadUserOverview(
          userId,
          teamId,
          "",
        );
        this.teamDetails = await this.firebaseConnector.getDatabaseObjAtPath(
          `teams/${teamId}`,
        );
        this.allGames = combinedObject(
          await this.firebaseConnector.getDatabaseObjAtPath(
            `gamesByTeam/${teamId}`,
          ),
          await this.firebaseConnector.getDatabaseObjAtPath(`games`),
        );
        // this.allGames = await this.firebaseConnector.getDatabaseObjAtPath(
        //   `games`
        // );
        this.gamesAttended = await this.firebaseConnector.getDatabaseObjAtPath(
          `tracking/gameIdsAttendedByUserByTeam/${teamId}/${userId}`,
        );
        this.orders = await this.firebaseConnector.loadOrdersFromUser(
          userId,
          teamId,
          this.userOverview.orders,
        );
        this.rewards = await this.firebaseConnector.loadRewardsFromUser(
          userId,
          teamId,
          // this.userOverview.rewards
        );
        this.transactions =
          await this.firebaseConnector.loadTransactionsFromUser(userId, teamId);
        this.expiringTransactions =
          await this.firebaseConnector.loadExpiringTransactionsFromUser(
            userId,
            teamId,
          );
        this.checkInViews = await this.firebaseConnector.getDatabaseObjAtPath(
          `tracking/nonSeasonalCheckInViewsByDateByUserIdByTeam/${teamId}/${userId}`,
        );
        this.checkInAttends = await this.firebaseConnector.getDatabaseObjAtPath(
          `tracking/nonSeasonalCheckInAttendsByDateByUserIdByTeam/${teamId}/${userId}`,
        );
        this.checkInScanningAttendsCount =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/nonSeasonalCheckInScanningAttendsByDateByUserIdByTeam/${teamId}/${userId}`,
          );
        this.checkInScanningAttendsCountWithGuests =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/nonSeasonalCheckInScanningAttendsByDateByUserIdByTeamWithGuests/${teamId}/${userId}`,
          );
        this.userEngagementDetails =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/engagementStatsByUserIdByTeam/${teamId}/${userId}`,
          )) || {};
        this.engagementDataByTimeframe["daily"] = convertTruesToOnes(
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/dailyUserActivityByUserIdByTeam/${teamId}/${userId}`,
          ),
        );
        //We don't actually use these
        this.engagementDataByTimeframe["weekly"] = {};
        this.engagementDataByTimeframe["monthly"] = {};
        // this.engagementDataByTimeframe["weekly"] = convertTruesToOnes(await this.firebaseConnector.getDatabaseObjAtPath(`tracking/weeklyUserActivityByUserIdByTeam/${teamId}/${userId}`,));
        // this.engagementDataByTimeframe["monthly"] = convertTruesToOnes(await this.firebaseConnector.getDatabaseObjAtPath(`tracking/monthlyUserActivityByUserIdByTeam/${teamId}/${userId}`,));
        this.isFrozen =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `frozenAccountsByUserId/${teamId}/${userId}`,
          )) == true;
      },
      this.uiStore.notifications,
    );
  };

  sendNotification = async (
    teamId: string,
    userId: string,
    title: string,
    body: string,
  ) => {
    let teamUserNotificationPath = `notificationsByTeamByUser/${teamId}/${userId}`;
    let notificationId = await this.firebaseConnector.getKeyFromPushedPath(
      teamUserNotificationPath,
    );
    let errorMessage = errorMessageFromAllTruthy({
      userId,
      teamId,
      title,
      body,
      notificationId,
    });
    if (errorMessage) {
      console.log(`${errorMessage}`);
      return;
    }
    this.firebaseConnector.setDatabasePathAsObj(
      `${teamUserNotificationPath}/${notificationId}`,
      {
        timestamp: moment().toISOString(),
        title: title,
        body: body,
      },
    );
  };

  editUserDetails = async (
    teamId: string,
    userId: string,
    attribute: any,
    newVal: any,
  ) => {
    if (alertErrorMessageFromAllTruthy({ teamId, userId, attribute, newVal }))
      return;
    this.firebaseConnector.setDatabasePathAsObj(
      `userDetailsByUserIdByTeam/${teamId}/${userId}/${attribute}`,
      newVal,
    );
  };

  freezeAccount = async (teamId: string, userId: string, isFrozen: boolean) => {
    this.firebaseConnector.setDatabasePathAsObj(
      `frozenAccountsByUserId/${teamId}/${userId}`,
      isFrozen,
    );
    this.isFrozen = isFrozen;
  };

  setLoyaltyPointsTransaction = async (
    userId: string,
    teamId: string,
    seasonId: string,
    transaction: any,
  ) => {
    this.firebaseConnector.setLoyaltyPointsTransaction(
      userId,
      teamId,
      seasonId,
      transaction,
    );
  };

  get pointsEarnedData() {
    let dataByWeekday = getAverageData(
      this.userOverview.totalPointsEarnedByWeekday,
      this.userOverview.totalPointsEarnedWeekdaysSeen,
    );
    let dataByMonth = getAverageData(
      this.userOverview.totalPointsEarnedByMonth,
      this.userOverview.totalPointsEarnedMonthsSeen,
    );
    return [
      dataByWeekday,
      dataByMonth,
      this.userOverview.totalPointsEarnedByDate,
      this.userOverview.totalPointsEarnedByMonthAndYear,
    ];
  }

  get dailyRewardsData() {
    let dataByWeekday = getAverageData(
      this.userOverview.dailyRewardRedemptionsByWeekday,
      this.userOverview.dailyRewardRedemptionsWeekdaysSeen,
    );
    let dataByMonth = getAverageData(
      this.userOverview.dailyRewardRedemptionsByMonth,
      this.userOverview.dailyRewardRedemptionsMonthsSeen,
    );
    return [
      dataByWeekday,
      dataByMonth,
      this.userOverview.dailyRewardRedemptionsByDate,
      this.userOverview.dailyRewardRedemptionsByMonthAndYear,
    ];
  }

  get dailyWeeklyMonthlyEngagementData() {
    return [this.engagementDataByTimeframe];
  }

  get gamesAttendedData() {
    let gameIds = Object.keys(this.gamesAttended || {});
    let allGames = this.allGames;
    let result: any = [];
    gameIds.forEach((gameId) => {
      let gameName = getAttributeFromObject(`${gameId}/gameSubtext`, allGames);
      let gameTime = getAttributeFromObject(`${gameId}/date`, allGames);
      if (!gameName)
        gameName = getAttributeFromObject(`${gameId}/subtext`, allGames);
      if (!gameTime)
        gameTime = getAttributeFromObject(`${gameId}/startTime`, allGames);
      gameTime = formatDate(gameTime);
      console.log(`pushing ${gameName}, ${gameTime}`);
      result.push({ gameName, gameTime });
    });
    return result;
  }

  // get checkInData() {
  //   return [
  //     this.userOverview.checkInAttendsByDate,
  //     this.userOverview.checkInViewsByDate,
  //   ];
  // }

  get dailyWeeklyMonthlyCheckInData() {
    return [
      {
        daily: this.checkInAttends,
        weekly: {},
        monthly: {},
      },
      {
        daily: this.checkInViews,
        weekly: {},
        monthly: {},
      },
    ];
  }

  get dailyWeeklyMonthlyScanningCheckInData() {
    return [
      {
        daily: this.checkInScanningAttendsCount,
        weekly: {},
        monthly: {},
      },
      {
        daily: this.checkInScanningAttendsCountWithGuests,
        weekly: {},
        monthly: {},
      },
    ];
  }

  get transactionsData() {
    return this.transactions;
  }

  get expiringTransactionsData() {
    return this.expiringTransactions;
  }

  get rewardData() {
    return this.rewards;
  }

  get orderData() {
    return this.orders;
  }
}
