import axios, { AxiosRequestConfig } from "axios";

const API_CONFIG: AxiosRequestConfig = {
  baseURL: `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net`,
  timeout: 200000,
  transformResponse: [
    (data) => {
      return JSON.parse(data);
    },
  ],
  headers: {
    "Content-Type": "application/json",
  },
};

export const API = axios.create(API_CONFIG);
