import React from "react";
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MainLayout } from "../../layouts/MainLayout";
import { useStore } from "../../helpers/use-store";
import { observer } from "mobx-react-lite";
import { PageTitle } from "../../components/PageTitle";
import { CrowdSelect } from "../../components/CrowdSelect";
import {
  coreTeamRoles,
  SessionUserType,
  UserAccessesForm,
} from "../../types/user-types";
import { CrowdAutocomplete } from "../../components/CrowdAutoComplete";
import { Loading } from "../../helpers/loading";

const useStyles = makeStyles((theme) => ({
  auto_input: {
    width: "100%",
  },
  user_title: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(28),
  },
  data_value: {
    color: theme.palette.primary.main,
  },
}));

const validationSchema = Yup.object().shape({
  userId: Yup.string().min(20, "UserID is not valid").required(),
  role: Yup.mixed().oneOf([
    null,
    SessionUserType.ADMIN,
    SessionUserType.SPONSOR,
    SessionUserType.STADIUM,
    SessionUserType.CPTEAMADMIN,
    SessionUserType.ORDERS,
  ]),
  stadium: Yup.string().when("role", {
    is: (role: SessionUserType) => coreTeamRoles.includes(role),
    then: Yup.string()
      .min(1, "Field is required")
      .required("Field is required"),
    otherwise: Yup.string().nullable(),
  }),
  sponsor: Yup.string().when("role", {
    is: (role: SessionUserType) => role === SessionUserType.SPONSOR, //just an e.g. you can return a function
    then: Yup.string()
      .min(1, "Field is required")
      .required("Field is required"),
    otherwise: Yup.string().nullable(),
  }),
  team: Yup.string().nullable(),
});

const NO_ACCESSES_OPTION = "NO ACCESSES";

interface Props {
  formValues: UserAccessesForm;
  onSubmit: (formValues: UserAccessesForm) => void;
}

export const UserAccessPage: React.FC<Props> = observer(() => {
  const { userAccessPage } = useStore().ui;
  const { userAccesses, userAccessesForm, grandAccess } = userAccessPage;

  const formik = useFormik<UserAccessesForm>({
    initialValues: userAccessesForm,
    enableReinitialize: true,
    validationSchema,
    onSubmit: grandAccess,
  });

  React.useEffect(() => {
    userAccessPage.loadDictionaries();
  }, [userAccessPage]);

  React.useEffect(() => {
    if (!formik.errors.userId && formik.touched.userId) {
      userAccessPage.loadUser(formik.values.userId);
    }
  }, [
    formik.errors.userId,
    formik.touched.userId,
    formik.values.userId,
    userAccessPage,
  ]);

  const onAutoSelectChange = (field: string, value: string | null) => {
    if (field === "role") {
      formik.setFieldValue("stadium", null, false);
      formik.setFieldValue("sponsor", null, false);
      formik.setFieldValue("team", null, false);
      formik.setFieldValue(
        field,
        value === NO_ACCESSES_OPTION ? null : value,
        true,
      );
    } else {
      formik.setFieldValue(field, value, true);
    }
  };

  const classes = useStyles();
  let options: any[] = [];
  [
    NO_ACCESSES_OPTION,
    SessionUserType.ADMIN,
    SessionUserType.ORDERS,
    SessionUserType.SPONSOR,
    SessionUserType.STADIUM,
    SessionUserType.CPTEAMADMIN,
  ].forEach((title) => {
    let label = title;
    if (title === SessionUserType.STADIUM) {
      label = "EXTERNAL TEAM";
    } else if (title === SessionUserType.CPTEAMADMIN) {
      label = "CP TEAM ADMIN";
    }
    options.push({ value: title, label: label });
  });
  console.log(`formike errors ${JSON.stringify(formik.errors)}`);
  console.log(
    `!formik.isValid || !formik.dirty ${!formik.isValid}, ${!formik.dirty}`,
  );
  return (
    <MainLayout
      loading={userAccessPage.loading}
      firebaseConnector={userAccessPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <PageTitle title="Grant Access" />
        </Box>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box width="40%" marginBottom={5}>
            <TextField
              className={classes.auto_input}
              name="userId"
              label="User ID"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.userId}
              error={
                (!!formik.touched.userId && !!formik.errors.userId) ||
                userAccessPage.loading === Loading.ERROR
              }
              helperText={formik.errors.userId || userAccessPage.error}
            />
          </Box>
          {!!userAccessPage.userAccesses.userId && (
            <>
              <Box
                width="40%"
                display="flex"
                flexDirection="column"
                marginBottom={5}
              >
                <Box className={classes.user_title} marginBottom={3}>
                  User Data
                </Box>
                <Box
                  fontSize={18}
                  display="flex"
                  fontWeight="bold"
                  marginBottom={2}
                >
                  <Box width="6rem">Full Name: </Box>
                  <Typography
                    component="div"
                    variant="subtitle1"
                    color="primary"
                  >
                    <Box fontWeight="bold">{userAccesses.fullName}</Box>
                  </Typography>
                </Box>
                <Box fontSize={18} display="flex" fontWeight="bold">
                  <Box width="6rem">Email: </Box>
                  <Typography
                    component="div"
                    variant="subtitle1"
                    color="primary"
                  >
                    <Box fontWeight="bold">{userAccesses.email}</Box>
                  </Typography>
                </Box>
              </Box>
              <Box width="40%" marginBottom={5}>
                <CrowdSelect
                  className={classes.auto_input}
                  selected={formik.values.role || NO_ACCESSES_OPTION}
                  options={options}
                  // optionsWithMatchingLabelAndValue={[
                  //   NO_ACCESSES_OPTION,
                  //   SessionUserType.ADMIN,
                  //   SessionUserType.ORDERS,
                  //   SessionUserType.SPONSOR,
                  //   SessionUserType.STADIUM,
                  //   SessionUserType.CPTEAMADMIN,
                  // ]}
                  onChange={(value) => {
                    onAutoSelectChange("role", value);
                  }}
                  defaultInput
                />
              </Box>
            </>
          )}
          {formik.values.role === SessionUserType.SPONSOR && (
            <Box width="40%" marginBottom={5}>
              <CrowdAutocomplete
                className={classes.auto_input}
                label="Select sponsor"
                options={userAccessPage.sponsors}
                onChange={(value) => {
                  onAutoSelectChange("sponsor", value);
                }}
                value={formik.values.sponsor}
                onBlur={formik.handleBlur}
              />
            </Box>
          )}
          {coreTeamRoles.includes(formik.values.role!) && (
            <>
              <Box width="40%" marginBottom={5}>
                <CrowdAutocomplete
                  label="Select stadium"
                  options={userAccessPage.stadiums}
                  onChange={(value) => {
                    onAutoSelectChange("stadium", value);
                  }}
                  value={formik.values.stadium}
                  onBlur={formik.handleBlur}
                />
              </Box>
              {!!formik.values.stadium && (
                <Box width="40%" marginBottom={5}>
                  <CrowdAutocomplete
                    label="Select team"
                    options={userAccessPage.teams}
                    onChange={(value) => {
                      onAutoSelectChange("team", value);
                    }}
                    value={formik.values.team}
                    onBlur={formik.handleBlur}
                  />
                </Box>
              )}
            </>
          )}
          {formik.values.role === SessionUserType.ORDERS && (
            <>
              <Box width="40%" marginBottom={5}>
                <CrowdAutocomplete
                  label="Select team"
                  options={userAccessPage.teams}
                  onChange={(value) => {
                    onAutoSelectChange("team", value);
                  }}
                  value={formik.values.team}
                  onBlur={formik.handleBlur}
                />
              </Box>
            </>
          )}
          {!!userAccessPage.userAccesses.userId && (
            <Box width="40%">
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={!formik.isValid || !formik.dirty}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      </form>
    </MainLayout>
  );
});
