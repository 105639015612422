import { SessionUserType } from "../types/user-types";

export const infinitySymbol: string = "∞";
export const allOptionValue: string = "all";
export const allOption = { label: "All", value: allOptionValue };
export const allSeasonsOption: any = {
  label: "All Seasons",
  value: allOptionValue,
};
export const allPickupsOption: any = {
  label: "All Pickups",
  value: allOptionValue,
};
export const globalTestingString = "";
// export const globalTestingString = 'testing/';
export const inProgressString = "In Progress...";
export const defaultDicAges = {
  "15 and Under": 0,
  "16-18": 0,
  "19-24": 0,
  "25-34": 0,
  "35-44": 0,
  "45-54": 0,
  "55-64": 0,
  "65+": 0,
};
export const defaultDicGenders = {
  Male: 0,
  Female: 0,
  Other: 0,
};

export const cpGray77 = "#777777";
export const cpGrayD7 = "#D7D7D7";
export const cpGrayF7 = "#F7F7F7";
export const halfStandardDashboardPadding = "10px";
export const standardDashboardPadding = "20px";
// export const standardCardPaddingPercentage = 2;
export const cpLargeCardPadding = "24px";
export const cpLargeCardHeight = "460px";
export const cpMediumCardHeight = "320px";
export const cpSmallCardHeight = "140px";

export const cpPositionedStyle: any = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};
export const cpCardBoxShadow: any = "none";
export const cpBoldMainHeaderStyle: any = {
  fontSize: "32px",
  fontWeight: "bolder",
};
export const cpBoxHeaderStyle: any = {
  fontSize: "22px",
  fontWeight: "bolder",
};
export const cpBoxSubHeaderStyle: any = {
  fontSize: "12px",
  fontWeight: "bolder",
};
export const cpPageTitleStyle: any = { fontSize: "22px", fontWeight: "700" };
export const cpBoldSubHeaderStyle: any = {
  fontSize: "14px",
  fontWeight: "bolder",
  color: "rgba(119, 119, 119, 1)",
};
export const cpNonexistentStyle: any = {
  display: "none",
};
export const cpInvisibleStyle: any = {
  display: "hidden",
};
export const cpInputStyle: any = {
  borderRadius: 4,
  backgroundColor: "white",
  border: "1px solid #ced4da",
  fontSize: 16,
  // transition: theme.transitions.create(["border-color", "box-shadow"]),
  // Use the system font instead of the default Roboto font.
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
};
export const cpInputHeight = "35px";

export const attendingLabel = "Attending";
export const watchingLabel = "Watching";
export const membersOnlyLabel = "Members";
export const withGuestsLabel = "Members+Guests";

export const dashboardColor1 = "cD1";
export const dashboardColor2 = "cD2";
export const dashboardColor3 = "cD3";
export const dashboardColor4 = "cD4";
export const dashboardColor5 = "cD5";
export const dashboardColor6 = "cD6";

export const cpBlue = "#56BCF0";
export const cpDarkBlue = "#0C243A";

export const teamDashboardIndex = 0;
export const marketplaceIndex = 1;
export const gamesIndex = 2;
export const membersIndex = 3;
export const activeRewardsIndex = 4;
export const ordersIndex = 5;
export const notificationsIndex = 6;
export const sponsorDashboardIndex = 7;
export const sponsorsIndex = 8;
export const grantUserAccessIndex = 9;
export const integrationsIndex = 10;

export const allowedIndexByRole: any = {
  [SessionUserType.CPTEAMADMIN]: [
    teamDashboardIndex,
    // marketplaceIndex,
    gamesIndex,
    membersIndex,
    activeRewardsIndex,
    ordersIndex,
    notificationsIndex,
    integrationsIndex,
  ],
  [SessionUserType.ADMIN]: [
    teamDashboardIndex,
    marketplaceIndex,
    gamesIndex,
    membersIndex,
    activeRewardsIndex,
    ordersIndex,
    notificationsIndex,
    grantUserAccessIndex,
    integrationsIndex,
  ],
  [SessionUserType.SPONSOR]: [sponsorDashboardIndex, sponsorsIndex],
  [SessionUserType.STADIUM]: [
    teamDashboardIndex,
    marketplaceIndex,
    gamesIndex,
    membersIndex,
    ordersIndex,
  ],
  [SessionUserType.ORDERS]: [membersIndex, ordersIndex],
};
