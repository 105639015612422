import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import { DataCard } from "./DataCard";

const useStyles = makeStyles((theme) => ({
  card_block: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  card: {
    flexBasis: "22%",
    flex: "1 0 auto",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      flexBasis: "45%",
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      flexBasis: "98%",
      marginRight: 0,
    },
    height: "150px",
  },
}));

interface Props {
  className?: string;
  items: {
    title: string;
    value: string | number;
  }[];
}

export const DataCardBlock: React.FC<Props> = ({ className, items = [] }) => {
  const classes = useStyles();
  return (
    <Box
      width="100%"
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      className={clsx(classes.card_block, className)}
    >
      {items.map((item) => (
        <DataCard className={classes.card} key={item.title} {...item} />
      ))}
    </Box>
  );
};
