import { makeAutoObservable } from "mobx";
import { Loading, loadingFunction } from "../../helpers/loading";
import { OrderOverview, orderOverviewDefaults } from "../../types/order-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class OrderDetailsPageStore {
  public orderOverview: OrderOverview = orderOverviewDefaults();
  public loading: Loading = Loading.NOT_LOADED;
  private _order: OrderOverview = orderOverviewDefaults();
  public seasonId: string = "";

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  loadOrder = async (teamId: string, userId: string, orderId: string) => {
    loadingFunction(
      this,
      async () => {
        this._order = await this.firebaseConnector.loadOrder(
          teamId,
          userId,
          orderId
        );
      },
      this.uiStore.notifications
    );
  };

  setOrderStatus = async (
    teamId: string,
    userId: string,
    orderId: string,
    newStatus: Number
  ) => {
    this.firebaseConnector.setOrderStatus(teamId, userId, orderId, newStatus);
    this._order.status = `${newStatus}`;
  };

  get order() {
    return this._order;
  }
}
