import { makeAutoObservable } from "mobx";
import { Loading } from "../../helpers/loading";
import { spacesToBackslash } from "../../helpers/name-helpers";
import { formatDuration } from "../../helpers/time-helpers";
import {
  SponsorGame,
  SponsorStadiumResponse,
  sponsorStadiumResponseDefaults,
  SponsorStadiumCategory,
  SponsorStadiumGame,
} from "../../types/sponsors-types";
import { API } from "../connectors/api-connector";
import { UiStore } from "./ui-store";
import { sponsorRoutes } from "../../app-routes";
import { FirebaseConnector } from "../connectors/firebase-connector";

export class SponsorStadiumOverviewPageStore {
  private stadiumResponse: SponsorStadiumResponse =
    sponsorStadiumResponseDefaults();
  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get dataBlock() {
    const { nGameSpon, nTotalImp, timeView, nReached } = this.stadiumResponse;
    return {
      items: [
        { title: "Games Sponsored", value: nGameSpon },
        { title: "Total Impressions", value: nTotalImp },
        {
          title: "Average Impressions",
          value: Math.round(nTotalImp / (nReached || 1)),
        },
        { title: "Total Unique Viewers", value: nReached },
        { title: "Average Ad View Time", value: formatDuration(timeView) },
      ],
    };
  }

  get genderStats() {
    const { genders } = this.stadiumResponse;
    const genderUsers = Object.values(genders).reduce((p, n) => p + n, 0);
    return {
      male: Math.round((genders.male / genderUsers) * 100),
      female: Math.round((genders.female / genderUsers) * 100),
      preferNotSay: Math.round((genders.other / genderUsers) * 100),
      maleAmount: genders.male,
      femaleAmount: genders.female,
      preferNotSayAmount: genders.other,
    };
  }

  get demographicStats() {
    return Object.keys(this.stadiumResponse.ages)
      .sort((a, b) => {
        const aa = a.replace(/[^0-9 ]/g, "").slice(0, 2);
        const bb = b.replace(/[^0-9 ]/g, "").slice(0, 2);
        return Number(aa) - Number(bb);
      })
      .map((key) => {
        return {
          name: key,
          amount: this.stadiumResponse.ages[key],
        };
      });
  }

  get categories(): SponsorStadiumCategory[] {
    return this.stadiumResponse.categories;
  }

  get games(): SponsorStadiumGame[] {
    return this.stadiumResponse.games;
  }

  onGameRowClick = (
    sponsorName: string,
    stadiumName: string,
    game: SponsorGame
  ) => {
    this.uiStore.routerStore.push(
      sponsorRoutes.sponsorGameOverview(sponsorName, stadiumName, game.id)
    );
  };

  loadStadiumOverview = async (sponsorName: string, stadiumName: string) => {
    try {
      this.loading = Loading.LOADING;
      const response = await API.post<{ data: SponsorStadiumResponse }>(
        "/stadium",
        {
          data: {
            stadiumId: stadiumName,
            userId: spacesToBackslash(sponsorName),
          },
        }
      );
      this.stadiumResponse = response.data.data;
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
