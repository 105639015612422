import React from "react";
import { TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { PageTitle } from "../../components/PageTitle";
import { gamesRoutes } from "../../app-routes";
import { ButtonLink } from "../../components/ButtonLink";
import { GamesTable } from "./components/GamesTable";

export const GamesPage = observer(() => {
  const { gamesPage } = useStore().ui;

  React.useEffect(() => {
    gamesPage.loadGames();
    gamesPage.loadTeams();
  }, [gamesPage]);

  const onSearch: React.ChangeEventHandler<{ value: string }> =
    React.useCallback((e) => gamesPage.searchGame(e.target.value), [gamesPage]);

  return (
    <MainLayout
      loading={gamesPage.loading}
      firebaseConnector={gamesPage.firebaseConnector}
      pageActions={
        <Box display="flex" alignItems="space-between" width="100%">
          <Box display="flex" width="50%">
            <PageTitle title="Games" />
            <Box width={"5%"} />
            <ButtonLink
              variant="contained"
              color="primary"
              to={gamesRoutes.newGame}
            >
              Add a game
            </ButtonLink>
          </Box>
          <Box display="flex" width="50%">
            <TextField
              style={{ width: "100%" }}
              label="Search games..."
              onChange={onSearch}
            />
          </Box>
        </Box>
      }
    >
      <Box height="40rem" width={"100%"}>
        <GamesTable
          games={gamesPage.games}
          onEdit={gamesPage.onEditGame}
          onRun={gamesPage.onRunGame}
        />
      </Box>
    </MainLayout>
  );
});
