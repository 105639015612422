import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import { marketplaceRoutes } from "../../app-routes";
import product_tab_disabled from "../../assets/product_tab_disabled.png";
import product_tab_enabled from "../../assets/product_tab_enabled.png";
import sweepstakes_tab_disabled from "../../assets/sweepstakes_tab_disabled.png";
import sweepstakes_tab_enabled from "../../assets/sweepstakes_tab_enabled.png";
import auctions_tab_disabled from "../../assets/auctions_tab_disabled.png";
import auctions_tab_enabled from "../../assets/auctions_tab_enabled.png";
import { CPDashboardSpacer } from "../../components/CPDashboardSpacer";
import { CPDivider } from "../../components/CPDivider";
import { CPDownloadButton } from "../../components/CPDownloadButton";
import { CPDropdown } from "../../components/CPDropdown";
import { CPHiddenDiv } from "../../components/CPHiddenDiv";
import { CPProductThumbnail } from "../../components/CPProductThumbnail";
import { CPSearchBar } from "../../components/CPSearchBar";
import { CPTabBar } from "../../components/CPTabBar";
import { CustomizableDataCardBlock } from "../../components/CustomizableDataCardBlock";
import {
  allOption,
  cpBoldMainHeaderStyle,
  cpBoldSubHeaderStyle,
  cpBoxSubHeaderStyle,
  cpGrayD7,
  cpSmallCardHeight,
  standardDashboardPadding,
} from "../../helpers/constants";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { RowData } from "../../types/data-table-types";
import { exportAsCSVByRow } from "../../types/util-types";
import { NoMatchPage } from "../NoMatchPage";

const useStyles = makeStyles((theme) => ({
  card: {
    flexBasis: theme.typography.pxToRem(200),
    flex: "1 0 auto",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      flexBasis: theme.typography.pxToRem(280),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface UrlParams {
  teamId: string;
}

export const MarketplaceOverviewPage = observer(() => {
  const urlParams = useParams<UrlParams>();
  const { session } = useStore().ui;
  const { teamId } = useParams<{ teamId: string }>();
  const { user } = useStore().ui.session;
  const team = user?.type?.identifier?.team;
  if (team !== teamId) {
    //Page not found
    return <NoMatchPage />;
  }
  const { marketplaceOverviewPage } = useStore().ui;
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [barBackdropOpen, setBarBackdropOpen] = React.useState<boolean>(false);
  const [barBackdropTitle, setBarBackdropTitle] = React.useState<string>("");
  const [barBackdropUserIds, setBarBackdropUserIds] = React.useState<string[]>(
    []
  );
  const { routerStore } = useStore();

  const classes = useStyles();

  const onSearch: React.ChangeEventHandler<{ value: string }> =
    React.useCallback(
      (e) => marketplaceOverviewPage.searchProduct(e.target.value),
      [marketplaceOverviewPage]
    );

  React.useEffect(() => {
    marketplaceOverviewPage.loadOverview(teamId);
  }, [marketplaceOverviewPage, teamId, urlParams]);

  //Products
  let marketplaceProductTypeOptions =
    marketplaceOverviewPage.marketplaceProductTypeOptions;
  let filteredMarketplaceProducts =
    marketplaceOverviewPage.filteredMarketplaceProducts;
  let selectedProductTypeFilter =
    marketplaceOverviewPage.marketplaceSelectedProductTypeFilter;
  //Sweepstakes

  const goToProduct = (productId: string) => {
    routerStore.push(marketplaceRoutes.productDetail(teamId, productId));
  };

  let productsBody = (
    <div>
      <Box display={"flex"}>
        <Box flex={1}>
          <CustomizableDataCardBlock
            {...marketplaceOverviewPage.productsDataBlock}
            itemsPerRow={4}
            height={cpSmallCardHeight}
          />
        </Box>
      </Box>
      <CPDashboardSpacer />
      <Box display={"flex"}>
        <Box width="250px">
          <CPSearchBar
            label="Search Orders..."
            onSearch={onSearch}
            value={marketplaceOverviewPage.searchInput}
          />
        </Box>
        <CPDashboardSpacer />
        <Box width={"200px"}>
          <CPDropdown
            options={marketplaceProductTypeOptions}
            selectedOptionFilter={selectedProductTypeFilter}
            setSelectedOptionFilter={marketplaceOverviewPage.changeFilter}
            allOption={allOption}
          />
        </Box>
      </Box>
      <CPDashboardSpacer />
      <Box
        width="100%"
        display="flex"
        flexWrap="wrap"
        gridRowGap={"18px"}
        gridColumnGap={"18px"}
        // gridGap={`${standardCardPaddingPercentage}%`}
        //justify content so it all goes to the start as much as possible
        justifyContent={"flex-start"}
        justifyItems={"space-between"}
      >
        {Object.keys(filteredMarketplaceProducts).map((productId: any) => {
          let product = filteredMarketplaceProducts[productId];
          let name = product["name"];
          let imageUrl = (product["imageUrls"] || [""])[0];
          return (
            <Box key={productId} onClick={() => goToProduct(productId)}>
              <CPProductThumbnail
                name={name}
                imageUrl={imageUrl}
                tooltipDetails={product}
              />
            </Box>
          );
          //Return box with image on top, text on bottom. Background color of text on bottom should be transparent, unless hovered over, then it should be black and the text should turn white.
        })}
      </Box>
    </div>
  );
  let sweepstakesBody = (
    <div>
      <Box display={"flex"}>
        <Box flex={1}>
          <CustomizableDataCardBlock
            {...marketplaceOverviewPage.sweepstakesDataBlock}
            itemsPerRow={2}
            height={cpSmallCardHeight}
          />
        </Box>
        <CPDashboardSpacer />
      </Box>
      <Box
        mt={
          marketplaceOverviewPage.liveMarketplaceSweepstakes.length > 0
            ? "20px"
            : "0px"
        }
      >
        <SweepstakeOrAuctionList
          title="Live Sweepstakes"
          type={SweepstakeOrAuctionType.SWEEPSTAKE}
          dataList={marketplaceOverviewPage.liveMarketplaceSweepstakes}
        />
      </Box>
      <CPDashboardSpacer />
      <SweepstakeOrAuctionList
        title="Ended Sweepstakes"
        type={SweepstakeOrAuctionType.SWEEPSTAKE}
        dataList={marketplaceOverviewPage.endedMarketplaceSweepstakes}
      />
      <CPDashboardSpacer />
    </div>
  );
  let auctionsBody = (
    <div>
      <Box flex={1} height={"160px"}>
        <CustomizableDataCardBlock
          {...marketplaceOverviewPage.auctionsDataBlock}
          itemsPerRow={2}
          height={cpSmallCardHeight}
        />
      </Box>
      <SweepstakeOrAuctionList
        title="Live Auctions"
        type={SweepstakeOrAuctionType.AUCTION}
        dataList={marketplaceOverviewPage.liveMarketplaceAuctions}
      />
      {marketplaceOverviewPage.liveMarketplaceAuctions.length > 0 && (
        <CPDashboardSpacer />
      )}
      <SweepstakeOrAuctionList
        title="Ended Auctions"
        type={SweepstakeOrAuctionType.AUCTION}
        dataList={marketplaceOverviewPage.endedMarketplaceAuctions}
      />
      <CPDashboardSpacer />
    </div>
  );

  return (
    <MainLayout
      isOverview={false}
      loading={marketplaceOverviewPage.loading}
      firebaseConnector={marketplaceOverviewPage.firebaseConnector}
      pageActions={
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box display="flex">
              <Box style={cpBoldMainHeaderStyle}>Marketplace</Box>
            </Box>
          </Box>
        </div>
      }
    >
      <CPTabBar
        tabs={[
          {
            label: "Products",
            icon: product_tab_enabled,
            disabled_icon: product_tab_disabled,
          },
          {
            label: "Sweepstakes",
            icon: sweepstakes_tab_enabled,
            disabled_icon: sweepstakes_tab_disabled,
          },
          {
            label: "Auctions",
            icon: auctions_tab_enabled,
            disabled_icon: auctions_tab_disabled,
          },
        ]}
        selected={selectedTab}
        onTabChange={setSelectedTab}
      />
      <Box width={"100%"} height="2px" bgcolor={cpGrayD7}></Box>
      <CPDashboardSpacer />
      <div style={{ position: "relative", paddingBottom: "130px" }}>
        <CPHiddenDiv hidden={selectedTab != 0} child={productsBody} />
        <CPHiddenDiv hidden={selectedTab != 1} child={sweepstakesBody} />
        <CPHiddenDiv hidden={selectedTab != 2} child={auctionsBody} />
      </div>
    </MainLayout>
  );
});

enum SweepstakeOrAuctionType {
  SWEEPSTAKE,
  AUCTION,
}

const sweepstakeOrAuctionFlexList = [1, 4, 1, 1, 1];

const SweepstakeOrAuctionList = ({
  title,
  dataList,
  type,
}: {
  title: string;
  dataList: RowData[];
  type: SweepstakeOrAuctionType;
}) => {
  let flexList = sweepstakeOrAuctionFlexList;
  if (!dataList || dataList.length == 0) return null;
  return (
    <Box p={"20px"} style={{ background: "white" }}>
      <Box display={"flex"}>
        <Box style={cpBoldSubHeaderStyle}>{title}</Box>
        <Box flex={1} />

        <CPDownloadButton
          onDownload={() =>
            exportAsCSVByRow(
              ["", "name", "activeDateTime", "expirationDateTime", "count"],
              dataList,
              title
            )
          }
        />
      </Box>
      <CPDashboardSpacer />
      <Box display={"flex"} style={cpBoxSubHeaderStyle}>
        <Box flex={flexList[0]}></Box>
        <Box flex={flexList[1]}>Item</Box>
        <Box flex={flexList[2]}>Start Date</Box>
        <Box flex={flexList[3]}>End Date</Box>
        <Box flex={flexList[4]}>Tickets Redeemed</Box>
      </Box>
      {(dataList || []).map((data: any) => {
        return (
          <SweepstakeOrAuctionRow key={data["id"]} data={data} type={type} />
        );
      })}
    </Box>
  );
};

const SweepstakeOrAuctionRow = ({
  data,
  type,
}: {
  data: RowData;
  type: SweepstakeOrAuctionType;
}) => {
  const routerStore = useStore().routerStore;
  const { teamId } = useParams<{ teamId: string }>();
  let imageUrl = data["imageUrl"];
  let name = data["name"];
  let startDate = moment(data["activeDateTime"]).format("MMMM D h:mma z");
  let endDate = moment(data["expirationDateTime"]).format("MMMM D h:mma z");
  let count = data["count"];
  let flexList = sweepstakeOrAuctionFlexList;
  return (
    <Box>
      {/* Want children to be centered vertically */}
      <Box
        alignItems={"center"}
        display={"flex"}
        pt={"18px"}
        pb={"18px"}
        fontSize={"12px"}
        onClick={() => {
          if (type == SweepstakeOrAuctionType.SWEEPSTAKE) {
            routerStore.push(
              marketplaceRoutes.sweepstakeDetail(teamId, `${data["id"]}`)
            );
          } else {
            routerStore.push(
              marketplaceRoutes.auctionDetail(teamId, `${data["id"]}`)
            );
          }
        }}
      >
        <Box flex={flexList[0]}>
          <img height={"100px"} src={`${imageUrl}`} />
        </Box>
        <Box flex={flexList[1]}>{name}</Box>
        <Box flex={flexList[2]}>{startDate}</Box>
        <Box flex={flexList[3]}>{endDate}</Box>
        <Box flex={flexList[4]}>{count}</Box>
      </Box>
      <CPDivider />
    </Box>
  );
};
