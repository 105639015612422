import { makeAutoObservable } from "mobx";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";
import { SponsorListItem } from "../../types/sponsors-types";
import { sponsorRoutes } from "../../app-routes";
import { Loading } from "../../helpers/loading";
import { formatSpaces } from "../../helpers/name-helpers";

export class SponsorsPageStore {
  private searchInput: string = "";
  private _sponsors: string[] = [];
  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get sponsors() {
    return this._sponsors
      .filter((sponsorName) =>
        sponsorName.toLowerCase().includes(this.searchInput.toLowerCase())
      )
      .map((sponsorName) => ({ sponsorName: formatSpaces(sponsorName) }));
  }

  onRowClick = ({ sponsorName }: SponsorListItem) => {
    this.uiStore.routerStore.push(sponsorRoutes.sponsorOverview(sponsorName));
  };

  searchSponsor = (input: string) => {
    this.searchInput = input;
  };

  loadSponsors = async () => {
    try {
      this.loading = Loading.LOADING;
      const { sponsors = [] } = await this.firebaseConnector.loadDictionaries();
      this._sponsors = sponsors;
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
