import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect } from "react-router-dom";
import {
  authRoutes,
  orderRoutes,
  sponsorRoutes,
  stadiumRoutes,
  teamRoutes,
} from "../app-routes";
import { formatSpaces } from "../helpers/name-helpers";
import { useStore } from "../helpers/use-store";
import { SessionUserType, coreTeamRoles } from "../types/user-types";

export const RootPage = observer(() => {
  const { user } = useStore().ui.session;

  if (!user) return <Redirect to={authRoutes.logIn} />;
  const role = user.type?.role;
  const sponsor = user.type?.identifier?.sponsor;
  const stadium = user.type?.identifier?.stadium;
  const team = user.type?.identifier?.team;
  if (role === SessionUserType.SPONSOR) {
    return (
      <Redirect
        to={sponsorRoutes.sponsorOverview(formatSpaces(sponsor || ""))}
      />
    );
  }
  if (coreTeamRoles.includes(role)) {
    return <Redirect to={teamRoutes.teamOverview(`${team}`)} />;
  }
  if (role === SessionUserType.ORDERS) {
    return <Redirect to={orderRoutes.ordersByTeam(`${team}`)} />;
  }

  return null;
});
