import moment from "moment";
import {
  cpGrayF7,
  defaultDicAges,
  defaultDicGenders,
} from "../helpers/constants";
import { clamp } from "../helpers/number-helpers";
import { SessionStore } from "../stores/ui/session-store";
import { RowData } from "./data-table-types";
import { hMFormat, mdyFormat } from "./formik-types";

export const hasId = <T, K extends { id: string }>(
  value: T | K
): value is K => {
  return (value as K).id !== undefined;
};

export interface DropdownOption {
  [key: string]: string;
  label: string;
  value: string;
}

export const debugStyle = (color: string) => {
  return {
    backgroundColor: color,
  };
};

export const percentageFromNumber = (num: number) => {
  return `${clamp(num * 100, 0, 100).toFixed(2)}%`;
};

export const firstNameFromFullName = (fullName: string) => {
  let list = fullName.split(" ");
  if (list.length > 0) return list[0];
  return fullName;
};

export const lastNameFromFullName = (fullName: string) => {
  let list = fullName.split(" ");
  if (list.length > 0) list[0] = "";
  return list.join("");
};

export const stringFromFirebaseFriendlyKey = (input: string) => {
  let result = input;
  result = result.replace(/\^20/g, " ");
  result = result.replace(/\^2E/g, ".");
  result = result.replace(/\^23/g, "#");
  result = result.replace(/\^24/g, "$");
  result = result.replace(/\^5B/g, "[");
  result = result.replace(/\^5D/g, "]");
  result = result.replace(/\^2F/g, "/");
  return result;
};

export const firebaseFriendlyKeyFromString = (input: string) => {
  let result = input;
  result = result.replace(/ /g, "^20");
  result = result.replace(/\./g, "^2E");
  result = result.replace(/#/g, "^23");
  result = result.replace(/\$/g, "^24");
  result = result.replace(/\[/g, "^5B");
  result = result.replace(/\]/g, "^5D");
  result = result.replace(/\//g, "^2F");
  return result;
};

export const copyObject = (object: any) => {
  return JSON.parse(JSON.stringify(object));
};

export const getStyleFromSpacingList = (
  spacingList: any,
  onDelete: any,
  onChevron: any,
  fullWidth: any
) => {
  let gridTemplateColumns = "20px ";
  spacingList.forEach((spacingFr: any) => {
    gridTemplateColumns += `${spacingFr} `;
  });
  if (onDelete) gridTemplateColumns += "50px ";
  if (onChevron) gridTemplateColumns += "50px ";
  gridTemplateColumns += "20px ";
  return {
    gridTemplateColumns: gridTemplateColumns,
    width: fullWidth ? "100%" : null,
    marginLeft: fullWidth ? "0" : null,
  };
};

export const getAttributeFromObject = (path: any, object: any) => {
  if (!object) return;
  let list = path.split("/");
  for (let i = 0; i < list.length; i++) {
    let attribute = list[i];
    if (attribute) object = object[attribute];
    if (!object && object !== 0) return;
  }
  return object;
};

export const setAttributeInObject = (path: string, object: any, value: any) => {
  if (!object) {
    console.log("No object passed");
    return;
  }
  let list = path.split("/");
  for (let i = 0; i < list.length; i++) {
    let attribute = list[i];
    let isLast = i === list.length - 1;
    if (attribute) {
      object[attribute] = isLast ? value : object[attribute] || {};
      if (!isLast) object = object[attribute];
    }
  }
  return object;
};

export const getSeasonFilterFromSeasonId = (
  seasonOptions: any,
  currentSeasonId: string
) => {
  let seasonFilter;
  Object.keys(seasonOptions).forEach((index) => {
    let seasonDetails = seasonOptions[index];
    let seasonId = seasonDetails["value"];
    if (seasonId == currentSeasonId)
      seasonFilter = {
        label: seasonDetails["label"],
        value: seasonId,
        endDate: seasonDetails["endDate"],
        startDate: seasonDetails["startDate"],
      };
  });
  console.log(`setting seasonFilter to ${JSON.stringify(seasonFilter)}`);
  return seasonFilter;
};

export const genericDemographicsDownload = (
  demographics: any,
  allUserInfo: any,
  hasCount?: boolean
) => {
  hasCount = hasCount == true;
  let csvKeys: string[] = [];
  Object.keys(defaultDicAges).forEach((ageCategory) => {
    Object.keys(defaultDicGenders).forEach((gender) => {
      let ageGenderPath = `${ageCategory}/${gender}`;
      csvKeys.push(`${ageCategory} ${gender} Email`);
      csvKeys.push(`${ageCategory} ${gender} Name`);
      if (hasCount && getAttributeFromObject(ageGenderPath, demographics))
        csvKeys.push(`${ageCategory} ${gender} Count`);
    });
  });
  let cols: any = {};
  let colIndexArr = [0];
  Object.keys(defaultDicAges).forEach((ageCategory) => {
    Object.keys(defaultDicGenders).forEach((gender) => {
      let ageGenderPath = `${ageCategory}/${gender}`;

      if (hasCount) {
        if (!getAttributeFromObject(ageGenderPath, demographics)) return;
        setColEmailsAndNamesWithUserCount(
          colIndexArr,
          cols,
          allUserInfo,
          getAttributeFromObject(ageGenderPath, demographics) || {}
        );
      } else {
        setColEmailsAndNames(
          ageGenderPath,
          colIndexArr,
          cols,
          demographics,
          allUserInfo
        );
      }
    });
  });
  exportAsCSVByCol(csvKeys, cols, "Demographics");
};

export const setColEmailsAndNames = (
  path: string,
  colIndexArr: number[],
  cols: any,
  object: any,
  allUserInfo: any,
  userIds?: any
) => {
  let colIndex: number = colIndexArr[0] || 0;
  setAttributeInObject(`${colIndex}`, cols, []);
  setAttributeInObject(`${colIndex + 1}`, cols, []);
  if (!userIds) userIds = getAttributeFromObject(path, object) || [];
  userIds.forEach((userId: string) => {
    let userDetails = allUserInfo[userId];
    let fullName = userDetails
      ? `${userDetails["firstName"]} ${userDetails["lastName"]}`
      : userId;
    let email = userDetails ? userDetails["email"] : userId;
    let prevEmails = getAttributeFromObject(`${colIndex}`, cols) || [];
    let prevFullNames = getAttributeFromObject(`${colIndex + 1}`, cols) || [];
    prevEmails.push(email);
    prevFullNames.push(fullName);
    setAttributeInObject(`${colIndex}`, cols, prevEmails);
    setAttributeInObject(`${colIndex + 1}`, cols, prevFullNames);
  });
  colIndexArr[0] = colIndex + 2;
};

export const setColEmailsAndNamesWithUserCount = (
  colIndexArr: number[],
  cols: any,
  allUserInfo: any,
  userIdMap: any
) => {
  let colIndex: number = colIndexArr[0] || 0;
  setAttributeInObject(`${colIndex}`, cols, []);
  setAttributeInObject(`${colIndex + 1}`, cols, []);
  setAttributeInObject(`${colIndex + 2}`, cols, []);
  Object.keys(userIdMap).forEach((userId: string) => {
    let userDetails = allUserInfo[userId] || {};
    let fullName = userDetails["firstName"]
      ? `${userDetails["firstName"]} ${userDetails["lastName"]}`
      : userId;
    let email = userDetails["email"] || userId;
    let prevEmails = getAttributeFromObject(`${colIndex}`, cols) || [];
    let prevFullNames = getAttributeFromObject(`${colIndex + 1}`, cols) || [];
    let prevCounts = getAttributeFromObject(`${colIndex + 2}`, cols) || [];
    prevEmails.push(email);
    prevFullNames.push(fullName);
    prevCounts.push(userIdMap[userId] || 0);
    setAttributeInObject(`${colIndex}`, cols, prevEmails);
    setAttributeInObject(`${colIndex + 1}`, cols, prevFullNames);
    setAttributeInObject(`${colIndex + 2}`, cols, prevCounts);
  });
  colIndexArr[0] = colIndex + 3;
};

export const exportAsCSVByRow = (
  csvKeys: string[],
  rows: RowData[],
  csvTitle: string
) => {
  let csvContent = getStartingCsvContent(csvKeys);
  rows.forEach((rowArray: RowData) => {
    let row = "";
    csvKeys.forEach((key: any) => {
      if (row) row += ",";
      row += rowArray[key]
        ? `"${rowArray[key].toString().replace(/"/g, '""')}"`
        : "";
    });
    csvContent += row + "\r\n";
  });
  exportCsvContent(csvContent, csvTitle);
};

export const exportAsCSVByCol = (
  csvKeys: string[],
  cols: {},
  csvTitle: string
) => {
  let csvContent = getStartingCsvContent(csvKeys);
  let rowLength = csvKeys.length;
  let maxColLength = 0;
  Object.values(cols).forEach((col: any) => {
    maxColLength = Math.max(maxColLength, col.length);
  });
  for (let i = 0; i < maxColLength; i++) {
    let row = "";
    Object.values(cols).forEach((col: any) => {
      if (row) row += ",";
      row += col[`${i}`]
        ? `"${col[`${i}`].toString().replace(/"/g, '""')}"`
        : "";
    });
    csvContent += row + "\r\n";
  }
  exportCsvContent(csvContent, csvTitle);
};

export const convertTruesToOnes = (object: any) => {
  let newObject: any = {};
  Object.keys(object || {}).forEach((dateKey) => {
    if (object[dateKey] === true) newObject[dateKey] = 1;
  });
  return newObject;
};

const getStartingCsvContent = (csvKeys: string[]) => {
  let csvContent = "data:text/csv;charset=utf-8,";
  if (!csvKeys) return "";
  let keyRow = "";
  csvKeys.forEach((key: any) => {
    if (keyRow) keyRow += ",";
    keyRow += key;
  });
  csvContent += keyRow + "\r\n";
  return csvContent;
};

const exportCsvContent = (csvContent: string, csvTitle: string) => {
  csvContent = csvContent.replaceAll("#", "");
  var encodedUri = encodeURI(csvContent);
  console.log(`csvContent: ${csvContent}`);
  console.log(`encodedUri: ${encodedUri}`);
  // window.open(encodedUri);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${csvTitle}.csv`);
  document.body.appendChild(link); // Required for FF
  link.click();
};

export const getColorStringFromKey = (key: string, session: SessionStore) => {
  return "#" + getAttributeFromObject(`colors/${key}`, session.teamInfo);
};

export const getTableCellColorFromRowIndex = (rowIndex: number) => {
  return rowIndex % 2 === 1 ? cpGrayF7 : "white";
};

export const setDateAndTimeFromDateTime = (
  object: any,
  dateTimePath: any,
  datePath: any,
  timePath: any
) => {
  let dateTime = getAttributeFromObject(dateTimePath, object);
  let dateTimeMoment = moment(dateTime);
  if (dateTime && dateTimeMoment.isValid()) {
    let time = dateTimeMoment.format(hMFormat);
    let date = dateTimeMoment.format(mdyFormat);
    setAttributeInObject(datePath, object, date);
    setAttributeInObject(timePath, object, time);
  }
};

export const onFileUpload = async (
  file: File,
  path: string,
  firebaseConnector: any
) => {
  const formData = new FormData();
  formData.append("file", file);
  let url = await firebaseConnector.uploadImageFile(file, path);
  return url;
};
