import React from "react";
import PhoneRoundedIcon from "@material-ui/icons/PhoneRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";

import { makeStyles, Paper, Box, Avatar, Typography } from "@material-ui/core";

const useUsersStyled = makeStyles((theme) => ({
  icon: {
    marginRight: theme.typography.pxToRem(10),
    color: theme.palette.grey["500"],
  },
  bigAvatar: {
    margin: 10,
    width: theme.typography.pxToRem(100),
    height: theme.typography.pxToRem(100),
  },
  divider: {
    height: "100%",
    width: theme.typography.pxToRem(2),
    background: theme.palette.secondary.main,
  },
}));

interface UsersInfoProps {
  name: string;
  age: number;
  email: string;
  photo: string | number;
}

export const UsersInfo: React.FC<UsersInfoProps> = ({
  name,
  age,
  email,
  photo,
}) => {
  const classes = useUsersStyled();
  return (
    <Paper>
      <Box p="4rem" display="flex" height="18.6rem">
        <Box
          flex="1 0 auto"
          width="49%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Avatar
            alt={name}
            src={`https://firebasestorage.googleapis.com/v0/b/crowdplayfirebase.appspot.com/o/avatars%2Ffr${photo}.png?alt=media`}
            className={classes.bigAvatar}
          />
          <Box textAlign="left">
            <Box fontWeight="fontWeightBold">{name}</Box>
            {!!age && <Box fontWeight="fontWeightBold">{age} years old</Box>}
          </Box>
        </Box>
        <div className={classes.divider} />
        <Box
          width="49%"
          flex="1 0 auto"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          pl="4rem"
        >
          <Box>
            <Box display="flex" fontWeight="fontWeightBold" alignItems="center">
              <EmailRoundedIcon className={classes.icon} />
              Email:
            </Box>
            <Typography component="div" color="primary">
              <Box mt={1} fontWeight="fontWeightBold">
                {email}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
