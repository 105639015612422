import { makeAutoObservable } from "mobx";
import moment from "moment";
import { Loading, loadingFunction } from "../../helpers/loading";
import { intValOrZero } from "../../helpers/number-helpers";
import { mdyFormat } from "../../types/formik-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";
import { inProgressString } from "../../helpers/constants";
import { getAttributeFromObject } from "../../types/util-types";
import { DailyWeeklyMonthlyData } from "../../types/chart-types";

export class MarketplaceAuctionPageStore {
  public loading: Loading = Loading.NOT_LOADED;
  public product: any = {};
  public auctionLogistics: any = [];
  public userBids: any = [];
  private auctionBidsByTimeframe: any = {};
  public userDetailsByUserId: any;
  private _teamId: string = "";
  private auctionBidsDataUserIdSpecific: any = {};

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  resetValues = () => {
    this.auctionLogistics = [];
  };

  get dailyWeeklyMonthlyBidData(): DailyWeeklyMonthlyData[] {
    return [this.auctionBidsByTimeframe];
  }

  getAllUserInfo = async () => {
    if (this.userDetailsByUserId) return this.userDetailsByUserId;
    this.userDetailsByUserId =
      await this.firebaseConnector.getDatabaseObjAtPath(
        `userDetailsByUserIdByTeam/${this._teamId}`
      );
    return this.userDetailsByUserId;
  };

  getUserIdSpecificBidData = async (timeframe: string, auctionId: string) => {
    if (this.auctionBidsDataUserIdSpecific[timeframe])
      return this.auctionBidsDataUserIdSpecific[timeframe];
    let path = `tracking/${timeframe}AuctionCountsByTeamUserIdSpecific/${this._teamId}/${auctionId}`;
    this.auctionBidsDataUserIdSpecific[timeframe] =
      await this.firebaseConnector.getDatabaseObjAtPath(path);
    return this.auctionBidsDataUserIdSpecific[timeframe];
  };

  loadOverview = async (teamId: string, auctionId: string) => {
    this._teamId = teamId;
    this.resetValues();
    loadingFunction(
      this,
      async () => {
        let auction = await this.firebaseConnector.getDatabaseObjAtPath(
          `auctionsByTeam/${teamId}/${auctionId}`
        );
        let productId = auction["productId"];
        this.product = await this.firebaseConnector.getDatabaseObjAtPath(
          `marketplaceProductsByTeam/${teamId}/${productId}`
        );
        let auctionBids =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `auctionBidsByTeam/${teamId}/${auctionId}`
          )) || {};
        let totalBids = Object.keys(auctionBids).length;
        let userDetailsByUserId =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `userDetailsByUserIdByTeam/${teamId}`
          );
        Object.keys(auctionBids).forEach((bidId: any) => {
          let bidData = auctionBids[bidId] || {};
          let bid = intValOrZero(`${bidData["bid"]}`);
          let userId = bidData["userId"];
          let fullName =
            getAttributeFromObject(`${userId}/fullName`, userDetailsByUserId) ||
            userId;
          this.userBids.push({
            userId: userId,
            name: fullName,
            bid: bid,
          });
        });
        let startingBid = auction["minimumPoints"] || "N/A";
        let currentBid = auction["highestBid"] || "N/A";

        this.auctionLogistics.push({
          label: "Total Bids",
          value: totalBids,
        });
        this.auctionLogistics.push({
          label: "Starting Bid",
          value: startingBid,
        });
        this.auctionLogistics.push({
          label: "Current Bid",
          value: currentBid,
        });
        let startDate = moment(auction["activeDateTime"]).format(mdyFormat);
        let endDate = moment(auction["expirationDateTime"]).format(mdyFormat);
        this.auctionLogistics.push({
          label: "Start Date",
          value: startDate,
        });
        this.auctionLogistics.push({
          label: "End Date",
          value: endDate,
        });
        let winningUserId = auction["winningUserId"];
        let winner = winningUserId
          ? await this.firebaseConnector.getDatabaseObjAtPath(
              `userDetailsByUserIdByTeam/${teamId}/${winningUserId}/fullName`
            )
          : "None";
        let color;
        if (moment().isBefore(endDate)) {
          winner = inProgressString;
          color = "orange";
        }

        this.auctionLogistics.push({
          label: "Winner",
          value: winner,
          color: color,
        });

        this.auctionBidsByTimeframe["daily"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/dailyAuctionCountsByTeam/${teamId}/${auctionId}`
          );
        this.auctionBidsByTimeframe["weekly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/weeklyAuctionCountsByTeam/${teamId}/${auctionId}`
          );
        this.auctionBidsByTimeframe["monthly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/monthlyAuctionCountsByTeam/${teamId}/${auctionId}`
          );
      },
      this.uiStore.notifications
    );
  };
}
