import { makeAutoObservable } from "mobx";
import { Loading } from "../../helpers/loading";
import { LoyaltyItem } from "../../types/loyalties-types";
import { API } from "../connectors/api-connector";
import { DomainsStore } from "../domains";
import { UiStore } from "./ui-store";

import {
  CategoryAnalyticsResponse,
  categoryAnalyticsStatsDefaults,
} from "../../types/loyalties-types";
import { FirebaseConnector } from "../connectors/firebase-connector";

export class LoyaltiesCategoriesPageStore {
  public loading: Loading = Loading.NOT_LOADED;
  private categoryAnalyticsStats: CategoryAnalyticsResponse =
    categoryAnalyticsStatsDefaults();
  public allLoyalties: LoyaltyItem[] = [];

  constructor(
    private uiStore: UiStore,
    private domainsStore: DomainsStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  loadLoyalties = async (surelyReload: boolean = true) => {
    const shouldReload = surelyReload || !this.allLoyalties.length;
    if (shouldReload) {
      // const response = await API.post<{ data: { active: LoyaltyItem[]; expired: LoyaltyItem[] } }>("/loyaltyPrograms", {
      //   data: { userId, teamId },
      // });
      this.allLoyalties = await this.firebaseConnector.getLoyaltyCodesByTeam(
        this.uiStore.session.userTeamId,
      );
    }
  };

  filteredLoyalties = (categoryId: string) => {
    let result = this.allLoyalties.filter(
      (loyaltyItem) => loyaltyItem.loyaltyCodeCategory === categoryId,
    );
    return result;
  };

  get userId() {
    return this.uiStore.session.userId;
  }

  get teamId() {
    return this.uiStore.session.userTeamId;
  }

  categorySpecificDataBlock = (categoryId: string) => {
    const { nCodes, nCodeRedemptions, totalPointsRedeemed } =
      this.categoryAnalyticsStats;
    let nCodesTemp = Object.keys(this.filteredLoyalties(categoryId)).length;
    return {
      items: [
        {
          title: "Total Codes",
          value: nCodesTemp ? nCodesTemp : nCodes ? nCodes : 0,
        },
        {
          title: "Total Codes Redeemed",
          value: nCodeRedemptions ? nCodeRedemptions : 0,
        },
        {
          title: "Total Points Redeemed",
          value: totalPointsRedeemed ? totalPointsRedeemed : 0,
        },
      ],
    };
  };

  get genderStats() {
    const { genders } = this.categoryAnalyticsStats;
    const genderUsers = genders
      ? Object.values(genders).reduce((p, n) => p + n, 0)
      : 1;
    let male = genders ? Math.round((genders.male / genderUsers) * 100) : 0;
    let female = genders ? Math.round((genders.female / genderUsers) * 100) : 0;
    let other = genders ? Math.round((genders.other / genderUsers) * 100) : 0;
    let maleAmount = genders ? genders.male : 0;
    let femaleAmount = genders ? genders.female : 0;
    let otherAmount = genders ? genders.other : 0;
    return {
      male: male,
      female: female,
      preferNotSay: other,
      maleAmount: maleAmount,
      femaleAmount: femaleAmount,
      preferNotSayAmount: otherAmount,
    };
  }

  get demographicStats() {
    let ages = this.categoryAnalyticsStats.ages;
    if (!ages) ages = {};
    return Object.keys(ages)
      .sort((a, b) => {
        const aa = a.replace(/[^0-9 ]/g, "").slice(0, 2);
        const bb = b.replace(/[^0-9 ]/g, "").slice(0, 2);
        return Number(aa) - Number(bb);
      })
      .map((key) => {
        return {
          name: key,
          amount: ages[key],
        };
      });
  }

  loadCategory = async (categoryId: string) => {
    let teamId = this.teamId;
    try {
      this.loading = Loading.LOADING;
      console.log(`attempting teamId: ${teamId}`);
      const [stats] = await Promise.all([
        await API.post<{ data: CategoryAnalyticsResponse }>(
          "/loyaltiesCategoryData",
          { data: { teamId, categoryId } },
        ),
      ]);
      console.log(`done with teamId: ${teamId}`);
      console.log(`stats: ${JSON.stringify(stats.data.data)}`);
      this.categoryAnalyticsStats = stats.data.data;
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };

  changeCategoryName = async (categoryId: string, newName: string) => {
    await this.firebaseConnector.setDatabasePathAsObj(
      `activeRewardCategoriesByTeam/${this.teamId}/${categoryId}/name`,
      newName,
    );
    await this.loadCategory(categoryId);
  };
}
