import * as React from "react";
import { LoyaltyItem } from "../../../types/loyalties-types";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import StarIcon from "@material-ui/icons/Star";
import { Box, Checkbox, makeStyles, TableContainer } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { loyaltyRoutes } from "../../../app-routes";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers/use-store";
import { SortableTable } from "../../../components/SortableTable";
import { BooleanLocale } from "yup/lib/locale";
import { ToggleButton } from "@material-ui/lab";

const commonProps = {
  width: 100,
  flexGrow: 1,
};

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    border: "transparent",
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
      // color: theme.palette.text.color
    },
    "& .MuiDataGrid-colCellWrapper": {
      color: theme.palette.secondary.main,
      background: "transparent",
      // color: theme.palette.text.color
    },
    "& .MuiDataGrid-row": {
      background: theme.palette.common.white,
      marginBottom: theme.spacing(3),
      // color: theme.palette.text.color
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    // "& .ReactVirtualized__Table__headerColumn": {
    //   outline: "none",
    // },
    // "& .ReactVirtualized__Table__row": {
    //   outline: "none",
    // },
  },
}));

interface Data {
  [key: string]: number | string;
}
interface Props {
  loyalties: LoyaltyItem[];
  onDelete?: (codeId: string) => void;
  onToggleVisibility?: (codeId: string, enabled: boolean) => void;
  onToggleFeatured?: (codeId: string, newBoolean: boolean) => void;
  disabled: boolean;
  itemsInSection?: any;
}

export const LoyaltiesTable: React.FC<Props> = observer(
  ({
    loyalties,
    onDelete,
    onToggleVisibility,
    disabled = false,
    itemsInSection,
    onToggleFeatured,
  }) => {
    const { routerStore } = useStore();
    const classes = useStyles();
    if (!itemsInSection) itemsInSection = {};
    return (
      <div style={{ height: 500, width: "100%" }}>
        <TableContainer title="Codes">
          <SortableTable
            height="80vh"
            columns={[
              // {
              //   dataKey: "key",
              //   label: "Key",
              //   ...commonProps,
              //   flexGrow: 2,
              // },
              {
                dataKey: "loyaltyCodeTitle",
                label: "Title",
                ...commonProps,
                flexGrow: 2,
              },
              {
                dataKey: "loyaltyCodeCode",
                label: "Code",
                ...commonProps,
              },
              {
                dataKey: "loyaltyCodeActiveDate",
                label: "Active Date",
                ...commonProps,
              },
              {
                dataKey: "loyaltyCodeExpirationDate",
                label: "Expiration Date",
                ...commonProps,
              },
              {
                dataKey: "loyaltyCodeNRedemptions",
                label: "Redemptions",
                ...commonProps,
              },
              // {
              //   dataKey: "key",
              //   label: "ID",
              //   ...commonProps,
              // },
              // {
              //   dataKey: "loyaltyCodeVisibility",
              //   label: "Visibility in Program",
              //   ...commonProps,
              //   flexGrow: 2,
              // //TODO watch out, these may sort unexpectedly
              //   cellRender: (props) => (
              //     <Box width="100%" display="flex" alignItems="center">
              //       {`${props.loyaltyCodeVisibility?"Visible":"Invisible"}`}
              //     </Box>
              //   ),
              // },
              ...(onToggleVisibility
                ? [
                    {
                      dataKey: "loyaltyCodeVisibility",
                      label: "Visibility",
                      ...commonProps,
                      disableClickEventBubbling: true,
                      cellRender: (props: any) => {
                        return (
                          <Checkbox
                            color="primary"
                            onClick={(event) => {
                              onToggleVisibility(
                                `${props.key}`,
                                !!props.loyaltyCodeVisibility
                              );
                              event.stopPropagation();
                            }}
                            checked={!!props.loyaltyCodeVisibility}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        );
                      },
                    },
                  ]
                : []),
              ...(onToggleFeatured
                ? [
                    {
                      dataKey: "featured",
                      label: "Feature (fanzone)",
                      ...commonProps,
                      disableClickEventBubbling: true,
                      cellRender: (props: any) => {
                        if (!onToggleFeatured) return <></>;
                        let loyaltyCodeId = props.loyaltyCodeId;
                        let isFeatured = itemsInSection[loyaltyCodeId];
                        const iconProps = {
                          style: { cursor: "pointer" },
                          onClick: (event: any) => {
                            onToggleFeatured(loyaltyCodeId, !isFeatured);
                            event.stopPropagation();
                          },
                        };
                        return (
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            className="delete-icon"
                          >
                            <Typography color="secondary">
                              {isFeatured ? (
                                <StarIcon {...iconProps} />
                              ) : (
                                <StarOutlineIcon {...iconProps} />
                              )}
                            </Typography>
                          </Box>
                        );
                      },
                    },
                  ]
                : []),
              ...(onDelete
                ? [
                    {
                      dataKey: "delete",
                      label: "",
                      ...commonProps,
                      disableClickEventBubbling: true,
                      cellRender: (props: any) => {
                        if (!onDelete) return <></>;
                        return (
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            className="delete-icon"
                          >
                            <Typography color="secondary">
                              <DeleteOutlineIcon
                                style={{ cursor: "pointer" }}
                                onClick={(event) => {
                                  onDelete(`${props.loyaltyCodeCode}`);
                                  event.stopPropagation();
                                }}
                              />
                            </Typography>
                          </Box>
                        );
                      },
                    },
                  ]
                : []),
            ]}
            rows={loyalties}
            onRowClick={(row) => {
              if (!disabled)
                routerStore.push(
                  loyaltyRoutes.detailsLoyalty(`${row.loyaltyCodeCode}`)
                );
            }}
            // @ts-ignore
          />
        </TableContainer>
      </div>
    );
  }
);
