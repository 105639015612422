import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation } from "react-router";
import {
  loyaltyRoutes,
  marketplaceRoutes,
  notificationRoutes,
  orderRoutes,
  sponsorRoutes,
  stadiumRoutes,
  teamRoutes,
  usersAccessRoutes,
  usersRoutes,
  integrationsRoutes,
} from "../../app-routes";
import {
  activeRewardsIndex,
  cpNonexistentStyle,
  teamDashboardIndex,
  gamesIndex,
  marketplaceIndex,
  membersIndex,
  notificationsIndex,
  ordersIndex,
  sponsorsIndex,
  sponsorDashboardIndex,
  allowedIndexByRole,
  grantUserAccessIndex,
  integrationsIndex
} from "../../helpers/constants";
import { useStore } from "../../helpers/use-store";
import { SessionUserType } from "../../types/user-types";

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: theme.spacing(5),
    color: theme.palette.common.white,
  },
  tab_indicator: {
    background: theme.palette.common.white,
  },
}));

export const HeaderTabs = observer(() => {
  const { routerStore, session } = useStore().ui;
  const stadium = session.user?.type?.identifier?.stadium || "";
  const hasTeam = !!session.user?.type?.identifier?.team;
  const team = session.user?.type?.identifier?.team || "";
  const role = session.user?.type?.role;

  const classes = useStyles();

  const HeaderTab = (props: { label: string; index: number }) => {
    if (!role) return null;
    let display = allowedIndexByRole[role].includes(props.index) ? "" : "none";
    return (
      <Tab
        onClick={() => handleTabChange({}, props.index)}
        style={{ display: display }}
        className={classes.tab}
        label={props.label}
        value={props.index}
      />
    );
  };

  let routeByIndex: any = {
    [teamDashboardIndex]: teamRoutes.teamOverview(team),
    [marketplaceIndex]: marketplaceRoutes.marketplaceOverview(team),
    [gamesIndex]: stadiumRoutes.stadiumOverview(team),
    [membersIndex]: usersRoutes.usersByTeam(team),
    [activeRewardsIndex]: loyaltyRoutes.loyalties,
    [ordersIndex]: orderRoutes.ordersByTeam(team),
    [notificationsIndex]: notificationRoutes.notificationsByTeam(team),
    [sponsorDashboardIndex]: sponsorRoutes.sponsorOverview(stadium),
    [sponsorsIndex]: sponsorRoutes.sponsors,
    [grantUserAccessIndex]: usersAccessRoutes.userAccesses,
    [integrationsIndex]: integrationsRoutes.cloverForTeam,
  };

  const { pathname } = useLocation();
  const getValue = () => {
    if (pathname.startsWith("/teams")) return teamDashboardIndex;
    if (pathname.startsWith("/marketplace")) return marketplaceIndex;
    if (pathname.startsWith("/stadiums")) return gamesIndex;
    if (pathname.startsWith(loyaltyRoutes.loyalties)) return activeRewardsIndex;
    if (pathname.startsWith("/order") || pathname.startsWith("/pickups"))
      return ordersIndex;
    if (pathname.startsWith("/user")) return membersIndex;
    if (pathname.startsWith("/notification")) return notificationsIndex;
    if (pathname.startsWith("/integrations")) return integrationsIndex;
    return teamDashboardIndex;
  };

  const handleTabChange = (event: any, value: number) => {
    console.log(`handleTabChange: ${value}`);
    routerStore.push(routeByIndex[value]);
  };

  return (
    <Tabs
      value={getValue()}
      textColor="inherit"
      variant="standard"
      classes={{ indicator: classes.tab_indicator }}
    >
      <HeaderTab label="DASHBOARD" index={teamDashboardIndex} />
      <HeaderTab label="MARKETPLACE" index={marketplaceIndex} />
      <HeaderTab label="GAMES" index={gamesIndex} />
      <HeaderTab label="MEMBERS" index={membersIndex} />
      <HeaderTab label="ACTIVE REWARDS" index={activeRewardsIndex} />
      <HeaderTab label="ORDERS" index={ordersIndex} />
      <HeaderTab label="NOTIFICATIONS" index={notificationsIndex} />
      <HeaderTab label="DASHBOARD" index={sponsorDashboardIndex} />
      <HeaderTab label="SPONSORS" index={sponsorsIndex} />
      <HeaderTab label="GRANT ACCESS" index={grantUserAccessIndex} />
      <HeaderTab label="INTEGRATIONS" index={integrationsIndex} />
    </Tabs>
  );
});
