import { Box, TextField } from "@material-ui/core";
import React from "react";
import search_icon from "../assets/search_icon.png";
import { cpInputHeight, cpInputStyle } from "../helpers/constants";

interface Props {
  label: string;
  onSearch: any;
  value: any;
}

export const CPSearchBar: React.FC<Props> = ({ label, onSearch, value }) => {
  return (
    <Box
      width="100%"
      height={cpInputHeight}
      style={cpInputStyle}
      display={"flex"}
    >
      <Box padding={"6px 8px 8px 12px"}>
        <img height={"14px"} src={search_icon} />
      </Box>
      <TextField
        style={{ top: 0, padding: "0px" }}
        onChange={onSearch}
        value={value}
        InputProps={{ disableUnderline: true }}
      />
    </Box>
    // <Box width={"100%"} height={cpInputHeight} style={combinedObject(cpInputStyle, { position: "relative" })}>
    // <TextField style={combinedObject(cpPositionedStyle, { bgColor: "transparent" })} onChange={onSearch} value={value} InputProps={{ disableUnderline: true }} />
    // </Box>
  );
};
