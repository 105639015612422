import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { QuestionsTable } from "./components/QuestionsTable";
import { PageTitle } from "../../components/PageTitle";
import { CrowdSelect } from "../../components/CrowdSelect";
import { questionsRoutes } from "../../app-routes";
import { ButtonLink } from "../../components/ButtonLink";

export const QuestionsPage = observer(() => {
  const { questionsPage } = useStore().ui;

  React.useEffect(() => {
    questionsPage.loadQuestionsForDisplay();
    questionsPage.loadFolders();
  }, [questionsPage]);

  const onSearch: React.ChangeEventHandler<{ value: string }> =
    React.useCallback(
      (e) => questionsPage.searchQuestion(e.target.value),
      [questionsPage],
    );

  const setSelectedFolderName = (selected: string) => {
    questionsPage.onSelectFolder(selected);
  };

  const promptNewFolder = () => {
    const folderName = window.prompt("Enter a new folder name");
    if (folderName) questionsPage.createFolder(folderName);
  };

  return (
    <MainLayout
      loading={questionsPage.loading}
      firebaseConnector={questionsPage.firebaseConnector}
      pageActions={
        <Box display="flex" alignItems="space-between" width="100%">
          <Grid container>
            <Grid item container md={6} spacing={2}>
              <Grid item>
                <PageTitle title="Questions" />
              </Grid>
              <Grid item>
                <ButtonLink
                  variant="contained"
                  color="primary"
                  to={questionsRoutes.newQuestion}
                >
                  New question
                </ButtonLink>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={promptNewFolder}
                >
                  New Folder
                </Button>
              </Grid>
            </Grid>
            <Grid item container md={6} spacing={2}>
              <Grid item md={4} />
              <Grid item md={6}>
                <TextField
                  style={{ width: "100%" }}
                  label="Search questions..."
                  onChange={onSearch}
                />
              </Grid>
              <Grid item md={2}>
                <CrowdSelect
                  options={questionsPage.getFolderOptions}
                  selected={questionsPage.selectedFolderName}
                  onChange={setSelectedFolderName}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
    >
      <Box height="40rem">
        <QuestionsTable
          questions={questionsPage.questions}
          onRowClick={questionsPage.onRowClick}
        />
      </Box>
    </MainLayout>
  );
});
