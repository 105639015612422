import { string } from "yup/lib/locale";

export interface TeamListItem {
  [key: string]: string;
}

export interface TeamItem {
  [key: string]: number | {} | string | boolean | undefined;
  editTeamsName: string;
  teamId: string;
}

export const teamItemDefaults = (): TeamItem => ({
  editTeamsName: "",
  teamId: "",
});
export interface TeamOverviewResponse {
  nSeasonTicketMembers: number;
  nParticipants: number;
  nActiveParticipants: number;
  activeParticipants: string[];
  inactiveParticipants: string[];
  enrolledParticipants: string[];
  averageDailyEngagement: number;
  averageWeeklyEngagement: number;
  averageMonthlyEngagement: number;
  dailyActiveUserCounts: {};
  weeklyActiveUserCounts: {};
  monthlyActiveUserCounts: {};
  dailyCheckInAttendsCount: {};
  weeklyCheckInAttendsCount: {};
  monthlyCheckInAttendsCount: {};
  dailyCheckInViewsCount: {};
  weeklyCheckInViewsCount: {};
  monthlyCheckInViewsCount: {};
  dailyCheckInScanningAttendsCount: {};
  weeklyCheckInScanningAttendsCount: {};
  monthlyCheckInScanningAttendsCount: {};
  dailyCheckInScanningAttendsCountWithGuests: {};
  weeklyCheckInScanningAttendsCountWithGuests: {};
  monthlyCheckInScanningAttendsCountWithGuests: {};
  dailyUsersWhoWereActiveCounts: {};
  weeklyUsersWhoWereActiveCounts: {};
  monthlyUsersWhoWereActiveCounts: {};
  totalPointsDistributed: number;
  totalPointsUsed: number;
  totalPointsOutstanding: number;
  pointsTransferPoints: number;
  nOrders: number;
  budgetSpent: number;
  totalBudget: number;
  demographicsData: {};
  orderedProducts: [];
  dailyRewardRedemptionsByWeekday: {};
  dailyRewardRedemptionsByMonth: {};
  dailyRewardRedemptionsWeekdaysSeen: {};
  dailyRewardRedemptionsMonthsSeen: {};
  dailyRewardRedemptionsByDate: {};
  dailyRewardRedemptionsByMonthAndYear: {};
  checkInAttendsByDate: {};
  checkInViewsByDate: {};
  totalPointsDistributedByWeekday: {};
  totalPointsDistributedByMonth: {};
  totalPointsDistributedWeekdaysSeen: {};
  totalPointsDistributedMonthsSeen: {};
  totalPointsDistributedByDate: {};
  totalPointsDistributedByMonthAndYear: {};
  totalOrdersByWeekday: {};
  totalOrdersByMonth: {};
  totalOrdersWeekdaysSeen: {};
  totalOrdersMonthsSeen: {};
  totalOrdersByDate: {};
  totalOrdersByMonthAndYear: {};
  numOrdersByUser: {};
  dailyOrderPlacerCounts: {};
  weeklyOrderPlacerCounts: {};
  monthlyOrderPlacerCounts: {};
}

export const teamOverviewStatsDefaults = (): TeamOverviewResponse => ({
  nSeasonTicketMembers: 0,
  nParticipants: 0,
  nActiveParticipants: 0,
  activeParticipants: [],
  inactiveParticipants: [],
  enrolledParticipants: [],
  averageDailyEngagement: 0,
  averageWeeklyEngagement: 0,
  averageMonthlyEngagement: 0,
  dailyActiveUserCounts: {},
  weeklyActiveUserCounts: {},
  monthlyActiveUserCounts: {},
  dailyCheckInAttendsCount: {},
  weeklyCheckInAttendsCount: {},
  monthlyCheckInAttendsCount: {},
  dailyCheckInViewsCount: {},
  weeklyCheckInViewsCount: {},
  monthlyCheckInViewsCount: {},
  dailyCheckInScanningAttendsCount: {},
  weeklyCheckInScanningAttendsCount: {},
  monthlyCheckInScanningAttendsCount: {},
  dailyCheckInScanningAttendsCountWithGuests: {},
  weeklyCheckInScanningAttendsCountWithGuests: {},
  monthlyCheckInScanningAttendsCountWithGuests: {},
  dailyUsersWhoWereActiveCounts: {},
  weeklyUsersWhoWereActiveCounts: {},
  monthlyUsersWhoWereActiveCounts: {},
  totalPointsDistributed: 0,
  totalPointsUsed: 0,
  totalPointsOutstanding: 0,
  pointsTransferPoints: 0,
  nOrders: 0,
  budgetSpent: 0,
  totalBudget: 0,
  demographicsData: {},
  orderedProducts: [],
  dailyRewardRedemptionsByWeekday: {},
  dailyRewardRedemptionsByMonth: {},
  dailyRewardRedemptionsWeekdaysSeen: {},
  dailyRewardRedemptionsMonthsSeen: {},
  dailyRewardRedemptionsByDate: {},
  dailyRewardRedemptionsByMonthAndYear: {},
  checkInAttendsByDate: {},
  checkInViewsByDate: {},
  totalPointsDistributedByWeekday: {},
  totalPointsDistributedByMonth: {},
  totalPointsDistributedWeekdaysSeen: {},
  totalPointsDistributedMonthsSeen: {},
  totalPointsDistributedByDate: {},
  totalPointsDistributedByMonthAndYear: {},
  totalOrdersByWeekday: {},
  totalOrdersByMonth: {},
  totalOrdersWeekdaysSeen: {},
  totalOrdersMonthsSeen: {},
  totalOrdersByDate: {},
  totalOrdersByMonthAndYear: {},
  numOrdersByUser: {},
  dailyOrderPlacerCounts: {},
  weeklyOrderPlacerCounts: {},
  monthlyOrderPlacerCounts: {},
});

export interface MemberEngagementData {
  // rank: number;
  nameAndAvatar: string[];
  totalActiveDays: number;
  currentStreak: number;
  longestStreak: number;
  engagementRate: number;
}

export type MemberEngagementDataKeys = keyof MemberEngagementData;
