import {
  Backdrop,
  Box,
  List,
  ListItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { usersRoutes } from "../../app-routes";
import adoption_tab_disabled from "../../assets/adoption_tab_disabled.png";
import adoption_tab_enabled from "../../assets/adoption_tab_enabled.png";
import android_square from "../../assets/android_square.png";
import apple_square from "../../assets/apple_square.png";
import orders_tab_disabled from "../../assets/orders_tab_disabled.png";
import orders_tab_enabled from "../../assets/orders_tab_enabled.png";
import points_tab_disabled from "../../assets/points_tab_disabled.png";
import points_tab_enabled from "../../assets/points_tab_enabled.png";
import web_square from "../../assets/web_square.png";
import { CPCard } from "../../components/CPCard";
import { CPCloseButton } from "../../components/CPCloseButton";
import { CPDashboardSpacer } from "../../components/CPDashboardSpacer";
import { CPDataChart } from "../../components/CPDataChart";
import { CPDownloadButton } from "../../components/CPDownloadButton";
import { CPDropdown } from "../../components/CPDropdown";
import { CPHiddenDiv } from "../../components/CPHiddenDiv";
import { CPPieChartNew } from "../../components/CPPieChartNew";
import { CPSearchBar } from "../../components/CPSearchBar";
import { CPTabBar } from "../../components/CPTabBar";
import { CPText } from "../../components/CPText";
import { CustomizableDataCardBlock } from "../../components/CustomizableDataCardBlock";
import { DataCard } from "../../components/DataCard";
import { DemographicsChart } from "../../components/DemographicsChart";
import { MonthlyDailyWeeklyChart } from "../../components/MonthlyDailyWeeklyChart";
import {
  allSeasonsOption,
  attendingLabel,
  cpBoldMainHeaderStyle,
  cpBoldSubHeaderStyle,
  cpGray77,
  cpGrayD7,
  cpGrayF7,
  cpLargeCardHeight,
  dashboardColor1,
  membersOnlyLabel,
  standardDashboardPadding,
  watchingLabel,
  withGuestsLabel,
} from "../../helpers/constants";
import { combinedObject } from "../../helpers/generic-helpers";
import { formatCommas } from "../../helpers/name-helpers";
import { parseDateKeyAndFormat } from "../../helpers/time-helpers";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import {
  exportAsCSVByCol,
  genericDemographicsDownload,
  getAttributeFromObject,
  getColorStringFromKey,
  getTableCellColorFromRowIndex,
  percentageFromNumber,
  setColEmailsAndNames,
} from "../../types/util-types";
import { BudgetProgressBar } from "../stadiums/components/BudgetProgressBar";
import { NoMatchPage } from "../NoMatchPage";
import CPBackdrop from "../../components/CPBackdrop";
import { useBackdrop } from "../../hooks/useBackdrop";

const useStyles = makeStyles((theme) => ({
  card: {
    flexBasis: theme.typography.pxToRem(200),
    flex: "1 0 auto",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      flexBasis: theme.typography.pxToRem(280),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface UrlParams {
  teamId: string;
}

export const TeamOverviewPage = observer(() => {
  const urlParams = useParams<UrlParams>();
  const { session } = useStore().ui;
  const { teamId } = useParams<{ teamId: string }>();
  const { user } = useStore().ui.session;
  const team = user?.type?.identifier?.team;
  if (team !== teamId) {
    //Page not found
    return <NoMatchPage />;
  }
  const { teamOverviewPage } = useStore().ui;
  const [selectedSeasonFilter, setSelectedSeasonFilter] = React.useState<any>();
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  //Backdrop
  const [barBackdropOpen, setBarBackdropOpen] = React.useState<boolean>(false);
  const [barBackdropTitle, setBarBackdropTitle] = React.useState<string>("");
  const [barBackdropUserIds, setBarBackdropUserIds] = React.useState<string[]>(
    []
  );
  const [barBackdropDateKey, setBarBackdropDateKey] =
    React.useState<string>("");
  const [barBackdropSearchTerm, setBarBackdropSearchTerm] =
    React.useState<string>("");
  const [barBackdropOnDownload, setBarBackdropOnDownload] =
    React.useState<any>();
  const [barBackdropOptions, setBarBackdropOptions] = React.useState<any>({});

  const handleBackdrop = (
    userIds: string[],
    dateKey: string,
    title: string,
    func: any,
    options: any
  ) => {
    console.log(`handling backrop`);
    setBarBackdropOpen(true);
    setBarBackdropTitle(title);
    setBarBackdropUserIds(userIds);
    setBarBackdropDateKey(dateKey);
    setBarBackdropSearchTerm("");
    let newOptions = combinedObject(options, { specificDateKey: dateKey });
    setBarBackdropOptions(newOptions);
    setBarBackdropOnDownload(() => {
      return func;
    });
  };

  const { routerStore } = useStore();

  const classes = useStyles();

  React.useEffect(() => {
    teamOverviewPage.loadOverview(
      teamId,
      selectedSeasonFilter,
      setSelectedSeasonFilter
    );
  }, [teamOverviewPage, teamId, selectedSeasonFilter, urlParams]);

  let budgetSpent = Number((teamOverviewPage.budgetSpent || 0).toFixed(2));
  let totalBudget = teamOverviewPage.totalBudget || 999999999999;
  let budgetRemaining = (totalBudget - budgetSpent).toFixed(2);
  let seasonOptions = teamOverviewPage.seasonOptions;
  let teamDetails = teamOverviewPage.teamDetails || {};
  let collectsGenders = teamDetails["collectsGenders"] !== false;
  let isTicketScanningOnly =
    teamOverviewPage.teamDetails["editTeamsTicketScanningVisibility"] !== false;
  let attendingWatchingDataListOptions = [attendingLabel, watchingLabel];
  let dailyWeeklyMonthlyDataList =
    teamOverviewPage.dailyWeeklyMonthlyCheckinData;
  if (isTicketScanningOnly) {
    attendingWatchingDataListOptions = [watchingLabel];
    dailyWeeklyMonthlyDataList = [dailyWeeklyMonthlyDataList[1]];
  }
  let collectsBirthdays = teamDetails["collectsBirthdays"] !== false;
  let shouldShowDemographics = collectsGenders && collectsBirthdays;
  let shouldShowBudget = session.teamInfo["editTeamsBudgetVisibility"];
  let platformDisplayDetails: any = {
    ios: {
      icon: apple_square,
      label: "iOS",
    },
    android: {
      icon: android_square,
      label: "Android",
    },
    web: {
      icon: web_square,
      label: "Computer",
    },
  };

  const goToUser = (rowData: any) => {
    let userId = rowData["userId"];
    if (userId) routerStore.push(usersRoutes.userOverview(userId, teamId));
  };

  const onDownloadActiveMembers = async (options: any) => {
    let timeframe = options["timeframe"];
    let specificDateKey = options["specificDateKey"];
    let userIdSpecificData =
      await teamOverviewPage.getUserIdSpecificActiveMembersData(timeframe);
    let allUserInfo = await teamOverviewPage.getAllUserInfo();
    let csvKeys: string[] = [];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      csvKeys.push(`${dateKey} Email`);
      csvKeys.push(`${dateKey} Name`);
    });
    let cols: any = {};
    let colIndexArr = [0];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      setColEmailsAndNames(
        dateKey,
        colIndexArr,
        cols,
        userIdSpecificData,
        allUserInfo
      );
    });
    exportAsCSVByCol(csvKeys, cols, `Active Members (${timeframe})`);
  };

  const onDownloadUsersWhoWereActive = async (options: any) => {
    let timeframe = options["timeframe"];
    let specificDateKey = options["specificDateKey"];
    let userIdSpecificData =
      await teamOverviewPage.getUserIdSpecificUsersWhoWereActiveData(timeframe);
    let allUserInfo = await teamOverviewPage.getAllUserInfo();
    let csvKeys: string[] = [];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      csvKeys.push(`${dateKey} Email`);
      csvKeys.push(`${dateKey} Name`);
    });
    let cols: any = {};
    let colIndexArr = [0];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      setColEmailsAndNames(
        dateKey,
        colIndexArr,
        cols,
        userIdSpecificData,
        allUserInfo
      );
    });
    exportAsCSVByCol(csvKeys, cols, `Signups (${timeframe})`);
  };

  const onDownloadSignups = async (options: any) => {
    let timeframe = options["timeframe"];
    let specificDateKey = options["specificDateKey"];
    let userIdSpecificData =
      await teamOverviewPage.getUserIdSpecificSignupsData(timeframe);
    let allUserInfo = await teamOverviewPage.getAllUserInfo();
    let csvKeys: string[] = [];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      csvKeys.push(`${dateKey} Email`);
      csvKeys.push(`${dateKey} Name`);
    });
    let cols: any = {};
    let colIndexArr = [0];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      setColEmailsAndNames(
        dateKey,
        colIndexArr,
        cols,
        userIdSpecificData,
        allUserInfo
      );
    });
    exportAsCSVByCol(csvKeys, cols, `Signups (${timeframe})`);
  };

  const onDownloadCheckins = async (options: any) => {
    let timeframe = options["timeframe"];
    let specificDateKey = options["specificDateKey"];
    let attendingOrWatching = options["dataListSelection"];
    let userIdSpecificData =
      await teamOverviewPage.getUserIdSpecificCheckInData(
        timeframe,
        attendingOrWatching
      );
    let allUserInfo = await teamOverviewPage.getAllUserInfo();
    let csvKeys: string[] = [];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      csvKeys.push(`${dateKey} Email`);
      csvKeys.push(`${dateKey} Name`);
    });
    let cols: any = {};
    let colIndexArr = [0];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      setColEmailsAndNames(
        dateKey,
        colIndexArr,
        cols,
        userIdSpecificData,
        allUserInfo
      );
    });
    exportAsCSVByCol(
      csvKeys,
      cols,
      `Check-Ins (${attendingOrWatching}) (${timeframe})`
    );
  };

  const onDownloadOrders = async (options: any) => {
    let timeframe = options["timeframe"];
    let specificDateKey = options["specificDateKey"];
    let userIdSpecificData =
      await teamOverviewPage.getUserIdSpecificOrderData(timeframe);
    let allUserInfo = await teamOverviewPage.getAllUserInfo();
    let csvKeys: string[] = [];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      csvKeys.push(`${dateKey} Email`);
      csvKeys.push(`${dateKey} Name`);
    });
    let cols: any = {};
    let colIndexArr = [0];
    Object.keys(userIdSpecificData).forEach((dateKey) => {
      if (specificDateKey && dateKey !== specificDateKey) return;
      setColEmailsAndNames(
        dateKey,
        colIndexArr,
        cols,
        userIdSpecificData,
        allUserInfo
      );
    });
    exportAsCSVByCol(csvKeys, cols, `Orders (${timeframe})`);
  };
  let scanningChart = (
    <MonthlyDailyWeeklyChart
      dailyWeeklyMonthlyDataList={
        teamOverviewPage.dailyWeeklyMonthlyScanningCheckinData
      }
      title="Game Day Scanned Check-Ins"
      dataKeyName="Scans"
      selectedSeasonFilter={selectedSeasonFilter}
      usersCount={teamOverviewPage.getUsersCountByTimeframe}
      dataListOptions={[membersOnlyLabel, withGuestsLabel]}
      ignoreLimit={5}
      handleBackdrop={async (options: any, dateKey: string) => {
        let timeframe = options["timeframe"];
        let attendingOrWatching = options["dataListSelection"];
        let userIdSpecificData =
          (await teamOverviewPage.getUserIdSpecificCheckInData(
            timeframe,
            attendingOrWatching
          )) || {};
        let userIds = userIdSpecificData[dateKey];
        handleBackdrop(
          userIds,
          dateKey,
          "Check-Ins",
          onDownloadCheckins,
          options
        );
      }}
      onDownload={onDownloadCheckins}
    />
  );
  let engagedMembersChart = (
    <CPCard cardPadding="10px 20px 10px 20px">
      <Box height="100%">
        <Box flex={1}>
          <CPDataChart
            title="Most Engaged Members"
            dataKeyList={[
              "nameAndAvatar",
              "totalActiveDays",
              "currentStreak",
              "longestStreak",
              "engagementRate",
            ]}
            separateLabelMap={{ nameAndAvatar: "Name" }}
            displayFunctionMap={{
              engagementRate: (value: any) => {
                return percentageFromNumber(Number(`${value}`));
              },
            }}
            data={teamOverviewPage.topMostEngagedMembers(teamId)}
            flexList={[3, 3, 3, 3, 3]}
            defaultSort="totalActiveDays"
            onRowClick={goToUser}
            exportCSVKeys={[
              "fullName",
              "totalActiveDays",
              "currentStreak",
              "longestStreak",
              "engagementRate",
            ]}
          />
        </Box>
      </Box>
    </CPCard>
  );

  let adoptionBody = (
    <div>
      <Box display={"flex"}>
        <Box flex={1}>
          <CustomizableDataCardBlock
            {...teamOverviewPage.adoptionDataBlock}
            itemsPerRow={2}
            height={cpLargeCardHeight}
          />
        </Box>
        {shouldShowDemographics && <CPDashboardSpacer />}
        {shouldShowDemographics && (
          <Box flex={1} height={cpLargeCardHeight}>
            <DemographicsChart
              demographics={teamOverviewPage.userDemographics}
              height={cpLargeCardHeight}
              onDownload={async () => {
                let demographics =
                  await teamOverviewPage.getUserIdSpecificDemographics();
                let allUserInfo = await teamOverviewPage.getAllUserInfo();
                genericDemographicsDownload(demographics, allUserInfo);
              }}
            />
          </Box>
        )}
      </Box>
      <Box display={"flex"} mt={standardDashboardPadding}>
        <Box flex={1}>
          <MonthlyDailyWeeklyChart
            dailyWeeklyMonthlyDataList={
              teamOverviewPage.dailyWeeklyMonthlyActiveMembersData
            }
            title="Member Engagement"
            dataKeyName="Members"
            usersCount={
              teamOverviewPage.getUsersCountExcludingInactiveByTimeframe
            }
            selectedSeasonFilter={selectedSeasonFilter}
            handleBackdrop={async (options: any, dateKey: string) => {
              let timeframe = options["timeframe"];
              let userIds =
                ((await teamOverviewPage.getUserIdSpecificActiveMembersData(
                  timeframe
                )) || {})[dateKey];
              handleBackdrop(
                userIds,
                dateKey,
                "Member Engagement",
                onDownloadActiveMembers,
                options
              );
            }}
            onDownload={onDownloadActiveMembers}
          />
        </Box>
        <CPDashboardSpacer />
        <Box flex={1}>
          <MonthlyDailyWeeklyChart
            dailyWeeklyMonthlyDataList={
              teamOverviewPage.dailyWeeklyMonthlySignUpsData
            }
            title="Sign Ups"
            dataKeyName="Members"
            selectedSeasonFilter={selectedSeasonFilter}
            handleBackdrop={async (options: any, dateKey: string) => {
              let timeframe = options["timeframe"];
              let userIds =
                ((await teamOverviewPage.getUserIdSpecificSignupsData(
                  timeframe
                )) || {})[dateKey];
              handleBackdrop(
                userIds,
                dateKey,
                "Signups",
                onDownloadSignups,
                options
              );
            }}
            onDownload={onDownloadSignups}
          />
        </Box>
      </Box>
      <Box display={"flex"} mt={standardDashboardPadding}>
        <Box flex={1}>
          <MonthlyDailyWeeklyChart
            dailyWeeklyMonthlyDataList={dailyWeeklyMonthlyDataList}
            title="Game Day Check-Ins"
            dataKeyName="Check-Ins"
            selectedSeasonFilter={selectedSeasonFilter}
            usersCount={teamOverviewPage.getUsersCountByTimeframe}
            dataListOptions={attendingWatchingDataListOptions}
            ignoreLimit={5}
            handleBackdrop={async (options: any, dateKey: string) => {
              let timeframe = options["timeframe"];
              let attendingOrWatching = options["dataListSelection"];
              let userIdSpecificData =
                (await teamOverviewPage.getUserIdSpecificCheckInData(
                  timeframe,
                  attendingOrWatching
                )) || {};
              let userIds = userIdSpecificData[dateKey];
              handleBackdrop(
                userIds,
                dateKey,
                "Check-Ins",
                onDownloadCheckins,
                options
              );
            }}
            onDownload={onDownloadCheckins}
          />
        </Box>
        <CPDashboardSpacer />
        <Box flex={1}>
          {isTicketScanningOnly ? scanningChart : engagedMembersChart}
        </Box>
      </Box>
      <CPDashboardSpacer />

      {isTicketScanningOnly ? (
        <Box mb={standardDashboardPadding}>{engagedMembersChart}</Box>
      ) : (
        <></>
      )}
      <Box display={"flex"} mt={standardDashboardPadding}>
        <Box flex={1}>
          <MonthlyDailyWeeklyChart
            dailyWeeklyMonthlyDataList={
              teamOverviewPage.dailyWeeklyMonthlyUsersWhoWereActiveData
            }
            title="Active Users Over Time"
            dataKeyName="Members"
            selectedSeasonFilter={selectedSeasonFilter}
            usersCount={teamOverviewPage.getUsersCountByTimeframe}
            ignoreLimit={5}
            handleBackdrop={async (options: any, dateKey: string) => {
              let timeframe = options["timeframe"];
              let userIdSpecificData =
                (await teamOverviewPage.getUserIdSpecificUsersWhoWereActiveData(
                  timeframe
                )) || {};
              let userIds = userIdSpecificData[dateKey];
              handleBackdrop(
                userIds,
                dateKey,
                "Members",
                onDownloadUsersWhoWereActive,
                options
              );
            }}
            onDownload={onDownloadUsersWhoWereActive}
          />
        </Box>
      </Box>
      <Box height="160px">
        <CPCard cardPadding="10px 20px 10px 20px">
          <Box height="100%">
            <Box>
              <Box height="35px">
                <Typography component="div">
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    fontWeight="fontWeightBold"
                    fontSize={14}
                  >
                    <Box pt={"10px"} style={cpBoldSubHeaderStyle}>
                      {"Member Device"}
                    </Box>
                    <CPDownloadButton
                      onDownload={async () => {
                        let allUserInfo =
                          await teamOverviewPage.getAllUserInfo();
                        let csvKeys: string[] = [];
                        let userIdSpecificData =
                          await teamOverviewPage.userIdSpecificPlatformData;
                        Object.keys(platformDisplayDetails).forEach(
                          (platform) => {
                            csvKeys.push(`${platform} Email`);
                            csvKeys.push(`${platform} Name`);
                          }
                        );
                        let cols: any = {};
                        let colIndexArr = [0];
                        Object.keys(platformDisplayDetails).forEach(
                          (platform) => {
                            setColEmailsAndNames(
                              platform,
                              colIndexArr,
                              cols,
                              userIdSpecificData,
                              allUserInfo
                            );
                          }
                        );
                        exportAsCSVByCol(csvKeys, cols, `Member Device`);
                      }}
                    />
                  </Box>
                </Typography>
              </Box>
              <Box>
                {
                  <Box display={"flex"} gridGap="20px">
                    {Object.keys(platformDisplayDetails).map(
                      (platform: string) => {
                        let icon = getAttributeFromObject(
                          `${platform}/icon`,
                          platformDisplayDetails
                        );
                        let label = getAttributeFromObject(
                          `${platform}/label`,
                          platformDisplayDetails
                        );
                        let count =
                          teamOverviewPage.platformCountData[platform] || 0;
                        return (
                          <Box
                            marginTop={"20px"}
                            display={"flex"}
                            alignItems="center"
                            height={"54px"}
                            padding="10px 24px 10px 24px"
                            style={{
                              backgroundColor: cpGrayF7,
                              borderRadius: "8px",
                            }}
                            flex={1}
                          >
                            <Box height={"32px"}>
                              <img height={"32px"} src={icon}></img>
                            </Box>
                            <Box ml={"10px"}>{label}</Box>
                            <Box flex={1}></Box>
                            <Box>{`${count} Members`}</Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                }
              </Box>
            </Box>
          </Box>
        </CPCard>
      </Box>

      <CPDashboardSpacer />
      <CPDashboardSpacer />
    </div>
  );
  let pointsBody = (
    <div>
      <Box display={"flex"}>
        <Box flex={1}>
          <CustomizableDataCardBlock
            {...teamOverviewPage.pointsDataBlock}
            itemsPerRow={2}
            height={cpLargeCardHeight}
          />
        </Box>
        <CPDashboardSpacer />
        <Box flex={1}>
          <CPDataChart
            title="Most Points By Member"
            dataKeyList={["nameAndAvatar", "points"]}
            separateLabelMap={{ nameAndAvatar: "Name" }}
            data={teamOverviewPage.mostPointsByMember(teamId)}
            flexList={[5, 3]}
            defaultSort="points"
            onRowClick={goToUser}
            exportCSVKeys={["fullName", "points"]}
          />
        </Box>
      </Box>
      <CPDashboardSpacer />
      <CPDashboardSpacer />
    </div>
  );
  let ordersBody = (
    <div>
      <Box flex={1} height={"160px"}>
        <CustomizableDataCardBlock
          {...teamOverviewPage.ordersDataBlock}
          itemsPerRow={2}
          height={"140px"}
        />
      </Box>
      <Box display={"flex"}>
        <Box flex={1}>
          {/* By Orders, we mean users who have placed an order */}
          <MonthlyDailyWeeklyChart
            dailyWeeklyMonthlyDataList={
              teamOverviewPage.dailyWeeklyMonthlyOrderPlacersData
            }
            title="Orders"
            dataKeyName="Orders"
            selectedSeasonFilter={selectedSeasonFilter}
            handleBackdrop={async (options: any, dateKey: string) => {
              let timeframe = options["timeframe"];
              let userIdSpecificData =
                (await teamOverviewPage.getUserIdSpecificOrderData(
                  timeframe
                )) || {};
              let userIds = userIdSpecificData[dateKey];
              handleBackdrop(
                userIds,
                dateKey,
                "Orders",
                onDownloadOrders,
                options
              );
            }}
            onDownload={onDownloadOrders}
          />
        </Box>
        <CPDashboardSpacer />
        <Box flex={1} height={cpLargeCardHeight}>
          <CPPieChartNew
            title="Orders by type"
            data={teamOverviewPage.ordersByProductType}
          />
        </Box>
      </Box>
      <CPDashboardSpacer />
      <Box display={"flex"}>
        <Box flex={1}>
          <CPDataChart
            title="Top Orders"
            dataKeyList={["name", "nOrders"]}
            separateLabelMap={{ nOrders: "Order Count" }}
            data={teamOverviewPage.orderedProducts}
            flexList={[5, 3]}
            defaultSort="nOrders"
            onRowClick={() => console.log("hi")}
            exportCSVKeys={["name", "nOrders"]}
          />
        </Box>
        <CPDashboardSpacer />
        <Box flex={1}>
          <CPDataChart
            title="Most Orders By Member"
            dataKeyList={["nameAndAvatar", "orders"]}
            separateLabelMap={{ nameAndAvatar: "Name" }}
            data={teamOverviewPage.numOrdersByMember(teamId)}
            flexList={[5, 3]}
            defaultSort="orders"
            onRowClick={goToUser}
            exportCSVKeys={["fullName", "orders"]}
          />
        </Box>
      </Box>
      <CPDashboardSpacer />
      <CPDashboardSpacer />
    </div>
  );

  return (
    <MainLayout
      isOverview={true}
      loading={teamOverviewPage.loading}
      firebaseConnector={teamOverviewPage.firebaseConnector}
      pageActions={
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box display="flex">
              <Box style={cpBoldMainHeaderStyle}>Dashboard</Box>
            </Box>

            <CPDropdown
              options={seasonOptions}
              selectedOptionFilter={selectedSeasonFilter}
              setSelectedOptionFilter={setSelectedSeasonFilter}
              allOption={allSeasonsOption}
            />
          </Box>
        </div>
      }
    >
      {shouldShowBudget && (
        <DataCard
          title="Budget"
          child={
            <>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
              >
                <div> {`\$${formatCommas(`${budgetSpent}`)}`} </div>
                <div> {`\$${formatCommas(`${totalBudget}`)}`} </div>
              </Box>
              <div style={{ height: "10px" }} />
              <BudgetProgressBar min={0} max={totalBudget} cur={budgetSpent} />
              <Box justifyContent="space-between" display={"flex"}>
                <div></div>
                <CPText
                  color={cpGray77}
                  fontSize={16.0}
                  title={`\$${formatCommas(`${budgetRemaining}`)} remaining`}
                />
              </Box>
            </>
          }
        />
      )}
      <CPTabBar
        tabs={[
          {
            label: "Adoption",
            icon: adoption_tab_enabled,
            disabled_icon: adoption_tab_disabled,
          },
          {
            label: "Points",
            icon: points_tab_enabled,
            disabled_icon: points_tab_disabled,
          },
          {
            label: "Orders",
            icon: orders_tab_enabled,
            disabled_icon: orders_tab_disabled,
          },
        ]}
        selected={selectedTab}
        onTabChange={setSelectedTab}
      />
      <Box width={"100%"} height="2px" bgcolor={cpGrayD7}></Box>
      <CPDashboardSpacer />
      <div style={{ position: "relative", paddingBottom: "130px" }}>
        <CPHiddenDiv hidden={selectedTab != 0} child={adoptionBody} />
        <CPHiddenDiv hidden={selectedTab != 1} child={pointsBody} />
        <CPHiddenDiv hidden={selectedTab != 2} child={ordersBody} />
      </div>
      <CPBackdrop
        userDetailsByUserId={teamOverviewPage.userDetailsByUserId}
        userIds={barBackdropUserIds}
        barBackdropDateKey={barBackdropDateKey}
        barBackdropOpen={barBackdropOpen}
        setBarBackdropOpen={setBarBackdropOpen}
        barBackdropTitle={barBackdropTitle}
        barBackdropSearchTerm={barBackdropSearchTerm}
        barBackdropOnDownload={barBackdropOnDownload}
        barBackdropOptions={barBackdropOptions}
        setBarBackdropSearchTerm={setBarBackdropSearchTerm}
      />
    </MainLayout>
  );
});
