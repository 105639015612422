import { LoyaltyEditForm } from "./components/LoyaltyEditForm";

import React, { useRef } from "react";
import { MainLayout } from "../../layouts/MainLayout";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { PageTitle } from "../../components/PageTitle";
import { useParams } from "react-router-dom";
import { useStore } from "../../helpers/use-store";
import { LoyaltyItem, loyaltyItemDefaults } from "../../types/loyalties-types";
import { loyaltyRoutes } from "../../app-routes";
import { HeaderedBox } from "../../components/HeaderedBox";
import QRCode from "react-qr-code";
import { DownloadableQRCode } from "../../components/DownloadableQRCode";
import { CPDisplayTextField } from "../../components/CPDisplayTextField";
import { TableContainer } from "../../components/TableContainer";
import { SortableTable } from "../../components/SortableTable";
import { formatDate, formatTime } from "../../helpers/time-helpers";
import moment from "moment";
import { CPCard } from "../../components/CPCard";
import {
  cpBoldSubHeaderStyle,
  cpPageTitleStyle,
} from "../../helpers/constants";
import { getTableCellColorFromRowIndex } from "../../types/util-types";
import { formatCommas, splitCamelCase } from "../../helpers/name-helpers";

export const LoyaltyDetailsPage = observer(() => {
  const { code } = useParams<{ code?: string }>();

  const { loyaltyEditPage, session } = useStore().ui;
  const { routerStore } = useStore();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleMenuClick(event: any) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  React.useEffect(() => {
    if (code) loyaltyEditPage.loadByCode(code, false);
  }, [code, loyaltyEditPage]);

  React.useEffect(() => {
    loyaltyEditPage.loadCategoryOptions();
    loyaltyEditPage.loadPointOptions();
    loyaltyEditPage.loadCurrentSeasonId();
    loyaltyEditPage.getAllSeasons();
    loyaltyEditPage.getAllCodes();
  }, [loyaltyEditPage]);

  const formRef = React.createRef<HTMLButtonElement>();
  const downloadRef = useRef();

  const codeDetails: LoyaltyItem = code
    ? loyaltyEditPage.editableLoyalty
    : { ...loyaltyItemDefaults() };
  let codeRedemptions: any = loyaltyEditPage.codeRedemptions;
  let usersAbridged: any = loyaltyEditPage.usersAbridged || {};
  let redemptionsFormattedForTable: any[] = [];
  if (codeRedemptions)
    Object.keys(codeRedemptions)?.forEach((userId) => {
      let userDetails = usersAbridged[userId] || {};
      let name = userDetails["fullName"] || "";
      let timestamp = codeRedemptions[userId];
      let date = formatDate(timestamp);
      let time = formatTime(timestamp);
      let redemptionDetails = {
        name,
        date,
        time,
      };
      console.log(`adding redemption: ${redemptionDetails["name"]}`);
      redemptionsFormattedForTable.push(redemptionDetails);
    });
  let codeId = codeDetails.id;
  let title = codeDetails.loyaltyCodeTitle;
  let points = codeDetails.loyaltyCodePoints;
  let activeDate = codeDetails.loyaltyCodeActiveDate;
  let expirationDate = codeDetails.loyaltyCodeExpirationDate;
  let totalRedemptions = codeDetails.loyaltyCodeNRedemptions;
  let totalPointsRedeemed = (totalRedemptions || 0) * parseFloat(`${points}`);
  let enabled = codeDetails.loyaltyCodeVisibility;
  if (!codeDetails.loyaltyCodeSeasonId)
    codeDetails.loyaltyCodeSeasonId = loyaltyEditPage.seasonId;
  let pointsRewarded = points;

  let detailsRowCounter = 0;
  let detailsMap: any = {
    pointsRewarded,
    activeDate,
    expirationDate,
    totalRedemptions,
    totalPointsRedeemed,
  };

  return (
    <MainLayout
      loading={loyaltyEditPage.loading}
      firebaseConnector={loyaltyEditPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title={`${title}`} />
          <Box display="flex" alignItems="center">
            <Box width={5} />
            <Box width={180}>
              <Menu
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                open={Boolean(anchorEl)}
              >
                <MenuItem
                  onClick={() =>
                    routerStore.push(loyaltyRoutes.editLoyalty(`${code}`))
                  }
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    handleMenuClose();
                    if (code && codeId)
                      await loyaltyEditPage.toggleLoyaltyCode(
                        `${code}`,
                        `${codeId}`,
                        enabled,
                      );
                    if (code) loyaltyEditPage.loadByCode(code, true);
                  }}
                >
                  {enabled ? "Disable" : "Enable"}
                </MenuItem>
              </Menu>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={(e) => {
                  handleMenuClick(e);
                }}
              >
                {"Actions"}
              </Button>
            </Box>
          </Box>
        </Box>
      }
    >
      <Box display={"flex"}>
        <Box flex={2}>
          <HeaderedBox
            title="Active Reward Details"
            child={
              <>
                {
                  <div>
                    <Box fontWeight="fontWeightBold" fontSize={14}>
                      {title}
                    </Box>

                    <div>{`Points: ${points}`}</div>
                    <Box height={"20px"} />
                    <Box display={"flex"}>
                      <Box
                        border="1px solid rgba(205, 205, 217, 1)"
                        m={"auto"}
                        p={8}
                        fontWeight="fontWeightBold"
                        fontSize={44}
                        alignContent="center"
                        style={{ borderRadius: "4px" }}
                      >
                        {code}
                      </Box>
                      <Box width="50%" pt={6}>
                        <DownloadableQRCode
                          height="190px"
                          size={140}
                          downloadRef={downloadRef}
                          codeText={`${code}`}
                        />
                      </Box>
                    </Box>
                  </div>
                }
              </>
            }
          />
        </Box>
        <Box width={"20px"}></Box>
        <Box flex={1}>
          <CPCard>
            <Box
              padding="40px 60px 0px 60px"
              flex="4"
              display="flex"
              flexDirection={"column"}
            >
              <Box style={cpPageTitleStyle}>Details</Box>
              <Box height="30px"></Box>
              {Object.keys(detailsMap).map((key: string) => {
                let value = detailsMap[key];
                if (detailsMap[key]) {
                  let bgColor =
                    getTableCellColorFromRowIndex(detailsRowCounter);
                  detailsRowCounter += 1;
                  return (
                    <Box
                      padding="0px 18px 0 18px"
                      bgcolor={bgColor}
                      width="100%"
                      height="52px"
                      display="flex"
                      alignItems={"center"}
                    >
                      <Box style={cpBoldSubHeaderStyle}>
                        {splitCamelCase(key, true)}
                      </Box>
                      <Box flex="1" />
                      <Box style={cpPageTitleStyle}>{formatCommas(value)}</Box>
                    </Box>
                  );
                }
                return <Box />;
              })}
            </Box>
          </CPCard>
        </Box>
      </Box>
      <Box mt={10}>
        <TableContainer>
          <SortableTable
            title="Redemptions"
            csvKeys={["name", "date", "time"]}
            height="100vh"
            columns={[
              {
                dataKey: "name",
                label: "Name",
                flexGrow: 3,
                width: 40,
              },
              {
                dataKey: "date",
                label: "Date",
                flexGrow: 5,
                width: 70,
              },
              {
                dataKey: "time",
                label: "Time",
                flexGrow: 4,
                width: 100,
              },
            ]}
            rows={redemptionsFormattedForTable}
            // withChevron
          />
        </TableContainer>
      </Box>
    </MainLayout>
  );
});
