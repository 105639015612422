import { makeAutoObservable } from "mobx";
import { Loading } from "../../helpers/loading";
import { DomainsStore } from "../domains";
import { UiStore } from "./ui-store";
import { API } from "../connectors/api-connector";
import {
  LoyaltyAnaltyicsResponse,
  loyaltyAnalyticsStatsDefaults,
  CategoryListItem,
  categoryListItemDefaults,
} from "../../types/loyalties-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { DailyWeeklyMonthlyData } from "../../types/chart-types";

export class LoyaltiesPageStore {
  public loading: Loading = Loading.NOT_LOADED;
  private loyaltyAnalyticsStats: LoyaltyAnaltyicsResponse =
    loyaltyAnalyticsStatsDefaults();
  private activeRewardRedemptions: any = {};

  constructor(
    private uiStore: UiStore,
    private domainsStore: DomainsStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get userId() {
    return this.uiStore.session.userId;
  }

  get teamId() {
    return this.uiStore.session.userTeamId;
  }

  get activeLoyalties() {
    return this.domainsStore.loyaltiesDomain.activeLoyalties;
  }

  get expiredLoyalties() {
    return this.domainsStore.loyaltiesDomain.expiredLoyalties;
  }

  get featuredLoyalties() {
    return this.domainsStore.loyaltiesDomain.featuredLoyalties;
  }

  get redemptionsByCategory() {
    return this.loyaltyAnalyticsStats.nRedemptionsByCategory;
  }

  get totalRedemptions() {
    return this.loyaltyAnalyticsStats.nCodeRedemptions;
  }

  get categoryNameByCategoryId() {
    return this.loyaltyAnalyticsStats.categoryNameByCategoryId;
  }

  get hiddenCategoryIds() {
    return this.loyaltyAnalyticsStats.hiddenCategoryIds || [];
  }

  weekdayMap: { [key: string]: string } = {
    n1: "Monday",
    n2: "Tuesday",
    n3: "Wednesday",
    n4: "Thursday",
    n5: "Friday",
    n6: "Saturday",
    n7: "Sunday",
  };

  monthMap: { [key: string]: string } = {
    n0: "January",
    n1: "February",
    n2: "March",
    n3: "April",
    n4: "May",
    n5: "June",
    n6: "July",
    n7: "August",
    n8: "September",
    n9: "October",
    n10: "November",
    n11: "December",
  };

  codeRedemptionsData(timeframe: string) {
    if (timeframe === "weekday") return this.totalCodesWeekdayBarData;
    if (timeframe === "month") return this.totalCodesMonthBarData;
    return [{}];
  }

  get totalCodesWeekdayBarData() {
    return Object.keys(
      this.loyaltyAnalyticsStats.nTotalCodeRedemptionsByWeekday
    )
      .sort((a, b) => {
        const aa = a.replace(/[^0-9 ]/g, "");
        const bb = b.replace(/[^0-9 ]/g, "");
        return Number(aa) - Number(bb);
      })
      .map((key) => {
        return {
          name: this.weekdayMap[key],
          amount:
            this.loyaltyAnalyticsStats.nTotalCodeRedemptionsByWeekday[key],
        };
      });
  }

  get totalCodesMonthBarData() {
    return Object.keys(this.loyaltyAnalyticsStats.nTotalCodeRedemptionsByMonth)
      .sort((a, b) => {
        const aa = a.replace(/[^0-9 ]/g, "");
        const bb = b.replace(/[^0-9 ]/g, "");
        return Number(aa) - Number(bb);
      })
      .map((key) => {
        return {
          name: this.monthMap[key],
          amount: this.loyaltyAnalyticsStats.nTotalCodeRedemptionsByMonth[key],
        };
      });
  }

  get dailyWeeklyMonthlyRedemptionData(): DailyWeeklyMonthlyData[] {
    return [this.activeRewardRedemptions];
  }

  get categoryRows() {
    let redemptionsByCategory =
      this.loyaltyAnalyticsStats.nRedemptionsByCategory;
    let tempCategoriesWithCount: CategoryListItem[] = [];
    Object.keys(redemptionsByCategory).forEach((categoryId) => {
      let listItem: CategoryListItem = categoryListItemDefaults();
      listItem.title =
        this.loyaltyAnalyticsStats.categoryNameByCategoryId[categoryId];
      listItem.count = `${redemptionsByCategory[categoryId]}`;
      listItem.id = categoryId;
      listItem.key = categoryId;
      console.log(
        `hiddenCategoryIds: ${this.loyaltyAnalyticsStats.hiddenCategoryIds}`
      );
      if (
        !(this.loyaltyAnalyticsStats.hiddenCategoryIds || []).includes(
          categoryId
        )
      )
        tempCategoriesWithCount.push(listItem);
    });

    return tempCategoriesWithCount;
  }

  get loyaltyAnalyticsDataBlock() {
    const {
      nCodes,
      nCodeRedemptions,
      totalPointsFromRedemptions,
      hottestCategory,
      categoryNameByCategoryId,
    } = this.loyaltyAnalyticsStats;
    return {
      items: [
        { title: "Total Codes", value: nCodes || 0 },
        { title: "Total Code Redemptions", value: nCodeRedemptions || 0 },
        {
          title: "Hottest Category",
          value: `${categoryNameByCategoryId[hottestCategory] || ""}`,
        },
        // { title: "Total Points Redeemed", value: totalPointsRedeemed || 0},
        //Broken above
        {
          title: "Total Points Redeemed",
          value: totalPointsFromRedemptions || 0,
        },
      ],
    };
  }

  loadLoyalties = async () => {
    let teamId = this.teamId;
    // let seasonId = this.seasonId;
    try {
      this.loading = Loading.LOADING;
      await this.domainsStore.loyaltiesDomain.loadLoyalties(
        true,
        this.userId,
        this.teamId
      );
      console.log(`attempting teamId: ${teamId}`);
      const [stats] = await Promise.all([
        await API.post<{ data: LoyaltyAnaltyicsResponse }>(
          "/loyaltiesAnalytics",
          { data: { teamId } }
        ),
      ]);
      console.log(`done with teamId: ${teamId}`);
      this.loyaltyAnalyticsStats = stats.data.data;
      let totalPointsFromRedemptions =
        await this.firebaseConnector.getDatabaseObjAtPath(
          `tracking/seasonlessTeamOverview/${teamId}/totalPointsFromActiveRewards`
        );
      this.activeRewardRedemptions["daily"] =
        await this.firebaseConnector.getDatabaseObjAtPath(
          `tracking/seasonlessTeamOverview/${teamId}/dailyActiveRewardRedemptions`
        );
      this.activeRewardRedemptions["weekly"] =
        await this.firebaseConnector.getDatabaseObjAtPath(
          `tracking/seasonlessTeamOverview/${teamId}/weeklyActiveRewardRedemptions`
        );
      this.activeRewardRedemptions["monthly"] =
        await this.firebaseConnector.getDatabaseObjAtPath(
          `tracking/seasonlessTeamOverview/${teamId}/monthlyActiveRewardRedemptions`
        );
      this.loyaltyAnalyticsStats["totalPointsFromRedemptions"] =
        totalPointsFromRedemptions;
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };

  deleteLoyaltyCode = async (id: string) => {
    try {
      this.loading = Loading.LOADING;
      await this.domainsStore.loyaltiesDomain.deleteCode(
        id,
        this.userId,
        this.teamId
      );
      await this.domainsStore.loyaltiesDomain.loadLoyalties(
        true,
        this.userId,
        this.teamId
      );
      this.loading = Loading.SUCCESS;
      this.uiStore.notifications.showSuccess("Code has been deleted.");
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };

  toggleLoyaltyCodeVisibility = async (id: string, visibility: boolean) => {
    try {
      this.loading = Loading.LOADING;
      await this.domainsStore.loyaltiesDomain.toggleCodeVisiblity(
        id,
        visibility,
        this.teamId
      );
      await this.domainsStore.loyaltiesDomain.loadLoyalties(
        true,
        this.userId,
        this.teamId
      );
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };

  toggleFeatured = async (id: string, newBoolean: boolean) => {
    try {
      this.loading = Loading.LOADING;
      let path = `fanzoneItemsBySectionByTeam/${this.teamId}/activeRewards/${id}`;

      if (newBoolean) {
        await this.firebaseConnector.setDatabasePathAsObj(
          `${path}/visibility`,
          true
        );
      } else {
        await this.firebaseConnector.setDatabasePathAsObj(`${path}`, null);
      }
      await this.domainsStore.loyaltiesDomain.loadLoyalties(
        true,
        this.userId,
        this.teamId
      );
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
