import React from "react";
import { Box, Typography } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { formatDate } from "../helpers/time-helpers";

interface Props {
  date: string;
}

export const DateCell: React.FC<Props> = ({ date }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <Typography color="secondary">
          <CalendarTodayIcon />
        </Typography>
      </Box>
      {formatDate(date)}
    </Box>
  );
};
