import { colors } from "@material-ui/core";
import { Color } from "react-color";
import { primaryColorString } from "./theme";

let detailsByOrderStatus: any = {
  "1": {
    label: "Approved",
    color: "green",
  },
  "2": {
    label: "Rejected",
    color: "red",
  },
  "3": {
    label: "Awaiting Approval",
    color: "#FF5F00",
  },
};

export const getStatusLabelFromStatus = (status: any): string => {
  let details = detailsByOrderStatus[`${status}`];
  if (!details) return "N/A";
  return details["label"] || "N/A";
};

export const getTextColorFromStatus = (status: any): string => {
  let details = detailsByOrderStatus[`${status}`];
  if (!details) return "black";
  return details["color"] || "black";
};

export const getFirstVariantNameFromCombination = (
  combination: string,
): string => {
  if (!combination) return "";
  let variantName = "";
  let splits = combination.split("::");
  if (splits.length > 0) {
    let nameSplit = splits[0].split("=");
    if (nameSplit.length >= 2) {
      return nameSplit[1];
    }
  }
  return variantName;
};

export const getVariantNameFromCombination = (combination: string): string => {
  if (!combination) return "";
  let variantName = "";
  let splits = combination.split("::");
  for (let i = 0; i < splits.length; i++) {
    let nameSplit = splits[i].split("=");
    if (nameSplit.length >= 2) {
      if (variantName) variantName += ", ";
      variantName += nameSplit[1];
    }
  }
  return variantName;
};

export const getAddressNameFromShippingData = (shippingData: any): string => {
  let addressName = "";
  addressName += shippingData["address1"]
    ? `${shippingData["address1"]}, `
    : "";
  addressName += shippingData["address2"] ? `${shippingData["address2"]},` : "";
  addressName += `\n`;
  addressName += shippingData["city"] ? `${shippingData["city"]}, ` : "";
  addressName += shippingData["uSState"] ? `${shippingData["uSState"]}, ` : "";
  addressName += shippingData["zipCode"] ? `${shippingData["zipCode"]}` : "";
  return addressName;
};

export const getPickupDateFromPickupData = (pickupData: any): string => {
  return pickupData["pickupDate"] || "";
};

export const getPickupNameFromPickupData = (pickupData: any): string => {
  let pickupDate = pickupData["pickupDate"];
  let pickupLocation = pickupData["pickupLocation"];
  let pickupStart = pickupData["pickupStart"];
  let pickupEnd = pickupData["pickupEnd"];
  let pickupAdditionalDetails = pickupData["pickupAdditionalDetails"];

  return `
  ${pickupDate}
  ${pickupStart} - ${pickupEnd}
  ${pickupLocation}
  ${pickupAdditionalDetails}
  `;
};

export const unableToContinueStatuses = ["1", "2"];
