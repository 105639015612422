import { TimePicker } from "@mui/x-date-pickers";
import { FormikProps } from "formik";
import moment from "moment";
import React from "react";
import { hMFormat } from "../types/formik-types";
import { CPFormikErrorWrapper } from "./CPFormikErrorWrapper";

interface Props<T = any> {
  name: string;
  label: string;
  formik: FormikProps<T>;
}

export const FormikTimePicker: React.FC<Props> = ({ name, label, formik }) => {
  const handleActiveTimeChange = (date: Date | null) => {
    formik.setFieldValue(name, moment(date).format(hMFormat), true);
  };
  const error = !!formik.touched[name] && formik.errors[name];

  return (
    <CPFormikErrorWrapper
      child={
        <TimePicker
          ampm={false}
          format={hMFormat}
          label={label}
          value={
            formik.values[name]
              ? moment(formik.values[name], hMFormat).toDate()
              : null
          }
          onChange={handleActiveTimeChange}
          onClose={() => {
            formik.setFieldTouched(name, true, true);
          }}
        />
      }
      errorText={error}
    />
  );
};
