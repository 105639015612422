import { Box, Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import {
  HomeGameNotificationOverview,
  PushNotificationOverview,
} from "../../types/notification-types";
import { NotificationEditForm } from "./components/NotificationEditForm";
import { useFormikContext } from "formik";

interface UrlParams {
  teamId: string;
  notificationId: string;
}

export const NotificationDetailsPage = observer(() => {
  const { notificationId } = useParams<{ notificationId: string }>();
  const { teamId } = useParams<{ teamId: string }>();
  const { typeIndex } = useParams<{ typeIndex: string }>();
  let typeIndexInt = parseInt(typeIndex) || 0;
  let isHomeGameAlert = typeIndexInt > 0;

  const { notificationDetailsPage, session } = useStore().ui;

  React.useEffect(() => {
    notificationDetailsPage.loadNotification(
      teamId,
      notificationId,
      typeIndexInt,
    );
  }, [notificationId, notificationDetailsPage]);

  const formRef = React.createRef<HTMLButtonElement>();

  let formValues = notificationDetailsPage.notification;
  return (
    <MainLayout
      firebaseConnector={notificationDetailsPage.firebaseConnector}
      loading={notificationDetailsPage.loading}
    >
      {formValues && (
        <NotificationEditForm
          activeCollections={notificationDetailsPage.activeCollections}
          formValues={formValues}
          onSave={(
            notification:
              | PushNotificationOverview
              | HomeGameNotificationOverview,
          ) => {
            notificationDetailsPage.saveNotification(
              teamId,
              notification,
              typeIndexInt,
            );
          }}
          isHomeGameAlert={isHomeGameAlert}
          formRef={formRef}
        />
      )}
    </MainLayout>
  );
});
