import React from "react";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { DataCardBlock } from "../../components/DataCardBlock";
import { OverviewGamesTable } from "./components/OverviewGamesTable";
import { OverviewUsersTable } from "./components/OverviewUsersTable";
import { PageTitle } from "../../components/PageTitle";
import { ButtonLink } from "../../components/ButtonLink";
import { sponsorRoutes } from "../../app-routes";
import { useParams } from "react-router-dom";
import { GenderDemographicsStatistics } from "../../components/GenderDemographicsStatics";
import { formatSpaces } from "../../helpers/name-helpers";
import { seasons } from "../../helpers/time-helpers";
import { CrowdSelect } from "../../components/CrowdSelect";

export const SponsorOverviewPage = observer(() => {
  const { sponsorName } = useParams<{ sponsorName: string }>();
  const [selectedSeason, setSelectedSeason] = React.useState<string>(
    seasons[seasons.length - 1],
  );
  const { sponsorsOverviewPage } = useStore().ui;

  React.useEffect(() => {
    sponsorsOverviewPage.loadOverview(sponsorName, selectedSeason);
  }, [sponsorsOverviewPage, sponsorName, selectedSeason]);

  return (
    <MainLayout
      loading={sponsorsOverviewPage.loading}
      firebaseConnector={sponsorsOverviewPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex">
            <PageTitle
              title="Overview"
              subTitle={`for ${formatSpaces(sponsorName)}`}
            />
            <Box marginLeft={2}>
              <ButtonLink
                variant="outlined"
                color="secondary"
                to={sponsorRoutes.sponsorStadiums(sponsorName)}
              >
                View All Stadiums
              </ButtonLink>
            </Box>
          </Box>
          <CrowdSelect
            optionsWithMatchingLabelAndValue={seasons}
            selected={selectedSeason}
            onChange={setSelectedSeason}
          />
        </Box>
      }
    >
      <DataCardBlock {...sponsorsOverviewPage.firstDataBlock} />
      <DataCardBlock {...sponsorsOverviewPage.secondDataBlock} />
      <Box marginTop={5} marginBottom={5}>
        <GenderDemographicsStatistics
          genderStats={sponsorsOverviewPage.genderStats}
          demographicStats={sponsorsOverviewPage.demographicStats}
        />
      </Box>
      {!!sponsorsOverviewPage.games.length && (
        <OverviewGamesTable
          games={sponsorsOverviewPage.games}
          onRowClick={sponsorsOverviewPage.onGameRowClick}
        />
      )}
      {!!sponsorsOverviewPage.users.length && (
        <Box marginTop={8}>
          <OverviewUsersTable
            users={sponsorsOverviewPage.users}
            onRowClick={sponsorsOverviewPage.onUserRowClick}
          />
        </Box>
      )}
    </MainLayout>
  );
});
