import { Box } from "@material-ui/core";
import React from "react";
import { SortableTable } from "../../../components/SortableTable";
import { TableContainer } from "../../../components/TableContainer";
import { formatDate } from "../../../helpers/time-helpers";

interface Props {
  rewards: any[];
  height: string;
}

export const UserRewardsTable: React.FC<Props> = ({ rewards, height }) => {
  let body = (
    <SortableTable
      title="Rewards Won"
      height={height}
      columns={[
        {
          dataKey: "name",
          label: "Reward",
          width: 80,
          flexGrow: 3,
        },
        {
          dataKey: "status",
          label: "Status",
          flexGrow: 2,
          width: 40,
        },
        {
          dataKey: "timestamp",
          label: "Date Won",
          flexGrow: 2,
          width: 40,
          //TODO watch out, these may sort unexpectedly
          cellRender: (props) => <> {formatDate(`${props.timestamp}`)} </>,
        },
      ]}
      rows={rewards}
      // @ts-ignore
    />
  );
  return <TableContainer>{body}</TableContainer>;
};
