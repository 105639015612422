import { string } from "yup/lib/locale";

export interface MarketplaceProductStatsResponse {
  liveProducts: number;
  liveVariants: number;
  liveCategories: number;
  liveCollections: number;
}

export const marketplaceProductStatsDefaults =
  (): MarketplaceProductStatsResponse => ({
    liveProducts: 0,
    liveVariants: 0,
    liveCategories: 0,
    liveCollections: 0,
  });

export interface MarketplaceSweepstakeStatsResponse {
  sweepstakesConducted: number;
  ticketsRedeemed: number;
}

export const marketplaceSweepstakeStatsDefaults =
  (): MarketplaceSweepstakeStatsResponse => ({
    sweepstakesConducted: 0,
    ticketsRedeemed: 0,
  });

export interface MarketplaceAuctionStatsResponse {
  auctionsConducted: number;
  nBids: number;
}

export const marketplaceAuctionStatsDefaults =
  (): MarketplaceAuctionStatsResponse => ({
    auctionsConducted: 0,
    nBids: 0,
  });
