import React from "react";
import { Box, Card, Typography } from "@material-ui/core";
import { CPCard } from "./CPCard";

interface Props {
  className?: string;
  title: any;
  child: any;
}

export const HeaderedBox: React.FC<Props> = ({ className, title, child }) => {
  return (
    <CPCard>
      <Box p="2rem" height="100%">
        <Typography component="div">
          <Box fontWeight="fontWeightBold" fontSize={18}>
            {title}
          </Box>
        </Typography>
        <Box mt={2} mb={2} border="0.4px solid #eeeeee"></Box>
        <Box marginTop={3}>{child}</Box>
      </Box>
    </CPCard>
  );
};
