import { Box, Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { PickupOverview } from "../../types/order-types";
import { PickupEditForm } from "./components/PickupEditForm";

export const PickupEditPage = observer(() => {
  const { pickupId } = useParams<{ pickupId: string }>();
  const { teamId } = useParams<{ teamId: string }>();

  const { pickupEditPage } = useStore().ui;

  React.useEffect(() => {
    pickupEditPage.loadPickup(teamId, pickupId);
  }, [teamId, pickupId, pickupEditPage]);

  const formRef = React.createRef<HTMLButtonElement>();

  let formValues = pickupEditPage.pickup;
  return (
    <MainLayout
      loading={pickupEditPage.loading}
      firebaseConnector={pickupEditPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title={`Pickup Details`} />
          <Box display="flex" alignItems="center">
            <Box width={5} />
            <Box width={180}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => {
                  // code && formRef?.current ? pickupEditPage.saveCode(formRef?.current) :
                  formRef?.current?.click();
                }}
              >
                {"Finalize"}
              </Button>
            </Box>
          </Box>
        </Box>
      }
    >
      <PickupEditForm
        formValues={formValues}
        onSave={(pickup: PickupOverview, sendNotification: boolean) => {
          pickupEditPage.savePickup(teamId, pickup, sendNotification);
        }}
        formRef={formRef}
      />
    </MainLayout>
  );
});
