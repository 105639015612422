import { Box } from "@material-ui/core";
import React from "react";
import { SortableTable } from "../../../components/SortableTable";
import { TableContainer } from "../../../components/TableContainer";
import { formatDate } from "../../../helpers/time-helpers";

interface Props {
  transactions: any[];
  height: string;
}

export const UserTransactionsTable: React.FC<Props> = ({
  transactions,
  height,
}) => {
  let body =
    transactions.length === 0 ? (
      <Box height={50}> No Transactions </Box>
    ) : (
      <SortableTable
        title={`History (${transactions.length} transactions)`}
        height={height}
        columns={[
          {
            dataKey: "description",
            label: "Description",
            width: 80,
            flexGrow: 3,
          },
          {
            dataKey: "points",
            label: "Points",
            flexGrow: 3,
            width: 40,
          },
          {
            dataKey: "timestamp",
            label: "Date",
            flexGrow: 1,
            width: 40,
            //TODO watch out, these may sort unexpectedly
            cellRender: (props) => <> {formatDate(`${props.timestamp}`)} </>,
          },
        ]}
        rows={transactions}
        // @ts-ignore
      />
    );
  return <TableContainer>{body}</TableContainer>;
};
