import React from "react";
import { SortableTable } from "../../../components/SortableTable";
import { SponsorListItem } from "../../../types/sponsors-types";

interface Props {
  sponsors: SponsorListItem[];
  onRowClick: (rowData: SponsorListItem) => void;
}

export const SponsorsTable: React.FC<Props> = ({ sponsors, onRowClick }) => {
  return (
    <SortableTable
      height="100%"
      columns={[
        {
          dataKey: "sponsorName",
          label: "Sponsor Name",
          flexGrow: 1,
          width: 100,
        },
      ]}
      rows={sponsors}
      // @ts-ignore
      onRowClick={onRowClick}
      withChevron
    />
  );
};
