import { makeAutoObservable } from "mobx";
import { loyaltyRoutes } from "../../app-routes";
import { Loading, loadingFunction } from "../../helpers/loading";
import { LoyaltyItem, loyaltyItemDefaults } from "../../types/loyalties-types";
import { onFileUpload } from "../../types/util-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { DomainsStore } from "../domains";
import { UiStore } from "./ui-store";

export class LoyaltyEditPageStore {
  public usersAbridged: any;
  public codeRedemptions: any;
  public editableLoyalty: LoyaltyItem = loyaltyItemDefaults();
  public loading: Loading = Loading.NOT_LOADED;
  public categoryOptions: any;
  public pointOptions: string[] = [];
  public seasonId: string = "";
  public allSeasons: Record<string, string> = {};
  public allCodes: string[] = [];

  constructor(
    private uiStore: UiStore,
    private domainsStore: DomainsStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get userId() {
    return this.uiStore.session.user?.userId || "";
  }

  saveCode = async (loyalty: LoyaltyItem) => {
    console.log("saveCode;");
    console.log(`saveCode: teamId ${loyalty["teamId"]}`);
    // if (!loyalty.id) {
    //   console.log("No loyalty Id!");
    //   return;
    // }
    // if (loyalty.file) {
    //   console.log("will try loyalty file");
    // } else {
    //   console.log("no loyalty.file");
    // }
    // return;
    let isCreate = !loyalty.key;
    let teamId = this.uiStore.session.userTeamId;
    try {
      this.loading = Loading.LOADING;
      if (isCreate) {
        let newId = await this.domainsStore.loyaltiesDomain.getNewLoyaltyId(
          loyalty,
          this.uiStore.session.userTeamId
        );
        if (newId) loyalty.key = newId;
      }
      // if (loyalty.loyaltyCodeFile) {
      //   const fileUrl = await this.onFileUpload(loyalty.loyaltyCodeFile, `loyaltyCodeImages/${teamId}/${loyalty.key}`);
      //   loyalty.loyaltyCodeFileUrl = fileUrl;
      // }
      // if (loyalty.loyaltyCodeMainFile) {
      //   console.log("uploading loyaltyCodeMainImages 2");
      //   const mainFileUrl = await onFileUpload(
      //     loyalty.loyaltyCodeMainFile,
      //     `loyaltyCodeMainImages/${teamId}/${loyalty.key}`,
      //     this.firebaseConnector,
      //   );
      //   loyalty.loyaltyCodeMainFileUrl = mainFileUrl;
      // } else {
      //   console.log("No mainFile found");
      // }
      if (loyalty.loyaltyCodeBackgroundFile) {
        console.log("uploading loyaltyCodeBackgroundFile 2");
        const backgroundFileUrl = await onFileUpload(
          loyalty.loyaltyCodeBackgroundFile,
          `loyaltyCodeBackgroundImages/${teamId}/${loyalty.key}`,
          this.firebaseConnector
        );
        loyalty.loyaltyCodeBackgroundFileUrl = backgroundFileUrl;
      }
      if (loyalty.loyaltyCodeIconFile) {
        console.log("uploading loyaltyCodeIconFile 2");
        const iconFileUrl = await onFileUpload(
          loyalty.loyaltyCodeIconFile,
          `loyaltyCodeIconImages/${teamId}/${loyalty.key}`,
          this.firebaseConnector
        );
        loyalty.loyaltyCodeIconFileUrl = iconFileUrl;
      }
      await this.domainsStore.loyaltiesDomain.saveOrUpdate(
        loyalty,
        this.uiStore.session.userTeamId,
        isCreate
      );
      this.uiStore.notifications.showSuccess("Code has been updated");
      this.uiStore.routerStore.push(loyaltyRoutes.loyalties);
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.loading = Loading.ERROR;
      console.log(`Error: ${JSON.stringify(error, null, 2)}`);
      this.uiStore.notifications.showError(`${error}`);
    }
  };

  createCategory = async (categoryName: string) => {
    console.log(`createCategory: ${categoryName}`);
    let teamId = this.uiStore.session.userTeamId;
    loadingFunction(
      this,
      async () => {
        let newCategoryId = await this.firebaseConnector.getKeyFromPushedPath(
          `activeRewardCategoriesByTeam/${teamId}`
        );
        await this.firebaseConnector.setDatabasePathAsObj(
          `activeRewardCategoriesByTeam/${teamId}/${newCategoryId}`,
          {
            name: categoryName,
          }
        );
        await this.loadCategoryOptions();
        this.uiStore.notifications.showSuccess("Category has been added");
      },
      this.uiStore.notifications
    );
  };

  loadByCode = async (code: string, surelyReload: boolean) => {
    this.loading = Loading.LOADING;
    await this.domainsStore.loyaltiesDomain.loadLoyalties(
      surelyReload,
      this.userId,
      this.uiStore.session.userTeamId
    );
    const loyaltyItem = this.domainsStore.loyaltiesDomain.searchByCode(code);
    console.log(`found loyaltyItem: ${JSON.stringify(loyaltyItem)}`);
    if (loyaltyItem) {
      this.editableLoyalty = loyaltyItem;
      this.codeRedemptions =
        await this.domainsStore.loyaltiesDomain.getCodeRedemptions(
          loyaltyItem.key,
          this.uiStore.session.userTeamId
        );
      this.usersAbridged =
        await this.domainsStore.loyaltiesDomain.getUsersAbridged();
    } else {
      this.uiStore.notifications.showError(
        `Cannot find item with ${code} code`
      );
      this.uiStore.routerStore.push(loyaltyRoutes.loyalties);
    }
    this.loading = Loading.SUCCESS;
  };

  loadCategoryOptions = async () => {
    let teamId = this.uiStore.session.userTeamId;
    loadingFunction(
      this,
      async () => {
        this.categoryOptions =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `activeRewardCategoriesByTeam/${teamId}`
          );
      },
      this.uiStore.notifications
    );
  };

  loadPointOptions = async () => {
    console.log("loadPointOptions");
    this.loading = Loading.LOADING;
    this.pointOptions = await this.domainsStore.loyaltiesDomain.getPointOptions(
      this.userId,
      this.uiStore.session.userTeamId
    );
    this.loading = Loading.SUCCESS;
    console.log(`got pointOptions: ${this.pointOptions}`);
  };

  loadCurrentSeasonId = async () => {
    this.loading = Loading.LOADING;
    this.seasonId = await this.domainsStore.loyaltiesDomain.getCurrentSeason(
      this.userId,
      this.uiStore.session.userTeamId
    );
    this.loading = Loading.SUCCESS;
    console.log(`got season: ${this.seasonId}`);
  };

  getAllSeasons = async () => {
    this.loading = Loading.LOADING;
    this.allSeasons = await this.domainsStore.loyaltiesDomain.getAllSeasons(
      this.userId,
      this.uiStore.session.userTeamId
    );
    this.loading = Loading.SUCCESS;
  };

  getAllCodes = async () => {
    loadingFunction(
      this,
      async () => {
        this.allCodes = await this.firebaseConnector.getAllLoyaltyCodes();
      },
      this.uiStore.notifications
    );
  };

  toggleLoyaltyCode = async (code: any, id: string, visibility: boolean) => {
    try {
      this.loading = Loading.LOADING;
      await this.domainsStore.loyaltiesDomain.toggleCodeVisiblity(
        id,
        visibility,
        this.uiStore.session.userTeamId
      );
      // if (code) {
      //   console.log(`loading code: ${code}`);
      // } else {
      //   console.log(`Could not find code: ${code}`);
      // }
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
