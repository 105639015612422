import { Box } from "@material-ui/core";
import React from "react";
import { UsersInfo } from "../../components/UserInfo";
import { MainLayout } from "../../layouts/MainLayout";
import { useStore } from "../../helpers/use-store";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PageTitle } from "../../components/PageTitle";
import { DataCardBlock } from "../../components/DataCardBlock";

interface UrlParams {
  userId: string;
  sponsorName: string;
  stadiumName: string;
  gameTimestamp?: string;
}

export const UserOverviewPageOld: React.FC = observer(() => {
  const urlParams = useParams<UrlParams>();
  const { userOverviewPageOld } = useStore().ui;

  React.useEffect(() => {
    userOverviewPageOld.loadUserOverview(urlParams);
  }, [urlParams, userOverviewPageOld]);

  return (
    <MainLayout
      loading={userOverviewPageOld.loading}
      firebaseConnector={userOverviewPageOld.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title="User Detail" />
        </Box>
      }
    >
      <Box display="flex" justifyContent="space-between">
        <Box flexGrow={1} mr={1} flexBasis={100}>
          <UsersInfo {...userOverviewPageOld.userOverview} />
        </Box>
        <Box flexGrow={1} ml={1} flexBasis={100}>
          {!!urlParams.gameTimestamp && (
            <>
              <DataCardBlock {...userOverviewPageOld.firstDataBlock} />
              <DataCardBlock {...userOverviewPageOld.secondDataBlock} />
            </>
          )}
        </Box>
      </Box>
    </MainLayout>
  );
});
