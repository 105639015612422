import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { authRoutes, loyaltyRoutes } from "../../app-routes";
import { ButtonLink } from "../../components/ButtonLink";
import { CPCard } from "../../components/CPCard";
import { CPDashboardSpacer } from "../../components/CPDashboardSpacer";
import { CPPieChartNew } from "../../components/CPPieChartNew";
import { CustomizableDataCardBlock } from "../../components/CustomizableDataCardBlock";
import { MonthlyDailyWeeklyChart } from "../../components/MonthlyDailyWeeklyChart";
import { PageTitle } from "../../components/PageTitle";
import { SortableTable } from "../../components/SortableTable";
import { TableContainer } from "../../components/TableContainer";
import { allSeasonsOption, cpMediumCardHeight } from "../../helpers/constants";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { CategoryListItem } from "../../types/loyalties-types";
import { CodesBar } from "./components/CodesBar";
import { LoyaltiesTable } from "./components/LoyaltiesTable";
import { SessionUserType, adminTeamRoles } from "../../types/user-types";
import { Redirect } from "react-router";

export const LoyaltiesPage = observer(() => {
  const { loyaltiesPage } = useStore().ui;

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const { routerStore } = useStore();

  const { session } = useStore().ui;
  const role = session.user?.type?.role;
  if (!adminTeamRoles.includes(role)) return <Redirect to={authRoutes.logIn} />;

  React.useEffect(() => {
    loyaltiesPage.loadLoyalties();
  }, [loyaltiesPage]);

  let deleteCode = (code: string) => {
    if (window.confirm(`Are you sure you want to delete code ${code}`)) {
      loyaltiesPage.deleteLoyaltyCode(`${code}`);
    }
  };

  let toggleFeatured = (loyaltyCodeId: string, newBoolean: boolean) => {
    loyaltiesPage.toggleFeatured(loyaltyCodeId, newBoolean);
  };

  let toggleCodeVisibility = async (codeId: string, enabled: boolean) => {
    loyaltiesPage.toggleLoyaltyCodeVisibility(`${codeId}`, enabled);
  };

  let redemptionsByCategory: any = loyaltiesPage.redemptionsByCategory || {};
  let pieChartData: any[] = [];
  Object.keys(redemptionsByCategory).forEach((categoryId: string) => {
    let count = redemptionsByCategory[categoryId];
    let label = loyaltiesPage.categoryNameByCategoryId
      ? loyaltiesPage.categoryNameByCategoryId[categoryId]
      : "";
    pieChartData.push({
      count,
      label,
    });
  });

  return (
    <MainLayout
      loading={loyaltiesPage.loading}
      firebaseConnector={loyaltiesPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title="Active Rewards" />
          <Box display="flex" alignItems="center">
            <Box width={5} />
            <Box width={120}>
              <ButtonLink
                variant="outlined"
                to={loyaltyRoutes.loyaltyCategories}
                fullWidth
              >
                Filter
              </ButtonLink>
            </Box>
            <Box width={20} />
            <Box width={180}>
              <ButtonLink to={loyaltyRoutes.newLoyalty} fullWidth>
                Add Active Reward
              </ButtonLink>
            </Box>
          </Box>
        </Box>
      }
    >
      <CodesBar selected={selectedTab} onTabChange={setSelectedTab} />
      <Box />
      {selectedTab === 2 ? (
        <div>
          <Box display={"flex"} mt="10px">
            <Box flex={1} marginTop="8px">
              <CustomizableDataCardBlock
                {...loyaltiesPage.loyaltyAnalyticsDataBlock}
                itemsPerRow={2}
                height={cpMediumCardHeight}
              />
            </Box>
            <CPDashboardSpacer />
            <Box flex={1} height={cpMediumCardHeight}>
              {!redemptionsByCategory ? (
                <div> No category Data </div>
              ) : (
                <TableContainer>
                  <SortableTable
                    height={cpMediumCardHeight}
                    title="Categories"
                    columns={[
                      {
                        dataKey: "title",
                        label: "Name",
                        flexGrow: 2,
                        width: 100,
                      },
                      {
                        dataKey: "count",
                        label: "Redemptions",
                        numeric: true,
                        flexGrow: 1,
                        width: 100,
                      },
                    ]}
                    rows={loyaltiesPage.categoryRows}
                    // @ts-ignore
                    onRowClick={(category: CategoryListItem) => {
                      routerStore.push(
                        loyaltyRoutes.categoryAnalytics(category.id)
                      );
                    }}
                    withChevron
                  />
                </TableContainer>
              )}
            </Box>
          </Box>
          <CPDashboardSpacer />
          <Box display={"flex"}>
            <Box flex={1}>
              <CPCard>
                <Box p="4rem" height="100%">
                  <MonthlyDailyWeeklyChart
                    dailyWeeklyMonthlyDataList={
                      loyaltiesPage.dailyWeeklyMonthlyRedemptionData
                    }
                    title="Redemptions"
                    dataKeyName="Redemptions"
                    selectedSeasonFilter={allSeasonsOption}
                  />
                </Box>
              </CPCard>
            </Box>
            <CPDashboardSpacer />
            <Box flex={1}>
              <CPPieChartNew title="Category Breakdown" data={pieChartData} />
            </Box>
          </Box>
        </div>
      ) : (
        <LoyaltiesTable
          loyalties={
            !selectedTab
              ? loyaltiesPage.activeLoyalties
              : loyaltiesPage.expiredLoyalties
          }
          itemsInSection={loyaltiesPage.featuredLoyalties}
          onToggleFeatured={toggleFeatured}
          onDelete={deleteCode}
          onToggleVisibility={toggleCodeVisibility}
          disabled={false}
        />
      )}
    </MainLayout>
  );
});
