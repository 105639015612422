import React from "react";
import { makeAutoObservable } from "mobx";
import { UiStore } from "./ui-store";

export class NotificationsStore {
  public successMessage: string | React.ReactNode | null = null;
  public errorMessage: string | React.ReactNode | null = null;

  constructor(private uiStore: UiStore) {
    makeAutoObservable(this);
  }

  showSuccess = (message: string | React.ReactNode) => {
    this.successMessage = message;
  };

  showError = (message: string | React.ReactNode) => {
    this.errorMessage = message;
  };

  clearSuccess = () => {
    this.successMessage = null;
  };

  clearError = () => {
    this.errorMessage = null;
  };
}
