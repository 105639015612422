import { makeAutoObservable } from "mobx";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";
import { Loading, loadingFunction } from "../../helpers/loading";
import {
  allFoldersOption,
  QuestionItem,
  questionItemDefaults,
} from "../../types/questions-types";
import { questionsRoutes } from "../../app-routes";

export class QuestionOverviewPageStore {
  public loading: Loading = Loading.NOT_LOADED;
  private _question: QuestionItem = questionItemDefaults();

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector,
  ) {
    makeAutoObservable(this);
  }

  get question() {
    return this._question;
  }

  loadFolders = async () => {
    loadingFunction(
      this,
      async () => {
        const { foldersDomain } = this.uiStore.domainsStore;
        await foldersDomain.loadFolders();
      },
      this.uiStore.notifications,
    );
  };

  get folderOptions() {
    const { folders } = this.uiStore.domainsStore.foldersDomain;
    return [
      ...folders.map((folder) => {
        return {
          label: folder.folderName,
          value: folder.folderId,
        };
      }),
    ];
  }

  loadQuestion = async (questionId?: string) => {
    loadingFunction(
      this,
      async () => {
        this._question =
          await this.firebaseConnector.loadQuestionById(questionId);
      },
      this.uiStore.notifications,
    );
  };

  saveQuestion = async (question: QuestionItem) => {
    loadingFunction(
      this,
      async () => {
        await this.firebaseConnector.saveQuestion(question);
        this.uiStore.routerStore.push(questionsRoutes.questions);
      },
      this.uiStore.notifications,
    );
  };
}
