import React from "react";
import { PaperTableContainer } from "../../../components/PaperTableContainer";
import { SortableTable } from "../../../components/SortableTable";
import { SponsorStadiumCategory } from "../../../types/sponsors-types";

interface Props {
  categories: SponsorStadiumCategory[];
  onRowClick?: (game: SponsorStadiumCategory) => void;
}

export const SponsorCategoriesTable: React.FC<Props> = ({
  categories,
  onRowClick,
}) => {
  return (
    <PaperTableContainer title="Category Breakdown">
      <SortableTable
        columns={[
          {
            dataKey: "category",
            label: "Category",
            flexGrow: 1,
            width: 100,
          },
          {
            dataKey: "nGames",
            label: "Number Of Games",
            flexGrow: 1,
            width: 100,
          },
          {
            dataKey: "nUsers",
            label: "Total Viewers Reached",
            flexGrow: 1,
            width: 100,
          },
          {
            dataKey: "nImp",
            label: "Total Impressions",
            flexGrow: 1,
            width: 100,
          },
        ]}
        rows={categories}
        // @ts-ignore
        onRowClick={onRowClick}
      />
    </PaperTableContainer>
  );
};
// category: "Recall"
// nGames: 2
// nImp: 414
// nUsers: 150
