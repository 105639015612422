import { makeAutoObservable } from "mobx";
import { usersRoutes } from "../../app-routes";
import { Loading } from "../../helpers/loading";
import { formatDuration } from "../../helpers/time-helpers";
import {
  SponsorStadiumGameCategory,
  SponsorStadiumGameResponse,
  sponsorStadiumGameResponseDefaults,
  SponsorGameUser,
} from "../../types/sponsors-types";
import { API } from "../connectors/api-connector";
import { UiStore } from "./ui-store";
import { FirebaseConnector } from "../connectors/firebase-connector";

export class SponsorGameOverviewPageStore {
  private stadiumGameResponse: SponsorStadiumGameResponse =
    sponsorStadiumGameResponseDefaults();
  public users: SponsorGameUser[] = [];
  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get gameName() {
    return this.stadiumGameResponse.name;
  }

  get firstDataBlock() {
    const { nTotalImp, nUsers } = this.stadiumGameResponse;
    return {
      items: [
        { title: "Total Impressions", value: nTotalImp },
        {
          title: "Average Impressions",
          value: Math.round(nTotalImp / (nUsers || 1)),
        },
        { title: "Total Unique Viewers", value: nUsers },
      ],
    };
  }

  get secondDataBlock() {
    const { totalEngagementTime, nUsers, nTotalClickThroughs, totalAdTime } =
      this.stadiumGameResponse;
    return {
      items: [
        { title: "Total Click-Throughs", value: nTotalClickThroughs },
        {
          title: "Average Engagement Time",
          value: Math.round(totalEngagementTime / (nUsers || 1)),
        },
        {
          title: "Average Ad View Time",
          value: Math.round(totalAdTime / (nUsers || 1)),
        },
      ],
    };
  }

  get genderStats() {
    const { genders } = this.stadiumGameResponse;
    const genderUsers = Object.values(genders).reduce((p, n) => p + n, 0);
    return {
      male: Math.round((genders.male / genderUsers) * 100),
      female: Math.round((genders.female / genderUsers) * 100),
      preferNotSay: Math.round((genders.other / genderUsers) * 100),
      maleAmount: genders.male,
      femaleAmount: genders.female,
      preferNotSayAmount: genders.other,
    };
  }

  get demographicStats() {
    return Object.keys(this.stadiumGameResponse.ages)
      .sort((a, b) => {
        const aa = a.replace(/[^0-9 ]/g, "").slice(0, 2);
        const bb = b.replace(/[^0-9 ]/g, "").slice(0, 2);
        return Number(aa) - Number(bb);
      })
      .map((key) => {
        return {
          name: key,
          amount: this.stadiumGameResponse.ages[key],
        };
      });
  }

  get categories(): SponsorStadiumGameCategory[] {
    return this.stadiumGameResponse.categories;
  }

  downloadReport = () => {
    const [, , stadiumName] =
      this.uiStore.routerStore.location.pathname.split("/") || [];
    const { users } = this;
    const headers = [
      "Name",
      "Gender",
      "Age",
      "Email",
      "Impressions",
      "Click-Throughs",
      "Ad View Time",
      "Engagement Time",
      "User",
    ];
    let csvContent =
      "data:text/csv;charset=utf-8," +
      `${headers.join(",")}\n` +
      users
        .map((user) => {
          return [
            user.name,
            user.gender,
            user.age,
            user.email,
            user.nImp,
            user.nClick,
            formatDuration(user.timeView),
            formatDuration(user.timeEng),
            user.type,
          ].join(",");
        })
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${this.gameName}_on_${stadiumName}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  onUserRowClick = (user: SponsorGameUser) => {
    const [, , sponsorName] =
      this.uiStore.routerStore.location.pathname.split("/") || [];
    this.uiStore.routerStore.push(
      usersRoutes.userBySponsor(user.id, sponsorName)
    );
  };

  loadGameOverview = async (
    sponsorName: string,
    stadiumName: string,
    gameTimestamp: string
  ) => {
    try {
      this.loading = Loading.LOADING;
      const data = {
        gameId: gameTimestamp,
        stadiumId: stadiumName,
        userId: sponsorName,
      };
      const [statsResponse, usersResponse] = await Promise.all([
        await API.post<{ data: SponsorStadiumGameResponse }>("/game", { data }),
        await API.post<{ data: { users: SponsorGameUser[] } }>("/gameUsers", {
          data,
        }),
      ]);
      this.stadiumGameResponse = statsResponse.data.data;
      this.users = usersResponse.data.data.users;
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
