import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import { DataCard } from "./DataCard";
import { CustomizableDataCard } from "./CustomizableDataCard";
import { debugStyle } from "../types/util-types";
import { standardDashboardPadding } from "../helpers/constants";

const useStyles = makeStyles((theme) => ({}));

interface Props {
  height: string;
  className?: string;
  itemsPerRow?: number;
  items: {
    title: string;
    value: string | number;
    flex?: number;
    cumulativeFlex?: number;
  }[];
}

export const CustomizableDataCardBlock: React.FC<Props> = ({
  className,
  items = [],
  itemsPerRow = 4,
  height,
}) => {
  const classes = useStyles();
  let cumulativeFlex = 0;
  return (
    <Box
      maxHeight={height}
      height={height}
      width="100%"
      display="flex"
      flexWrap="wrap"
      // gridRowGap={standardDashboardPadding}
      // gridColumnGap={0}
      // gridColumnGap={standardDashboardPadding}
      // gridGap={`${standardCardPaddingPercentage}%`}
      justifyContent={"space-between"}
      justifyItems={"space-between"}
      // style={debugStyle("red")}
    >
      {items.map((item) => {
        if (!item.flex) item.flex = 1;
        cumulativeFlex += item.flex;
        item.cumulativeFlex = cumulativeFlex;
        return (
          <CustomizableDataCard
            key={item.title}
            {...item}
            itemsPerRow={itemsPerRow}
          />
        );
      })}
    </Box>
  );
};
