import { Box, IconButton, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import { CPText } from "./CPText";

interface Props {
  child: any;
  errorText: any;
}

export const CPFormikErrorWrapper: React.FC<Props> = ({ child, errorText }) => {
  if (!errorText) return child;
  return (
    <Box>
      <Box
        style={{
          outline: "1px solid red",
          borderRadius: "4px",
        }}
      >
        {child}
      </Box>
      <Box ml={4}>
        <CPText title={errorText} fontSize={12} color="red" />
      </Box>
    </Box>
  );
};
