import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../helpers/use-store";
import { useSnackbar } from "notistack";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

export const ToastNotifications = observer(() => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { notifications } = useStore().ui;

  React.useEffect(() => {
    if (notifications.successMessage) {
      enqueueSnackbar(notifications.successMessage, {
        variant: "success",
        autoHideDuration: 3000,
      });
      notifications.clearSuccess();
    }
    if (notifications.errorMessage) {
      const key = enqueueSnackbar(notifications.errorMessage, {
        variant: "error",
        autoHideDuration: null,
        action: () => <CancelOutlinedIcon onClick={() => closeSnackbar(key)} />,
      });
      notifications.clearError();
    }
  }, [
    enqueueSnackbar,
    notifications.successMessage,
    notifications.errorMessage,
    closeSnackbar,
    notifications,
  ]);

  return null;
});
