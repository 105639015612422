export interface PushNotificationOverview {
  [key: string]: any;
  id: string;
  notificationId: string;
  notificationTitle: string;
  notificationBody: string;
  notificationIsScheduled: boolean;
  notificationAction: string;
  notificationActiveCollectionId: string;
  notificationHasSent: boolean;
  notificationDate: string;
  notificationTime: string;
  notificationScheduledDateTime: string;
  notificationSentDateTime: string;
}

export const pushNotificationOverviewDefaults =
  (): PushNotificationOverview => ({
    id: "",
    notificationId: "",
    notificationTitle: "",
    notificationBody: "",
    notificationIsScheduled: false,
    notificationAction: "",
    notificationActiveCollectionId: "",
    notificationHasSent: false,
    notificationDate: "",
    notificationTime: "",
    notificationScheduledDateTime: "",
    notificationSentDateTime: "",
  });

export interface HomeGameNotificationOverview {
  [key: string]: any;
  id: string;
  notificationId: string;
  notificationTitle: string;
  notificationBody: string;
  notificationVisibility: boolean;
  notificationAction: string;
  notificationActiveCollectionId: string;
  notificationStartDate: string;
  notificationStartTime: string;
  notificationStartDateTime: string;
  notificationEndDate: string;
  notificationEndTime: string;
  notificationEndDateTime: string;
  notificationFileUrl: string | null;
  notificationFile?: File | null;
}

export const homeGameNotificationOverviewDefaults =
  (): HomeGameNotificationOverview => ({
    id: "",
    notificationId: "",
    notificationTitle: "",
    notificationBody: "",
    notificationVisibility: true,
    notificationAction: "",
    notificationActiveCollectionId: "",
    notificationStartDate: "",
    notificationStartTime: "",
    notificationStartDateTime: "",
    notificationEndDate: "",
    notificationEndTime: "",
    notificationEndDateTime: "",
    notificationFileUrl: null,
    notificationFile: null,
  });

export const notificationTypeDetails = [
  {
    label: "push",
    path: "notificationsByTeam",
  },
  {
    label: "home",
    path: "scheduledHomeNotificationsByTeam",
    imagePath: "scheduledHomeNotifications",
  },
  {
    label: "game",
    path: "scheduledGameNotificationsByTeam",
    imagePath: "scheduledGameNotifications",
  },
];
