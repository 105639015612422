import { makeAutoObservable } from "mobx";
import { Loading } from "../../helpers/loading";
import { StadiumListItem } from "../../types/stadiums-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class StadiumsPageStore {
  private searchInput: string = "";
  private _stadiums: string[] = [];
  public loading: Loading = Loading.NOT_LOADED;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get stadiums(): StadiumListItem[] {
    return this._stadiums
      .filter((stadium) =>
        stadium.toLowerCase().includes(this.searchInput.toLowerCase())
      )
      .map((stadiumName) => ({ stadiumName }));
  }

  searchSponsor = (input: string) => {
    this.searchInput = input;
  };

  // onRowClick = ({ stadiumName, teamId }: StadiumListItem) => {
  //   this.uiStore.routerStore.push(stadiumRoutes.stadiumOverview(stadiumName, teamId));
  // };

  loadStadiums = async () => {
    try {
      this.loading = Loading.LOADING;
      const { stadiums = [] } = await this.firebaseConnector.loadDictionaries();
      this._stadiums = stadiums;
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.uiStore.notifications.showError(error.message);
      this.loading = Loading.ERROR;
    }
  };
}
