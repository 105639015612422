import React from "react";
import { TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { SponsorStadiumsTable } from "./components/SponsorStadiumsTable";
import { useParams } from "react-router-dom";
import { SponsorStadium } from "../../types/sponsors-types";
import { PageTitle } from "../../components/PageTitle";

export const SponsorStadiumsPage = observer(() => {
  const { sponsorName } = useParams<{ sponsorName: string }>();
  const { sponsorStadiumsPage } = useStore().ui;

  React.useEffect(() => {
    sponsorStadiumsPage.loadStadiums(sponsorName);
  }, [sponsorStadiumsPage, sponsorName]);

  const onSearch: React.ChangeEventHandler<{ value: string }> =
    React.useCallback(
      (e) => sponsorStadiumsPage.searchStadium(e.target.value),
      [sponsorStadiumsPage],
    );

  const onRowClick = React.useCallback(
    (stadium: SponsorStadium) => {
      sponsorStadiumsPage.onStadiumClick(sponsorName, stadium);
    },
    [sponsorName, sponsorStadiumsPage],
  );

  return (
    <MainLayout
      loading={sponsorStadiumsPage.loading}
      firebaseConnector={sponsorStadiumsPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title="Stadiums" />
          <Box width={250}>
            <TextField
              style={{ width: "100%" }}
              label="Search stadium..."
              onChange={onSearch}
            />
          </Box>
        </Box>
      }
    >
      <SponsorStadiumsTable
        stadiums={sponsorStadiumsPage.stadiums}
        onRowClick={onRowClick}
      />
    </MainLayout>
  );
});
