import {
  Box,
  Theme,
  createStyles,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import * as Yup from "yup";
import { FormikCheckbox } from "../../../components/FormikCheckbox";
import { FormikDatePicker } from "../../../components/FormikDatePicker";
import { FormikTextField } from "../../../components/FormikTextField";
import { FormikTimePicker } from "../../../components/FormikTimePicker";
import {
  HomeGameNotificationOverview,
  PushNotificationOverview,
} from "../../../types/notification-types";
import { FormikDropdown } from "../../../components/FormikDropdown";
import { DropdownOption } from "../../../types/util-types";
import { FileUploader } from "../../loyalty/components/FileUploader";
import { PageTitle } from "../../../components/PageTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginTop: 0,
      width: "100%",
    },
  }),
);

const validationSchema = (
  notification: PushNotificationOverview | HomeGameNotificationOverview,
  isHomeGameAlert: boolean,
) => {
  let startTimeYup = isHomeGameAlert
    ? Yup.string().required("Start Time required")
    : Yup.string();
  let startDateYup = isHomeGameAlert
    ? Yup.string().required("Start Date required")
    : Yup.string();
  let endDateYup = isHomeGameAlert
    ? Yup.string().required("End Date required")
    : Yup.string();
  let endTimeYup = isHomeGameAlert
    ? Yup.string().required("End Time required")
    : Yup.string();
  return Yup.object().shape({
    notificationTitle: Yup.string().required("Title required"),
    notificationBody: Yup.string().required("Body required"),
    notificationStartTime: startTimeYup,
    notificationEndTime: endTimeYup,
    notificationStartDate: startDateYup,
    notificationEndDate: endDateYup,
  });
};

interface Props {
  activeCollections: any;
  onSave: (
    notification: PushNotificationOverview | HomeGameNotificationOverview,
  ) => void;
  formValues: PushNotificationOverview | HomeGameNotificationOverview;
  formRef?: React.RefObject<HTMLButtonElement>;
  isHomeGameAlert: boolean;
}

export const NotificationEditForm: React.FC<Props> = observer(
  ({ onSave, formValues, formRef, activeCollections, isHomeGameAlert }) => {
    const formik = useFormik<
      PushNotificationOverview | HomeGameNotificationOverview
    >({
      enableReinitialize: true,
      initialValues: formValues,
      validationSchema: validationSchema(formValues, isHomeGameAlert),
      onSubmit: onSave,
    });
    //p = page, iA = innerAction
    let actionOptionMap: any = {
      "No Link": "",
      //Isn't fanzone the same as no link?
      // "Fanzone"
      "Game Day": `{"p":"team_page","iA":"gameDay"}`,
      Marketplace: `{"p":"team_page","iA":"marketplace"}`,
      "Active Rewards": `{"p":"team_page","iA":"activeRewards"}`,
      "Daily Rewards": `{"p":"team_page","iA":"dailyRewards"}`,
      "Check In": `{"p":"team_page","iA":"checkIn"}`,
      "Claim Reward": `{"p":"team_page","iA":"claimReward"}`,
      "Scan / Enter Code": `{"p":"team_page","iA":"scanEnterCode"}`,
      Achievements: `{"p":"achievements_page"}`,
      "Add Friends": `{"p":"my_crowd_page"}`,
      "Points Directory": `{"p":"points_directory_page"}`,
      Tutorial: `{"p":"team_tutorial_page"}`,
      Auction: `{"p":"team_page","iA":"auction"}`,
      Sweepstakes: `{"p":"team_page","iA":"sweepstakes"}`,
      "Active Collections": `{"p":"team_page","iA":"activeCollections"}`,
      //Sample active collection for devils -MkI8cXE_77PCEfe4Zuo
      // {"p":"team_page","iA":"activeCollections::-MkI8cXE_77PCEfe4Zuo"}
    };
    let activeCollectionOptions: DropdownOption[] = [
      {
        label: "No Specific Collection",
        value: "none",
      },
    ];
    Object.keys(activeCollections).forEach((collectionId) => {
      activeCollectionOptions.push({
        label: activeCollections[collectionId].name,
        value: collectionId,
      });
    });
    const actionOptions: DropdownOption[] = Object.keys(actionOptionMap).map(
      (key) => {
        return {
          label: key,
          value: actionOptionMap[key],
        };
      },
    );

    const onFileChange = React.useCallback(
      async (file: File | null, name: string, fileName: string) => {
        if (!formik.values[name] && file) {
          console.log("setting file...");
          formik.setFieldValue(fileName, file, true);
        }
        if (file === null) {
          console.log("file was null");
          formik.setValues(
            { ...formik.values, [name]: null, [fileName]: null },
            true,
          );
        }
      },
      [formik],
    );

    return (
      <form onSubmit={formik.handleSubmit}>
        {
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <PageTitle title={`Notification Details`} />
            <Box display="flex" alignItems="center">
              <Box width={5} />
              <Box width={180}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    formik.validateForm().then((errors) => {
                      if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).forEach((key) => {
                          formik.setFieldTouched(key, true, true);
                        });
                        return;
                      } else {
                        formRef?.current?.click();
                      }
                    });
                  }}
                >
                  {"Finalize"}
                </Button>
              </Box>
            </Box>
          </Box>
        }
        <Box height={8}></Box>
        <Box>
          *Note: Any changes submitted will trigger all users to see the alert
          again
        </Box>
        <Box height={16}></Box>
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            flexGrow={1}
          >
            <Box display="flex" width="100%" marginBottom={4}>
              <FormikTextField
                name="notificationTitle"
                label="Title"
                formik={formik}
              />
            </Box>
            <Box display="flex" width="100%" marginBottom={4}>
              <FormikTextField
                name="notificationBody"
                label="Body"
                formik={formik}
              />
            </Box>
            <Box display="flex" width={"100%"} mb={4}>
              <FormikDropdown
                name="notificationAction"
                label="Action"
                options={actionOptions}
                formik={formik}
              />
            </Box>
            {formik.values.notificationAction ===
              `{"p":"team_page","iA":"activeCollections"}` && (
              <Box display="flex" width={"100%"} mb={4}>
                <FormikDropdown
                  name="notificationActionCollectionId"
                  label="Collection"
                  options={activeCollectionOptions}
                  formik={formik}
                />
              </Box>
            )}
            {!isHomeGameAlert && (
              <Box display="flex" width="100%" marginBottom={4}>
                <FormikCheckbox
                  name={"notificationIsScheduled"}
                  label={"Should Schedule"}
                  formik={formik}
                />
              </Box>
            )}
            {formik.values.notificationIsScheduled && (
              <>
                <Box width="100%" display="flex" marginBottom={4}>
                  <FormikDatePicker
                    name="notificationDate"
                    label="Date"
                    formik={formik}
                  />
                </Box>
                <Box width="100%" display="flex" marginBottom={4}>
                  <FormikTimePicker
                    name="notificationTime"
                    label="Time"
                    formik={formik}
                  />
                </Box>
              </>
            )}
            {isHomeGameAlert && (
              <Box width="100%" display="flex" marginBottom={4}>
                <FileUploader
                  onFileChange={(file) =>
                    onFileChange(
                      file,
                      "notificationFileUrl",
                      "notificationFile",
                    )
                  }
                  fileUrl={formik.values.notificationFileUrl}
                />
              </Box>
            )}
            {isHomeGameAlert && (
              <Box display={"flex"}>
                <Box>
                  <Box width="100%" display="flex" marginBottom={4}>
                    <FormikDatePicker
                      name="notificationStartDate"
                      label="Start Date"
                      formik={formik}
                    />
                  </Box>
                  <Box width="100%" display="flex" marginBottom={4}>
                    <FormikTimePicker
                      name="notificationStartTime"
                      label="Start Time"
                      formik={formik}
                    />
                  </Box>
                  <Box>
                    <Box width="100%" display="flex" marginBottom={4}>
                      <FormikCheckbox
                        name={"notificationVisibility"}
                        label={"Enabled"}
                        formik={formik}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box ml={"10px"}>
                  <Box width="100%" display="flex" marginBottom={4}>
                    <FormikDatePicker
                      name="notificationEndDate"
                      label="End Date"
                      formik={formik}
                    />
                  </Box>
                  <Box width="100%" display="flex" marginBottom={4}>
                    <FormikTimePicker
                      name="notificationEndTime"
                      label="End Time"
                      formik={formik}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <button style={{ display: "none" }} type="submit" ref={formRef} />
      </form>
    );
  },
);
