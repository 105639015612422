import { makeAutoObservable } from "mobx";
import moment from "moment";
import { notificationRoutes } from "../../app-routes";
import { Loading, loadingFunction } from "../../helpers/loading";
import {
  HomeGameNotificationOverview,
  PushNotificationOverview,
  homeGameNotificationOverviewDefaults,
  notificationTypeDetails,
  pushNotificationOverviewDefaults,
} from "../../types/notification-types";
import {
  onFileUpload,
  setDateAndTimeFromDateTime,
} from "../../types/util-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class NotificationDetailsPageStore {
  public activeCollections: any = {};
  public loading: Loading = Loading.NOT_LOADED;
  private _allNotifications?: any = {};
  private _notification?:
    | PushNotificationOverview
    | HomeGameNotificationOverview;

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  loadNotification = async (
    teamId: string,
    notificationId: string,
    typeIndexInt: number
  ) => {
    loadingFunction(
      this,
      async () => {
        let isHomeGameNotification = typeIndexInt > 0;
        let notificationOverview:
          | PushNotificationOverview
          | HomeGameNotificationOverview = isHomeGameNotification
          ? pushNotificationOverviewDefaults()
          : homeGameNotificationOverviewDefaults();

        const notificationData =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `${notificationTypeDetails[typeIndexInt].path}/${teamId}/${notificationId}`
          )) || {};

        this._allNotifications =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `${notificationTypeDetails[typeIndexInt].path}/${teamId}`
          )) || {};

        const collections =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `marketplaceCollectionsByTeam/${teamId}/`
          )) || {};
        Object.keys(collections).forEach((collectionId) => {
          if (collections[collectionId]["visible"]) {
            this.activeCollections[collectionId] = collections[collectionId];
          }
        });
        notificationOverview = notificationData;
        notificationOverview.notificationId = notificationId || "";
        setDateAndTimeFromDateTime(
          notificationOverview,
          "notificationStartDateTime",
          "notificationStartDate",
          "notificationStartTime"
        );
        setDateAndTimeFromDateTime(
          notificationOverview,
          "notificationEndDateTime",
          "notificationEndDate",
          "notificationEndTime"
        );
        this._notification = notificationOverview;
      },
      this.uiStore.notifications
    );
  };

  get notification() {
    return this._notification;
  }

  saveNotification = async (
    teamId: string,

    notification: PushNotificationOverview | HomeGameNotificationOverview,
    typeIndexInt: number
  ) => {
    try {
      let isHomeGameNotification = typeIndexInt > 0;
      let notificationId = notification.notificationId;
      console.log(`notificationId: ${notificationId}`);
      console.log(`isHomeGameNotification: ${isHomeGameNotification}`);

      if (isHomeGameNotification) {
        let startMoment = moment(
          `${notification.notificationStartDate} ${notification.notificationStartTime}`,
          "MM/DD/YYYY h:mm a"
        );
        let endMoment = moment(
          `${notification.notificationEndDate} ${notification.notificationEndTime}`,
          "MM/DD/YYYY h:mm a"
        );
        console.log(`made it here`);
        if (startMoment.isAfter(endMoment)) {
          this.uiStore.notifications.showError(
            "Start date must be before end date"
          );
          return;
        }
        let conflictingNotificationIds: string[] = [];
        let conflictingNotificationTitles: string[] = [];
        Object.keys(this._allNotifications).forEach((notificationId) => {
          if (notificationId == notification.notificationId) {
            console.log(`skipping notificationId: ${notificationId}`);
            return;
          }
          console.log(`checking notificationId: ${notificationId}`);
          let notificationOverview = this._allNotifications[notificationId];
          let notificationStartMoment = moment(
            notificationOverview.notificationStartDateTime
          );
          let notificationEndMoment = moment(
            notificationOverview.notificationEndDateTime
          );
          if (
            notificationStartMoment.isBetween(startMoment, endMoment) ||
            notificationEndMoment.isBetween(startMoment, endMoment) ||
            startMoment.isBetween(
              notificationStartMoment,
              notificationEndMoment
            ) ||
            endMoment.isBetween(notificationStartMoment, notificationEndMoment)
          ) {
            conflictingNotificationIds.push(notificationId);
            conflictingNotificationTitles.push(
              notificationOverview.notificationTitle
            );
          }
        });
        if (conflictingNotificationIds.length > 0) {
          this.uiStore.notifications.showError(
            `The following notifications conflict with the start and end dates you have selected: ${conflictingNotificationTitles.join(
              ", "
            )}`
          );
          return;
        }
        notification.notificationStartDateTime = startMoment.toISOString();
        notification.notificationEndDateTime = endMoment.toISOString();
      } else {
        let scheduledDateTime;
        let date = notification.notificationDate;
        let time = notification.notificationTime;
        console.log(`date: ${date}, time: ${time}`);
        if (date && time) {
          scheduledDateTime = moment(`${date} ${time}`, "MM/DD/YYYY h:mm a");
          notification.notificationScheduledDateTime =
            scheduledDateTime.toISOString();
        }
      }

      this.loading = Loading.LOADING;

      if (notification.notificationFile && isHomeGameNotification) {
        const mainFileUrl = await onFileUpload(
          notification.notificationFile,
          `${notificationTypeDetails[typeIndexInt].imagePath}/${teamId}/${notification.notificationId}`,
          this.firebaseConnector
        );
        notification.notificationFileUrl = mainFileUrl;
        notification.notificationFile = null;
      } else {
        console.log("No mainFile found");
      }

      if (notificationId) {
        let path = `${notificationTypeDetails[typeIndexInt].path}/${teamId}/${notificationId}`;
        await this.firebaseConnector.setDatabasePathAsObj(path, notification);
      } else {
        this.uiStore.notifications.showSuccess("No notificationId");
        return;
      }
      this.uiStore.notifications.showSuccess("Notification has been scheduled");
      this.uiStore.routerStore.push(
        notificationRoutes.notificationsByTeam(teamId)
      );
      this.loading = Loading.SUCCESS;
    } catch (error: any) {
      this.loading = Loading.ERROR;
      this.uiStore.notifications.showError(`${error}`);
    }
  };
}
