import React from "react";
import { TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { SponsorsTable } from "./components/SponsorsTable";
import { PageTitle } from "../../components/PageTitle";

export const SponsorsPage = observer(() => {
  const { sponsorsPage } = useStore().ui;

  React.useEffect(() => {
    sponsorsPage.loadSponsors();
  }, [sponsorsPage]);

  const onSearch: React.ChangeEventHandler<{ value: string }> =
    React.useCallback(
      (e) => sponsorsPage.searchSponsor(e.target.value),
      [sponsorsPage],
    );
  return (
    <MainLayout
      loading={sponsorsPage.loading}
      firebaseConnector={sponsorsPage.firebaseConnector}
      pageActions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <PageTitle title="Sponsors" />
          <Box width={250}>
            <TextField
              style={{ width: "100%" }}
              label="Search sponsor..."
              onChange={onSearch}
            />
          </Box>
        </Box>
      }
    >
      <Box height="40rem">
        <SponsorsTable
          sponsors={sponsorsPage.sponsors}
          onRowClick={sponsorsPage.onRowClick}
        />
      </Box>
    </MainLayout>
  );
});
