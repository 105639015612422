import { makeAutoObservable } from "mobx";
import {
  allSeasonsOption,
  allOptionValue,
  attendingLabel,
  membersOnlyLabel,
  watchingLabel,
  withGuestsLabel,
} from "../../helpers/constants";
import {
  mapOfUserIdsByPossibleValuesOfKeyInObject,
  verifyAllTruthy,
} from "../../helpers/generic-helpers";
import { Loading, loadingFunction } from "../../helpers/loading";
import { DailyWeeklyMonthlyData } from "../../types/chart-types";
import { RowData } from "../../types/data-table-types";
import {
  MemberEngagementData,
  TeamOverviewResponse,
  teamOverviewStatsDefaults,
} from "../../types/teams-types";
import {
  exportAsCSVByCol,
  exportAsCSVByRow,
  getSeasonFilterFromSeasonId,
  percentageFromNumber,
  setColEmailsAndNames,
} from "../../types/util-types";
import { FirebaseConnector } from "../connectors/firebase-connector";
import { UiStore } from "./ui-store";

export class TeamOverviewPageStore {
  private seasonalStats: TeamOverviewResponse = teamOverviewStatsDefaults();
  private seasonlessStats: TeamOverviewResponse = teamOverviewStatsDefaults();
  private engagementStatsByUserId: any = {};
  private _ordersByProductType: any[] = [];
  private _ordersByProductTypeBySeason: any = {};
  private loyaltyPointsByUserId: any = {};
  public userDetailsByUserId: any = {};
  public loading: Loading = Loading.NOT_LOADED;
  private _teamId: string = "";
  public seasonId: string = "";
  public teamName: string = "";
  public seasonOptions: any = [];
  public teamDetails: any = {};
  public usersCountByTimeframe: any = {};
  public usersCountExcludingInactiveByTimeframe: any = {};
  public userSignupsByTimeframe: any = {};
  private demographicsDataUserIdSpecific: any;
  private activeMembersDataUserIdSpecific: any = {};
  private signupsDataUserIdSpecific: any = {};
  private activeUserDataUserIdSpecific: any = {};
  private checkInDataUserIdSpecific: any = {};
  private orderPlacerDataUserIdSpecific: any = {};
  public platformCountData: any = {};
  public userIdSpecificPlatformData: any = {};

  constructor(
    private uiStore: UiStore,
    public firebaseConnector: FirebaseConnector
  ) {
    makeAutoObservable(this);
  }

  get adoptionDataBlock() {
    const {
      nParticipants,
      nActiveParticipants,
      activeParticipants,
      inactiveParticipants,
      enrolledParticipants,
      averageDailyEngagement,
      averageWeeklyEngagement,
      averageMonthlyEngagement,
      nSeasonTicketMembers,
    } = this.seasonlessStats;
    let nEnrolledParticipants = enrolledParticipants?.length || 0;
    return {
      itemsPerRow: 2,
      items: [
        {
          title: "Total Enrolled Members",
          value: nEnrolledParticipants,
          onDownload: async () => {
            let csvKeys = ["firstName", "lastName", "username", "email"];
            let rows: RowData[] =
              await this.firebaseConnector.loadUsersByTeamId(
                this._teamId,
                this.seasonId,
                enrolledParticipants
              );
            exportAsCSVByRow(csvKeys, rows, "Total Enrolled Members");
          },
        },
        {
          title: "Active Members",
          value: percentageFromNumber(
            nActiveParticipants / nEnrolledParticipants
          ),
          onDownload: async () => {
            let csvKeys = ["firstName", "lastName", "username", "email"];
            let rows: RowData[] =
              await this.firebaseConnector.loadUsersByTeamId(
                this._teamId,
                this.seasonId,
                activeParticipants
              );
            exportAsCSVByRow(csvKeys, rows, "Total Active Members");
          },
        },
        // {
        //   title: "Inactive Members",
        //   value: percentageFromNumber(
        //     (inactiveParticipants.length) / nEnrolledParticipants
        //   ),
        //   onDownload: async () => {
        //     let csvKeys = ["firstName", "lastName", "username", "email"];
        //     let rows: RowData[] =
        //       await this.firebaseConnector.loadUsersByTeamId(
        //         this._teamId,
        //         this.seasonId,
        //         inactiveParticipants
        //       );
        //     exportAsCSVByRow(csvKeys, rows, "Total Inactive Members");
        //   },
        // },
        {
          title: "% of Season Ticket Members Enrolled",
          value: percentageFromNumber(
            nEnrolledParticipants / nSeasonTicketMembers
          ),
          onDownload: async () => {
            let csvKeys = [
              "Enrolled Email",
              "Enrolled Name",
              "Not Enrolled Email",
              "Not Enrolled Name",
            ];
            let seasonTicketMembers =
              await this.firebaseConnector.getDatabaseObjAtPath(
                `seasonTicketEmails/${this._teamId}`
              );
            let userIdsByEmail: any = {};
            let nonEnrolledEmailKeys: any = [];
            Object.keys(this.userDetailsByUserId).forEach((userId) => {
              let userDetails = this.userDetailsByUserId[userId] || {};
              let email = userDetails["email"];
              userIdsByEmail[email] = userId;
            });
            Object.keys(seasonTicketMembers).forEach((emailKey) => {
              let memberDetails = seasonTicketMembers[emailKey] || {};
              let email = memberDetails["email"];
              if (!userIdsByEmail[email]) {
                nonEnrolledEmailKeys.push(emailKey);
              }
            });
            // exportAsCSVByCol(csvKeys, rows, "Season Ticket Members");

            let cols: any = {};
            let colIndexArr = [0];
            setColEmailsAndNames(
              "",
              colIndexArr,
              cols,
              null,
              this.userDetailsByUserId,
              enrolledParticipants
            );
            setColEmailsAndNames(
              "",
              colIndexArr,
              cols,
              null,
              seasonTicketMembers,
              nonEnrolledEmailKeys
            );
            exportAsCSVByCol(csvKeys, cols, `Enrolled + Not Enrolled`);
          },
        },
        {
          title: "Average Daily Engagement",
          value: percentageFromNumber(averageDailyEngagement),
        },
        {
          title: "Average Weekly Engagement",
          value: percentageFromNumber(averageWeeklyEngagement),
        },
        {
          title: "Average Monthly Engagement",
          value: percentageFromNumber(averageMonthlyEngagement),
        },
      ],
    };
  }

  get pointsDataBlock() {
    const { totalPointsDistributed, totalPointsUsed } = this.seasonalStats;
    const { nParticipants, totalPointsOutstanding, pointsTransferPoints } =
      this.seasonlessStats;
    return {
      itemsPerRow: 2,
      items: [
        // { title: "Points Started", value: (pointsTransferPoints)},
        { title: "Total Points Earned", value: totalPointsDistributed },
        { title: "Total Points Spent", value: totalPointsUsed },
        { title: "Total Points Outstanding", value: totalPointsOutstanding },
        {
          title: "Average Points Earned / Participant",
          value: (totalPointsDistributed / nParticipants).toFixed(2),
        },
        {
          title: "Average Points Spent / Participant",
          value: (totalPointsUsed / nParticipants).toFixed(2),
        },
        {
          title: "Average Points Outstanding / Participant",
          value: (totalPointsOutstanding / nParticipants).toFixed(2),
        },
      ],
    };
  }

  get ordersDataBlock() {
    const { nOrders } = this.seasonalStats;
    const { numOrdersByUser, nParticipants } = this.seasonlessStats;
    let nUniqueUsersWhoHaveOrdered = Object.keys(numOrdersByUser || {}).length;
    return {
      itemsPerRow: 2,
      items: [
        {
          title: "Total Orders",
          value: nOrders,
          onDownload: async () => {
            let csvKeys = [
              "userFullName",
              "email",
              "productName",
              "firstVariantName",
              "orderItemCount",
              "statusName",
              "formattedOrderDate",
              "friendlyId",
            ];
            let rows: RowData[] =
              await this.firebaseConnector.loadOrdersByTeamId(
                this._teamId,
                this.seasonId
              );
            exportAsCSVByRow(csvKeys, rows, "Total Orders");
          },
        },
        {
          title: "Fans that have placed an order",
          value: percentageFromNumber(
            nUniqueUsersWhoHaveOrdered / nParticipants
          ),
        },
      ],
    };
  }

  loadTeamName = async (teamId: string) => {
    this.teamName = await this.firebaseConnector.getTeamName(teamId);
  };

  loadOverview = async (
    teamId: string,
    seasonFilter: any,
    setSelectedSeasonFilter: any
  ) => {
    loadingFunction(
      this,
      async () => {
        this._teamId = teamId;
        this.seasonOptions =
          await this.firebaseConnector.getSeasonOptions(teamId);
        if (!this.seasonId || !seasonFilter) {
          this.seasonId =
            await this.firebaseConnector.getCurrentSeasonId(teamId);
        } else {
          this.seasonId = seasonFilter.value;
        }
        if (!seasonFilter || seasonFilter.value != this.seasonId) {
          let defaultSeasonFilter = getSeasonFilterFromSeasonId(
            this.seasonOptions,
            this.seasonId
          );
          setSelectedSeasonFilter(defaultSeasonFilter);
        }
        await this.loadTeamName(teamId);
        this.userDetailsByUserId =
          await this.firebaseConnector.loadUsersByTeamIdAsMap(teamId);
        this.userIdSpecificPlatformData =
          mapOfUserIdsByPossibleValuesOfKeyInObject(
            "platform",
            this.userDetailsByUserId,
            "unknown"
          );
        Object.keys(this.userIdSpecificPlatformData).forEach(
          (platform: string) => {
            this.platformCountData[platform] =
              this.userIdSpecificPlatformData[platform].length;
          }
        );
        this.seasonalStats = await this.firebaseConnector.loadTeamOverview(
          teamId,
          this.seasonId
        );
        this.seasonlessStats =
          await this.firebaseConnector.loadSeasonlessTeamOverview(teamId);
        this.engagementStatsByUserId =
          await this.firebaseConnector.loadEngagementStatsByUserIdByTeam(
            teamId
          );
        this._ordersByProductType =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/ordersByProductTypeByTeam/${teamId}`
          );
        this._ordersByProductTypeBySeason =
          (await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/ordersByProductTypeByTeamBySeason/${teamId}/`
          )) || {};
        this.loyaltyPointsByUserId =
          await this.firebaseConnector.loadLoyaltyPointsByTeam(teamId);
        this.usersCountByTimeframe["daily"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/dailyUsersCountByTeam/${teamId}`
          );
        this.usersCountByTimeframe["weekly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/weeklyUsersCountByTeam/${teamId}`
          );
        this.usersCountByTimeframe["monthly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/monthlyUsersCountByTeam/${teamId}`
          );
        this.usersCountExcludingInactiveByTimeframe["daily"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/dailyUsersCountByTeamExcludingInactive/${teamId}`
          );
        this.usersCountExcludingInactiveByTimeframe["weekly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/weeklyUsersCountByTeamExcludingInactive/${teamId}`
          );
        this.usersCountExcludingInactiveByTimeframe["monthly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/monthlyUsersCountByTeamExcludingInactive/${teamId}`
          );
        this.userSignupsByTimeframe["daily"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/dailyUserSignupsCountByTeam/${teamId}`
          );
        this.userSignupsByTimeframe["weekly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/weeklyUserSignupsCountByTeam/${teamId}`
          );
        this.userSignupsByTimeframe["monthly"] =
          await this.firebaseConnector.getDatabaseObjAtPath(
            `tracking/monthlyUserSignupsCountByTeam/${teamId}`
          );
        this.teamDetails = await this.firebaseConnector.loadTeamDetails(teamId);
        this.firebaseConnector.triggerTeamOverviewDataCalculations(
          teamId,
          this.seasonId
        );
      },
      this.uiStore.notifications
    );
  };

  // triggerCalculation = async (teamId: string, seasonFilter: any) => {
  //   await this.setSeasonId(teamId, seasonFilter);
  //   console.log(`deleteme will trigger calculation with ${this.seasonId}`);
  //   this.firebaseConnector.triggerTeamOverviewDataCalculations(teamId, this.seasonId);
  // };

  get budgetSpent() {
    return this.seasonalStats.budgetSpent;
  }

  get totalBudget() {
    return this.seasonalStats.totalBudget;
  }

  get userDemographics() {
    return this.seasonlessStats.demographicsData;
  }

  getUserIdSpecificDemographics = async () => {
    if (this.demographicsDataUserIdSpecific)
      return this.demographicsDataUserIdSpecific;
    let path = `tracking/demographicsDataUserIdSpecificByTeam/${this._teamId}`;
    this.demographicsDataUserIdSpecific =
      await this.firebaseConnector.getDatabaseObjAtPath(path);
    return this.demographicsDataUserIdSpecific;
  };

  getAllUserInfo = async () => {
    if (this.userDetailsByUserId) return this.userDetailsByUserId;
    this.userDetailsByUserId =
      await this.firebaseConnector.getDatabaseObjAtPath(
        `userDetailsByUserIdByTeam/${this._teamId}`
      );
    return this.userDetailsByUserId;
  };

  get getUsersCountByTimeframe(): any {
    if (this.usersCountByTimeframe) return this.usersCountByTimeframe;
    return {};
  }

  get getUsersCountExcludingInactiveByTimeframe(): any {
    if (this.usersCountExcludingInactiveByTimeframe)
      return this.usersCountExcludingInactiveByTimeframe;
    return {};
  }

  get dailyWeeklyMonthlyActiveMembersData(): DailyWeeklyMonthlyData[] {
    return [
      {
        daily: this.seasonlessStats.dailyActiveUserCounts,
        weekly: this.seasonlessStats.weeklyActiveUserCounts,
        monthly: this.seasonlessStats.monthlyActiveUserCounts,
      },
    ];
  }

  getUserIdSpecificActiveMembersData = async (timeframe: string) => {
    if (this.activeMembersDataUserIdSpecific[timeframe])
      return this.activeMembersDataUserIdSpecific[timeframe];
    let path = `tracking/${timeframe}UserActivityUserIdSpecificByTeam/${this._teamId}`;
    this.activeMembersDataUserIdSpecific[timeframe] =
      await this.firebaseConnector.getDatabaseObjAtPath(path);
    return this.activeMembersDataUserIdSpecific[timeframe];
  };

  getUserIdSpecificSignupsData = async (timeframe: string) => {
    if (this.signupsDataUserIdSpecific[timeframe])
      return this.signupsDataUserIdSpecific[timeframe];
    let path = `tracking/${timeframe}UserSignupsUserIdSpecificByTeam/${this._teamId}`;
    this.signupsDataUserIdSpecific[timeframe] =
      await this.firebaseConnector.getDatabaseObjAtPath(path);
    return this.signupsDataUserIdSpecific[timeframe];
  };

  getUserIdSpecificUsersWhoWereActiveData = async (timeframe: string) => {
    if (this.activeUserDataUserIdSpecific[timeframe])
      return this.activeUserDataUserIdSpecific[timeframe];
    let path = `tracking/${timeframe}UsersWhoWereActive/${this._teamId}`;
    this.activeUserDataUserIdSpecific[timeframe] =
      await this.firebaseConnector.getDatabaseObjAtPath(path);
    return this.activeUserDataUserIdSpecific[timeframe];
  };

  getUserIdSpecificCheckInData = async (
    timeframe: string,
    attendingOrWatching: string
  ) => {
    let typeString = "";
    let suffix = "";
    if (attendingOrWatching === attendingLabel) typeString = "Attends";
    if (attendingOrWatching === watchingLabel) typeString = "Views";
    if (
      attendingOrWatching === membersOnlyLabel ||
      attendingOrWatching === withGuestsLabel
    )
      typeString = "ScanningAttends";
    if (attendingOrWatching === withGuestsLabel) suffix = "WithGuests";
    if (!typeString) return {};
    let timeFrameAttendingWatchingKey = `${timeframe}-${attendingOrWatching}`;
    if (this.checkInDataUserIdSpecific[timeFrameAttendingWatchingKey])
      return this.checkInDataUserIdSpecific[timeFrameAttendingWatchingKey];
    let path = `tracking/${timeframe}CheckIn${typeString}UserIdSpecificByTeam${suffix}/${this._teamId}`;
    this.checkInDataUserIdSpecific[timeFrameAttendingWatchingKey] =
      await this.firebaseConnector.getDatabaseObjAtPath(path);
    let result: any =
      this.checkInDataUserIdSpecific[timeFrameAttendingWatchingKey];
    console.log(`found result ${timeframe}: `, result);
    return result;
  };

  getUserIdSpecificOrderData = async (timeframe: string) => {
    if (this.orderPlacerDataUserIdSpecific[timeframe])
      return this.orderPlacerDataUserIdSpecific[timeframe];
    let path = `tracking/${timeframe}OrderPlacersUserIdSpecificByTeam/${this._teamId}`;
    this.orderPlacerDataUserIdSpecific[timeframe] =
      await this.firebaseConnector.getDatabaseObjAtPath(path);
    return this.orderPlacerDataUserIdSpecific[timeframe];
  };

  get dailyWeeklyMonthlySignUpsData(): DailyWeeklyMonthlyData[] {
    return [this.userSignupsByTimeframe];
  }

  get dailyWeeklyMonthlyCheckinData(): DailyWeeklyMonthlyData[] {
    return [
      {
        daily: this.seasonlessStats.dailyCheckInAttendsCount,
        weekly: this.seasonlessStats.weeklyCheckInAttendsCount,
        monthly: this.seasonlessStats.monthlyCheckInAttendsCount,
      },
      {
        daily: this.seasonlessStats.dailyCheckInViewsCount,
        weekly: this.seasonlessStats.weeklyCheckInViewsCount,
        monthly: this.seasonlessStats.monthlyCheckInViewsCount,
      },
    ];
  }

  get dailyWeeklyMonthlyScanningCheckinData(): DailyWeeklyMonthlyData[] {
    return [
      {
        daily: this.seasonlessStats.dailyCheckInScanningAttendsCount,
        weekly: this.seasonlessStats.weeklyCheckInScanningAttendsCount,
        monthly: this.seasonlessStats.monthlyCheckInScanningAttendsCount,
      },
      {
        daily: this.seasonlessStats.dailyCheckInScanningAttendsCountWithGuests,
        weekly:
          this.seasonlessStats.weeklyCheckInScanningAttendsCountWithGuests,
        monthly:
          this.seasonlessStats.monthlyCheckInScanningAttendsCountWithGuests,
      },
    ];
  }

  get dailyWeeklyMonthlyUsersWhoWereActiveData(): DailyWeeklyMonthlyData[] {
    return [
      {
        daily: this.seasonlessStats.dailyUsersWhoWereActiveCounts,
        weekly: this.seasonlessStats.weeklyUsersWhoWereActiveCounts,
        monthly: this.seasonlessStats.monthlyUsersWhoWereActiveCounts,
      },
    ];
  }

  topMostEngagedMembers = (teamId: string) => {
    let userDetailsByUserId = this.userDetailsByUserId;
    let engagementDetailsByUserId = this.engagementStatsByUserId;
    // console.log(`engagementDetailsByUserId`, engagementDetailsByUserId);
    let result: MemberEngagementData[] = [];
    Object.keys(engagementDetailsByUserId).forEach((userId) => {
      let userDetails = userDetailsByUserId[userId];
      let engagementDetails = engagementDetailsByUserId[userId] || {};
      // console.log(`engagementDetails`, engagementDetails);
      if (!verifyAllTruthy({ userDetails, engagementDetails })) return;
      let fullName = userDetails["fullName"];
      let nameAndAvatar = [
        userDetails["fullName"] || "",
        userDetails["avatar"],
        teamId,
      ];
      let totalActiveDays = engagementDetails["totalActiveDays"];
      let longestStreak = engagementDetails["consecutiveDays"];
      let currentStreak = engagementDetails["currentStreak"];
      let engagementRate = engagementDetails["engagementRate"];
      let newEngagementData = {
        nameAndAvatar,
        totalActiveDays,
        currentStreak,
        longestStreak,
        engagementRate,
        fullName,
        userId,
      };
      result.push(newEngagementData);
    });
    return result;
  };

  mostPointsByMember = (teamId: string) => {
    let userDetailsByUserId = this.userDetailsByUserId;
    let loyaltyPointsByUserId = this.loyaltyPointsByUserId;
    let result: any[] = [];
    Object.keys(loyaltyPointsByUserId).forEach((userId) => {
      let userDetails = userDetailsByUserId[userId];
      if (!verifyAllTruthy({ userDetails })) return;
      let fullName = userDetails["fullName"] || "";
      let points = loyaltyPointsByUserId[userId] || 0;
      let nameAndAvatar = [fullName, userDetails["avatar"], teamId];
      let newData = {
        nameAndAvatar,
        points,
        fullName,
        userId,
      };
      result.push(newData);
    });
    return result;
  };

  numOrdersByMember = (teamId: string) => {
    let userDetailsByUserId = this.userDetailsByUserId;
    let numOrdersByUser: any = this.seasonlessStats.numOrdersByUser || {};
    let result: any[] = [];
    Object.keys(numOrdersByUser).forEach((userId) => {
      let userDetails = userDetailsByUserId[userId];
      if (!verifyAllTruthy({ userDetails })) return;
      let orders = numOrdersByUser[userId] || 0;
      let fullName = userDetails["fullName"] || "";
      let nameAndAvatar = [fullName, userDetails["avatar"], teamId];
      let newData = {
        nameAndAvatar,
        orders,
        fullName,
        userId,
      };
      result.push(newData);
    });
    return result;
  };

  usersMatchingUserIdList = (userIdList: string[], searchTerm: string) => {
    let userDetailsByUserId = this.userDetailsByUserId;
    let result: any[] = [];
    if (!userIdList) return result;
    userIdList.forEach((userId) => {
      let userDetails = userDetailsByUserId[userId];
      if (!verifyAllTruthy({ userDetails })) return;
      let fullName = userDetails["fullName"] || "";
      if (!fullName.toLowerCase().includes(searchTerm.toLowerCase())) return;
      let newData = {
        fullName,
        userId,
      };
      result.push(newData);
    });
    return result;
  };

  get dailyWeeklyMonthlyOrderPlacersData(): DailyWeeklyMonthlyData[] {
    return [
      {
        daily: this.seasonlessStats.dailyOrderPlacerCounts,
        weekly: this.seasonlessStats.weeklyOrderPlacerCounts,
        monthly: this.seasonlessStats.monthlyOrderPlacerCounts,
      },
    ];
  }

  get ordersByProductType(): any[] {
    console.log(`getting ordersByProductType ${this.seasonId}`);
    if (!this.seasonId || this.seasonId == allOptionValue) {
      return this._ordersByProductType || [];
    } else {
      return this._ordersByProductTypeBySeason[this.seasonId] || [];
    }
  }

  get orderedProducts() {
    let result: any = [];
    if (!this.seasonalStats.orderedProducts) return result;
    this.seasonalStats.orderedProducts.forEach((product) => {
      let name = product["name"];
      let nOrders = product["nOrders"];
      let newData = {
        name,
        nOrders,
      };
      result.push(newData);
    });

    return result;
  }
}
