import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import React from "react";
import { useStore } from "../helpers/use-store";
import { getColorStringFromKey } from "../types/util-types";

interface Props {
  color: string;
  child: any;
}

export const CPThemeProvider: React.FC<Props> = ({ color, child }) => {
  const { session } = useStore().ui;
  let customTheme = createMuiTheme({
    palette: {
      primary: {
        main: getColorStringFromKey(color, session),
        contrastText: "#FFFFFF",
      },
    },
  });
  return <ThemeProvider theme={customTheme}>{child}</ThemeProvider>;
};
