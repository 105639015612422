import React from "react";
import { TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers/use-store";
import { MainLayout } from "../../layouts/MainLayout";
import { PageTitle } from "../../components/PageTitle";
import { gamesRoutes } from "../../app-routes";
import { ButtonLink } from "../../components/ButtonLink";
//import { IntegrationsTable } from "./components/IntegrationsTable";

export const IntegrationsPage = observer(() => {
  const { integrationsPageStore } = useStore().ui;

  React.useEffect(() => {
    integrationsPageStore.loadIntegrations();
  }, [integrationsPageStore]);

  return (
    <MainLayout
      loading={integrationsPageStore.loading}
      firebaseConnector={integrationsPageStore.firebaseConnector}
    >
      <Box height="40rem" width={"100%"}>
        {/* <IntegrationsTable
          integrations={integrationsPageStore.integrations}
        /> */}
      </Box>
    </MainLayout>
  );
});
